import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(0)}That’s right!{" "}
        <Italic>{languageLookup(1)}Harlan’s</Italic>
        {languageLookup(2)}, <Italic>{languageLookup(3)}ships’</Italic>
        {languageLookup(4)}, <Italic>{languageLookup(5)}geese’s</Italic>
        {languageLookup(6)}, and <Italic>{languageLookup(7)}Liam’s</Italic>{" "}
        {languageLookup(8)}are the correct possessive nouns.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(9)}Please select an answer!
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        {languageLookup(10)}Try again! Choose the correct possessive noun for
        each sentence.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
