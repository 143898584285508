import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Solve Word Problems with Dividing a Whole Number by a Unit Fraction
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Brad has 9 pounds of ground turkey to make turkey burgers for a
          picnic.
        </div>
        <div>
          How many <Fraction numerator="1" denominator="3" /> -pound turkey
          burgers can he make?
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/f45407f7bb09ed91dbab221c067f9a24.svg"
            width="60%"
          />
        </Center>
        <div>You can write an equation to solve the problem.</div>
        <Instructions>Click or tap each step.</Instructions>
        <div className={css.row}>
          <FoldBox width="48%">
            <div>
              <Bold>Step 1:</Bold> Write and solve an equation.
            </div>
            <div>
              <div>
                Let <Italic>t</Italic> = the number of turkey burgers Brad can
                make.
              </div>
              <Center>
                <div>
                  <Italic>t</Italic> = 9 ÷{" "}
                  <Fraction numerator="1" denominator="3" />
                </div>
              </Center>
              <Center style={{ marginTop: "10px", marginBottom: "20px" }}>
                <img
                  width="40%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/ccd439be90701006f28ae5ed4f051af4.svg"
                />
              </Center>
              <Center>
                <div>
                  <Italic>t</Italic> = 27
                </div>
              </Center>
              <div>
                There are 3 thirds in 1 whole, so there are 27 thirds in 9
                wholes.
              </div>
              <div>
                Brad can make 27 of the{" "}
                <Fraction numerator="1" denominator="3" /> -pound turkey
                burgers.
              </div>
            </div>
          </FoldBox>
          <FoldBox width="48%">
            <div>
              <Bold>Step 2:</Bold> Check your answer.
            </div>
            <div>
              <div>You can check your work by looking at the model.</div>
              <div>
                There are 9 wholes divided into thirds. Count the thirds. The
                are 27 thirds in all.
              </div>
              <div>You can also check your work with multiplication.</div>
              <div style={{ marginTop: "5px" }}>
                9 ÷ <Fraction numerator="1" denominator="3" /> = 27 because 27 ×{" "}
                <Fraction numerator="1" denominator="3" /> = 9.
              </div>
            </div>
          </FoldBox>
        </div>
        <div style={{ height: "100px" }} />
      </div>
    </div>
  );
};

export default Contents;
