import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            Read the sentences. Look at the bolded words, and then answer the
            question.
          </Bold>
        </div>
        <div>
          Uncle Hugh can stay with us, <Bold>or</Bold> he can <Bold>go</Bold> to
          Yifeng’s house.
        </div>
        <div>
          Enzo hates pears, <Bold>but</Bold> Jamila <Bold>will</Bold> eat them
          when they are ripe.
        </div>
        <div>
          Lana wanted <Bold>to</Bold> go swimming, <Bold>so</Bold> we went to
          the pool.
        </div>
        <div>
          Mom <Bold>is</Bold> picking us up, <Bold>and</Bold> we are going to
          the library.
        </div>
      </div>
    </div>
  );
};

export default Contents;
