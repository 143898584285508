import React from "react";
import css from "./Box.module.css";

const Box = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>{props.title}</div>
      <div className={css.contents}>{props.children}</div>
    </div>
  );
};

export default Box;
