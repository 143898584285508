import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "That's it! From the title, the subheading, and the first sentence, you can see that this text is about all the ways in which polar bears have adapted to their environment.",
    sp: "¡Así es! Gracias al título, puedes ver que este texto trata sobre las diversas maneras en que los osos polares se han adaptado a su ambiente.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Try again. This title hints at the main idea by stating the topic, but the main idea is more fully expressed elsewhere.",
    sp: "¡Inténtalo otra vez! Este título sugiere la idea principal al mencionar el tema, pero la idea principal está expresada de manera más completa en otro lugar.",
  },
  3: {
    en: "Look again. This detail tells you one of the ways polar bears have adapted to their environment. What big idea does this detail support?",
    sp: "Vuelve a mirar. Este detalle expresa una de las maneras en que los osos polares se han adaptado a su ambiente. ¿Qué idea importante apoya este detalle?",
  },
  4: {
    en: (
      <Fragment>
        Look more closely. This detail shows one way polar bears have adapted to
        their environment. Look at the subheading: <Italic>Adaptations</Italic>.
        The main idea should be a broad statement about polar bear adaptations.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Observa con más atención. Este detalle muestra una de las maneras en que
        los osos polares se han adaptado a su ambiente. Observa el subtítulo:{" "}
        <Italic>Adaptaciones</Italic>. La idea principal debe ser un enunciado
        general sobre las adaptaciones de los osos polares.
      </Fragment>
    ),
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.selected[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.selected[2]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.selected[3]) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
