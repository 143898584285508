import React from "react";
import css from "./Profile.module.css";

import MainAvatar from "./Assets/main_avatar.png";
import Avatar from "./Assets/avatar.png";
import Avatar2 from "./Assets/avatar2.png";
import Avatar3 from "./Assets/avatar3.png";

const Profile = (props) => {
  const mouseDownHandler = () => {
    if (props.index != 0) return;

    props.onSelect(props);
  };

  const avatars = [
    MainAvatar,
    Avatar,
    Avatar2,
    Avatar3,
    Avatar,
    Avatar2,
    Avatar,
    MainAvatar,
    Avatar3,
    Avatar,
    MainAvatar,
  ];

  return (
    <div className={css.root} onMouseDown={mouseDownHandler}>
      <div className={css.inner}>
        <div className={css.top}>
          <div className={css.name}>{props.data.name}</div>
          <div className={css.score}>
            <div className={css.title}>Score</div>
            <div className={css.value}>{props.data.score.points.overall}</div>
          </div>
        </div>
        <div className={css.portrait}>
          <img className={css.background}></img>
          <img className={css.avatar} src={avatars[props.index]}></img>
        </div>
        <div className={css.footer}>
          <img
            className={css.icon}
            width="56px"
            height="40px"
            src="https://practice.gogetwaggle.com/student/public/badges/newbie.svg"
          />
          <div className={css.type}>{props.data.type}</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
