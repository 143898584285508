import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import SmallBox from "../Assets/math_2_2_box1.png";
import MediumBox from "../Assets/math_2_2_box10.png";
import LargeBox from "../Assets/math_2_2_box100.png";

const Contents = (props) => {
  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };
  const dropHandler = (ev) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = { items: [...props.data.items] };
    data.items[dragId]++;
    props.setData(data);
  };

  const dragStartHandler = (ev, index) => {
    ev.dataTransfer.setData("text/plain", index);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      const data = { items: [...props.data.items] };
      data.items[index]--;
      props.setData(data);
    }, 10);
  };

  const items = [];

  for (let i = 0; i < props.data.items[0]; i++) {
    items.push(
      <div
        draggable
        onDragStart={(ev) => dragStartHandler(ev, 0)}
        className={css.large}
      >
        <img src={LargeBox} alt="100" key={items.length} />
      </div>
    );
  }
  for (let i = 0; i < props.data.items[1]; i++) {
    items.push(
      <div
        draggable
        onDragStart={(ev) => dragStartHandler(ev, 1)}
        className={css.medium}
      >
        <img src={MediumBox} alt="10" key={items.length} />
      </div>
    );
  }
  for (let i = 0; i < props.data.items[2]; i++) {
    items.push(
      <div
        draggable
        onDragStart={(ev) => dragStartHandler(ev, 2)}
        className={css.small}
      >
        <img src={SmallBox} alt="1" key={items.length} />
      </div>
    );
  }

  return (
    <div className={css.root}>
      <div className={css.header}>548</div>
      <div
        className={css.grid}
        onDragOver={(ev) => dragOverHandler(ev)}
        onDrop={(ev) => dropHandler(ev)}
      >
        {items}
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
