/*
  UI flow for the multiple choice quesitons where the options stay on the screen and might each have a voiceover that
  is used when the item is selected.

  Currently used in the K2 ela 1 lesson.
*/

import { useCallback, useState } from "react";
import { BUTTON_STATES } from "../BottomToolbar";
import { playVoiceover, playVoiceovers } from "../voiceover";

/*
    options looks like:
    {
        img: url,
        vo: "votag",
        correct: false | true
    }
*/

export const useMCFeedback = (options, setFoxState, voiceovers = {}, language = 'en') => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [tries, setTries] = useState(0);
  const [feedbackState, setFeedbackState] = useState(null);

  const [continueState, setContinueState] = useState(BUTTON_STATES.hidden);
  const [checkState, setCheckState] = useState(BUTTON_STATES.disabled);

  const chooseAnswer = useCallback((option) => {
    option.vo && playVoiceover(option.vo, setFoxState);
    setCheckState(BUTTON_STATES.enabled);
    setContinueState(BUTTON_STATES.hidden);
    setFeedbackState(null);
    setSelectedOption(option);
  }, [setFoxState]);

  const onCorrect = useCallback(() => {
    setCheckState(BUTTON_STATES.hidden);
    setContinueState(BUTTON_STATES.enabled);
    setFeedbackState("display");
    playVoiceovers(
      [
        voiceovers.success || "success",
        voiceovers.waytogo || "waytogo",
        voiceovers.correctFeedback || null,
        voiceovers.goonarrow || "go_on_arrow",
      ].filter((vo) => !!vo),
      setFoxState
    );
  }, [setFoxState, voiceovers.correctFeedback, voiceovers.goonarrow, voiceovers.success, voiceovers.waytogo]);

  const onIncorrect = useCallback(() => {
    if (tries >= 2) {
      setSelectedOption(options.find((o) => o.correct));
      playVoiceovers([`ks_l0_math_vo_${language}`, "go_on_arrow"], setFoxState);
      setCheckState(BUTTON_STATES.hidden);
      setFeedbackState("display");
      setContinueState(BUTTON_STATES.enabled);
      return;
    }
    setTries(tries + 1);
    playVoiceovers(["error", selectedOption.feedback], setFoxState);
    setFeedbackState("display");
    setCheckState(BUTTON_STATES.disabled);
    setContinueState(BUTTON_STATES.hidden);
  }, [tries, selectedOption, setFoxState, options, language]);

  const onCheck = useCallback(() => {
    if (selectedOption?.correct) {
      onCorrect();
    } else {
      onIncorrect();
    }
  }, [onCorrect, onIncorrect, selectedOption?.correct]);

  return {
    selectedOption,
    onCheck,
    chooseAnswer,
    feedbackState,
    continueState,
    checkState,
  };
};
