import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Decimal by a Whole Number
        </span>
      </div>
      <div className={css.contents}>
        <div>Divide 3.75 ÷ 3.</div>
        <Instructions>
          Click or tap each step to see a visual model for the division.
        </Instructions>

        <FoldBox width="70%">
          <div>
            <Bold>Step 1:</Bold> Shade decimal grids to show 3.75.
          </div>
          <img
            width="100%"
            src="https://d16qzots3do3wk.cloudfront.net/images/bf8dad50b8ba5ae8634089e40413bdf0.svg"
          />
        </FoldBox>
        <FoldBox width="70%">
          <div>
            <Bold>Step 2:</Bold> Separate the shaded model into 3 equal groups.
          </div>
          <img
            width="100%"
            src="https://d16qzots3do3wk.cloudfront.net/images/f8bd597010c086eec5803124349cf5ff.svg"
          />
        </FoldBox>
        <FoldBox width="70%">
          <div>
            <Bold>Step 3:</Bold> Find the number each group shows.
          </div>
          <div>
            <img
              width="100%"
              src="https://d16qzots3do3wk.cloudfront.net/images/f8bd597010c086eec5803124349cf5ff.svg"
            />
            <div>Each group shows 1 whole and 25 hundredths or 1.25.</div>
            <div>
              So, 3.75 ÷ 3 = <Bold>1.25</Bold>.
            </div>
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
