import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Italic from "../../../../Italic";
import Fraction from "../../../../Fraction";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>Which equation represents the line?</div>
      <Instructions>Click or tap the correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>
            <Italic>y</Italic> = −
            <Fraction inline numerator="5" denominator="2" />
            <Italic>x</Italic>
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>
            <Italic>y</Italic> = −
            <Fraction inline numerator="2" denominator="5" />
            <Italic>x</Italic>
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>
            <Italic>y</Italic> ={" "}
            <Fraction inline numerator="2" denominator="5" />
            <Italic>x</Italic>
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>
            <Italic>y</Italic> ={" "}
            <Fraction inline numerator="5" denominator="2" />
            <Italic>x</Italic>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
