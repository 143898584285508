import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import video from "../../assets/ela_1.mp4";
import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playSoundEffect,
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../useMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { PromptBox } from "../PromptBox";

import shed from "../../assets/art/shed.png";
import train from "../../assets/art/train.png";
import shorts from "../../assets/art/shorts.png";
import vacuum from "../../assets/art/vacuum.png";
import camera from "../../assets/art/camera.png";

import { useRouteMatch } from "react-router-dom";
import { useMultiMCFeedback } from "../useMultiMCFeedback";
import { MultiPromptBox } from "../MultiPromptBox";

const options = [
  {
    text: "shed",
    vo: "s5_opt1_GK_078B2_ela_vo_en",
    correct: true,
    img: shed,
  },
  {
    text: "train",
    vo: "s5_opt2_GK_078B2_ela_vo_en",
    feedback: "",
    correct: false,
    img: train,
  },
  {
    text: "shorts",
    vo: "s5_opt3_GK_078B2_ela_vo_en",
    feedback: "",
    correct: true,
    img: shorts,
  },
  {
    text: "vacuum",
    vo: "s5_opt4_GK_078B2_ela_vo_en",
    feedback: "",
    correct: false,
    img: vacuum,
  },
  {
    text: "camera",
    vo: "s5_opt5_GK_078B2_ela_vo_en",
    feedback: "",
    correct: false,
    img: camera,
  },
];

export const Screen6 = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const onNextScreen = () => history.push("7");
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    feedbackState,
    selectedOptions,
    onCheck,
    chooseAnswer,
    continueState,
    checkState,
  } = useMultiMCFeedback(options, setFoxState, {
    incorrect: "s5_error1_GK_078B2_ela_vo_en",
    correct: "s5_successAnswer_GK_078B2_ela_vo_en",
    showAnswer: "s5_ks_GK_078B2_ela_vo_en",
  });

  const { getHint, playVideo, closeVideo } = useHints(
    ["s5_hint2_GK_078B_ela_vo_en"],
    setFoxState
  );

  const playInstruction = useCallback(
    () => playVoiceover("s5_instruction_GK_078B_ela_vo_en", setFoxState),
    [setFoxState]
  );

  const playTargetSound = useCallback(() => {
    // The Screen 2 vo was wrong
    playSoundEffect("s1_tapButton_GK_078A_ela_vo_en");
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <MultiPromptBox
        state={feedbackState}
        selectedOptions={selectedOptions}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
