import React from "react";
import css from "./Logout.module.css";

const Logout = (props) => {
  return (
    <div className={css.logout}>
      {props.language == "sp" ? "SALIR" : "LOGOUT"}
    </div>
  );
};

export default Logout;
