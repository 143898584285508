import * as React from "react"

function PreviousIcon(props) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={24} cy={24} r={24} fill="#C5D3E2" />
            <path
                d="M26.605 35.871l-11.48-11.476a.626.626 0 010-.752l11.48-11.477a.504.504 0 01.746 0l3.48 3.548a.458.458 0 010 .713l-7.667 7.554 7.626 7.679a.626.626 0 010 .751l-3.522 3.505a.51.51 0 00-.145.042.44.44 0 01-.373 0 .428.428 0 01-.145-.087z"
                fill="#9CAAB9"
            />
        </svg>
    )
}

export default PreviousIcon
