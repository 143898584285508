import React, { useState } from "react";
import css from "./Contents.module.css";

import Shapes from "../Assets/math_2_1_shapes.png";
import Reset from "../../../../Icons/Reset";

import IconImg from "../Assets/math_2_1_icon.JPG";

import Icon from "../Components/Icon";

const Contents = (props) => {
  const selectIconHandler = (index) => {
    const data = { icons: [...props.data.icons] };
    data.icons[index] = !data.icons[index];

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>Books Read</div>

      <div className={css.grid}>
        <div className={css.text}>Duke</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon} />

        <div className={css.text}>Felix</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon} />
        <div className={css.icon} />
        <div className={css.icon} />

        <div className={css.text}>Sara</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>

        <div className={css.text}>Miley</div>
        <div className={css.icon}>
          <Icon
            index={0}
            selected={props.data.icons[0]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={1}
            selected={props.data.icons[1]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={2}
            selected={props.data.icons[2]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={3}
            selected={props.data.icons[3]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={4}
            selected={props.data.icons[4]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={5}
            selected={props.data.icons[5]}
            onSelect={selectIconHandler}
          />
        </div>
      </div>

      <div className={css.bottom}>
        <div className={css.key}>Key:</div>
        <div className={css.text}>Each</div>
        <div className={css.image}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.text}>= 2 shape</div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
