import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: `That's right! The title "Rediscovering an Old Art," the second sentence, and the last sentence all suggest the following main idea—that quilting is becoming popular again.`,
    sp: `¡Correcto! El título "Redescubrir un arte antiguo", la segunda oración y la última oración sugieren la siguiente idea principal: que hacer colchas de retazos se está poniendo de moda nuevamente.`,
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: `Try again. Quilting is described as an "old art," but this is not the implied main idea of the entire passage. Look again at the title and the supporting details. What do they suggest about the topic of quilting?`,
    sp: "Inténtalo otra vez. Hacer colchas de retazos se describe como un “arte antiguo”, pero esta no es la idea principal implícita del fragmento entero. Vuelve a mirar el título y los detalles de apoyo. ¿Qué sugieren sobre el tema de hacer colchas de este tipo?",
  },
  3: {
    en: `Look more closely. The title "Rediscovering an Old Art," the second sentence, and the last sentence all suggest the following main idea—that quilting is becoming popular again.`,
    sp: `Observa con más atención. El título "Redescubrir un arte antiguo", la segunda oración y la última oración sugieren la siguiente idea principal: que hacer colchas de retazos se está poniendo de moda nuevamente.`,
  },
  4: {
    en: "Look again. The passage does state that making quilts is time-consuming, but this isn't the implied main idea.",
    sp: `Vuelve a mirar. El fragmento dice que hacer colchas de retazos lleva mucho tiempo, pero no es la idea principal implícita.`,
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.selected[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.selected[2]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.selected[3]) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
