import React from "react";

export default (data, setData) => {};

export const correctAnswers = [true, true, true, true, false, false];

export const getDefaultState = () => {
  return {
    icons: [false, false, false, false, false, false],
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.icons.filter((x) => x == true).length == 0) return "empty";
  else if (
    data.icons[0] == correctAnswers[0] &&
    data.icons[1] == correctAnswers[1] &&
    data.icons[2] == correctAnswers[2] &&
    data.icons[3] == correctAnswers[3] &&
    data.icons[4] == correctAnswers[4] &&
    data.icons[5] == correctAnswers[5]
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.icons.filter((x) => x > -1).length > 0;
};

export const getTitle = (data, setData) => {
  if (data.icons.filter((x) => x == true).length == 0) return "";

  if (
    data.icons[0] == correctAnswers[0] &&
    data.icons[1] == correctAnswers[1] &&
    data.icons[2] == correctAnswers[2] &&
    data.icons[3] == correctAnswers[3] &&
    data.icons[4] == correctAnswers[4] &&
    data.icons[5] == correctAnswers[5]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    <div>Which student has no symbols on the picture graph?</div>
    <div>How many books did that student read?</div>
  </div>,
  "Look at the key. How many books does each symbol represent on this graph?",
  <div>
    <div>Skip count by 2's until you reach the number of books Miley read.</div>
    <div>
      This is the number of symbols you need to add to the picture graph.
    </div>
  </div>,
];

export const showReader = () => {
  return false;
};
