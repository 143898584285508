import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You have used context clues to correctly distinguish between{" "}
        <Italic>amicable</Italic>, or friendly, relationships and{" "}
        <Italic>hostile</Italic>, or unfriendly, ones.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 0).length > 0) {
    contents = (
      <div className={css.text}>
        Reread the first two sentences of paragraph 7. According to these
        sentences, how can you classify the fights that happened over land,
        food, and supplies? Try again.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 1).length > 0) {
    contents = (
      <div className={css.text}>
        Reread the first two sentences of paragraph 8. According to these
        sentences, how can you classify Powhatan ordering Smith to be killed?
        Try again.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 2).length > 0) {
    contents = (
      <div className={css.text}>
        These events are examples of an <Italic>amicable</Italic>, or friendly,
        relationship: Pocahontas did become friends with Smith. She visited him
        at Jamestown, bringing messages from her father and going with other
        Algonquians to trade with the colonists. For several years, they enjoyed
        friendly commerce and trade. These events are examples of a{" "}
        <Italic>hostile</Italic>, or unfriendly, relationship: Instead, they
        became enemies, fighting over food, land, and supplies. Powhatan became
        angry and ordered that Captain Smith be killed. He demanded that her
        father release English prisoners and return stolen weapons and tools.
        Drag each event to the correct bin to move on to the next question.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 3).length > 0) {
    contents = (
      <div className={css.text}>
        These events are examples of an <Italic>amicable</Italic>, or friendly,
        relationship: Pocahontas did become friends with Smith. She visited him
        at Jamestown, bringing messages from her father and going with other
        Algonquians to trade with the colonists. For several years, they enjoyed
        friendly commerce and trade. These events are examples of a{" "}
        <Italic>hostile</Italic>, or unfriendly, relationship: Instead, they
        became enemies, fighting over food, land, and supplies. Powhatan became
        angry and ordered that Captain Smith be killed. He demanded that her
        father release English prisoners and return stolen weapons and tools.
        Drag each event to the correct bin to move on to the next question.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 4).length > 0) {
    contents = (
      <div className={css.text}>
        In the sentence “He demanded that her father release English prisoners
        and return stolen weapons and tools,” words such as{" "}
        <Italic>demanded</Italic>, <Italic>prisoners</Italic>, and{" "}
        <Italic>stolen</Italic> suggest conflict and war. Is this relationship{" "}
        <Italic>amicable</Italic> or <Italic>hostile</Italic>? Try again.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 2).length > 0) {
    contents = (
      <div className={css.text}>
        The friendship between Pocahontas and Smith is described in paragraph 6.
        According to the next paragraph, however, “The relationship between the
        colonists and the Algonquians did not remain <Italic>amicable</Italic>.”
        Based on this context clue, can you classify friendship as{" "}
        <Italic>amicable</Italic> or <Italic>hostile</Italic>? Try again.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 3).length > 0) {
    contents = (
      <div className={css.text}>
        Paragraph 6 describes Pocahontas visiting Smith and bringing him
        messages as an example of their friendship. In contrast, the next
        paragraph begins, “The relationship between the colonists and the
        Algonquians did not remain <Italic>amicable</Italic>.” Based on this
        context clue, can you classify Pocahontas’s actions as{" "}
        <Italic>amicable</Italic> or <Italic>hostile</Italic>? Try again.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 5).length > 0) {
    contents = (
      <div className={css.text}>
        Paragraph 10 describes the commerce and trade between the English and
        the Algonquians as “friendly.” Are friendly relationships{" "}
        <Italic>amicable</Italic> or <Italic>hostile</Italic>? Try again.
      </div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 2 || x.id == 3 || x.id == 5)
      .length > 0 ||
    props.data.items[1].filter((x) => x.id == 0 || x.id == 1 || x.id == 4)
      .length > 0
  ) {
    contents = (
      <div className={css.text}>
        These events are examples of an <Italic>amicable</Italic>, or friendly,
        relationship: Pocahontas did become friends with Smith. She visited him
        at Jamestown, bringing messages from her father and going with other
        Algonquians to trade with the colonists. For several years, they enjoyed
        friendly commerce and trade. These events are examples of a{" "}
        <Italic>hostile</Italic>, or unfriendly, relationship: Instead, they
        became enemies, fighting over food, land, and supplies. Powhatan became
        angry and ordered that Captain Smith be killed. He demanded that her
        father release English prisoners and return stolen weapons and tools.
        Drag each event to the correct bin to move on to the next question.
      </div>
    );
  } else {
    contents = <div className={css.text}></div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
