import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";

const lines = {
  title: {
    en: "Use Tiling to Find the Area of a Rectangle",
    sp: "Utilizar fichas cuadradas para hallar el área de un rectángulo",
  },
  0: {
    en: "Christina has a garden that is shaped like the rectangle shown.",
    sp: "El jardín de Christina tiene la forma del rectángulo que se muestra a continuación.",
  },
  1: {
    en: "Each unit square shows 1 square meter.",
    sp: "Cada cuadrado de una unidad muestra 1 metro cuadrado.",
  },
  2: {
    en: "What is the area of Christina’s garden?",
    sp: "¿Cuál es el área del jardín de Christina?",
  },
  3: {
    en: "You can count the number of unit squares to find the area of the rectangle.",
    sp: "Puedes contar el número de cuadrados de una unidad para hallar el área del rectángulo.",
  },
  4: {
    en: "There are 18 unit squares.",
    sp: "Hay 18 cuadrados de una unidad.",
  },
  5: {
    en: "So, the area of the garden is 18 square meters.",
    sp: "Entonces, el área del jardín mide 18 metros cuadrados.",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>{languageLookup(0)}</div>
          <div>{languageLookup(1)}</div>
          <div>{languageLookup(2)}</div>
        </div>
        <div>{languageLookup(3)}</div>
        <img
          width="286px"
          src="https://d16qzots3do3wk.cloudfront.net/images/238483fa0986a740979c6eed3875b0c2.svg"
        />
        <div>{languageLookup(4)}</div>
        <div>{languageLookup(5)}</div>
      </div>
    </div>
  );
};

export default Contents;
