import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};

export const correctAnswers = { indices: [1, 3, 1] };

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  return (
    data.indices[0] == correctAnswers.indices[0] &&
    data.indices[1] == correctAnswers.indices[1] &&
    data.indices[2] == correctAnswers.indices[2]
  );
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Figurative language has a meaning that is different from the literal, or
    dictionary, meaning of each word or phrase. Think of what the poet is really
    trying to say with each example.
  </div>,
  <div>
    Reread the poem, and think about what it is really about. Understanding the
    poem as a whole will help you to figure out what each example actually
    means.
  </div>,
  <div>
    There are several references to sailing in the poem, but is the speaker
    really on a boat? Think about what the poet is really describing. This will
    help you to figure out what each reference to sailing really means.
  </div>,
];

export const showReader = () => {
  return true;
};
