import React from "react";
import Fraction from "../../../../Fraction";

export default (data, setData) => {};

export const correctAnswers = [false, true, false, false];

export const isCorrect = (data) => {
  return (
    data.selections[0] == correctAnswers[0] &&
    data.selections[1] == correctAnswers[1] &&
    data.selections[2] == correctAnswers[2] &&
    data.selections[3] == correctAnswers[3]
  );
};

export const isEmpty = (data) => {
  return data.selections.filter((x) => x == true).length == 0;
};

export const getDefaultState = () => {
  return {
    selections: [],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    First, make your own prediction and explanation. Then see if one of the
    students’ explanations matches yours.
  </div>,
  <div>
    <Fraction numerator="9" denominator="2" /> is an improper fraction that is
    equivalent to <Fraction integer="4" numerator="1" denominator="2" />.
  </div>,
  <div>
    When a number is multiplied by a fraction less than 1, the product is less
    than the number. When a number is multiplied by a fraction greater than 1,
    the product is greater than the number.
  </div>,
  <div>
    Remember, a fraction with a numerator less than the denominator is less than
    1. A fraction with a numerator greater than the denominator is greater than
    1. A fraction with a numerator equal to the denominator is equal to 1.
  </div>,
];

export const showReader = () => {
  return false;
};
