import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That’s it! <Italic>So</Italic>, <Italic>but</Italic>,{" "}
        <Italic>and</Italic>, and <Italic>or</Italic> are the correct
        coordinating conjunctions.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Try again! Which coordinating conjunction BEST fits each sentence?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
