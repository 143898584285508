import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [3, 7, 6, 1, 0, 5, -1, -1, -1];

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1, -1, -1, -1, -1, -1, -1],
    showLeft: [false, false, false, false, false, false, false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    showRight: [true, true, true, true, true, true, true, true, true],
  };
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.left.length; i++) {
    if (data.left[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Find the words in the passage, and use context clues to help you determine
    each word’s meaning.
  </div>,
  <div>
    Context clues are words or phrases located near an unfamiliar word that can
    help you determine the meaning of the unknown word. Context clues sometimes
    describe or provide examples of the unknown term.
  </div>,
  <div>
    For some words, determining the root of the word and recognizing any
    prefixes and suffixes attached to it can help you. For example, the root of
    gelatinous is gelatin, and it has the suffix -ous. Does identifying those
    parts give you clues to the word's meaning?
  </div>,
];

export const showReader = () => {
  return true;
};
