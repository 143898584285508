import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use Context Clues to Understand Domain-Specific Language
        </span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          <Bold>
            Read the passage. Pay attention to the bolded words, and look for
            context clues. Then answer the question.
          </Bold>
        </div>
        <div className={css.title}>The Probability of a Shared Birthday</div>
        <div>
          The human brain does many impressive things, but sometimes it can be
          fooled. Imagine there’s a classroom with thirty students. What do you
          think the chances are that any two students in the room share the same
          birthday? Do you think it is likely or unlikely?
        </div>
        <div>
          To answer these questions, an area of mathematics known as{" "}
          <Bold>probability</Bold> can help. This field of study explores the
          chance that an event will happen—for example, the likelihood of tails
          coming up when a coin is flipped, or the chance of it snowing at least
          once during the winter in Maine. To figure out the probability of
          something happening, you need to consider all the possible{" "}
          <Bold>outcomes</Bold> of a given situation. By thinking about all the
          possible ways things may turn out, you can determine likelihood.
        </div>
        <div>
          In the shared birthday problem, since there are 365 days in a year,
          the chances that two random people share a birthday is 2/365, or less
          than 1%. Extremely unlikely! However, as more and more people walk
          into the room, the odds that any two people share a birthday increase.
          So, if there are a total of 30 students in the room, the chance that
          any two sharing a birthday is about 70%, which is likely. (And if
          there were 57 people, it would be 99%!)
        </div>
      </div>
    </div>
  );
};

export default Contents;
