import React, { useState } from "react";
import css from "./Contents.module.css";

import Highlight from "../../../../Highlight";

import Reset from "../../../../Icons/Reset";

const Contents = (props) => {
  const selectHandler = (index) => {
    const data = { highlights: [...props.data.highlights] };
    data.highlights[index] = !data.highlights[index];

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[0]}
          index={0}
        >
          As they walked along, Jahi looked at the donkey.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[1]}
          index={1}
        >
          Then he laughed to himself.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[2]}
          index={2}
        >
          He thought he knew a way to stop the donkey from losing salt in the
          water.
        </Highlight>
      </div>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[3]}
          index={3}
        >
          When they came to the wide, calm seashore, Jahi picked up sponges from
          the seashore instead of collecting salt.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[4]}
          index={4}
        >
          He filled the donkey's bags with the sponges.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[5]}
          index={5}
        >
          Jahi knew this meant he wouldn't have a load of salt to sell at the
          market.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[6]}
          index={6}
        >
          However, at the moment, he believed teaching the donkey a lesson was
          more important.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[7]}
          index={7}
        >
          After the sponges were loaded, they started for home.
        </Highlight>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
