import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>Tío Santiago bought a new bike with white tires.</li>
          <li>Sarah knows how to use garden shears.</li>
          <li>Cora swims, but she hates to dive.</li>
          <li>Ian has a sore leg, so his father took him to the doctor.</li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
