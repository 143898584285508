import React from "react";
import css from "./Shape.module.css";

const texts = [3, 6, 12, "6π", "9π", "12π", "24π", "36π", "144π"];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let text = texts[props.index];

  return (
    <div
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
      className={css.root}
      style={style}
    >
      <div className={css.contents}>{text}</div>
    </div>
  );
};

export default Shape;
