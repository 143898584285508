import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          <Bold>
            Read the sentences. Choose the word that BEST completes the sentence
            in a precise way.
          </Bold>
        </div>
        <Instructions>
          Click or tap the correct word from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>Linh’s</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "cottage" }, { text: "home" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>is located near the pine woods.</span>
        </div>
        <div className={css.row}>
          <span>Our classroom has a</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "cactus" }, { text: "plant" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>on the windowsill.</span>
        </div>
        <div className={css.row}>
          <span>Help yourself to the</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "food" }, { text: "tacos" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>on the table.</span>
        </div>
        <div className={css.row}>
          <span>The frog hopped into the</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[3] - 1}
              selectIndex={(index) => selectIndexHandler(3, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "pond" }, { text: "water" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>in my backyard.</span>
        </div>
        <div style={{ height: "75px" }} />
      </div>
    </div>
  );
};

export default Contents;
