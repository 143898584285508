import React from "react";
import Italic from "../../../../../Italic";
import css from "./Shape.module.css";

const contents = [
  <div>
    Instead, they became enemies, fighting over food, land, and supplies.
  </div>,
  <div>Powhatan became angry and ordered that Smith be killed.</div>,
  <div>Pocahontas did become friends with Smith.</div>,
  <div>
    She visited him at Jamestown, bringing messages from her father and going
    with other Algonquians to trade with the colonists.
  </div>,
  <div>
    He demanded that her father release English prisoners and return stolen
    weapons and tools.
  </div>,
  <div>For several years, they enjoyed friendly commerce and trade.</div>,
];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
    justifyContent: props.center ? "center" : null,
    textAlign: props.center ? "center" : null,
    color: props.blue ? "#1588c9" : null,
    height: props.height || null,
    width: props.width || null,
    ...props.style,
  };

  let content = contents[props.index];
  return (
    <div className={css.root} style={style}>
      <div
        className={css.contents}
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        style={props.innerStyle}
      >
        {content}
      </div>
    </div>
  );
};

export default Shape;
