import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Center from "../../../../Center";
import Bold from "../../../../Bold";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Connotation</span>
      </div>
      <div className={css.contents}>
        <div>
          Many words and phrases also have <Bold>connotations</Bold>, or
          meanings that go beyond the dictionary definitions. Connotations
          communicate the ideas or feelings associated with a particular word or
          phrase. Writers often use connotations to convey positive, negative,
          or neutral feelings.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/d0d43d4f7f9b527120757fab563810e2.webp"
            width="70%"
          />
        </Center>
        <div>
          For example, the student is now asleep. You could say he took a
          break—or he slacked off. <Italic>Took a break</Italic> sounds more
          positive than <Italic>slacked off</Italic>.
        </div>
      </div>
    </div>
  );
};

export default Contents;
