import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Compound Sentences</span>
      </div>
      <div className={css.contents}>
        <span>In this lesson, you will ...</span>
        <BulletPoints>
          <li>build sentences from two complete thoughts</li>
          <li>
            use coordinating conjunctions <Italic>and</Italic>,{" "}
            <Italic>but</Italic>, <Italic>or</Italic>, and so with a comma to
            avoid run-on sentences
          </li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
