import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_7_0_reader.JPG";
import ImageB from "../Assets/ela_7_0_readerb.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>GPS in All Cars</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        A GPS (global positioning system) device is incredibly useful and
        convenient. It can tell you where you are, how to get where you're
        going, and even where to stop for snacks. So sit back, relax, and the
        let GPS do the work of planning your next trip.
      </Paragraph>
      <div className={css.subtitle}>Look to the GPS</div>
      <Paragraph index="2">
        Have you ever been in a car with family or friends and gotten horribly
        lost? Maybe you are going somewhere new and your driver isn't familiar
        with the streets? One minute, everyone is having a wonderful time,
        excited for the adventure that lies ahead. The next minute, the driver
        has taken a wrong turn and has no idea where you are. The excitement
        evaporates and is replaced with ... panic.
      </Paragraph>
      <Paragraph index="3">
        Getting lost can be stressful, so why would you ever put yourself in
        that position? All cars should come with GPS. GPS is a device that uses
        signals from satellites to determine your position on the globe. It can
        tell you where you are and give you step-by-step directions to where you
        want to go. A GPS keeps you from getting lost. If you are going
        somewhere new and don't know how to get there, the GPS will guide you.
      </Paragraph>
      <Paragraph index="4">
        Having a GPS in every car doesn't just prevent people from getting lost;
        it also improves safety. With a GPS, drivers aren't distracted by
        looking at maps. Instead of trying to figure out how to get somewhere,
        drivers can focus on staying safe. Distracted drivers cause thousands of
        accidents each year; but with GPS technology, drivers can keep their
        attention on the road.
      </Paragraph>
      <Paragraph index="5">
        GPS devices have other features designed to help drivers. They can alert
        the driver to upcoming traffic and road closures, and even change the
        route to avoid traffic. The GPS can also show the locations of the
        nearest gas stations or rest stops. This is convenient if you're on the
        road and need to take a break, fill up your car with gas, or buy a
        snack. Many businesses rely on GPS to help drivers verify locations,
        estimate arrival times, and communicate with their colleagues.
      </Paragraph>
      <Paragraph index="6">
        GPS devices are not only useful, but they're also easy to use, and they
        don't take up much space. Some newer cars come equipped with a GPS built
        into the car's dashboard. To get somewhere, all you have to do is input
        where you want to go. The GPS will determine the best route, and an
        automated voice will tell you detailed, turn-by-turn directions. So, not
        only does the GPS find a route for you, but also it literally tells you
        where to go.
      </Paragraph>
      <Paragraph index="7">
        GPS devices also save money. Insurance companies are starting to lower
        car insurance rates for drivers who use a GPS. In addition, drivers
        spend less money on gas when they follow the guidance of the GPS and
        take the most direct route without getting lost. Cars might stop and go,
        but GPS devices are what get you there. The benefits of GPS devices are
        so great that all cars should come with them built in. These are
        technologies that were never available to people before, so why wouldn't
        we take advantage of them?
      </Paragraph>
      <div className={css.line} />
      <div className={css.title}>Don't Rely on the GPS</div>
      <div className={css.image}>
        <img src={ImageB} alt="image" />
      </div>
      <div className={css.subtitle}>Why You Should Learn Where You Are</div>
      <Paragraph index="1">
        You always hear stories about how wonderful GPS devices are when they
        help people who are lost. Why don't you hear stories about what happens
        when GPS fails? In the world of navigation technology, "recalculating"
        is a dreaded word. Most people have probably experienced a time when
        they used a GPS and all of a sudden it stopped giving directions. For
        many drivers, this experience can be stressful or even frightening.
        Since these drivers depend on their GPS and aren't prepared to navigate
        for themselves, they won't be able to get to where they are trying to
        go.
      </Paragraph>
      <Paragraph index="2">
        GPS may have some useful features, but that doesn't mean drivers should
        rely on it entirely. A computer should never be used to replace good
        common sense. Driving isn't just about pressing the gas pedal and
        brakes; it's also about thinking and planning. It's the driver's
        responsibility to know where he or she is going before stepping into the
        car. Otherwise, a dangerous situation might arise if the GPS stops
        working and the driver doesn't know where to go.
      </Paragraph>
      <Paragraph index="3">
        Why would the GPS stop working? The information it receives depends on
        satellite technology, and sometimes a car can be in a location without
        clear reception of a satellite signal. Tall buildings can block the
        satellite signal, or the signal can have trouble reaching a remote
        location, and either situation can cause the GPS to fail. In the time
        that it takes for the GPS to find the signal, determine your location,
        and recalculate your route, you might have taken several wrong turns.
        You might become worried or anxious, which could increase the
        probability of a car accident. GPS devices can also stop working if they
        rely on batteries. Batteries can drain quickly if the system is not
        plugged into the car or powered correctly. If the battery drains and the
        GPS stops working, the driver might not know where to go.
      </Paragraph>
      <Paragraph index="4">
        Even when the GPS is "working," it's not always as useful as some people
        might assume. There are dangers involved in relying on a device that can
        give little warning about when to make a turn. This is especially true
        in heavy traffic, when it can be difficult or dangerous to change lanes
        quickly. Additionally, not all models of GPS devices are regularly
        updated. Older models may not be updated with information about changes
        to roads or road closures; the GPS might direct you to roads that are
        closed or miss new roads that could have been more useful.
      </Paragraph>
      <Paragraph index="5">
        Some people argue that GPS is safer and saves money, but there are two
        sides to every story. High quality GPS devices can be expensive, and
        lower-priced ones don't always have the same features, like traffic
        information and updated maps. A GPS might be useful sometimes, but
        drivers shouldn't depend on one. Drivers should always have a map as a
        backup plan, and drivers should always review the route they plan to
        take before they start driving. After all, machines don't drive
        cars-people do. Common sense can go a long way in getting you where you
        need to go.
      </Paragraph>
    </div>
  );
};

export default Reader;
