import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Sentence Length</span>
      </div>
      <div className={css.contents}>
        <div>
          Writers can vary sentences by <Italic>length</Italic>.
        </div>
        <div>
          Longer sentences can be used to show connections between ideas.
          Shorter sentences can be used to clearly illustrate a point and can
          help move ideas along.
        </div>
        <div>
          To <Bold>lengthen</Bold> short sentences, combine sentences that have
          similar structures and meanings.
        </div>
        <div>
          For example, simple sentences can often be combined to form a compound
          or a complex sentence.
        </div>
        <BlockQuote>Put on your mittens. It is very cold outside.</BlockQuote>
        <BlockQuote>
          Put on your mittens because it is very cold outside.
        </BlockQuote>
        <div>
          To <Bold>shorten</Bold> long sentences, break them apart. Make them
          smaller and more concise.
        </div>
        <div>
          For example, a compound-complex sentence can be broken into a simple
          sentence and a complex sentence.
        </div>
        <BlockQuote>
          Kamal forgot his cousin’s birthday, so he sent him a card when he
          finally remembered.
        </BlockQuote>
        <BlockQuote>
          Kamal forgot his cousin’s birthday. He sent him a card when he finally
          remembered.
        </BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
