import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Vary Sentences for Effect</span>
      </div>
      <div className={css.contents}>
        <span>In this lesson, you will ...</span>
        <BulletPoints>
          <li>
            discover different kinds of sentences and explore how each one is
            used
          </li>
          <li>
            learn how to make your writing more interesting by varying sentence
            types
          </li>
        </BulletPoints>
        <div>
          Good writers don’t repeat the same kinds of sentences over and over.
          They use a variety of sentence types to make their writing engaging.
        </div>
      </div>
    </div>
  );
};

export default Contents;
