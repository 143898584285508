import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Decimal by a Decimal
        </span>
      </div>
      <div className={css.contents}>
        <div>You can use models to write division equations with decimals.</div>
        <img
          width="95%"
          src="https://d16qzots3do3wk.cloudfront.net/images/d453a308bc0ee8ce9a0d826982d92c11.svg"
        />
        <div>
          The shaded grids show the number <Bold>6.39</Bold>.
        </div>
        <div>
          Each group shows <Bold>2.13</Bold>.
        </div>
        <div>
          The model shows <Bold>3</Bold> groups of 2.13.
        </div>
        <div>So, we know 6.39 ÷ 2.13 = 3</div>
      </div>
    </div>
  );
};

export default Contents;
