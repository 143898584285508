import React from "react";

import css from "./Button.module.css";

const Button = (props) => {
  return (
    <div className={css.button} {...props}>
      {props.children}
    </div>
  );
};

export default Button;
