import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [true, false, true, false];

export const getAnswers = () => {
  return { selected: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    selected: [false, false, false, false],
  };
};

export const isEmpty = (data) => {
  return data.selected.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.selected.length; i++) {
    if (data.selected[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    To make a singular possessive noun, add an apostrophe and{" "}
    <Italic>-s</Italic>.
  </div>,
  <div>
    To make a plural possessive noun, add an apostrophe after the{" "}
    <Italic>-s</Italic>. For plural nouns that do not end in <Italic>-s</Italic>
    , add an apostrophe and <Italic>-s</Italic>.
  </div>,
  <div>
    For contractions with <Italic>not</Italic>, replace the letter{" "}
    <Italic>o</Italic> with an apostrophe.
  </div>,
  <div>
    Pronouns and verbs can be combined into contractions. An apostrophe replaces
    letters in the verb.
  </div>,
];

export const showReader = () => {
  return false;
};
