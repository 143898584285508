import React from "react";
import css from "./Footer.module.css";
import Tools from "./Tools";
import Hints from "./Hints";
import Text from "./Text";

import Controller from "../Activity/Controller";

const Footer = (props) => {
  const selectHandler = (index) => {
    props.onSelectHint(index);
    props.onShowFeedback(false);
  };

  const showFeedbackHandler = () => {
    props.onShowFeedback(true);
    props.onSelectHint(-1);
  };

  const doneHandler = () => {
    props.onDone(true);
    props.onSelectHint(-1);
  };

  const hints = Controller.GetHints(props.id);

  const tool = props.text ? (
    <Text onClick={() => props.setShowReader(true)} />
  ) : (
    <Tools />
  );

  return (
    <div className={css.root}>
      {tool}
      <div className={css.divider} />
      <Hints hints={hints} index={props.hint} onSelect={selectHandler} />
      <div
        className={css.button}
        onMouseDown={props.correct ? doneHandler : showFeedbackHandler}
      >
        {props.correct ? (props.index == 2 ? "Finished" : "Next") : "Done"}
      </div>
    </div>
  );
};

export default Footer;
