import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import InputField from "../../../../InputField";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const valueHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Understand Subtraction of Fractions</span>
      </div>
      <div className={css.contents}>
        <div>
          What is the missing number that makes the subtraction sentence true?
        </div>
        <div>
          <Fraction width="25px" numerator="10" denominator="100" /> −{" "}
          <Fraction width="25px" numerator="8" denominator="100" /> ={" "}
          <Fraction width="25px" numerator="?" denominator="100" />
        </div>
        <Instructions>Type the correct answer into the blank. </Instructions>
        <div style={{ marginLeft: "10px" }}>
          <span>? = </span>
          <InputField
            language={props.language}
            value={props.data.inputs[0]}
            onChange={(ev) => valueHandler(0, ev)}
            width="175px"
          ></InputField>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
