import React from "react";
import css from "./Shape.module.css";

import ShapeF from "../../Assets/math_4_2_f.png";
import ShapeG from "../../Assets/math_4_2_g.png";
import ShapeH from "../../Assets/math_4_2_h.png";
import ShapeI from "../../Assets/math_4_2_i.png";
import ShapeJ from "../../Assets/math_4_2_j.png";
import ShapeK from "../../Assets/math_4_2_k.png";

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let img;

  switch (props.index) {
    case 0:
      img = ShapeF;
      break;
    case 1:
      img = ShapeG;
      break;
    case 2:
      img = ShapeH;
      break;
    case 3:
      img = ShapeI;
      break;
    case 4:
      img = ShapeJ;
      break;
    case 5:
      img = ShapeK;
      break;
  }

  return (
    <div className={css.root} style={style}>
      <img
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        src={img}
      />
    </div>
  );
};

export default Shape;
