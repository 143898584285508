import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/video.mp4";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}Use Apostrophes</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>{languageLookup(1)}Apostrophes</Bold> {languageLookup(2)}can
          show possession—a person, place, or thing{" "}
          <Italic>{languageLookup(3)}has</Italic> {languageLookup(4)}or{" "}
          <Italic>{languageLookup(5)}owns</Italic> {languageLookup(6)}something.
        </div>
        <div>
          {languageLookup(7)}Apostrophes also form contractions—a word formed by
          combining two words.
        </div>
        <Instructions>
          {languageLookup(8)}Click or tap the video to learn more about
          apostrophes.
        </Instructions>
        <video width="320" height="240" controls src={Video} />
      </div>
    </div>
  );
};

export default Contents;
