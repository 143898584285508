import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good work! Two <Fraction numerator="1" denominator="6" /> parts equal{" "}
        <Fraction numerator="1" denominator="3" />. So{" "}
        <Fraction numerator="1" denominator="3" /> ÷ 2 ={" "}
        <Fraction numerator="1" denominator="6" /> because{" "}
        <Fraction numerator="1" denominator="6" /> x 2 ={" "}
        <Fraction numerator="1" denominator="3" />.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again! Divide <Fraction numerator="1" denominator="3" /> into 2
        equal parts or find half of <Fraction numerator="1" denominator="3" />.
        How much is one part?
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again! You need to divide <Fraction numerator="1" denominator="3" />{" "}
        by 2. Look at the fraction strips. Two parts of what fraction strip
        equal the length of <Fraction numerator="1" denominator="3" />
        ​?
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try the multiplication again! Division and multiplication undo each
        other. For example, 1 ÷ 2 = <Fraction numerator="1" denominator="2" />{" "}
        because <Fraction numerator="1" denominator="2" /> × 2 = 1.
      </div>
    );
  } else if (props.data.indices[1] == 3) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try the multiplication again! Division and multiplication undo each
        other. For example, 1 ÷ 2 = <Fraction numerator="1" denominator="2" />{" "}
        because <Fraction numerator="1" denominator="2" /> × 2 = 1.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
