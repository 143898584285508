import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "The title will often give you a hint of the main idea. However, if the main idea is stated directly, a more fully expressed main idea can be found as one of the sentences in the text.",
    sp: "El título a menudo te da una pista de la idea principal. Sin embargo, si la idea principal está explícita, se la puede hallar de manera más completa en alguna oración del texto.",
  },
  1: {
    en: "The passage also includes details that support the main idea. Do not choose one of these for your answer.",
    sp: "El fragmento también incluye detalles que apoyan la idea principal. No elijas uno de estos detalles como tu respuesta.",
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};
export const correctAnswers = [false, true, false, false];

export const getAnswers = () => {
  return { selected: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    selected: [false, false, false, false],
  };
};

export const isEmpty = (data) => {
  return data.selected.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.selected.length; i++) {
    if (data.selected[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
