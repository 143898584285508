import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply: Area of Rectangles</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>Look at the model.</div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/030f8a9858d05ce7501ea5d85c0f2af5.svg"
          width="40%"
        />
      </div>
    </div>
  );
};

export default Contents;
