/*
  UI flow for the multiple choice quesitons where the options stay on the screen and multiple might be selected at the same time.

  Currently used in the K2 ela 1 lesson.
*/

import { useCallback, useState } from "react";
import { BUTTON_STATES } from "../BottomToolbar";
import { playVoiceover, playVoiceovers } from "../voiceover";

/*
    options looks like:
    {
        img: url,
        vo: "votag",
        correct: false | true
    }
*/

export const useMultiMCFeedback = (options, setFoxState, voiceovers) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tries, setTries] = useState(0);
  const [feedbackState, setFeedbackState] = useState(null);

  const [continueState, setContinueState] = useState(BUTTON_STATES.hidden);
  const [checkState, setCheckState] = useState(BUTTON_STATES.disabled);

  const chooseAnswer = useCallback(
    (option) => {
      playVoiceover(option.vo, setFoxState);
      setCheckState(BUTTON_STATES.enabled);
      setContinueState(BUTTON_STATES.hidden);
      setFeedbackState(null);
      if (selectedOptions.indexOf(option) === -1) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        setSelectedOptions(selectedOptions.filter((o) => o !== option));
      }
    },
    [selectedOptions, setFoxState]
  );

  const onCorrect = useCallback(() => {
    setCheckState(BUTTON_STATES.hidden);
    setContinueState(BUTTON_STATES.enabled);
    setFeedbackState("display");
    playVoiceovers(["success", voiceovers.correct, "go_on_arrow"], setFoxState);
  }, [setFoxState, voiceovers.correct]);

  const onIncorrect = useCallback(() => {
    if (tries >= 2) {
      setSelectedOptions(options.filter((o) => o.correct));
      playVoiceovers([voiceovers.showAnswer, "go_on_arrow"], setFoxState);
      setCheckState(BUTTON_STATES.hidden);
      setContinueState(BUTTON_STATES.enabled);
      setFeedbackState("display");
      return;
    }
    setTries(tries + 1);
    playVoiceover(voiceovers.incorrect, setFoxState);
    setFeedbackState("display");
    setCheckState(BUTTON_STATES.disabled);
    setContinueState(BUTTON_STATES.hidden);
  }, [tries, voiceovers, setFoxState, options]);

  const onCheck = useCallback(() => {
    const correct =
      selectedOptions.find((p) => !p.correct) === undefined && // All the selected options are correct
      options.filter((o) => o.correct).length === selectedOptions.length; // And we have the right number of selected option

    if (correct) {
      onCorrect();
    } else {
      onIncorrect();
    }
  }, [onCorrect, onIncorrect, options, selectedOptions]);

  return {
    selectedOptions,
    onCheck,
    chooseAnswer,
    feedbackState,
    continueState,
    checkState,
  };
};
