import React from "react";
import css from "./Done.module.css";

import Fraction from "../../../../Fraction";

import { correctAnswers, isEmpty, isCorrect } from "../Controller";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        When a number is multiplied by a fraction greater than 1, the product is
        greater than the number. The product of{" "}
        <Fraction numerator="9" denominator="2" />×
        <Fraction numerator="5" denominator="8" /> is greater than{" "}
        <Fraction numerator="5" denominator="8" /> because the other factor,{" "}
        <Fraction numerator="9" denominator="2" />, is greater than 1.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Chen had the correct prediction and explanation. A product will be
        greater than one factor when the other factor is greater than 1. The
        product of
        <Fraction numerator="9" denominator="2" />×
        <Fraction numerator="5" denominator="8" /> is greater than
        <Fraction numerator="5" denominator="8" /> because the fraction{" "}
        <Fraction numerator="9" denominator="2" /> is greater than 1.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
