import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import Hotspot from "../../../../Hotspot";
import Underline from "../../../../Underline";

import DropDown from "../../../../DropDown";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.row}>
        <div className={css.dropdown}>
          <DropDown
            activity
            width="225px"
            selectedIndex={props.data.indices[0] - 1}
            selectIndex={(index) => selectIndexHandler(0, index + 1)}
            defaultText="Choose"
            data={[
              { text: "Both passages" },
              { text: `Only Mermaid or Manatee?"` },
              { text: `Only "Africa's Unicorn"` },
              { text: `Neither passage` },
            ]}
            /*rolloverTipTitle="Choose Subject"
                rolloverTip="Choose english or math."*/
          />
          <div className={css.text}>
            contain(s) paragraphs in which events are
          </div>
        </div>{" "}
        arranged chronologically.
      </div>
      <div className={css.row}>
        <div className={css.dropdown}>
          <DropDown
            activity
            width="225px"
            selectedIndex={props.data.indices[1] - 1}
            selectIndex={(index) => selectIndexHandler(1, index + 1)}
            defaultText="Choose"
            data={[
              { text: "Both passages" },
              { text: `Only Mermaid or Manatee?"` },
              { text: `Only "Africa's Unicorn"` },
              { text: `Neither passage` },
            ]}
            /*rolloverTipTitle="Choose Subject"
                rolloverTip="Choose english or math."*/
          />
          <div className={css.text}>
            describe(s) solutions to the problem of
          </div>
        </div>{" "}
        endangered animals.
      </div>
      <div className={css.row}>
        <div className={css.dropdown}>
          <DropDown
            activity
            width="225px"
            selectedIndex={props.data.indices[2] - 1}
            selectIndex={(index) => selectIndexHandler(2, index + 1)}
            defaultText="Choose"
            data={[
              { text: "Both passages" },
              { text: `Only Mermaid or Manatee?"` },
              { text: `Only "Africa's Unicorn"` },
              { text: `Neither passage` },
            ]}
            /*rolloverTipTitle="Choose Subject"
                rolloverTip="Choose english or math."*/
          />
          <div className={css.text}>
            compare(s) real-life animals to mythological
          </div>
        </div>{" "}
        creatures.
      </div>
      <div className={css.row}>
        <div className={css.dropdown}>
          <DropDown
            activity
            width="225px"
            selectedIndex={props.data.indices[3] - 1}
            selectIndex={(index) => selectIndexHandler(3, index + 1)}
            defaultText="Choose"
            data={[
              { text: "Both passages" },
              { text: `Only Mermaid or Manatee?"` },
              { text: `Only "Africa's Unicorn"` },
              { text: `Neither passage` },
            ]}
            /*rolloverTipTitle="Choose Subject"
                rolloverTip="Choose english or math."*/
          />
          <div className={css.text}>
            suggest(s) possible reasons why certain animals
          </div>
        </div>{" "}
        may look like humans.
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
