import React from "react";
import css from "./Island.module.css";

import Background from "./Background";
import People from "./People";
import Title from "./Title";

const Island = (props) => {
  return (
    <div style={{ top: props.top, left: props.left }} className={css.root}>
      <Background value={props.icon} />
      <People value={props.people} />
      <Title value={props.title} />
    </div>
  );
};

export default Island;
