import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import RowA from "../Assets/math_4_1_a.png";
import RowB from "../Assets/math_4_1_b.png";

import { list } from "../Controller";

const Contents = (props) => {
  const dragOverHandler = (ev) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, slot) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = {
      items: [[...props.data.items[0]], [...props.data.items[1]]],
    };
    data.items[slot].push(dragId);

    console.log(data);
    props.setData(data);
  };

  const dragStartHandler = (ev, slot, id, index) => {
    ev.dataTransfer.setData("text/plain", id);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      const data = {
        items: [[...props.data.items[0]], [...props.data.items[1]]],
      };
      data.items[slot].splice(index, 1);
      props.setData(data);
    }, 10);
  };

  const items = [[], []];

  for (let i = 0; i < props.data.items.length; i++) {
    for (let j = 0; j < props.data.items[i].length; j++) {
      const index = props.data.items[i][j];
      items[i].push(
        <div
          key={i + "_" + j}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, i, index, j)}
          className={css.item}
        >
          {list[index]}
        </div>
      );
    }
  }

  console.log(items);

  return (
    <div className={css.root}>
      <div className={css.title}>
        Mr. Anderson rewards his students for reading each night. If they read
        for at least 45 minutes a night, they will receive a bookmark. If they
        read for at least 90 minutes a night, they will receive a new book.
      </div>
      <div className={css.row}>
        <div className={css.image}>
          <img src={RowA} alt="icon" />
        </div>
        <div className={css.vertical}>
          <div className={css.header}>Bookmark</div>
          <div
            className={css.grid}
            onDragOver={(ev) => dragOverHandler(ev)}
            onDrop={(ev) => dropHandler(ev, 0)}
          >
            {items[0]}
          </div>
        </div>
      </div>
      <div className={css.row}>
        <div className={css.vertical}>
          <div className={css.header}>Bookmark</div>
          <div
            className={css.grid}
            onDragOver={(ev) => dragOverHandler(ev)}
            onDrop={(ev) => dropHandler(ev, 1)}
          >
            {items[1]}
          </div>
        </div>
        <div className={css.image}>
          <img src={RowB} alt="icon" />
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
