import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isEmpty, isCorrect } from "../Controller";

import Arrow from "../Assets/math_6_1_arrow.JPG";

const Done = (props) => {
  let contents;
  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! You can draw a net to represent each face, find the area, and
        then add the areas to find the surface area of each rectangular prism.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 6) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you multiply the length times the width times the height? To find
          surface area, find the area of each face and then add the areas.
        </div>
        <div className={css.image0}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[1] == 4) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you multiply the length times the width times the height? To find
          surface area, find the area of each face and then add the areas.
        </div>
        <div className={css.image1}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[2] == 7) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you multiply the length times the width times the height? To find
          surface area, find the area of each face and then add the areas.
        </div>
        <div className={css.image2}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you only find the area of 3 of the 6 faces? A rectangular prism
          has 3 pairs of congruent rectangular faces. Include the area of the
          other 3 faces.
        </div>
        <div className={css.image0}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[1] == 5) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you only find the area of 3 of the 6 faces? A rectangular prism
          has 3 pairs of congruent rectangular faces. Include the area of the
          other 3 faces.
        </div>
        <div className={css.image1}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you only find the area of 3 of the 6 faces? A rectangular prism
          has 3 pairs of congruent rectangular faces. Include the area of the
          other 3 faces.
        </div>
        <div className={css.image2}>
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else {
    contents = (
      <div className={css.text}>
        <div>
          You can use a net to find the area of each face of a rectangular
          prism. Use the formula <span className={css.italic}>A = lw</span> to
          find the area of a rectangle and{" "}
          <span className={css.italic}>A = s2</span> to find the area of a
          square.
        </div>
        <div className={css.contents}>
          <div className={css.row}>
            <div>
              <span className={css.bold}>Prism A:</span> Front and back faces:
              2(12 × 7) = 168 cm2
            </div>
            <div>Left and right faces: 2(7 × 6) = 84 cm2</div>
          </div>
          <div className={css.row}>
            <div>Top and bottom faces: 2(12 × 6) = 144 cm2</div>
            <div>The surface area of Prism A is 168 + 84 + 144 = 396 cm2.</div>
          </div>
          <div className={css.row}>
            <div>
              <span className={css.bold}>Prism B:</span> Front and back faces:
              2(11 × 8) = 176 cm2
            </div>
            <div>Left and right faces: 2(8 × 7) = 112 cm2</div>
          </div>
          <div className={css.row}>
            <div>Top and bottom faces: 2(11 × 7) = 154 cm2​</div>
            <div>The surface area of Prism B is 176 + 112 + 154 = 442 cm2.</div>
          </div>
          <div className={css.row}>
            <div>
              <span className={css.bold}>Prism C:</span> Front and back faces:
              2(10 × 10) = 200 cm2​
            </div>
            <div>Left and right faces: 2(10 × 5) = 100 cm2</div>
          </div>
          <div className={css.row}>
            <div>Top and bottom faces: 2(10 × 5) = 100 cm2</div>
            <div>The surface area of Prism C is 200 + 100 + 100 = 400 cm2.</div>
          </div>
        </div>
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
