import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Donkey from "../Assets/ela_3_1_donkey.JPG";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>The Donkey and the Salt</div>
      <Paragraph index="1">
        Jahi was a salt trader. He traveled a long way to the Red Sea each week
        to gather salt from the shores. The road Jahi traveled crossed a wide
        desert. With no trees in sight, the sun beat down on Jahi and his
        traveling partner-an old donkey. Jahi was always glad when he and his
        donkey finally reached the Red Sea.
      </Paragraph>
      <Paragraph index="2">
        On the way home from the Red Sea, there were many hills and streams to
        cross. One particularly hot day, the donkey was tired and did not like
        his heavy load.
      </Paragraph>
      <Paragraph index="3">
        The donkey hung his head down and went as slowly as he could.
      </Paragraph>
      <Paragraph index="4">
        After a while, they came to a stream that had a narrow wooden footbridge
        over it. It was not wide enough for the donkey to cross.
      </Paragraph>
      <Paragraph index="5">
        The donkey went through the water.{" "}
        <span style={{ fontStyle: "italics" }}>Splash! Splash! Splash!</span>
      </Paragraph>
      <Paragraph index="6">
        In the middle of the stream was a large stone that neither of them saw.
        The donkey stumbled and fell, soaking the bags. As the water ran over
        the salt, some of the salt dissolved in the water.
      </Paragraph>
      <Paragraph index="7">
        Jahi moved quickly to help the donkey and make sure he wasn't hurt.
        However, the donkey was glad he had fallen, for he found his load was
        now much lighter.
      </Paragraph>
      <Paragraph index="8">
        <div>
          Jahi moved quickly to help the donkey and make sure he wasn't hurt.
          However, the donkey was glad he had fallen, for he found his load was
          now much lighter.
        </div>
        <div className={css.donkey}>
          <img src={Donkey} alt="donkey" />
        </div>
      </Paragraph>
      <Paragraph index="9">
        This time he lost so much salt that his master was angry. Jahi would
        have to go back to the seashore for another load. He and the donkey
        turned around and walked back to the Red Sea to get more salt.
      </Paragraph>
      <Paragraph index="10">
        As they walked along, Jahi looked at the donkey. Then he laughed to
        himself. He thought he knew a way to stop the donkey from losing salt in
        the water.
      </Paragraph>
      <Paragraph index="11">
        When they came to the wide, calm seashore, Jahi picked up sponges from
        the seashore instead of collecting salt. He filled the donkey's bags
        with the sponges. Jahi knew this meant he wouldn't have a load of salt
        to sell at the market. However, at the moment, he believed teaching the
        donkey a lesson was more important. After the sponges were loaded, they
        started for home.
      </Paragraph>
      <Paragraph index="12">
        The donkey thought, "What a light load I have!" He trotted happily over
        the rough road.
      </Paragraph>
      <Paragraph index="13">
        Again they came to the stream. "Ah!" the donkey thought, "I will make my
        load even lighter!"
      </Paragraph>
      <Paragraph index="14">
        He lay down in the middle of the stream, and the water soaked the bags
        on his back. But unlike the salt, the sponges did not disappear. They
        filled with water. The sponges got bigger and heavier.
      </Paragraph>
      <Paragraph index="15">
        Now the donkey's load was so heavy that he was having trouble standing
        up.
      </Paragraph>
      <Paragraph index="16">
        Jahi kindly helped him, but the donkey was not happy.
      </Paragraph>
      <Paragraph index="17">
        "Oh," the donkey thought, "I will never lie down in the water again!"
      </Paragraph>
      <Paragraph index="18">
        A week later, Jahi led his donkey back to the seashore for more salt.
      </Paragraph>
      <Paragraph index="19">
        This time, he filled the bags with salt instead of sponges.
      </Paragraph>
      <Paragraph index="20">
        Remembering how heavy the bags were after he went into the water, the
        donkey watched his footing as he crossed the streams. He did not want to
        fall again.
      </Paragraph>
      <Paragraph index="21">
        Jahi and the donkey made it safely to the market that day, bags full of
        salt.
      </Paragraph>
    </div>
  );
};

export default Reader;
