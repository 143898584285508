import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";

import css from "../K2Lessons.module.css";

import videoEN from "../../assets/math_1_en.mp4";
import videoES from "../../assets/math_1_es.mp4";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { useParams } from "react-router-dom";
import { useLanguage } from "./useLanguage";

export const Screen1 = () => {
  const history = useHistory();
  const [continueState, setContinueState] = useState(BUTTON_STATES.disabled);
  const [foxState, setFoxState] = useState(FOX_STATE.idle);



  const params = useParams();
  const language = useLanguage()

  const video = language === 'en' ? videoEN : videoES

  const onNextScreen = () => history.push(`../2/${language}`);

  useEffect(() => {
    const vo = language === 'en' ? ["intro_G1_010A1_math_vo_en", "tap_play_get_started"] : ['intro_GK_066A1_math_vo_es']
    playVoiceovers(
      vo,
      setFoxState
    );

    return stopActiveVoiceover;
  }, [language]);

  const onPlay = () => {
    setFoxState((s) => (s != FOX_STATE.watching ? FOX_STATE.startTurn : s));
    setTimeout(() => setFoxState(FOX_STATE.watching), 1000);
  };

  const onEnd = () => {
    setContinueState(BUTTON_STATES.enabled);
    playVoiceover(
      language === 'en' ? "lesson_moveforward" : "videoEnd_l0_math_vo_es"
      , setFoxState);
  };

  return (
    <React.Fragment>
      <div className={css.videoHolder}>
        <video
          height="100%"
          controls
          src={video}
          onPlay={onPlay}
          onEnded={onEnd}
        />
      </div>

      <Fox state={foxState} />

      <BottomToolbar
        checkState={BUTTON_STATES.hidden}
        hintState={BUTTON_STATES.disabled}
        continueState={continueState}
        onContinue={onNextScreen}
      />
    </React.Fragment>
  );
};
