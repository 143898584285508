import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Use context clues from the passage to match each vocabulary word to its
        definition. Not all words will be used.
      </div>
      <div className={css.instructions}>
        Drag the words to the definitions. Click or tap the Reset button to
        start over.
      </div>
    </div>
  );
};

export default Sidebar;
