import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Understand Subtraction of Fractions</span>
      </div>
      <div className={css.contents}>
        <div>What fraction completes each subtraction sentence?</div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>7 tenths − 4 tenths = </span>
          <div className={css.dropdown}>
            <DropDown
              width="175px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "3 twentieths" },
                { text: "3 twelfths" },
                { text: "3 tenths" },
                { text: "11 twentieths" },
                { text: "11 tenths" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pan of pasta.</span>
        </div>
        <div className={css.row}>
          <span>
            <Fraction width="25px" numerator="7" denominator="10" /> −{" "}
            <Fraction width="25px" numerator="4" denominator="10" /> ={" "}
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      width="20px"
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="20"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      width="20px"
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="12"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      width="20px"
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="10"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      width="20px"
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="11"
                      denominator="20"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      width="20px"
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="11"
                      denominator="10"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pan of pasta.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
