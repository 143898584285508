import React from "react";
import Bold from "../../../../Bold";

export default (data, setData) => {};

export const correctAnswers = { selected: 1 };

export const isEmpty = (data) => {
  return data.selected == -1;
};

export const isCorrect = (data) => {
  return data.selected == correctAnswers.selected;
};

export const getDefaultState = () => {
  return {
    selected: -1,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Reread paragraph 2. What moves the magnet? Through what does the magnet
    move?
  </div>,
  <div>Look at the diagram. Where is the wire the magnet moves through?</div>,
  <div>
    The magnets help make electricity. Reread paragraph 3. Which of the answer
    choices makes electricity?
  </div>,
];

export const showReader = () => {
  return true;
};
