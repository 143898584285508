import React from "react";
import css from "./ItemBox.module.css";

import Center from "../../../../components/Center";
import ProgressBar from "../../../../components/ProgressBar";

import Hexagon from "../Hexagon";

const ItemBox = (props) => {
  let progress;

  const textColor = props.disabled ? "#DDDDDD" : "black";
  if (props.total) {
    progress = (
      <div className="progress">
        <Center>
          <ProgressBar
            width="72px"
            height="8px"
            background="#EEEEEE"
            data={[]}
          />
        </Center>
        <div
          style={{ color: textColor }}
          className={css.text}
        >{`level ${props.level} out of ${props.total}`}</div>
      </div>
    );
  }

  const mouseDownHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div
      onMouseDown={mouseDownHandler}
      className={css.root + (props.disabled ? " " + css.disabled : "")}
    >
      <Hexagon width="120" height="72" color={props.color}>
        <img className={css.icon} src={props.icon} width="70%" height="70%" />
      </Hexagon>
      <div style={{ color: textColor }} className={css.name}>
        {props.name}
      </div>
      {progress}
    </div>
  );
};

export default ItemBox;
