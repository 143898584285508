import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You chose the correct part of the illustration. The thought bubble shows
        the boy imagining himself as a bird.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.selected == 0) {
    contents = (
      <div className={css.text}>
        You have clicked or tapped on the bees. Choose a part of the image that
        shows the boy thinking about being a bird. Try again.
      </div>
    );
  } else if (props.data.selected == 1) {
    contents = (
      <div className={css.text}>
        You have clicked or tapped on the image of the deer. The boy thinks
        about being a bird in the sentence. Try again.
      </div>
    );
  } else if (props.data.selected == 2) {
    contents = (
      <div className={css.text}>
        You have clicked or tapped on the boy walking the dog. Choose a part of
        the image that shows the boy thinking about being a bird. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
