import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Divide a Decimal by a Whole Number</span>
      </div>
      <div className={css.contents}>
        <div>
          You can use what you know about dividing whole numbers to divide a
          decimal by a whole number.
        </div>
        <div>Find the quotient of 9.76 ÷ 8.</div>
        <div>9.76 ÷ 8 = 1.22.</div>
        <Instructions>
          Click or tap each step to see the long division.
        </Instructions>
        <div className={css.row}>
          <FlipBox
            style={{ color: "black", fontWeight: "normal" }}
            width="290px"
            height="230px"
          >
            <div>
              <Bold>STEP 1:</Bold> Use long division.
            </div>
            <Center>
              <img
                style={{ marginTop: "30px" }}
                src="https://d16qzots3do3wk.cloudfront.net/images/d69ab68c969adf30090648b8ffc18926.svg"
                width="20%"
              />
            </Center>
          </FlipBox>
          <FlipBox
            style={{ color: "black", fontWeight: "normal", textAlign: "left" }}
            padding="20px"
            width="290px"
            height="230px"
          >
            <div>
              <Bold>STEP 2:</Bold> Place a decimal point in the quotient
              directly above the decimal point in the dividend.
            </div>
            <Center>
              <img
                style={{ marginTop: "30px" }}
                src="https://d16qzots3do3wk.cloudfront.net/images/d7a0e547e76108a5e155a32538a70cdc.svg"
                width="20%"
              />
            </Center>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
