import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Center from "../../../../Center";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <Center>
        <img
          width="50%"
          src="https://d16qzots3do3wk.cloudfront.net/images/c5457aec1b80bca99817a88f8e58b455.svg"
        />
      </Center>
    </div>
  );
};

export default Sidebar;
