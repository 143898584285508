import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import DropDown from "../../../../../components/DropDown";

import Background from "../Assets/math_5_1_img.png";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = {
      indices: [...props.data.indices],
      inputs: [...props.data.inputs],
    };
    data.indices[slot] = index;

    props.setData(data);
  };

  const inputHandler = (slot, ev) => {
    const data = {
      indices: [...props.data.indices],
      inputs: [...props.data.inputs],
    };
    data.inputs[slot] = ev.target.value;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.background}>
        <img src={Background} alt="bg" />
      </div>
      <div className={css.top}>
        <div className={css.text}>
          A bridge stands 13.75 feet above a road. A truck is 13.82 feet tall.
        </div>
        <div className={css.equation}>
          Because 13.75
          <span className={css.dropdown}>
            <DropDown
              activity
              width="120px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText="Choose"
              data={[{ text: ">" }, { text: "<" }, { text: "=" }]}
            />
          </span>
          13.82, the truck
          <span className={css.dropdown}>
            <DropDown
              activity
              width="120px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText="Choose"
              data={[{ text: "can" }, { text: "cannot" }]}
            />
          </span>
          drive safely under the bridge.
        </div>
      </div>

      <div className={css.inputs}>
        <input
          type="text"
          className={css.input}
          value={props.data.inputs[0]}
          onChange={(ev) => inputHandler(0, ev)}
        ></input>
        <input
          type="text"
          className={css.input}
          value={props.data.inputs[1]}
          onChange={(ev) => inputHandler(1, ev)}
        ></input>
        <input
          type="text"
          className={css.input}
          value={props.data.inputs[2]}
          onChange={(ev) => inputHandler(2, ev)}
        ></input>
        <input
          type="text"
          className={css.input}
          value={props.data.inputs[3]}
          onChange={(ev) => inputHandler(3, ev)}
        ></input>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
