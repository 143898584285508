import React, { useCallback } from 'react';
import { toolTips } from "@indieasy.software/indieasy-engine";
import css from "./K2.module.css";
import { K2Header } from './K2Header';

import game1 from "./assets/game1.png";
import game2 from "./assets/game2.png";
import game3 from "./assets/game3.png";

import game4 from "./assets/game4.png";
import game5 from "./assets/game5.png";
import game6 from "./assets/game6.png";


import SinglePlayer from './SinglePlayer';
import PlayersIcon from '../Play/PlayersIcon';
import AudioIcon from './AudioIcon';

import Video from "../../assets/games_demo.mp4";
import PreviousIcon from './PreviousIcon';
import NextIcon from './NextIcon';
import rolloverTips from '../../data/rollovertips';
import Tokens from '../Play/Tokens';
import { BUTTON_STATES, BottomToolbar } from './BottomToolbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';




const games = {
    "math": [
        {
            title: 'Alien Addition',
            players: 'Single Player',
            image: game1
        },
        {
            title: 'Canoe Puppies',
            players: 'Up to 8 Players',
            image: game2
        },
        {
            title: 'Dolphin Dash',
            players: 'Up to 4 Players',
            image: game3
        },

        {
            title: 'Dirt Bikes',
            players: 'Up to 4 Players',
            image: game4
        },
        {
            title: 'Hungry Puppies',
            players: 'Up to 4 Players',
            image: game5
        },
        {
            title: 'Jumping Aliens',
            players: 'Up to 4 Players',
            image: game6
        },
    ],
    ela: [

        {
            title: 'Canoe Puppies',
            players: 'Up to 8 Players',
            image: game2
        },
        {
            title: 'Dolphin Dash',
            players: 'Up to 4 Players',
            image: game3
        },

        {
            title: 'Dirt Bikes',
            players: 'Up to 4 Players',
            image: game4
        },
        {
            title: 'Hungry Puppies',
            players: 'Up to 4 Players',
            image: game5
        },
        {
            title: 'Jumping Aliens',
            players: 'Up to 4 Players',
            image: game6
        }]
}


const createToolTip = (event, title, text) => {

    const rect = event.currentTarget.getBoundingClientRect();
    toolTips.manager.show(
        toolTips.createToolTip(
            Math.max(170, rect.left + rect.width - 160),
            rect.top + rect.height / 2 + 30,
            {
                style: { width: "300px" },
                title: title,
                text: text,
            }
        )
    );
};

export const K2Games = ({ grade, subject }) => {
    const [videoOpen, setVideoOpen] = React.useState(false);
    const toggleVideo = useCallback(() => setVideoOpen(v => !v), []);
    const history = useHistory();

    return <div className={css.root}>
        <K2Header grade={grade} subject={subject} />


        <div className={css.gameBackground}>
            <div className={css.tokenContainer}>
                <Tokens />
            </div>


            <div className={css.gameList}>
                {games[subject].map((game, index) => (
                    <div className={css.game} onClick={toggleVideo}>
                        <AudioIcon className={css.audioIcon} />
                        <img src={game.image} />
                        <label>{game.title}</label>
                        <label className={css.players}>{game.players === 'SinglePlayer' ? <SinglePlayer /> : <PlayersIcon />} {game.players}</label>
                    </div>
                ))}
            </div>

            <div className={css.gameNote}>
                Click on any game to see a video of sample play
            </div>
        </div>

        <PreviousIcon
            className={css.previousIcon}
            onMouseEnter={(ev) =>
                createToolTip(
                    ev,
                    rolloverTips["StudentK12MoreGames"].title,
                    rolloverTips["StudentK12MoreGames"].text
                )
            }
            onMouseLeave={() => toolTips.manager.hide()}
        />
        <NextIcon className={css.nextIcon}
            onMouseEnter={(ev) =>
                createToolTip(
                    ev,
                    rolloverTips["StudentK12MoreGames"].title,
                    rolloverTips["StudentK12MoreGames"].text
                )
            }
            onMouseLeave={() => toolTips.manager.hide()}
        />

        <BottomToolbar

            hintState={BUTTON_STATES.hidden}
            checkState={BUTTON_STATES.disabled}
            instructionState={BUTTON_STATES.hidden}
            continueState={BUTTON_STATES.hidden}
            resetState={BUTTON_STATES.enabled}

            onReset={() => { history.push(`/home-${subject}-${grade}/1`) }}

        />

        {videoOpen && (
            <div className={css.videoBackdrop}>
                <div className={css.video}>
                    <div className={css.closeButton} onClick={toggleVideo}>
                        Close Video
                    </div>
                    <video autoPlay={true} width="640" height="360" controls src={Video} />
                </div>
            </div>
        )}
    </div>

}