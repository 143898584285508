import React from "react";
import css from "./Gem.module.css";

const Gem = (props) => {
  return (
    <div className={css.root}>
      <div className={css.icon} style={{ background: props.icon }} />
      <div className={css.value}>{props.value}</div>
    </div>
  );
};

export default Gem;
