import React from "react";
import css from "./Shape.module.css";

import A from "../../Assets/math_8_1_ef.png";
import B from "../../Assets/math_8_1_jk.png";
import C from "../../Assets/math_8_1_cd.png";
import D from "../../Assets/math_8_1_gh.png";

const imgs = [A, B, C, D];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let image = imgs[props.index];

  return (
    <div
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
      className={css.root}
      style={style}
    >
      <div className={css.contents}>
        <img src={image} alt="image" />
      </div>
    </div>
  );
};

export default Shape;
