import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Write an Equation in the Form <Italic>y</Italic> = <Italic>mx</Italic>{" "}
          + <Italic>b</Italic>
        </span>
      </div>
      <div className={css.contents}>
        <div>A line is graphed on the coordinate plane shown.</div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/446735853d7693feea9928b6b55fcc29.svg"
            width="40%"
          />
        </Center>
        <div>Write the equation for the line.</div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <div>
            <span>
              <Bold>STEP 1:</Bold> Use the coordinate pairs (4,−3) and
            </span>
            <div className={css.dropdown}>
              <DropDown
                width="125px"
                selectedIndex={props.data.indices[0] - 1}
                selectIndex={(index) => selectIndexHandler(0, index + 1)}
                defaultText={
                  <div>
                    <Italic>Choose</Italic>
                  </div>
                }
                data={[
                  { text: "(0, 8)" },
                  { text: "(7, 0)" },
                  { text: "(8, 0)" },
                ]}
                /*rolloverTipTitle="Choose Subject"
                            rolloverTip="Choose english or math."*/
              />
            </div>
            <span>to find the slope.</span>
          </div>
          <div>
            <span>
              The slope <Italic>m</Italic> is
            </span>
            <div className={css.dropdown}>
              <DropDown
                width="125px"
                selectedIndex={props.data.indices[1] - 1}
                selectIndex={(index) => selectIndexHandler(1, index + 1)}
                defaultText={
                  <div>
                    <Italic>Choose</Italic>
                  </div>
                }
                data={[
                  { text: <Fraction inline numerator="3" denominator="4" /> },
                  { text: "1" },
                  { text: <Fraction inline numerator="4" denominator="3" /> },
                  {
                    text: (
                      <div>
                        -<Fraction inline numerator="3" denominator="4" />
                      </div>
                    ),
                  },
                ]}
                /*rolloverTipTitle="Choose Subject"
                            rolloverTip="Choose english or math."*/
              />
            </div>
            <span>.</span>
          </div>
        </div>
        <div className={css.row}>
          <div>
            <span>
              <Bold>STEP 2:</Bold> The line crosses the <Italic>y</Italic>-axis
              at the point
            </span>
            <div className={css.dropdown}>
              <DropDown
                width="125px"
                selectedIndex={props.data.indices[2] - 1}
                selectIndex={(index) => selectIndexHandler(2, index + 1)}
                defaultText={
                  <div>
                    <Italic>Choose</Italic>
                  </div>
                }
                data={[
                  { text: "(-6, 0)" },
                  { text: "(0, -6)" },
                  { text: "(8, 0)" },
                ]}
                /*rolloverTipTitle="Choose Subject"
                            rolloverTip="Choose english or math."*/
              />
            </div>
            <span>.</span>
          </div>
          <div>
            <span>
              The <Italic>y</Italic>-intercept is
            </span>
            <div className={css.dropdown}>
              <DropDown
                width="125px"
                selectedIndex={props.data.indices[3] - 1}
                selectIndex={(index) => selectIndexHandler(3, index + 1)}
                defaultText={
                  <div>
                    <Italic>Choose</Italic>
                  </div>
                }
                data={[{ text: "-6" }, { text: "0" }, { text: "8" }]}
                /*rolloverTipTitle="Choose Subject"
                            rolloverTip="Choose english or math."*/
              />
            </div>
            <span>.</span>
          </div>
        </div>
        <div className={css.row}>
          <div>
            <span>
              <Bold>STEP 3:</Bold> The equation of the line is
            </span>
            <div className={css.dropdown}>
              <DropDown
                width="150px"
                selectedIndex={props.data.indices[4] - 1}
                selectIndex={(index) => selectIndexHandler(4, index + 1)}
                defaultText={
                  <div>
                    <Italic>Choose</Italic>
                  </div>
                }
                data={[
                  {
                    text: (
                      <div>
                        <Italic>y</Italic> ={" "}
                        <Fraction inline numerator="3" denominator="4" />
                        <Italic>x</Italic> - 6
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div>
                        <Italic>y</Italic> ={" "}
                        <Fraction inline numerator="4" denominator="3" />
                        <Italic>x</Italic> - 6
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div>
                        <Italic>y</Italic> ={" "}
                        <Fraction inline numerator="3" denominator="4" />
                        <Italic>x</Italic> + 8
                      </div>
                    ),
                  },
                ]}
                /*rolloverTipTitle="Choose Subject"
                            rolloverTip="Choose english or math."*/
              />
            </div>
            <span>.</span>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "140px" }} />
    </div>
  );
};

export default Contents;
