import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import DropDown from "../../../../DropDown";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

const Sidebar = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Based on context clues in the text, select the correct definition for
        each bolded word.
      </div>
      <Instructions>
        Click or tap the correct answer from each drop-down menu.
      </Instructions>
      <div className={css.row}>
        <div>
          The word <Bold>probability</Bold> means
        </div>
        <div className={css.dropdown}>
          <DropDown
            width="225px"
            selectedIndex={props.data.indices[0] - 1}
            selectIndex={(index) => selectIndexHandler(0, index + 1)}
            defaultText={
              <div>
                <Italic>Choose</Italic>
              </div>
            }
            data={[
              { text: "situation" },
              { text: "mathematics" },
              { text: "likelihood" },
            ]}
            /*rolloverTipTitle="Choose Subject"
                    rolloverTip="Choose english or math."*/
          />
        </div>
        <span></span>
      </div>
      <div className={css.row}>
        <div>
          The word <Bold>outcomes</Bold> means
        </div>
        <div className={css.dropdown}>
          <DropDown
            width="225px"
            selectedIndex={props.data.indices[1] - 1}
            selectIndex={(index) => selectIndexHandler(1, index + 1)}
            defaultText={
              <div>
                <Italic>Choose</Italic>
              </div>
            }
            data={[{ text: "results" }, { text: "odds" }, { text: "details" }]}
            /*rolloverTipTitle="Choose Subject"
                    rolloverTip="Choose english or math."*/
          />
        </div>
        <span></span>
      </div>
    </div>
  );
};

export default Sidebar;
