import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Proportional Relationships",
    sp: "Relaciones proporcionales",
  },
  1: {
    en: "In this lesson you will:",
    sp: "Lo que harás en esta lección:",
  },
  2: {
    en: "Identify equivalent ratios and constant rates.",
    sp: "Identificar razones equivalentes y tasas constantes.",
  },
  3: {
    en: "Determine if a relationship is proportional.",
    sp: "Determinar si una relación es proporcional.",
  },
  4: {
    en: "Represent proportional relationships using tables, graphs, and ordered pairs.",
    sp: "Representar relaciones proporcionales mediante tablas, gráficas y pares ordenados.",
  },
  5: {
    en: "Click or tap the photo to learn more about how engineers are working to make cell phone batteries last longer.",
    sp: "Pulsa o haz clic en la foto para aprender más sobre cómo trabajan los ingenieros para que las baterías de los teléfonos celulares duren más.",
  },
  6: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/4c7a3952d0594d51151c5a33a191f7b4.webp",
    sp: "https://d16qzots3do3wk.cloudfront.net/images/4c7a3952d0594d51151c5a33a191f7b4.webp",
  },
  7: {
    en: "The more apps you use on your cell phone, the faster the battery drains. Scientists and engineers continue to make improvements by studying the proportional relationship of the amount of battery drained to the amount of voltage used.",
    sp: "Cuantas más aplicaciones usas en tu teléfono celular, más rápido se agota la batería. Los científicos e ingenieros continúan haciendo mejoras gracias al estudio de la relación proporcional entre la cantidad de batería usada y la cantidad de voltaje usado.",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>{languageLookup(1)}</span>
          <BulletPoints>
            <li>{languageLookup(2)}</li>
            <li>{languageLookup(3)}</li>
            <li>{languageLookup(4)}</li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>{languageLookup(5)}</Instructions>
          <FlipBox backPadding="20px" width="400px" height="275px">
            <img src={`${languageLookup(6)}`} />
            <div style={{ textAlign: "left" }}>{languageLookup(7)}</div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
