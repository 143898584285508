import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const lines = {
  0: {
    en: "What is the area of the bedroom?",
    sp: "¿Cuál es el área de la habitación?",
  },
  1: {
    en: "Click or tap the correct answer.",
    sp: "Pulsa o haz clic en la respuesta correcta.",
  },
  2: {
    en: "8 square yards",
    sp: "8 yardas cuadradas",
  },
  3: {
    en: "9 square yards",
    sp: "9 yardas cuadradas",
  },
  4: {
    en: "15 square yards",
    sp: "15 yardas cuadradas",
  },
  5: {
    en: "25 square yards",
    sp: "25 yardas cuadradas",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>{languageLookup(0)}</div>
      <Instructions>{languageLookup(1)}</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>{languageLookup(2)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>{languageLookup(3)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>{languageLookup(4)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>{languageLookup(5)}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
