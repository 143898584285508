import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Italic from "../../../../Italic";
import Fraction from "../../../../Fraction";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const data = { selected: [...props.data.selected] };
    data.selected[p.index] = !data.selected[p.index];
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Which statements about the line are correct? Select all that apply.
      </div>
      <Instructions>Click or tap each correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>
            The slope is −<Fraction inline numerator="3" denominator="5" />.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>
            The slope is −<Fraction inline numerator="5" denominator="3" />.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>
            The slope is <Fraction inline numerator="5" denominator="3" />.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>
            The <Italic>y</Italic>-intercept is 1.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={4}
            selected={props.data.selected[4]}
            onClick={checkHandler}
          />
          <span>
            The <Italic>y</Italic>-intercept is{" "}
            <Fraction inline numerator="1" denominator="2" />.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={5}
            selected={props.data.selected[5]}
            onClick={checkHandler}
          />
          <span>
            <div style={{ marginBottom: "5px" }}>
              The equation of the line is{" "}
            </div>
            <div>
              <Italic>y</Italic> = −
              <Fraction inline numerator="3" denominator="5" />
              <Italic>x</Italic> +{" "}
              <Fraction inline numerator="1" denominator="2" />.
            </div>
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={6}
            selected={props.data.selected[6]}
            onClick={checkHandler}
          />
          <span>
            <div style={{ marginBottom: "5px" }}>
              The equation of the line is{" "}
            </div>
            <div>
              <Italic>y</Italic> = −
              <Fraction inline numerator="5" denominator="3" />
              <Italic>x</Italic> + 1.
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
