import React from "react";
import Button from "./Button";
import css from "./Hints.module.css";

const Hints = (props) => {
  const hints = props.hints.map((x, index) => (
    <Button
      selected={props.index == index}
      text={index + 1}
      onClick={() => props.onSelect(index)}
      key={index}
    />
  ));
  return (
    <div className={css.root}>
      <div className={css.text}>
        {props.language == "sp" ? "Pistas" : "HINTS"}:
      </div>
      {hints}
    </div>
  );
};

export default Hints;
