import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_7_1_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>What's Old Is New Again</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        "I'm so glad to be spending time with you here," Gina said to her father
        with a smile. Gina and her father, Nick D'Antoni, were in the back
        office of their family-run movie theater. Nick was the manager of the
        theater, which had been in the family for generations.
      </Paragraph>
      <Paragraph index="2">
        "Feels like old times, doesn't it?" said Nick, a wistful look in his
        eyes. "Do you remember how much time you used to spend here? You
        practically grew up in this theater."
      </Paragraph>
      <Paragraph index="3">
        "Yeah, I used to love helping mom in the ticket booth or you behind the
        concession stand. Remember how I used to take naps in the projection
        room?" Gina asked.
      </Paragraph>
      <Paragraph index="4">
        "Of course I do. If I couldn't find you, I would always look in there,
        and there you'd be, curled up on the floor, dreaming away," Nick
        reminisced. Gina saw the look on her father's face, a look she had seen
        before, and knew he was a million miles away.
      </Paragraph>
      <Paragraph index="5">
        "Sometimes I wish things were still like they were back then," Nick{" "}
        <Italic>lamented</Italic>, his smile beginning to fade. He waved at the
        pile of papers on his desk. "We are so far behind with our bills, I
        don't know how much longer we'll be able to hold on-to keep the theater
        up and running."
      </Paragraph>
      <Paragraph index="6">
        "Oh, no, Dad, I had no idea!" Gina said. She could see the{" "}
        <Italic>anxiety</Italic> etched in her father's face.
      </Paragraph>
      <Paragraph index="7">
        "The fact is, nowadays people watch so many movies on cable or on the
        Internet, they only come to see movies at theaters once in a blue moon.
        But I can remember a time when this theater was always stuffed to the
        rafters with moviegoers," Nick recalled. He rummaged through an old box
        of movie reels and pulled out a few. "Come up to the projection room
        with me, Gina. I want to show you something."
      </Paragraph>
      <Paragraph index="8">
        Gina and her father walked up the stairs to the tiny projection room,
        which overlooked the theater. Everything took on a heavier meaning for
        Gina now that she knew about her father's financial troubles and the
        theater's impending demise. The musty, oaky smell of the staircase, the
        creak of the projection room door, and the snug, cozy feel of the
        projection room itself-it was almost impossible for her to believe that
        all of this could disappear from their lives.
      </Paragraph>
      <Paragraph index="9">
        Her father set the reel on the projector and switched it on. Gina
        watched through the tiny projection room window as the screen came alive
        with images of Gina's family: her great-grandfather at the theater's
        grand opening back in the early 1930s, her great-grandmother taking
        tickets at the door, and the smiling faces of the theater goers
        funneling out of the theater after watching a movie.
      </Paragraph>
      <Paragraph index="10">
        As the movie ended, Nick admitted with the look of consternation still
        on his face, "Times were very difficult back then, too, but people still
        found a dime to go to the movies and forget their troubles."
      </Paragraph>
      <Paragraph index="11">
        "Well, why don't we try to modernize the theater," Gina{" "}
        <Italic>suggested</Italic>. "You know-bigger screens, a better sound
        system, some 3D projectors? I'm sure that if we streamline the theater,
        more people would come here to watch movies instead of staying at home!"
      </Paragraph>
      <Paragraph index="12">
        "I would love to do that, but at this point, we can't afford to make
        those kinds of changes."
      </Paragraph>
      <Paragraph index="13">
        "Well, we have to do something," Gina said, feeling a little desperate.
        "I can't imagine our family without this theater
      </Paragraph>
      <Paragraph index="14">
        "Here's another old home movie. Let's see what this one is." As Nick set
        up the reel, Gina's grandmother, Viola, came into the booth with
        sandwiches for their lunch. The film began showing a scene from the
        1950s: A young Viola is on the stage of the movie theater, pulling out a
        ticket stub from a decorative box. She then calls out a number, and an
        excited man comes running onto the stage to collect his prize.
      </Paragraph>
      <Paragraph index="15">
        "Oh yes, I remember when we used to have a special raffle every Friday
        night," Viola explained. "The prizes weren't much, but they were enough
        to get people to buy the raffle tickets."
      </Paragraph>
      <Paragraph index="16">
        Gina slowly began to pace around the booth, mulling things over. Then,
        she came up with an idea that she thought would be the best thing since
        sliced bread. She turned to her father and grandmother with a huge smile
        that beamed as bright as the sun.
      </Paragraph>
      <Paragraph index="17">
        "Dad, Grandma, listen, I have an idea! What if one night a week we had a
        retro night? We could show old movies and have a special raffle. We can
        start to get people back to the theater and begin to raise money for the
        improvements we need to make to rejuvenate the theater," Gina excitedly
        explained.
      </Paragraph>
      <Paragraph index="18">
        "I don't know, Gina. People might not be as excited about it as you
        are," Nick said.
      </Paragraph>
      <Paragraph index="19">
        "Well, I think it is a wonderful idea. I love seeing old movies, and I'm
        sure that there are many other people in the neighborhood who would love
        to see the old classics again on a big screen," Viola told Nick.
      </Paragraph>
      <Paragraph index="20">
        "We can call it <Underline>Recollection</Underline> Night or something,"
        said Gina.
      </Paragraph>
      <Paragraph index="21">
        As Nick thought for a moment, a smile started to spread across his face.
        "You know," he said, "I think this could actually work! Why don't we
        call it <Underline>Nostalgia</Underline> Night at the Movies?"
      </Paragraph>
      <Paragraph index="22">
        "Wonderful, I love it! Let's get started planning right away. I'll go
        get some paper so we can write all of our ideas down now!" Gina{" "}
        <Underline>exclaimed</Underline> as she bounded toward the office.
      </Paragraph>
    </div>
  );
};

export default Reader;
