import * as activities from "../../../../components/Activities/LearningGoal";

const controller = {};

controller.HasActivity = (id) => {
  return activities[id];
};

controller.GetDefaultState = (id) => {
  return activities[id].getDefaultState();
};

controller.GetHint = (id) => {
  return activities[id].hint;
};

controller.GetDone = (id) => {
  return activities[id].done;
};

controller.GetContents = (id) => {
  return activities[id].contents;
};

controller.GetSidebar = (id) => {
  return activities[id].sidebar;
};

controller.GetFeedbackType = (id, data, setData) => {
  return activities[id].getFeedbackType(data, setData);
};

controller.GetHints = (id) => {
  return activities[id].hints;
};

controller.GetReader = (id) => {
  return activities[id].reader;
};

controller.ShowReader = (id) => {
  return activities[id].showReader();
};
export default controller;
