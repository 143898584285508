/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from "react";
import css from "./Play.module.css";

import Video from "../../assets/games_demo.mp4";

import Sidebar from "../../components/Sidebar";
import Logo from "../../components/Logo";
import Logout from "../../components/Icons/Logout";
import Help from "../../components/Icons/Help";
import Messages from "../../components/Icons/Messages/Messages";
import Bold from "../../components/Bold";

import card1 from "./card1.png";
import card2 from "./card2.png";
import card3 from "./card3.png";
import card4 from "./card4.png";
import card5 from "./card5.png";
import card6 from "./card6.png";
import card7 from "./card7.png";
import card8 from "./card8.png";

import profileData from "../../data/profiles.json";
import PlayersIcon from "./PlayersIcon";
import Tokens from "./Tokens";

const games = {
    math: [
        {
            title: "Canoe Penguins",
            image: card1,
            players: "up to 8 players"
        },
        {
            title: "Dirt Bikes",
            image: card3,
            players: "up to 4 players"
        },
        {
            title: "Division Derby",
            image: card4,
            players: "up to 12 players"
        },
        {
            title: "Drag Race",
            image: card5,
            players: "up to 4 players"
        },
        {
            title: "Four Wheel Fracas",
            image: card6,
            players: "up to 12 players"
        },
        {
            title: "Grand Prix",
            image: card7,
            players: "up to 4 players"
        },
        {
            title: "Hungry Puppies",
            image: card8,
            players: "up to 4 players"
        },
        {
            title: "Jumping Aliens",
            image: card2,
            players: "up to 4 players"
        },

    ],
    ela: [
        {
            title: "Canoe Penguins",
            image: card1,
            players: "up to 8 players"
        },
        {
            title: "Dirt Bikes",
            image: card3,
            players: "up to 4 players"
        },

        {
            title: "Drag Race",
            image: card5,
            players: "up to 4 players"
        },
        {
            title: "Four Wheel Fracas",
            image: card6,
            players: "up to 12 players"
        },
        {
            title: "Grand Prix",
            image: card7,
            players: "up to 4 players"
        },
        {
            title: "Hungry Puppies",
            image: card8,
            players: "up to 4 players"
        },
        {
            title: "Jumping Aliens",
            image: card2,
            players: "up to 4 players"
        },

    ]
}

export const Play = (props) => {
    const [itemIndex, setItemIndex] = useState(-1);
    const [videoOpen, setVideoOpen] = useState(false);

    const toggleVideo = useCallback(() => {
        setVideoOpen(v => !v);
    }, []);


    return (
        <div className={css.root}>
            <div className={css.background}>
                <div className={css.honeycomb} />
                <Sidebar {...props} index={1} />
                <div className={css.top}>
                    <div className={css.options}>
                        <div className={css.logout}>
                            <Logout />
                        </div>
                        <div className={css.help}>
                            <Help />
                        </div>
                        <div className={css.messages}>
                            <Messages />
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.bottom}>
                <div className={css.footer}>
                    <img
                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC0xIDAgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSA1NzYgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgNDAgMTIwKSIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAyMEw0MCA0MEg1NzZMNjE2IDIwTDU3NiAwSDQwTDAgMjBaIiBmaWxsPSIjREREREREIi8+CjxwYXRoIGQ9Ik0xNiAyMEw0MCAzMkg1NzZMNjAwIDIwTDU3NiA4SDQwTDE2IDIwWiIgZmlsbD0iI0NDQ0NDQyIvPgo8cGF0aCBkPSJNMTYgMjBMNDAgMzJWOEwxNiAyMFoiIGZpbGw9IiNCQUJBQkEiLz4KPHBhdGggZD0iTTYwMCAyMEw1NzYgMzJWOEw2MDAgMjBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
                        alt="header"
                    />
                    <div className={css.gameNote}>
                        Click on any game to see a video of sample play
                    </div>
                </div>
            </div>
            <div className={css.dashboard}>
                <div className={css.content}>
                    <div className={css.gamelist}>

                        {games[props.subject].map(g => (
                            <div onClick={toggleVideo} className={css.card}>
                                <img src={g.image} />
                                <label>{g.title}</label>
                                <label className={css.playerslabel}>
                                    <PlayersIcon />
                                    {g.players}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={css.header}>
                <img
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIGZpbGw9IiNFREVERUQiLz4KPHJlY3QgeD0iNTc2IiB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjRURFREVEIi8+CjxyZWN0IHg9IjQwIiB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAxMDBMNDAgODBINTc2TDYxNiAxMDBMNTc2IDEyMEg0MEwwIDEwMFoiIGZpbGw9IiNEREREREQiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OEg1NzZMNjAwIDEwMEw1NzYgMTEySDQwTDE2IDEwMFoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OFYxMTJMMTYgMTAwWiIgZmlsbD0iI0JBQkFCQSIvPgo8cGF0aCBkPSJNNjAwIDEwMEw1NzYgODhWMTEyTDYwMCAxMDBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
                    alt="header"
                />
                <div className={css.welcome}>
                    <Tokens />
                </div>
                <div className={css.logo}>
                    <Logo />
                </div>
            </div>

            {videoOpen && (
                <div className={css.videoBackdrop}>
                    <div className={css.video}>
                        <div className={css.closeButton} onClick={toggleVideo}>
                            Close Video
                        </div>
                        <video autoPlay={true} width="640" height="360" controls src={Video} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Play;
