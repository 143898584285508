import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! The equation that shows the problem is 2 ÷{" "}
        <Fraction numerator="1" denominator="5" /> = <Italic>t</Italic>. 2 ÷{" "}
        <Fraction numerator="1" denominator="5" /> is the same as 2 × 5, or 10.
        There are 10 fifths in 2. So, Mia stops 10 times to exercise.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again. You need to find how many
        <Fraction inline numerator="1" denominator="5" /> parts there are in 2
        wholes.
      </div>
    );
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again. You need to find how many
        <Fraction inline numerator="1" denominator="5" /> parts there are in 2
        wholes.
      </div>
    );
  } else if (props.data.indices[1] == 0) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try finding the value of t again. You need to divide 2 by{" "}
        <Fraction numerator="1" denominator="5" />.
      </div>
    );
  } else if (props.data.indices[1] == 1) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try finding the value of t again. You need to divide 2 by{" "}
        <Fraction numerator="1" denominator="5" />.
      </div>
    );
  } else if (props.data.indices[2] == 0) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again. You need to find how many
        <Fraction numerator="1" denominator="5" /> parts there are in 2 wholes.
      </div>
    );
  } else if (props.data.indices[2] == 1) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again. You need to find how many
        <Fraction numerator="1" denominator="5" /> parts there are in 2 wholes.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
