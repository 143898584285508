import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import InputField from "../../../../InputField";

const lines = {
  title: {
    en: "Use Tiling to Find the Area of a Rectangle",
    sp: "Utilizar fichas cuadradas para hallar el área de un rectángulo",
  },
  0: {
    en: "Each unit square has an area of 1 square meter.",
    sp: "Cada cuadrado de una unidad tiene un área de 1 metro cuadrado.",
  },
  1: {
    en: "Find the area of the figure.",
    sp: "Halla el área de la figura.",
  },
  2: {
    en: "Type the missing number into each blank.",
    sp: "Escribe el número que falta en cada espacio en blanco.",
  },
  3: {
    en: "There are",
    sp: "Hay",
  },
  4: {
    en: "unit squares.",
    sp: "cuadrados de una unidad.",
  },
  5: {
    en: "So, the area of the rectangle is",
    sp: "Entonces, el área del rectángulo mide",
  },
  6: {
    en: "square meters.",
    sp: "metros cuadrados.",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const valueHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>{languageLookup(0)}</div>
          <div>{languageLookup(1)}</div>
        </div>
        <Instructions>{languageLookup(2)}</Instructions>
        <div>
          <span>{languageLookup(3)}</span>
          <InputField
            language={props.language}
            value={props.data.inputs[0]}
            onChange={(ev) => valueHandler(0, ev)}
            width="175px"
          ></InputField>
          <span>{languageLookup(4)}</span>
        </div>
        <div>
          <span>{languageLookup(5)}</span>
          <InputField
            language={props.language}
            value={props.data.inputs[1]}
            onChange={(ev) => valueHandler(1, ev)}
            width="175px"
          ></InputField>
          <span>{languageLookup(6)}</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
