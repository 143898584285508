import React from "react";

import css from "./K2.module.css";

const preloadAssets = [
  require("./assets/games.svg").default,
  require("./assets/reset.svg").default,
  require("./assets/reset-hover.svg").default,
  require("./assets/reset-down.svg").default,
  require("./assets/reset-disabled.svg").default,
  require("./assets/check.svg").default,
  require("./assets/check-hover.svg").default,
  require("./assets/check-down.svg").default,
  require("./assets/check-disabled.svg").default,
  require("./assets/continue.svg").default,
  require("./assets/continue-hover.svg").default,
  require("./assets/continue-down.svg").default,
  require("./assets/check-disabled.svg").default,
  require("./assets/instruction.svg").default,
  require("./assets/instruction-hover.svg").default,
  require("./assets/instruction-down.svg").default,
  require("./assets/instruction-disabled.svg").default,
  require("./assets/hint.svg").default,
  require("./assets/hint-hover.svg").default,
  require("./assets/hint-down.svg").default,
  require("./assets/hint-disabled.svg").default,
];

export const BUTTON_STATES = {
  enabled: 1,
  disabled: 2,
  hidden: 3,
  pulsing: 4,
};

export const BottomToolbar = ({
  continueState = BUTTON_STATES.enabled,
  hintState = BUTTON_STATES.disabled,
  checkState = BUTTON_STATES.disabled,
  instructionState = BUTTON_STATES.disabled,
  resetState = BUTTON_STATES.hidden,
  gamesState = BUTTON_STATES.hidden,
  onContinue,
  onHint,
  onCheck,
  onInstruction,
  onReset,
  onGames
}) => (
  <div className={css.bottomToolbar}>

    {gamesState !== BUTTON_STATES.hidden && (
      <button
        className={css.gamesButton}
        onClick={onGames}
      />)}

    {resetState !== BUTTON_STATES.hidden && (
      <button
        disabled={resetState === BUTTON_STATES.disabled}
        className={css.resetButton}
        onClick={onReset}
      ></button>
    )}

    {hintState !== BUTTON_STATES.hidden && (
      <button
        disabled={hintState === BUTTON_STATES.disabled}
        className={css.hintButton}
        onClick={onHint}
      ></button>
    )}

    {instructionState !== BUTTON_STATES.hidden && (
      <button
        disabled={instructionState === BUTTON_STATES.disabled}
        className={css.instructionButton}
        onClick={onInstruction}
      ></button>
    )}

    {continueState !== BUTTON_STATES.hidden && (
      <button
        disabled={continueState === BUTTON_STATES.disabled}
        className={css.continueButton}
        onClick={onContinue}
      ></button>
    )}

    {checkState !== BUTTON_STATES.hidden && (
      <button
        disabled={checkState === BUTTON_STATES.disabled}
        className={`${css.checkButton} ${checkState === BUTTON_STATES.enabled ? css.checkActive : ""
          }`}
        onClick={onCheck}
      ></button>
    )}

    <div className={css.preload}>
      {
        // Preload our images to avoid a flicker.
        // For some reason, just doing a (new Image().src = src;) above didn't cut it
        preloadAssets.map((src, i) => (
          <img key={i} src={src} />
        ))
      }
    </div>
  </div>
);

BottomToolbar.defaultProps = {
  gamesState: BUTTON_STATES.hidden,
}