import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_2_1.JPG";
import Bold from "../../../../Bold";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Arches National Park</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.subtitle}>
        This arch in Arches National Park is called "Delicate Arch." It is a
        famous Utah landmark.
      </div>
      <Paragraph index="1">
        There are many examples of arches in the world. An arch has a curved
        shape. There is empty space under the bottom side of the curve. A
        rainbow is an arch. Many bridges are arches. Some buildings have arches.
        Some arches are made by nature. There are lots of natural arches in
        Arches National Park.
      </Paragraph>
      <div className={css.subtitle}>Sandstone Arches</div>
      <Paragraph index="2">
        The arches in the park are made of sandstone. <Bold>Sandstone</Bold> is
        a kind of rock. It is made of grains of sand. Other materials hold the
        sand together. They act like glue. But water can wash some of the
        materials away.
      </Paragraph>
      <div className={css.subtitle}>How Did the Arches Form?</div>
      <Paragraph index="3">
        Sometimes land wears away. That is called erosion. Water and wind can
        cause <Bold>erosion</Bold>. They can make land wash or blow away. Soil,
        dirt, or sand can all be eroded. Arches National Park gets 8 to 10
        inches of rain each year. That can erode some of the sandstone rocks in
        the park.
      </Paragraph>
      <Paragraph index="4">
        Imagine a big rock in the park. The rain falls. After many years, it
        washes away some of the minerals in the rock. The grains of sand start
        to come apart. Then water or wind carries the sand away. Over time, a
        hole forms in the rock. That hole makes an arch.
      </Paragraph>
      <div className={css.subtitle}>Care for the Arches</div>
      <Paragraph index="5">
        Over 2,000 arches in the park have been counted. Arches are delicate.
        You cannot walk on them. And, you cannot climb on them. Park rangers say
        we need to take care of the arches. We need to be careful around them.
      </Paragraph>
      <Paragraph index="6">
        Arches National Park is a beautiful place. There are many arches that
        you can visit at the park. Remember to be careful and enjoy the view.
      </Paragraph>
    </div>
  );
};

export default Reader;
