import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Learn about Different Types of Sentences
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Writers can also vary sentence <Italic>type</Italic>.
        </div>
        <div>
          <div>
            <Bold>Declarative sentences</Bold> tell something and end with a
            period. Use these to make a statement or tell a fact.
          </div>
          <BlockQuote>
            The beagle wags its tail whenever it sees Liz.
          </BlockQuote>
        </div>
        <div>
          <div>
            <Bold>Interrogative sentences</Bold> ask something and end with a
            question mark. Use these when you need to ask a question in your
            writing.
          </div>
          <BlockQuote>
            Does the beagle wag its tail when it sees Liz?
          </BlockQuote>
        </div>
        <div>
          <div>
            <Bold>Imperative sentences</Bold> give an order or a direction and
            end with a period. Use these when you want to give a command.
          </div>
          <BlockQuote>
            Put the hot biscuits on the counter to cool off.
          </BlockQuote>
        </div>
        <div>
          <div>
            <Bold>Exclamatory sentences</Bold> show strong feeling and end with
            an exclamation point. Use these to show strong emotions, such as
            excitement, happiness, and fear.
          </div>
          <BlockQuote>Those biscuits were so delicious!</BlockQuote>
        </div>
        <div>
          <div>
            Read on to see how using different types of sentences can make
            writing more interesting.{" "}
          </div>
          <BlockQuote>
            I saw a fruit stand near the playground. Do you think we should buy
            some strawberries for lunch? Call Pop and see if he wants some, too.
            He is wild about strawberries!{" "}
          </BlockQuote>
        </div>
      </div>
    </div>
  );
};

export default Contents;
