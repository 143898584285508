import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good work! Half of
        <Fraction numerator="1" denominator="2" /> is equal to
        <Fraction numerator="1" denominator="4" /> of a whole. So each friend
        gets
        <Fraction numerator="1" denominator="4" /> of a whole pineapple.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again. The two friends are sharing
        <Fraction numerator="1" denominator="2" /> of a pineapple. Each friend
        will get half of <Fraction numerator="1" denominator="2" />.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
