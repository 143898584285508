import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Fraction from "../../../../Fraction";

import ShapeA from "../Assets/math_5_2_a.png";
import ShapeB from "../Assets/math_5_2_b.png";
import ShapeC from "../Assets/math_5_2_c.png";
import ShapeD from "../Assets/math_5_2_d.png";
import Hotspot from "../../../../Hotspot";

const Contents = (props) => {
  const handleSelection = (index) => {
    const data = { selections: [...props.data.selections] };

    for (let i = 0; i < 4; i++) {
      if (i == index) continue;

      data.selections[i] = false;
    }
    data.selections[index] = !data.selections[index];

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Four students wrote predictions about the product of{" "}
        <Fraction numerator="9" denominator="2" /> x{" "}
        <Fraction numerator="5" denominator="8" /> and explained their
        reasoning.
      </div>
      <div className={css.grid}>
        <Hotspot
          selected={props.data.selections[0]}
          onSelected={(data) => handleSelection(0)}
        >
          <img src={ShapeA} alt="A" />
        </Hotspot>
        <Hotspot
          selected={props.data.selections[1]}
          onSelected={(data) => handleSelection(1)}
        >
          <img src={ShapeB} alt="B" />
        </Hotspot>
        <Hotspot
          selected={props.data.selections[2]}
          onSelected={(data) => handleSelection(2)}
        >
          <img src={ShapeC} alt="C" />
        </Hotspot>
        <Hotspot
          selected={props.data.selections[3]}
          onSelected={(data) => handleSelection(3)}
        >
          <img src={ShapeD} alt="D" />
        </Hotspot>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
