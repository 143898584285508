import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Understand Subtraction of Fractions</span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>In this lesson, you will ...</span>
          <BulletPoints>
            <li>Understand subtraction of fractions.</li>
            <li>Model subtraction of fractions with visuals.</li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>
            Click or tap the photo to read about segments of an orange.
          </Instructions>
          <FlipBox backPadding="20px" width="400px" height="275px">
            <Center>
              <img
                src="https://d16qzots3do3wk.cloudfront.net/images/bbb07215869b9b1021d72be31f2fdc35.webp"
                width="70%"
              />
            </Center>
            <div style={{ textAlign: "left", lineHeight: "30px" }}>
              <span>
                An orange usually has 10 segments. So, each segment is
              </span>
              <Fraction width="30px" numerator="1" denominator="10" />
              <span>
                of the whole orange. Next time you eat an orange, try counting
                the segments!
              </span>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
