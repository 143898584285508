import React from "react";
import Fraction from "../../../../Fraction";

export default (data, setData) => {};

export const correctAnswers = { inputs: ["6", "20", "6", "32"] };

export const isCorrect = (data) => {
  return (
    data.inputs[0] == correctAnswers.inputs[0] &&
    data.inputs[1] == correctAnswers.inputs[1] &&
    data.inputs[2] == correctAnswers.inputs[2] &&
    data.inputs[3] == correctAnswers.inputs[3]
  );
};

export const isEmpty = (data) => {
  return data.inputs.filter((x) => x.length > 0).length == 0;
};

export const getDefaultState = () => {
  return {
    inputs: ["", "", "", ""],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    The parallelogram can be broken into two triangles and a rectangle.
  </div>,
  <div>
    The formula for the area of a triangle is{" "}
    <span style={{ fontStyle: "italic" }}>A</span> =
    <Fraction numerator="1" denominator="2" />
    <span style={{ fontStyle: "italic" }}>bh</span>. What are the base and
    height of each triangle?
  </div>,
  <div>
    The formula for the area of a rectangle is{" "}
    <span style={{ fontStyle: "italic" }}>A</span> ={" "}
    <span style={{ fontStyle: "italic" }}>lw</span>. What are the dimensions of
    the rectangle?
  </div>,
  <div>Add the areas to find the area of the parallelogram.</div>,
  <div>
    You can check your answer by using the formula for the area of a
    parallelogram, <span style={{ fontStyle: "italic" }}>A</span> ={" "}
    <span style={{ fontStyle: "italic" }}>bh</span>.
  </div>,
];

export const showReader = () => {
  return false;
};
