import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Linear Equations</span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>In this lesson you will:</span>
          <BulletPoints>
            <li>Learn how to graph a linear equation.</li>
            <li>
              Write linear equations in the form <Italic>y = mx</Italic> for
              lines that pass through the origin.
            </li>
            <li>
              Write linear equations in the form <Italic>y = mx</Italic> +{" "}
              <Italic>b</Italic> for lines that intercept the vertical axis at{" "}
              <Italic>b</Italic>.
            </li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>
            Click or tap the photo to learn more about the Moon’s gravity.
          </Instructions>
          <FlipBox backPadding="20px" width="400px" height="267px">
            <img src="https://d16qzots3do3wk.cloudfront.net/images/54e6553b5e5cf5b9ee32f6d984691b56.webp" />
            <div>
              <div style={{ textAlign: "left", fontSize: "16px" }}>
                The Moon's gravity is about one-sixth of Earth's gravity. So, if
                you weigh 150 pounds on Earth, your weight on the Moon would be
                25 pounds. A 30 -pound dog would weigh just 5 pounds on the
                Moon!
              </div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  marginTop: "20px",
                }}
              >
                You can use the formula <Italic>y</Italic> =
                <Fraction numerator="1" denominator="6" />
                <Italic>x</Italic> to predict the weight of an object on the
                Moon, where <Italic>x</Italic> represents the object’s weight on
                Earth.
              </div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
