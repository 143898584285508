import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply: Linear Equations</span>
      </div>
      <div className={css.contents}>
        <div>A line is graphed on the coordinate plane shown.</div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/4935dbfc396499db26087f9e9153aa4a.svg"
            width="60%"
          />
        </Center>
      </div>
    </div>
  );
};

export default Contents;
