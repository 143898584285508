import * as React from "react"

function AudioIcon(props) {
    return (
        <svg
            width={64}
            height={64}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_1438_705)">
                <circle cx={32} cy={30} r={24} fill="#FBFCFD" />
            </g>
            <circle cx={32} cy={30} r={20} fill="#FFCF4B" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.804 20.187a1.121 1.121 0 00-1.024.083l-7.971 5.373h-4.785c-.271 0-.532.098-.724.271a.884.884 0 00-.3.655v7.412c0 .245.108.48.3.655.192.173.453.27.724.27h4.785l7.92 5.374a1.12 1.12 0 001.024.083c.178-.07.331-.185.44-.331a.866.866 0 00.175-.493V21.01a.862.862 0 00-.153-.484.995.995 0 00-.41-.34zm3.883 6.16a1.094 1.094 0 00-.706-.222 1.079 1.079 0 00-.685.27.887.887 0 00-.299.62.873.873 0 00.246.638c.38.345.683.753.89 1.203a3.4 3.4 0 01.311 1.419 3.4 3.4 0 01-.312 1.419c-.206.45-.508.859-.89 1.203a.883.883 0 00-.297.653c0 .245.107.48.298.653.191.172.451.27.722.27.27 0 .53-.098.722-.27 1.151-1.042 1.798-2.455 1.798-3.928 0-1.473-.647-2.886-1.798-3.928zm1.157-1.968c0-.245.107-.48.298-.654.192-.172.451-.269.722-.269.27 0 .53.097.722.27 1.92 1.737 3 4.093 3 6.549 0 2.456-1.08 4.812-3 6.55-.192.172-.451.269-.722.269-.27 0-.53-.097-.722-.27a.883.883 0 01-.298-.653c0-.244.107-.48.298-.653a7.41 7.41 0 001.779-2.405 6.8 6.8 0 00.624-2.838 6.8 6.8 0 00-.624-2.838 7.41 7.41 0 00-1.78-2.405.883.883 0 01-.297-.653z"
                fill="#131313"
            />
            <defs>
                <filter
                    id="filter0_d_1438_705"
                    x={0}
                    y={0}
                    width={64}
                    height={64}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={2} />
                    <feGaussianBlur stdDeviation={4} />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1438_705"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1438_705"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default AudioIcon
