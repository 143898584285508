import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Which TWO sentences from “Henry's Train Ride” BEST support the
        conclusion that Henry has never been on a train?
      </div>
      <div className={css.instructions}>
        Click or tap on the text to select or deselect it. Click or tap the
        Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
