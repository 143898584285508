import React, { useState } from "react";
import css from "./Contents.module.css";

import Highlight from "../../../../Highlight";

import Reset from "../../../../Icons/Reset";
import Bold from "../../../../Bold";
import Image from "../Assets/ela_2_1.JPG";
import Hotspot from "../../../../Hotspot";

const Contents = (props) => {
  const selectHandler = (hotspot) => {
    const data = { selected: hotspot.index };
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.box}>
        <div className={css.left}>
          <div className={css.title}>
            <Hotspot
              index={0}
              selected={props.data.selected == 0}
              onSelected={selectHandler}
            >
              Sandstone Arches
            </Hotspot>
          </div>
          <div className={css.text}>
            The arches in the park of made of sandstone.{" "}
            <Hotspot
              index={1}
              inline
              selected={props.data.selected == 1}
              onSelected={selectHandler}
            >
              <Bold>Sandstone</Bold>
            </Hotspot>{" "}
            is a kind of rock. It is made of round grains of sand. Other
            materials hold the sand together. They act like glue. But water can
            wash some of the materials away.
          </div>
        </div>
        <div className={css.right}>
          <div className={css.image}>
            <Hotspot
              index={2}
              selected={props.data.selected == 2}
              onSelected={selectHandler}
            >
              <img src={Image} alt="image" />
            </Hotspot>
          </div>

          <div className={css.title}>
            <Hotspot
              index={3}
              selected={props.data.selected == 3}
              onSelected={selectHandler}
            >
              This arch in Arches National Park is called "Delicate Arch." It is
              a famous Utah landmark.
            </Hotspot>
          </div>
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
