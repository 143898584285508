import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! Line 1 goes down from left to right, so it has a negative
        slope. It does not pass through the origin, so it can only be expressed
        in the form <Italic>y</Italic> = <Italic>mx</Italic> +{" "}
        <Italic>b</Italic>. Line 2 passes through (0,0) , so it can be
        represented by an equation in the form <Italic>y</Italic> ={" "}
        <Italic>mx</Italic>.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[1]) {
    contents = (
      <div className={css.text}>
        Try again! Look at Line 1. It does not pass through the origin.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        Try again! Look at Line 2. It goes up from left to right. Is the slope
        positive or negative?
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>You are almost there! Keep trying!</div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
