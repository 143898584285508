import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Terrific job! The shaded grids show the number 3.2. The model shows 2
        equal groups of 1.6 each. This means 3.2 ÷ 1.6 = 2. A quotient can show
        the number in each group or the number of equal groups. In this
        equation, the divisor, 1.6, is the number in each group and the
        quotient, 2, is the number of equal groups.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Try your first answer again. Each large square represents one whole.
        Each small square represents one hundredth. Each column of 10 small
        squares represents one tenth.
      </div>
    );
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Try your second answer again. Equal groups are shown with different
        shades of color.
      </div>
    );
  } else if (props.data.indices[2] != correctAnswers[2]) {
    contents = (
      <div className={css.text}>
        Try your third answer again. Since the light squares and the dark
        squares represent equal groups, you only need to count squares in one of
        the groups.
      </div>
    );
  } else if (props.data.indices[3] != correctAnswers[3]) {
    contents = (
      <div className={css.text}>
        Try your last answer again. The shaded grids show the number 3.2. The
        model shows 2 equal groups of 1.6 each.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
