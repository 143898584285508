import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        As a train of roller-coaster cars climbs a hill, it gathers potential
        energy for use later. When the cars stop, there is no kinetic energy at
        all. As gravity pulls the train down the other side of the hill, the
        amount of potential energy decreases, and the amount of kinetic energy
        increases.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        The effect you have dragged to the first cause is incorrect because if
        the train is in motion, it has kinetic energy. Reread paragraph 3 and
        try again.
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        The effect of a train of roller-coaster cars slowly climbing a hill is
        not a decrease in the amount of potential energy. Reread paragraph 3 and
        try again.
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        You have dragged the wrong effect to the second cause. Is there any
        transference of energy when a train of roller-coaster cars is not moving
        at all? Reread paragraph 3 and try again.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        Be careful! The train does have potential energy during the second
        cause, but it does not “gather” potential energy when it is stopped.
        Reread paragraph 3 and try again.
      </div>
    );
  } else if (props.data.left[2] == 0) {
    contents = (
      <div className={css.text}>
        You have dragged the wrong effect to the third cause. A train that is
        moving has kinetic energy. Reread paragraph 3 and try again.
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        You have dragged the wrong effect to the third cause. The train does not
        gather potential energy when the train’s height decreases. Reread
        paragraph 3 and try again.
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        Make sure you drag each sentence to the chart before checking your
        answer.
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        Make sure you drag each sentence to the chart before checking your
        answer.
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        Make sure you drag each sentence to the chart before checking your
        answer.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
