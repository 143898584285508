import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Precise Language</span>
      </div>
      <div className={css.contents}>
        <span>In this lesson, you will ...</span>
        <BulletPoints>
          <li>discover how to use precise language in descriptive writing</li>
          <li>
            learn about sensory details and other specific words that writers
            use to communicate
          </li>
        </BulletPoints>
        <div>
          Writers use precise language to make their writing clearer, more
          interesting, and easier to understand.
        </div>
      </div>
    </div>
  );
};

export default Contents;
