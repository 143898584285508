import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Identify Positive and Negative Slopes</span>
      </div>
      <div className={css.contents}>
        <div>Line 1 and Line 2 are graphed on the coordinate plane shown.</div>
        <Center>
          <img
            width="60%"
            src="https://d16qzots3do3wk.cloudfront.net/images/2542abb959c3288ea0b2c9dc5abc34e0.svg"
          />
        </Center>
      </div>
    </div>
  );
};

export default Contents;
