import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  let numCorrect = 0;
  if (props.data.left[0] == correctAnswers[0]) numCorrect++;
  if (props.data.left[1] == correctAnswers[1]) numCorrect++;
  if (props.data.left[2] == correctAnswers[2]) numCorrect++;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(0)}You correctly selected events that happen at the
        beginning, middle, and end of the story. In the beginning of the story,
        you learn that Sir Hops A. Lot is making trouble with his friends. In
        the middle, the animals try to fix the trouble by having a jumping
        contest. In the end, Sir Hops A. Lot jumps into the sky and can no
        longer make trouble.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(1)}Please drag and drop your answer!
      </div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        <Italic>{languageLookup(2)}Beginning</Italic> {languageLookup(3)}does
        not match “The animals have a contest.” There is an event that happens
        earlier in the story that causes the animals to have a contest. Try
        again!
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        {languageLookup(4)}End does not match “The animals have a contest.”
        Because of the contest, something else then happens. Think about how one
        event leads to another. Try again!
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        {languageLookup(5)}Beginning does not match “Sir Hops A. Lot jumps into
        the sky.” There is an event that happens earlier in the story that
        causes Sir Hops A. Lot to jump into the sky. Try again!
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        {languageLookup(6)}Middle does not match “Sir Hops A. Lot jumps into the
        sky.” This event happens later in the story. Another event happens
        before this event. Try again!
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        {languageLookup(7)}Middle does not match “Sir Hops A. Lot jumps on
        Squirrel’s home.” This event introduces you to Sir Hops A. Lot and lets
        you know he is causing trouble for his friends. When in the story do you
        learn this? Try again!
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        {languageLookup(8)}End does not match “Sir Hops A. Lot jumps on
        Squirrel’s home.” This event happens earlier in the story and causes
        other events to happen. Think about how one event leads to another. Try
        again!
      </div>
    );
  } else if (numCorrect == 2) {
    contents = (
      <div className={css.text}>
        <div>
          {languageLookup(9)}The correct word for "The animals have a contest"
          is <Bold>{languageLookup(10)}middle</Bold>.
        </div>
        <div>
          {languageLookup(11)}The correct word for "Sir Hops A. Lot jumps into
          the sky" is <Bold>{languageLookup(12)}end</Bold>.
        </div>
        <div>
          {languageLookup(13)}The correct word for "Sir Hops A. Lot jumps on
          Squirrel's home" is <Bold>{languageLookup(14)}beginning</Bold>.
        </div>
        <div>
          {languageLookup(15)}Drag each word to the correct event to move on to
          the next question.{" "}
        </div>
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        {languageLookup(16)}
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        {languageLookup(17)}
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  } else if (props.data.left[2] == 0) {
    contents = (
      <div className={css.text}>
        {languageLookup(18)}
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
