import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Identify Sensory Words</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            Read the sentences. Look at the bolded words, and then answer the
            question.
          </Bold>
        </div>
        <div>
          Marcia’s <Bold>fluffy</Bold> dog yapped loudly as he{" "}
          <Bold>jumped</Bold> into the pond.
        </div>
        <div>
          My dad complimented the chef on the <Bold>fantastic</Bold> spicy fries
          in his <Bold>booming</Bold> voice.
        </div>
        <div>
          Trying to hide his discomfort, David put on the <Bold>itchy</Bold>{" "}
          sweater Grandma Joan had <Bold>knitted</Bold> for him.
        </div>
      </div>
    </div>
  );
};

export default Contents;
