import React, { createRef } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import { store, utils } from "@indieasy.software/indieasy-engine";
import { components } from "@indieasy.software/indieasy-components";

import css from "./App.module.css";
import tooltipCss from "../../tooltip.module.css";
import tooltipDisabledCss from "../../tooltip.module.css";
import TutorialTip from "../../../../common/components/TutorialTip";

import Home from "../Home";
import LearningGoal from "../LearningGoal";
import Lessons from "../Lessons";
import Rewards from "../Rewards";
import ClassView from "../ClassView";

import Videos from "../Videos";
import { K2Lessons } from "../K2/K2Lessons/K2Lessons";
import { K2Home } from "../K2/K2Home/K2Home";
import { Play } from "../Play/Play";
import { K2Games } from "../K2/K2Games";

const { ToolTip } = components;

const App = (props) => {
  const wrapperRef = createRef();
  /*
    useEffect(() => {
      const script = document.createElement("script");
  
      script.src = "https://kit.fontawesome.com/a076d05399.js";
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  */
  const setScrollHandler = (value) => {
    wrapperRef.current.style.overflowY = value ? "auto" : "hidden";
  };

  const scrollTo = (value) => {
    wrapperRef.current.scrollTo(value);
  };

  return (
    <div ref={wrapperRef} className={css.wrapper}>
      <Provider store={store.getStore()}>
        <BrowserRouter basename={utils.url.getBaseURL()}>
          <ToolTip css={tooltipCss} />
          {/* <ToolTip css={tooltipDisabledCss} managerId="disabled" /> */}
          <div className={css.subwrapper}>
            <TutorialTip toggleStyle={{ top: "2px", right: "2px" }} />
          </div>
          <div className={css.app}>
            <div className={css.contents}>
              <Switch>
                <Route>
                  <Switch>
                    <Route path="/lesson-ela-1">
                      <K2Lessons subject="ela" grade={1} language="en" />
                    </Route>

                    <Route path="/lesson-ela-2">
                      <Lessons subject="ela" grade={2} language="en" />
                    </Route>
                    <Route path="/lesson-ela-3">
                      <Lessons subject="ela" grade={3} language="en" />
                    </Route>
                    <Route path="/lesson-ela-3-sp">
                      <Lessons subject="ela" grade={3} language="sp" />
                    </Route>
                    <Route path="/lesson-ela-4">
                      <Lessons subject="ela" grade={4} language="en" />
                    </Route>
                    <Route path="/lesson-ela-5">
                      <Lessons subject="ela" grade={5} language="en" />
                    </Route>
                    <Route path="/lesson-ela-6">
                      <Lessons subject="ela" grade={6} language="en" />
                    </Route>
                    <Route path="/lesson-ela-7">
                      <Lessons subject="ela" grade={7} language="en" />
                    </Route>
                    <Route path="/lesson-ela-7-sp">
                      <Lessons subject="ela" grade={7} language="sp" />
                    </Route>
                    <Route path="/lesson-ela-8">
                      <Lessons subject="ela" grade={8} language="en" />
                    </Route>

                    <Route path="/lesson-math-1">
                      <K2Lessons subject="math" grade={1} language="en" />
                    </Route>

                    <Route path="/lesson-math-2">
                      <Lessons subject="math" grade={2} language="en" />
                    </Route>
                    <Route path="/lesson-math-3">
                      <Lessons subject="math" grade={3} language="en" />
                    </Route>
                    <Route path="/lesson-math-3-sp">
                      <Lessons subject="math" grade={3} language="sp" />
                    </Route>
                    <Route path="/lesson-math-4">
                      <Lessons subject="math" grade={4} language="en" />
                    </Route>
                    <Route path="/lesson-math-5">
                      <Lessons subject="math" grade={5} language="en" />
                    </Route>
                    <Route path="/lesson-math-6">
                      <Lessons subject="math" grade={6} language="en" />
                    </Route>
                    <Route path="/lesson-math-7">
                      <Lessons subject="math" grade={7} language="en" />
                    </Route>
                    <Route path="/lesson-math-7-sp">
                      <Lessons subject="math" grade={7} language="sp" />
                    </Route>
                    <Route path="/lesson-math-8">
                      <Lessons subject="math" grade={8} language="en" />
                    </Route>

                    <Route path="/learninggoal-ela-1">
                      <LearningGoal subject="ela" grade={1} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-2">
                      <LearningGoal subject="ela" grade={2} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-3">
                      <LearningGoal subject="ela" grade={3} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-4">
                      <LearningGoal subject="ela" grade={4} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-5">
                      <LearningGoal subject="ela" grade={5} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-6">
                      <LearningGoal subject="ela" grade={6} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-7">
                      <LearningGoal subject="ela" grade={7} language="en" />
                    </Route>
                    <Route path="/learninggoal-ela-8">
                      <LearningGoal subject="ela" grade={8} language="en" />
                    </Route>

                    <Route path="/learninggoal-math-1">
                      <LearningGoal subject="math" grade={1} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-2">
                      <LearningGoal subject="math" grade={2} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-3">
                      <LearningGoal subject="math" grade={3} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-4">
                      <LearningGoal subject="math" grade={4} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-5">
                      <LearningGoal subject="math" grade={5} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-6">
                      <LearningGoal subject="math" grade={6} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-7">
                      <LearningGoal subject="math" grade={7} language="en" />
                    </Route>
                    <Route path="/learninggoal-math-8">
                      <LearningGoal subject="math" grade={8} language="en" />
                    </Route>

                    <Route path="/game-ela-1">
                      <K2Games grade={1} subject="ela" />
                    </Route>
                    <Route path="/game-math-1">
                      <K2Games grade={1} subject="math" />
                    </Route>

                    <Route path="/play-math(.*)">
                      <Play subject="math" />
                    </Route>

                    <Route path="/play-ela(.*)">
                      <Play subject="ela" />
                    </Route>

                    <Route path="/rewards-ela-1">
                      <Rewards subject="ela" grade={1} language="en" />
                    </Route>
                    <Route path="/rewards-ela-2">
                      <Rewards subject="ela" grade={2} language="en" />
                    </Route>
                    <Route path="/rewards-ela-3">
                      <Rewards subject="ela" grade={3} language="en" />
                    </Route>
                    <Route path="/rewards-ela-4">
                      <Rewards subject="ela" grade={4} language="en" />
                    </Route>
                    <Route path="/rewards-ela-5">
                      <Rewards subject="ela" grade={5} language="en" />
                    </Route>
                    <Route path="/rewards-ela-6">
                      <Rewards subject="ela" grade={6} language="en" />
                    </Route>
                    <Route path="/rewards-ela-7">
                      <Rewards subject="ela" grade={7} language="en" />
                    </Route>
                    <Route path="/rewards-ela-8">
                      <Rewards subject="ela" grade={8} language="en" />
                    </Route>

                    <Route path="/rewards-math-1">
                      <Rewards subject="math" grade={1} language="en" />
                    </Route>
                    <Route path="/rewards-math-2">
                      <Rewards subject="math" grade={2} language="en" />
                    </Route>
                    <Route path="/rewards-math-3">
                      <Rewards subject="math" grade={3} language="en" />
                    </Route>
                    <Route path="/rewards-math-4">
                      <Rewards subject="math" grade={4} language="en" />
                    </Route>
                    <Route path="/rewards-math-5">
                      <Rewards subject="math" grade={5} language="en" />
                    </Route>
                    <Route path="/rewards-math-6">
                      <Rewards subject="math" grade={6} language="en" />
                    </Route>
                    <Route path="/rewards-math-7">
                      <Rewards subject="math" grade={7} language="en" />
                    </Route>
                    <Route path="/rewards-math-8">
                      <Rewards subject="math" grade={8} language="en" />
                    </Route>

                    <Route path="/classview-ela-1">
                      <ClassView subject="ela" grade={1} language="en" />
                    </Route>
                    <Route path="/classview-ela-2">
                      <ClassView subject="ela" grade={2} language="en" />
                    </Route>
                    <Route path="/classview-ela-3">
                      <ClassView subject="ela" grade={3} language="en" />
                    </Route>
                    <Route path="/classview-ela-4">
                      <ClassView subject="ela" grade={4} language="en" />
                    </Route>
                    <Route path="/classview-ela-5">
                      <ClassView subject="ela" grade={5} language="en" />
                    </Route>
                    <Route path="/classview-ela-6">
                      <ClassView subject="ela" grade={6} language="en" />
                    </Route>
                    <Route path="/classview-ela-7">
                      <ClassView subject="ela" grade={7} language="en" />
                    </Route>
                    <Route path="/classview-ela-8">
                      <ClassView subject="ela" grade={8} language="en" />
                    </Route>

                    <Route path="/classview-math-1">
                      <ClassView subject="math" grade={1} language="en" />
                    </Route>
                    <Route path="/classview-math-2">
                      <ClassView subject="math" grade={2} language="en" />
                    </Route>
                    <Route path="/classview-math-3">
                      <ClassView subject="math" grade={3} language="en" />
                    </Route>
                    <Route path="/classview-math-4">
                      <ClassView subject="math" grade={4} language="en" />
                    </Route>
                    <Route path="/classview-math-5">
                      <ClassView subject="math" grade={5} language="en" />
                    </Route>
                    <Route path="/classview-math-6">
                      <ClassView subject="math" grade={6} language="en" />
                    </Route>
                    <Route path="/classview-math-7">
                      <ClassView subject="math" grade={7} language="en" />
                    </Route>
                    <Route path="/classview-math-8">
                      <ClassView subject="math" grade={8} language="en" />
                    </Route>

                    <Route path="/home-ela-1">
                      <K2Home subject="ela" grade={1} language="en" />
                    </Route>

                    <Route path="/home-ela-1/es">
                      <K2Home subject="ela" grade={1} language="es" />
                    </Route>

                    <Route path="/home-ela-2">
                      <Home subject="ela" grade={2} language="en" />
                    </Route>
                    <Route path="/home-ela-3">
                      <Home subject="ela" grade={3} language="en" />
                    </Route>
                    <Route path="/home-ela-4">
                      <Home subject="ela" grade={4} language="en" />
                    </Route>
                    <Route path="/home-ela-5">
                      <Home subject="ela" grade={5} language="en" />
                    </Route>
                    <Route path="/home-ela-6">
                      <Home subject="ela" grade={6} language="en" />
                    </Route>
                    <Route path="/home-ela-7">
                      <Home subject="ela" grade={7} language="en" />
                    </Route>
                    <Route path="/home-ela-8">
                      <Home subject="ela" grade={8} language="en" />
                    </Route>



                    <Route path="/home-math-1/:language">
                      <K2Home subject="math" grade={1} />
                    </Route>

                    <Route path="/home-math-2">
                      <Home subject="math" grade={2} language="en" />
                    </Route>
                    <Route path="/home-math-3">
                      <Home subject="math" grade={3} language="en" />
                    </Route>
                    <Route path="/home-math-4">
                      <Home subject="math" grade={4} language="en" />
                    </Route>
                    <Route path="/home-math-5">
                      <Home subject="math" grade={5} language="en" />
                    </Route>
                    <Route path="/home-math-6">
                      <Home subject="math" grade={6} language="en" />
                    </Route>
                    <Route path="/home-math-7">
                      <Home subject="math" grade={7} language="en" />
                    </Route>
                    <Route path="/home-math-8">
                      <Home subject="math" grade={8} language="en" />
                    </Route>

                    <Route path="/videos-ela-1-0">
                      <Videos subject="ela" grade="1" language="en" index={0} />
                    </Route>
                    <Route path="/videos-ela-1-1">
                      <Videos subject="ela" grade="1" language="en" index={1} />
                    </Route>
                    <Route path="/videos-ela-1-2">
                      <Videos subject="ela" grade="1" language="en" index={2} />
                    </Route>
                    <Route path="/videos-math-1-0">
                      <Videos
                        subject="math"
                        grade="1"
                        language="en"
                        index={0}
                      />
                    </Route>
                    <Route path="/videos-math-1-1">
                      <Videos
                        subject="math"
                        grade="1"
                        language="en"
                        index={1}
                      />
                    </Route>
                    <Route path="/videos-math-1-2">
                      <Videos
                        subject="math"
                        grade="1"
                        language="en"
                        index={2}
                      />
                    </Route>

                    <Route path="/">
                      <Redirect to="/home-ela-3" />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default App;
