import React from "react";
import css from "./Icon.module.css";

const Icon = (props) => {
  const contents = props.selected ? (
    <div className={css.fill} />
  ) : (
    <div className={css.empty} />
  );
  return (
    <div
      className={css.root}
      onMouseDown={() => props.onSelect(props.slot, props.index)}
    >
      {contents}
    </div>
  );
};

export default Icon;
