import React from "react";
import css from "./Background.module.css";

const Background = (props) => {
  return (
    <div className={css.root}>
      <img className={css.background} src={props.value} />
    </div>
  );
};

export default Background;
