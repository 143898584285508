import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Learn How Connotations Can Change a Sentence
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Writers carefully choose words to give each sentence just the right
          meaning. Choosing the synonym with the right connotation helps readers
          to better understand the author’s feelings.
        </div>
        <div>
          Several different words that mean “a smell” could be used to finish
          the following sentence. How does each word change the feeling behind
          the sentence?
        </div>
        <BlockQuote>Entering the kitchen, Cal noticed _____.</BlockQuote>
        <Instructions>
          Click or tap each word to see its connotation.
        </Instructions>
        <FoldBox>
          <div>an odor</div>
          <div>An odor is usually unpleasant. Sour milk has an odor.</div>
        </FoldBox>
        <FoldBox>
          <div>a stench</div>
          <div>A stench is repulsive. Trash has a stench.</div>
        </FoldBox>
        <FoldBox>
          <div>an aroma</div>
          <div>An aroma is appealing. Baked bread has an aroma.</div>
        </FoldBox>
        <FoldBox>
          <div>a fragrance</div>
          <div>A fragrance is pleasant. Vanilla has a fragrance.</div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
