import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply: Dividing Decimals</span>
      </div>
      <div className={css.contents}>
        <div>One inch is equivalent to 2.54 centimeters.</div>
      </div>
    </div>
  );
};

export default Contents;
