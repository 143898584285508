import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Find Missing Numerators and Denominators in Subtraction Sentences
        </span>
      </div>
      <div className={css.contents}>
        <div>
          When subtracting fractions with the same denominators, subtract the
          numerators and keep the denominator the same in the difference.
        </div>
        <div>What is the missing numerator and denominator?</div>
        <Center>
          <span>
            <Fraction numerator="4" denominator="4" /> −{" "}
            <Fraction numerator="1" denominator="4" /> ={" "}
            <Fraction numerator="?" denominator="?" />
          </span>
        </Center>
        <Instructions>
          Click or tap each box to find the missing numbers.
        </Instructions>
        <div className={css.row}>
          <FlipBox padding="20px" backPadding="20px" width="40%" height="200px">
            <div>Find the missing numerator.</div>
            <div style={{ textAlign: "left" }}>
              Since 4 − 1 = 3, the missing numerator is 3.
            </div>
          </FlipBox>
          <FlipBox padding="20px" backPadding="20px" width="40%" height="200px">
            <div>Find the missing denominator.</div>
            <div style={{ textAlign: "left" }} className={css.fb}>
              <div>
                When subtracting fractions with the same denominator, the
                denominator stays the same in the difference.
              </div>
              <div>So, the missing denominator is 4.</div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
