import React from "react";

import Center from "./Center";
import Island from "./Island/Island";
import css from "./Islands.module.css";

const Islands = (props) => {
  return (
    <div style={{ top: props.top, left: props.left }} className={css.root}>
      <Center />
      <Island
        top="-125px"
        left="-200px"
        icon="https://practice.gogetwaggle.com/student/public/images/world3-mini.png"
        people="2"
        title="Hero City"
      />
      <Island
        top="100px"
        left="175px"
        icon="https://practice.gogetwaggle.com/student/public/images/world1-mini.png"
        people="6"
        title="Oasis Outpost"
      />
      <Island
        top="300px"
        left="-200px"
        icon="https://practice.gogetwaggle.com/student/public/images/world2-mini.png"
        people="2"
        title="Planet Zebus"
      />
    </div>
  );
};

export default Islands;
