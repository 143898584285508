import { useCallback, useState } from "react";
import { playVoiceover } from "../voiceover";

export const useHints = (hints, setFoxState, language = 'en') => {
  const [currentHint, setCurrentHint] = useState(0);
  const [playVideo, setPlayVideo] = useState(false);

  const getHint = useCallback(() => {
    if (currentHint < hints.length) {
      playVoiceover(hints[currentHint], setFoxState);
      setCurrentHint((h) => h + 1);
    } else if (currentHint === hints.length) {
      setPlayVideo(true);
      setCurrentHint(0);
      playVoiceover(`hint1_l0_math_vo_${language}`, setFoxState);
    }
  }, [currentHint, hints, language, setFoxState]);

  const closeVideo = useCallback(() => setPlayVideo(false), []);

  return {
    getHint,
    playVideo,
    closeVideo,
  };
};
