import React from "react";
import css from "./Shape.module.css";

const texts = [396, 200, 198, 400, 616, 221, 504, 500, 442];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let text = texts[props.index];

  return (
    <div
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
      className={css.root}
      style={style}
    >
      <div className={css.contents}>{text}</div>
    </div>
  );
};

export default Shape;
