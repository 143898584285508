import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "Use Repeated Addition and Multiplication to Find the Area of a Rectangle",
    sp: "Utilizar la suma repetida y la multiplicación para hallar el área de un rectángulo",
  },
  0: {
    en: "Each unit square is 1 square foot.",
    sp: "Cada cuadrado de una unidad mide 1 pie cuadrado.",
  },
  1: {
    en: "Find the area of the figure.",
    sp: "Halla el área de la figura.",
  },
  2: {
    en: "Click or tap the correct answer from each drop-down menu.",
    sp: "Pulsa o haz clic en la respuesta correcta de cada menú desplegable.",
  },
  3: {
    en: "Think: There are",
    sp: "Piensa: Hay",
  },
  4: {
    en: "Choose",
    sp: "Elige",
  },
  5: {
    en: "rows of 10 unit squares.",
    sp: "filas de 10 cuadrados de una unidad.",
  },
  6: {
    en: "Add:",
    sp: "Suma:",
  },
  7: {
    en: "Choose",
    sp: "Elige",
  },
  8: {
    en: "Or, multiply:",
    sp: "O multiplica:",
  },
  9: {
    en: "Choose",
    sp: "Elige",
  },
  10: {
    en: "So, the area is",
    sp: "Entonces, el área mide",
  },
  11: {
    en: "Choose",
    sp: "Elige",
  },
  12: {
    en: "square feet.",
    sp: "pies cuadrados.",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(0)}</div>
        <div className={css.inst}>
          <Bold>{languageLookup(1)}</Bold>
        </div>
        <Instructions>{languageLookup(2)}</Instructions>
        <div className={css.row}>
          <span>{languageLookup(3)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(4)}</Italic>
                </div>
              }
              data={[{ text: "1" }, { text: "4" }, { text: "10" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(5)}</span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(6)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="250px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(7)}</Italic>
                </div>
              }
              data={[
                { text: "10 + 4 = 14" },
                { text: "10 + 10 + 10 + 10 = 40" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(8)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(9)}</Italic>
                </div>
              }
              data={[{ text: "4 x 10 = 40" }, { text: "4 x 4 = 16" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(10)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[3] - 1}
              selectIndex={(index) => selectIndexHandler(3, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(11)}</Italic>
                </div>
              }
              data={[{ text: "4" }, { text: "10" }, { text: "40" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(12)}</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
