import React from "react";
import css from "./Close.module.css";

const Audio = (props) => {
  return (
    <div className={css.root} onMouseDown={() => props.onClick()}>
      {props.circle ? (
        <img
          src="https://practice.gogetwaggle.com/student/public/images/pasge_close.png"
          alt="icon"
        />
      ) : (
        <img
          src="https://practice.gogetwaggle.com/student/public/images/footerimages/close_1.png"
          alt="icon"
        />
      )}
    </div>
  );
};

export default Audio;
