import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            Read the sentences. Look at the bolded words, and then answer the
            question.
          </Bold>
        </div>
        <div>
          Jean and Alvin caught a <Bold>shimmering</Bold> fish in their{" "}
          <Bold>net.</Bold>
        </div>
        <div>
          The glass bead landed in the blue <Bold>dish</Bold> with a{" "}
          <Bold>click.</Bold>
        </div>
        <div>
          We were awakened by a <Bold>wailing</Bold> siren from the{" "}
          <Bold>old</Bold> stone lighthouse.
        </div>
      </div>
    </div>
  );
};

export default Contents;
