import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";
import Fraction from "../../../../Fraction";

export default (data, setData) => {};

export const correctAnswers = [3, 3, 3];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    You are looking for how many <Fraction numerator="1" denominator="5" />{" "}
    -mile intervals there are in 2 miles. Find the equation that shows how many{" "}
    <Fraction numerator="1" denominator="5" /> parts are there in 2 .
  </div>,
  <div>Look at the number line. There are 5 fifths in 1 whole. </div>,
  <div>Count the jumps to find how many fifths there are in 2 wholes.</div>,
];

export const showReader = () => {
  return false;
};
