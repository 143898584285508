import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import DropDown from "../../../../../components/DropDown";

import Background from "../Assets/math_8_0_img.JPG";

const Contents = (props) => {
  const [isDDOpen, setIsDDOpen] = useState(false);

  const selectIndexHandler = (slot, index) => {
    const data = {
      indices: [...props.data.indices],
      inputs: [...props.data.inputs],
    };
    data.indices[slot] = index;

    props.setData(data);
  };

  const inputHandler = (slot, ev) => {
    const data = {
      indices: [...props.data.indices],
      inputs: [...props.data.inputs],
    };
    data.inputs[slot] = ev.target.value;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.text}>
          Mavis had already taken some pictures when she wondered how many more
          would fit on the memory card. She began tracking the amount of memory
          used as she added new pictures. The scatter plot shows the data she
          collected.
        </div>
        <div className={css.image}>
          <img src={Background} alt="bg" />
        </div>
      </div>
      <div className={css.bottom}>
        <div className={css.equation + (isDDOpen ? " " + css.open : "")}>
          Linear model:
          <span className={css.dropdown}>
            <DropDown
              activity
              width="175px"
              selectedIndex={props.data.indices[0] - 1}
              onOpen={() => setIsDDOpen(true)}
              onClose={() => setIsDDOpen(false)}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText="Choose"
              data={[
                { text: "y = 30x + 40" },
                { text: "y = 40x + 0.033" },
                { text: "y = 0.033x + 40" },
              ]}
            />
          </span>
        </div>
        <div className={css.equation}>
          The memory card
          <span className={css.dropdown}>
            <DropDown
              activity
              width="120px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText="Choose"
              data={[{ text: "fills" }, { text: "empties" }]}
            />
          </span>
          about 0.033
          <span className={css.dropdown}>
            <DropDown
              activity
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText="Choose"
              data={[
                { text: "percent per image" },
                { text: "image per percent" },
              ]}
            />
          </span>
          .
        </div>
        <div className={css.equation}>
          Initially,{" "}
          <input
            type="text"
            className={css.input}
            value={props.data.inputs[0]}
            onChange={(ev) => inputHandler(0, ev)}
          ></input>{" "}
          % of the memory card was full.
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
