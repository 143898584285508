import React, { Fragment } from "react";

import Arrow from "../Assets/math_7_1_arrow.png";

export default (data, setData) => {};

export const correctAnswers = [
  [0, 1, 3],
  [2, 4, 5],
];

export const isCorrect = (data) => {
  if (data.left[0].length != correctAnswers[0].length) return false;

  if (data.left[1].length != correctAnswers[1].length) return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      const answer = correctAnswers[i][j];
      if (!data.left[i].find((x) => x.id == answer)) return false;
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.right.filter((x) => x == -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [[], []],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (!isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <Fragment>
    <div>Identify the base of the rectangular pyramid.</div>
    <div
      style={{
        position: "absolute",
        top: "-85px",
        left: "325px",
        transform: "rotateZ(-45deg)",
        pointerEvents: "none",
      }}
    >
      <img src={Arrow} style={{ width: "100%" }} />
    </div>
  </Fragment>,
  <div>
    Visualize slicing the rectangular pyramid with a plane parallel to the base,
    a plane perpendicular to the base through the apex, and a plane
    perpendicular to the base but not through the apex.
  </div>,
  <div>
    If you slice the pyramid parallel to its base, the cross-section will have
    the same shape as the base.
  </div>,
  <div>
    If you slice the pyramid perpendicular to its base through the apex, the
    cross-section will have the same shape as a face of the pyramid.
  </div>,
  <Fragment>
    <div>
      The apex of a pyramid is the point at which the triangular faces of the
      pyramid meet.
    </div>
    <div
      style={{
        position: "absolute",
        top: "-170px",
        left: "313px",
        pointerEvents: "none",
        borderRadius: "50%",
        border: "2px solid #fa9e00",
        width: "35px",
        height: "35px",
      }}
    />
  </Fragment>,
];

export const showReader = () => {
  return false;
};
