import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Apply",
    sp: "¡Aplícalo!",
  },
  1: {
    en: "Read the following passage. Then answer the question.",
    sp: "Lee el siguiente fragmento. Luego, contesta la pregunta.",
  },
  2: {
    en: "People tend to confuse the cold and the flu. Although they are both caused by viral infections and share some symptoms, there are also important differences. It is important to know the difference because the flu is much more likely to lead to complications. Also, both of these illnesses typically develop in stages, with certain symptoms emerging as the infection progresses. But there are differences in the onset, severity, and duration of the typical cold or bout of influenza.",
    sp: "Las personas suelen confundir el resfrío común con la gripe. Aunque los dos son causados por infecciones virales y comparten algunos síntomas, también hay diferencias importantes. Es importante saber cuál es la diferencia porque es mucho más probable que la gripe traiga complicaciones. Además, las dos enfermedades generalmente se desarrollan por estadíos, y algunos síntomas solo aparecen a medida que avanza la infección. Pero hay diferencias en el inicio, la gravedad y la duración de un resfrío o una gripe típicos.",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>{languageLookup(1)}</div>
        <div className={css.detail}>{languageLookup(2)}</div>
      </div>
    </div>
  );
};

export default Contents;
