import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That's right! You correctly described the connotations of each word.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Look again. Recall other screeching sounds you've heard. Would you
        describe them as positive or negative?
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div className={css.text}>
        Take another look. In this case, the word "powerful" does not have a
        negative connotation.
      </div>
    );
  } else if (props.data.indices[2] == 1) {
    contents = (
      <div className={css.text}>
        Try again. The word "ear-splitting" in this example does not have a
        positive connotation.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
