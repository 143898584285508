import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        These sentences provide solid support for the conclusion. The movement
        was popular because many people would show up for demonstrations and
        would shout their approval. It was organized because the different
        groups would make plans together.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 2).length > 0) {
    contents = (
      <div className={css.text}>
        The information that the prime minister refused at first to meet the
        suffragettes would help you draw a conclusion about the prime minister,
        but not the conclusion that the suffragettes were popular or organized.
        Try again.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 4).length > 0) {
    contents = (
      <div className={css.text}>
        The information that the suffragettes were sometimes put in jail is not
        related to their popularity nor to how organized they were. From this
        information, you could draw a different conclusion, such as the
        suffragettes would gladly do dangerous or illegal things to make their
        point and demonstrate for their voting rights. Try again.{" "}
      </div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 0 || x.id == 1 || x.id == 3)
      .length == 1
  ) {
    contents = (
      <div className={css.text}>
        You are missing two pieces of information. Look for two more sentences
        that support the conclusion that the suffrage movement was popular and
        organized, and move them to the box.
      </div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 0 || x.id == 1 || x.id == 3)
      .length == 2
  ) {
    contents = (
      <div className={css.text}>
        You are missing one piece of information. Look for a sentence that
        supports the conclusion that the suffrage movement was organized, and
        move it to the box.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
