import React from "react";
import { K2Header } from "./K2Header";
import css from "./K2.module.css";
import { BottomToolbar } from "./BottomToolbar";
import { K2Progress } from "./K2Lessons/K2Progress";

export const K2Frame = ({ children, progress, grade, subject, language }) => (
  <div className={css.root}>
    <K2Header grade={grade} subject={subject} />
    <K2Progress progress={progress} />
    <div className={css.background}>{children}</div>
  </div>
);
