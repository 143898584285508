import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Complete the place-value chart, then choose the correct symbol and word
        to complete the comparison.
      </div>
      <div className={css.instructions}>
        Type the missing number into each blank. Click or tap the correct term
        from each drop-down menu.
      </div>
    </div>
  );
};

export default Sidebar;
