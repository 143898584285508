import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/g5_29_Divunit.mp4";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.contents}>
        <Instructions>Click or tap to watch the video.</Instructions>
        <video width="320" height="240" controls src={Video} />
      </div>
    </div>
  );
};

export default Contents;
