import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly dragged each food item into the correct category. You
        remembered that herbivores eat plants, carnivores eat animals, and
        omnivores eat both plants and animals.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (
    props.data.items[0].filter((x) => x == 2 || x == 3 || x == 4).length > 0
  ) {
    contents = (
      <div className={css.text}>
        Berries, nuts, and leaves are parts of plants. Think about what
        carnivores eat. Take another look at the passage, and then try again.
      </div>
    );
  } else if (
    (props.data.items[1].length != 3 ||
      props.data.items[1].filter((x) => x == 2).length == 0 ||
      props.data.items[1].filter((x) => x == 3).length == 0 ||
      props.data.items[1].filter((x) => x == 4).length == 0) &&
    props.data.items[1].filter((x) => x == 2).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that herbivores eat only plants, including leaves. But
        what types of foods do carnivores and omnivores eat? Take another look,
        and try again.
      </div>
    );
  } else if (
    (props.data.items[1].length != 3 ||
      props.data.items[1].filter((x) => x == 2).length == 0 ||
      props.data.items[1].filter((x) => x == 3).length == 0 ||
      props.data.items[1].filter((x) => x == 4).length == 0) &&
    props.data.items[1].filter((x) => x == 3).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that herbivores eat only plants, including nuts. But what
        types of foods do carnivores and omnivores eat? Take another look, and
        try again.
      </div>
    );
  } else if (
    (props.data.items[1].length != 3 ||
      props.data.items[1].filter((x) => x == 2).length == 0 ||
      props.data.items[1].filter((x) => x == 3).length == 0 ||
      props.data.items[1].filter((x) => x == 4).length == 0) &&
    props.data.items[1].filter((x) => x == 4).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that herbivores eat only plants, including berries. But
        what types of foods do carnivores and omnivores eat? Take another look,
        and try again.
      </div>
    );
  } else if (
    (props.data.items[0].length != 3 ||
      props.data.items[0].filter((x) => x == 0).length == 0 ||
      props.data.items[0].filter((x) => x == 1).length == 0 ||
      props.data.items[0].filter((x) => x == 5).length == 0) &&
    props.data.items[0].filter((x) => x == 0).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that carnivores eat only other animals, including ants.
        Think about what other type of animals eat other animals. Try again.
      </div>
    );
  } else if (
    (props.data.items[0].length != 3 ||
      props.data.items[0].filter((x) => x == 0).length == 0 ||
      props.data.items[0].filter((x) => x == 1).length == 0 ||
      props.data.items[0].filter((x) => x == 5).length == 0) &&
    props.data.items[0].filter((x) => x == 1).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that carnivores eat only other animals, including worms.
        Think about what other type of animals eat other animals. Try again.
      </div>
    );
  } else if (
    (props.data.items[0].length != 3 ||
      props.data.items[0].filter((x) => x == 0).length == 0 ||
      props.data.items[0].filter((x) => x == 1).length == 0 ||
      props.data.items[0].filter((x) => x == 5).length == 0) &&
    props.data.items[0].filter((x) => x == 5).length > 0
  ) {
    contents = (
      <div className={css.text}>
        You remembered that carnivores eat only other animals, including fish.
        Think about what other type of animals eat other animals. Try again.
      </div>
    );
  } else if (
    props.data.items[1].filter((x) => x == 0 || x == 1 || x == 5).length > 0
  ) {
    contents = (
      <div className={css.text}>
        Fish, ants, and worms are animals. What do herbivores eat? Take another
        look at the passage, and then try again.
      </div>
    );
  } else if (props.data.items[2].length < 6 && props.data.items[2].length > 0) {
    if (props.data.items[2].filter((x) => x == 0).length > 0) {
      contents = (
        <div className={css.text}>
          Omnivores are animals that eat other animals, which would include
          ants. Think about herbivores and carnivores. Do either of these types
          of animals eat other animals? Try again.
        </div>
      );
    } else if (props.data.items[2].filter((x) => x == 1).length > 0) {
      contents = (
        <div className={css.text}>
          Carnivores eat fish, ants, and worms. Herbivores eat berries, nuts,
          and leaves. Omnivores eat fish, ants, worms, berries, nuts, and
          leaves. Drag each food item to the correct category to move on to the
          next question.
        </div>
      );
    } else if (props.data.items[2].filter((x) => x == 2).length > 0) {
      contents = (
        <div className={css.text}>
          You correctly identified that omnivores eat plants, including leaves.
          What other type of animals would eat leaves? Take another look, and
          try again.
        </div>
      );
    } else if (props.data.items[2].filter((x) => x == 3).length > 0) {
      contents = (
        <div className={css.text}>
          You correctly identified that omnivores eat plants, which include
          nuts. What other type of animals would eat nuts? Take another look,
          and try again.
        </div>
      );
    } else if (props.data.items[2].filter((x) => x == 4).length > 0) {
      contents = (
        <div className={css.text}>
          Omnivores do eat berries. However, what other type of animal eats
          plants, including berries? Try again.
        </div>
      );
    } else if (props.data.items[2].filter((x) => x == 5).length > 0) {
      contents = (
        <div className={css.text}>
          Omnivores are animals that eat other animals, including fish. Think
          about herbivores and carnivores. Do either of these types of animals
          eat other animals? Try again.
        </div>
      );
    }
  } else if (props.data.items[1].length != 3) {
    contents = (
      <div className={css.text}>
        You remembered that carnivores eat only other animals, including fish.
        Think about what other type of animals eat other animals. Try again.
      </div>
    );
  } else if (props.data.items[0].length != 3) {
    contents = (
      <div className={css.text}>
        You remembered that herbivores eat only plants, including leaves. But
        what types of foods do carnivores and omnivores eat? Take another look,
        and try again.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        You remembered that carnivores eat only other animals, including fish.
        Think about what other type of animals eat other animals. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
