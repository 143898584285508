import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Italic from "../../../../Italic";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        In this excerpt, what does the phrase <Italic>protective gear</Italic>{" "}
        mean?
      </div>
      <Instructions>Click or tap the correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>specially designed skateboards</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>reduce injuries</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>safe skate parks</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>equipment that protects the body</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
