import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Use context clues to determine the BEST meaning of the word{" "}
        <Underline>charge</Underline> as it is used in stanza 1.
      </div>
      <div className={css.instructions}>Click or tap the box.</div>
    </div>
  );
};

export default Sidebar;
