import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Sentences</span>
      </div>
      <div className={css.contents}>
        <div>
          A <Bold>sentence</Bold> is a group of words that tells a complete
          thought.
        </div>
        <div>
          <span>There are three kinds of sentences.</span>
          <BulletPoints>
            <li>
              A <Bold>simple sentence</Bold> has a subject and a predicate.
              <div className={css.blockquote}>
                The duck swims in the little pond.
              </div>
            </li>
            <li>
              A <Bold>compound sentence</Bold> has two simple sentences joined
              together.
              <div className={css.blockquote}>
                Tara likes to run, and she is training for a race.
              </div>
            </li>
            <li>
              A <Bold>complex sentence</Bold> has an independent clause and a
              dependent clause..
              <div className={css.blockquote}>
                We went out for dinner after the game.
              </div>
            </li>
          </BulletPoints>
        </div>
        <div>Next, you will learn more about compound sentences.</div>
      </div>
    </div>
  );
};

export default Contents;
