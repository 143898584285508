import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import BlockQuote from "../../../../BlockQuote";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Precise Language</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>Writers use precise language to communicate clearly.</div>
          <div>
            <Bold>Precise</Bold> means to express an idea specifically and
            accurately.
          </div>
        </div>
        <div>
          <div>
            Precise language helps show emotions because words and phrases with
            similar meanings can show different things.{" "}
          </div>
          <BlockQuote>
            <div>
              Thora <Instructions span>walked</Instructions> out of the
              classroom after winning the contest.
            </div>
            <div>
              Thora <Instructions span>floated</Instructions> out of the
              classroom after winning the contest.
            </div>
          </BlockQuote>
          <div>
            Both <Italic>walked</Italic> and <Italic>floated</Italic> show that
            Thora left the classroom, but the second sentence expresses Thora’s
            happiness.
          </div>
        </div>
        <div>
          <div>
            Be careful! The goal is to communicate clearly, <Italic>not</Italic>{" "}
            to use difficult words.
          </div>
          <BlockQuote>
            Thora levitated in triumph over her vanquished peers, evacuating the
            classroom after winning the contest.
          </BlockQuote>
          <div>
            This sentence uses more difficult words, but the meaning may not be
            clear to the reader.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contents;
