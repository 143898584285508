import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Match each key detail to the main idea it supports in the passage
        “Treasure at Sea.”
      </div>
      <div className={css.instructions}>
        Drag each key detail to the main idea it supports. Click or tap the
        Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
