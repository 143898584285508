import React from "react";
import Profile from "./Profile/Profile";
import css from "./Profiles.module.css";

import data from "../../../data/profiles.json";

const Profiles = (props) => {
  const selectHandler = (p) => {
    if (props.onSelect) props.onSelect(p);
  };

  const profiles = data.map((x, index) => (
    <Profile index={index} key={index} data={x} onSelect={selectHandler} />
  ));

  return (
    <div className={css.root}>
      <div className={css.title}>Classmate Profiles</div>
      <div className={css.content}>{profiles}</div>
    </div>
  );
};

export default Profiles;
