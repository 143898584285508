import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_2_0_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>The Legend of Sir Hops A. Lot</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        A long time ago there lived a rabbit named Sir Hops A. Lot. He lived in
        the forest, and he loved jumping. He wanted to be the best jumper. All
        day long he practiced.
      </Paragraph>
      <Paragraph index="2">
        "Watch where you're hopping, Sir Hops A. Lot! You've ruined my home!"
        yelled Squirrel. Sir Hops A. Lot didn't listen. He just kept jumping.
      </Paragraph>
      <Paragraph index="3">
        He jumped on everything. He jumped on Beaver's dam. He jumped on
        Raccoon's garden. He jumped and jumped. He hopped and hopped. The
        animals were not happy.
      </Paragraph>
      <Paragraph index="4">
        "Sir Hops A. Lot," cried Beaver, "please stop jumping!"
      </Paragraph>
      <Paragraph index="5">
        "I need to know I'm the best jumper in the world," he said.
      </Paragraph>
      <Paragraph index="6">
        "You are the best jumper!" said Squirrel. All the animals agreed. They
        told him he was the best. Sir Hops A. Lot didn't believe them. He needed
        proof.
      </Paragraph>
      <Paragraph index="7">
        The animals decided to hold a contest. Sir Hops A. Lot agreed to stop
        jumping if he won.
      </Paragraph>
      <Paragraph index="8">
        Animals came from all over the world to compete. Bullfrog jumped high.
        Kangaroo jumped higher. It was finally Sir Hops A. Lot's turn. The
        animals cheered. They hoped he'd win.
      </Paragraph>
      <Paragraph index="9">
        He jumped into the sky. He went up! Up! Up! He kept going up. No one
        could believe it! He jumped so high, he got stuck in the sky and became
        a constellation. All the animals looked up at him in the stars. Everyone
        agreed Sir Hops A. Lot was the best jumper!
      </Paragraph>
    </div>
  );
};

export default Reader;
