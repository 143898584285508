import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Apply: Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          Use the model to find the quotient of 3 ÷{" "}
          <Fraction numerator="1" denominator="5" />.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/342b89144bf43a4bd80dabb3cc1571be.svg"
            width="80%"
          />
        </Center>
        <Instructions>
          Choose the correct number from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>
            The quotient of 3 ÷ <Fraction numerator="1" denominator="1" /> is
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      inline
                      width="20px"
                      numerator="3"
                      denominator="15"
                    />
                  ),
                },
                { text: "5" },
                { text: "10" },
                { text: "15" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>.</span>
        </div>
        <div className={css.row}>
          <span>Check your work:</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      inline
                      width="20px"
                      numerator="3"
                      denominator="15"
                    />
                  ),
                },
                { text: "5" },
                { text: "10" },
                { text: "15" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>
            × <Fraction numerator="1" denominator="5" /> = 3
          </span>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} />
    </div>
  );
};

export default Contents;
