import React, { useRef } from "react";
import css from "./DropDown.module.css";
import cssActivity from "./DropDownActivity.module.css";

import { components } from "@indieasy.software/indieasy-components";
import { toolTips } from "@indieasy.software/indieasy-engine";

const { DropDown } = components;

const DropDownWrapper = (props) => {
  const toggleOpenedRef = useRef(false);

  const dropdownEnterHandler = (ev) => {
    if (!props.rolloverTip) return;

    if (toggleOpenedRef.current) return;

    const rect = ev.currentTarget.getBoundingClientRect();
    toolTips.system.getManager("disabled").show(
      toolTips.createToolTip(
        rect.left + rect.width / 2 - 25,
        rect.top + rect.height,
        {
          style: { width: "200px" },
          title: props.rolloverTipTitle,
          text: props.rolloverTip,
        }
      )
    );
  };

  const dropdownLeaveHandler = (ev) => {
    toolTips.system.getManager("disabled").hide();
  };

  const onOpen = () => {
    if (props.onOpen) props.onOpen();
    toggleOpenedRef.current = true;
  };

  const onClose = () => {
    if (props.onClose) props.onClose();
    toggleOpenedRef.current = false;
  };
  const selectIndexHandler = (index) => {
    if (props.selectIndex) props.selectIndex(index);
  };

  const text =
    props.text ||
    (props.defaultText && props.selectedIndex == -1 ? props.defaultText : null);

  return (
    <div
      className={css.root}
      onMouseEnter={dropdownEnterHandler}
      onMouseLeave={dropdownLeaveHandler}
      onMouseUp={dropdownLeaveHandler}
    >
      <DropDown
        width={props.width}
        data={props.data}
        css={props.css || (!props.activity ? css : cssActivity)}
        popupCss={props.css || (!props.activity ? css : cssActivity)}
        arrow={props.arrow || "v"}
        selectedIndex={props.selectedIndex}
        onSelectIndex={selectIndexHandler}
        onOpen={onOpen}
        onClose={onClose}
        text={text}
      />
    </div>
  );
};

export default DropDownWrapper;
