import React, { Fragment, useState } from "react";
import { components } from "@indieasy.software/indieasy-components";

import TutorialTipToggle from "./TutorialTipToggle";

import Popup from "./types/Popup";
import Tip from "./types/Tip";

const { TutorialTip } = components;

const TutorialTipRenderer = (props) => {
  const nextTutorialId = props.tutorialTip.next
    ? props.tutorialTip.next.toLowerCase()
    : null;
  const nextTutorialType = props.tutorialTip.type;

  const lastTutorialId =
    !props.lastTutorialTip || !props.lastTutorialTip.id
      ? null
      : props.lastTutorialTip.id.toLowerCase();

  const lastTutorialType = !props.lastTutorialTip
    ? null
    : props.lastTutorialTip.type;

  const shouldAnimate =
    (nextTutorialType != lastTutorialType || !props.tutorialTip.prev) &&
    (!nextTutorialId || nextTutorialId != lastTutorialId);

  switch (props.tutorialTip.type) {
    case "popup":
      return (
        <Popup {...props} animate={shouldAnimate} tip={props.tutorialTip} />
      );
    case "tip":
      return <Tip {...props} animate={shouldAnimate} tip={props.tutorialTip} />;
    default:
      console.log(
        "ERROR! Invalid Tutorial Tip type: " + props.tutorialTip.type
      );
      return null;
  }
};

const SkinnedTutorialTip = (props) => {
  const [showTutorial, setShowTutorial] = useState(true);
  const toggleHandler = () => {
    setShowTutorial(!showTutorial);
  };

  return (
    <Fragment>
      <TutorialTipToggle
        style={props.toggleStyle}
        selected={showTutorial}
        onToggle={toggleHandler}
      />
      <TutorialTip
        style={{
          ...props.style,
          visibility: showTutorial ? "visible" : "hidden",
        }}
        headerColor={props.headerColor}
        managerId={props.managerId}
        renderer={TutorialTipRenderer}
      />
    </Fragment>
  );
};

export default SkinnedTutorialTip;
