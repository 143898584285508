import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [false, false, false, true];

export const getAnswers = () => {
  return { selected: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    selected: [false, false, false, false],
  };
};

export const isEmpty = (data) => {
  return data.selected.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.selected.length; i++) {
    if (data.selected[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>A positive connotation produces positive feelings in the reader.</div>,
];

export const showReader = () => {
  return false;
};
