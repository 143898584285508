import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Choose Sentences to Combine</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>
            You can take sentences with the same subject and combine them with
            commas.
          </div>
          <BlockQuote>
            The fleet left the port at Chennai. The ships sailed for twenty
            days. They docked at Johannesburg.
          </BlockQuote>
        </div>
        <div>
          <div>These three sentences become:</div>
          <BlockQuote>
            The fleet left the port at Chennai, sailed for twenty days, and
            docked at Johannesburg.
          </BlockQuote>
        </div>

        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>Mr. Goetta invited the astronomy club to go stargazing. </li>
          <li>The students turned off their flashlights. </li>
          <li>The school has a big, open field where the club meets.</li>
          <li>They lay down on the grass.</li>
          <li>They looked for the Big Dipper.</li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
