import React from "react";
import css from "./Text.module.css";

const Tools = (props) => {
  return (
    <div className={css.root} onMouseDown={props.onClick}>
      <div className={css.icon}>
        <img
          src="https://practice.gogetwaggle.com/student/public/images/passage_icon.png"
          alt="icon"
        />
      </div>
      <div className={css.text}>TEXT</div>
    </div>
  );
};

export default Tools;
