import React from "react";
import { useHistory } from "react-router-dom";
import css from "./Entry.module.css";

const Entry = (props) => {
  var history = useHistory();

  let desc = props.data.desc ? (
    <div className={css.description}>{props.data.description}</div>
  ) : null;

  const language = props.data.language ? "-" + props.data.language : "";

  var subtitle = props.data.subtitle[props.grade - 1];

  let hasMultipleEntriesForGrade = typeof subtitle !== "string";

  if (hasMultipleEntriesForGrade) subtitle = subtitle[props.index];

  const onSelectEntry = () => {
    if (!hasMultipleEntriesForGrade)
      history.push(
        `/${props.data.activity}-${props.subject}-${props.grade}${language}`
      );
    else
      history.push(
        `/${props.data.activity}-${props.subject}-${props.grade}${language}-${props.index}`
      );
  };

  return (
    <div className={css.root} onMouseDown={onSelectEntry}>
      <div className={css.header}>
        <div className={css.title}>{props.data.title}</div>
        <div className={css.subtitle}>{subtitle}</div>
      </div>
      {desc}
    </div>
  );
};

export default Entry;
