import React, { Fragment } from "react";

export default (data, setData) => {};

export const correctAnswers = [0, 2];

export const isCorrect = (data) => {
  return data.left[0] == correctAnswers[0] && data.left[1] == correctAnswers[1];
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1],
    showLeft: [false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    showRight: [true, true, true, true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty;
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>A translation is a slide of a figure to a new location.</div>,
  <div>
    A rotation is a turn of a figure around a point. A counterclockwise rotation
    turns in the direction opposite of the hands of a clock.
  </div>,
  <div>
    Rotate the tracing paper 90° counterclockwise around a point to find a line
    it coincides with.
  </div>,
];

export const showReader = () => {
  return false;
};
