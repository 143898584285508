import React from "react";
import css from "./Hotspot.module.css";

const Hotspot = (props) => {
  const style = { ...props.style };

  if (props.inline) style.display = "inline";

  return (
    <div
      style={style}
      className={css.root}
      onMouseDown={() => props.onSelected(props)}
    >
      {props.children}
      {props.selected ? <div className={css.highlight} /> : null}
    </div>
  );
};

export default Hotspot;
