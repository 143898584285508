import React from "react";
import css from "./Sidebar.module.css";

import Shape from "../Components/Shape";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  const dragRightStartHandler = (ev, shape, ref) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    console.log("Start dragging: " + shape.index);
    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.right[shape.slot] = -1;
      data.showRight[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragRightEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        In the passage, the author explains several cause-and-effect
        relationships. Read each cause. Choose the answer that BEST states the
        effect.
      </div>
      <div className={css.instructions}>
        Drag each effect to the correct box. Click or tap the Reset button to
        start over.
      </div>
      <div className={css.contents}>
        <Shape
          blue
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="40px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          blue
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="65px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          blue
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="65px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
      </div>
    </div>
  );
};

export default Sidebar;
