import React from "react";
import css from "./EntryPopup.module.css";

import Hexagon from "../Hexagon";
import Center from "../../../components/Center";

import ProgressBar from "../../../components/ProgressBar";

const EntryPopup = (props) => {
  const closeHandler = () => {
    if (props.onClose) props.onClose();
  };

  let progress;
  if (props.total) {
    progress = (
      <div className="progress">
        <Center>
          <ProgressBar
            width="72px"
            height="8px"
            background="#EEEEEE"
            data={[]}
          />
        </Center>
        <div
          className={css.text}
        >{`level ${props.level} out of ${props.total}`}</div>
      </div>
    );
  }

  return (
    <div className={css.root}>
      <div className={css.mask} />
      <div className={css.popup}>
        <div className={css.left}>
          <div className={css.contents}>
            <Hexagon width="218" height="125" color={props.color}>
              <img className={css.icon} src={props.icon} width="70%" />
            </Hexagon>
            <div className={css.name}>{props.name}</div>
            {progress}
          </div>
        </div>
        <div className={css.right}>
          <div className={css.title}>{props.title}</div>
          <div className={css.contents}>{props.children}</div>
        </div>
        <img
          onMouseDown={closeHandler}
          className={css.close}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPYSURBVHgBzZi7T9tQFMZPYgY6ILlioIElXaALqgsMFUvM1gEkEP8AHUDdQsXSiiHpgrqVjDAxIXVABcHQTnEmWomHKzFUTOkCbE3FUhCPfse1oyTYvsfEhH6S5Xt977V/PufcZ4JuKcMwzGQyaSQSicz19TXfdTzW3eIKrjJfKPt+dXVl2bZt0S2UiFIZUDqgsoCZrYGRqozLury8fAfYsrSRCNAFy7lgcWhFCqqpKgwODmYBtw44k+KTgXeOp1Kp38fHx3ZYxVDAgYGBDwDLI9lO8UvHu8d7enr0o6OjL0GVfF3MLtU07ROSJrVGNlw+ApdXGguSfrXb2tqK1Do4luEa5IZuuJjdits4tV5pP3fXAQJuCnHxnu5Pz92O89V7UI1BxF0aZmbXpul+VUE8PvbisRqDgMuREK63t5fwpyRVR0cHD1fS6jr6QM7LOIBsPdymJK1zuRytrq7S5uYmjY2NKetznWKxSEtLSzQ3N0cSYXqc5ZGE004MIji5YxiqhvyBycnJat40TUK80OHhIQXB8Q956u/vd6y5vb1NKqEvnOHdludikwRioEYxgJ8lG+E8SV0NwCzfNXdVIp5jh4eHbzxrtGQQHGttbY12d3dJoHZ4tqR1d3dPSefZg4MD5+5nBQ+yr68vEG55edm5pOKlGgOyKZ9IG3l/HwTpFwa3gWNxHDLgG6QfRWkYBhkXnKt2BuSZI/JqRQrZBJwj7sVRV8Z1H7csK7Ccy5qBg/QkNSHurUExx+IyyWAeJnYxDzGRXRw2lNRKNZgrVNEwp75yd2Sxw3lqAvIHW9CkCMNMGBzHG3eesHEyIuQ3tmAKFnwhqc0fWVhYoCA4D5AVByQG6o+Y5ZI2CTUzM0NhcEH5Wk1PT5NUYLOSOzs7Fv07CVDq9PSUVHCq58J5mK1XZjZnuQUvP5DMx6VSyVksdHZ2hkI0wnjuZtfOz8/T+fk5qQSedYTDhrPkd7eZv0ggXs9lMhknlqTWGB0dddptbW35esFP7rK/XN2T4C8XccvS/6EV/PxLTlRnEhDnSRiLdymOPT638fLVbefJyckfxOKZdMi5Q73e39+3vEzdvpj3oxi4HyL5nO5Hhb29vbp9ue/ZDOKx1UcfLBtx96zxoe9qBjEwwQ2odeKDzRG/gtADzBb17AIsF7hpCz0fREx+Rsf5iaQRdcWjEnprBVPZW8Dlw+opT1j5BLSrq2sDL+POo9zcC1XAwfoEOoSlqhj1EJ0PmPL4+wwsmo7S1rVY4eLiYtHvoDIWwFoNDQ2ZsIIJ0KfIpgGQ9sKAYXCrIG8jXeIVk7soiay/W5O4rklnc1cAAAAASUVORK5CYII="
        />
      </div>
    </div>
  );
};

export default EntryPopup;
