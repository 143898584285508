import React from "react";

export default (data, setData) => {};

export const correctAnswers = [
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  true,
  true,
  false,
  false,
  false,
];

export const isEmpty = (data) => {
  return data.highlights.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  return (
    data.highlights[0] == correctAnswers[0] &&
    data.highlights[1] == correctAnswers[1] &&
    data.highlights[2] == correctAnswers[2] &&
    data.highlights[3] == correctAnswers[3] &&
    data.highlights[4] == correctAnswers[4] &&
    data.highlights[5] == correctAnswers[5] &&
    data.highlights[6] == correctAnswers[6] &&
    data.highlights[7] == correctAnswers[7] &&
    data.highlights[8] == correctAnswers[8] &&
    data.highlights[9] == correctAnswers[9] &&
    data.highlights[10] == correctAnswers[10] &&
    data.highlights[11] == correctAnswers[11]
  );
};

export const getDefaultState = () => {
  return {
    highlights: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Reread the excerpt from “Henry’s Train Ride” and use details in the story to
    help you draw conclusions.
  </div>,
  <div>
    Think about what you know about the transcontinental railroad and the time
    period, and use your prior knowledge to help you draw your conclusion. Why
    would this be Henry’s first train ride?
  </div>,
  <div>
    Read each sentence from the excerpt separately. Make sure that the sentences
    you select help you understand why Henry has never been on a train.
  </div>,
];

export const showReader = () => {
  return true;
};
