import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the passage. Pay attention to the bolded word, and look for
          context clues. Then answer the question.
        </div>
        <div className={css.title}>
          The Importance of Enough Sleep for Teens
        </div>
        <div>
          Some teenagers insist that they do not require the recommended amount
          of sleep. They say that they can thrive on less than 8–10 hours of
          sleep per night. However, an insufficient amount of sleep can have
          negative consequences. Without enough sleep, a person’s{" "}
          <Bold>metabolism</Bold> can suffer, making them less efficient at
          converting food and water into energy. Juggling the demands of being
          an active teenager—homework, extracurricular activities, part-time
          jobs—without proper rest can take a serious toll on their physiology.
          In other words, their mental and physical functions are harmed by too
          little sleep.
        </div>
        <div>
          Doctors offer suggestions to help young people fight sleep
          deprivation. To maximize the quality and quantity of sleep, experts
          encourage teens to shut down devices and other distractions at least
          an hour before they doze off. They also encourage teens to experiment
          with getting more sleep to improve their mood and outlook.
        </div>
      </div>
    </div>
  );
};

export default Contents;
