export default (data, setData) => {};

export const correctAnswers = [
  { value: 7, crossValue: 7 },
  { value: 7, crossValue: 10 },
];

export const getDefaultState = () => {
  return {
    indices: [],
    complete: false,
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.indices.length < 2) return "empty";
  else if (
    data.complete &&
    data.indices.length == 2 &&
    ((data.indices[0].value == correctAnswers[0].value &&
      data.indices[0].crossValue == correctAnswers[0].crossValue &&
      data.indices[1].value == correctAnswers[1].value &&
      data.indices[1].crossValue == correctAnswers[1].crossValue) ||
      (data.indices[1].value == correctAnswers[0].value &&
        data.indices[1].crossValue == correctAnswers[0].crossValue &&
        data.indices[0].value == correctAnswers[1].value &&
        data.indices[0].crossValue == correctAnswers[1].crossValue))
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.indices.length > 0;
};

export const getTitle = (data, setData) => {
  if (data.indices.length == 0) return "";

  if (
    data.icons[0] == correctAnswers[0] &&
    data.icons[1] == correctAnswers[1] &&
    data.icons[2] == correctAnswers[2] &&
    data.icons[3] == correctAnswers[3] &&
    data.icons[4] == correctAnswers[4] &&
    data.icons[5] == correctAnswers[5] &&
    data.icons[6] == correctAnswers[6]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "Can you draw a line through the figure to divide it into two parts that are the exact same size and shape?",
  "To draw the line, click or tap the two points where the line of symmetry crosses the figure.",
  "To check your answer, trace the figure onto tracing paper. Fold it along the line you drew. Do the two parts match up exactly?",
];

export const showReader = () => {
  return false;
};
