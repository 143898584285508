import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>
            Rina gets goose bumps and shivers whenever we talk about riding the
            biggest roller coaster.
          </li>
          <li>
            Jennifer leaped in the air and cheered when she saw the sunny
            weather forecast for the weekend.
          </li>
          <li>
            Steaming mad, Sergei slammed the door of his room and hurled his
            books on his bed.
          </li>
          <li>
            Lenny’s eyes grew wide and his face broke out in a sweat when his
            friend Jorge yelled, “Boo!”
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
