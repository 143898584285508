import React from "react";
import css from "./Shape.module.css";

const texts = [
  "x² + z² = y²",
  "90² + 216² = z²",
  "90² + 126² = z²",
  "90² + 90² = z²",
];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let text = texts[props.index];

  return (
    <div
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
      className={css.root}
      style={style}
    >
      <div className={css.contents}>{text}</div>
    </div>
  );
};

export default Shape;
