import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good work! 4 ÷ <Fraction inline numerator="1" denominator="4" />
        is equivalent to 4 × 4. There are 4 fourths in 1 whole. There are 4 × 4
        = 16 fourths in 4 wholes. So 4 ÷
        <Fraction inline numerator="1" denominator="4" /> = 16.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again! 4 × <Fraction inline numerator="1" denominator="4" />
        is equal to 1. Dividing 4 by
        <Fraction inline numerator="1" denominator="4" />
        is the same as multiplying 4 by the reciprocal of
        <Fraction inline numerator="1" denominator="4" />
        ​.
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again! 4×16 is equal to 64. Find a multiplication expression that
        shows how many fourths there are in 4 wholes.
      </div>
    );
  } else if (props.data.indices[1] == 1) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again!
        <Fraction inline numerator="1" denominator="4" />
        is the size of each part. There are 4 fourths in 1 whole. How many
        fourths are in 4 wholes?
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div
        style={{ lineHeight: "30px", marginTop: "-5px" }}
        className={css.text}
      >
        Try again! There are 4 fourths in 1 whole. How many fourths are in 4
        wholes?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
