import React from "react";
import Bold from "../../../../Bold";

export default (data, setData) => {};

export const correctAnswers = { selected: 3 };

export const isEmpty = (data) => {
  return data.selected == -1;
};

export const isCorrect = (data) => {
  return data.selected == correctAnswers.selected;
};

export const getDefaultState = () => {
  return {
    selected: -1,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    An illustration is a picture. Illustrations often show a part of a story.
    They help the reader understand what is happening.
  </div>,
  <div>
    Look closely at the illustration. What do you see? Who is in the picture?
    What are they doing?
  </div>,
  <div>
    An illustration may include a thought bubble. This is a bubble near a
    person’s or animal’s head to show what he or she is thinking.
  </div>,
];

export const showReader = () => {
  return true;
};
