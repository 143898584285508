import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Look at the chart. When does each event happen in the story? Which
        happens in the beginning, in the middle, or at the end?
      </div>
      <div className={css.instructions}>
        Drag each word to the correct event. Click or tap the Reset button to
        start over.
      </div>
    </div>
  );
};

export default Sidebar;
