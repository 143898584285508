import React from "react";
import css from "./Hint.module.css";

import { hints } from "../Controller";

const Hint = (props) => {
  const hintsArray =
    Object.prototype.toString.call(hints) == "[object Function]"
      ? hints()
      : hints;
  return <div>{hintsArray[props.index]}</div>;
};

export default Hint;
