import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! If the change in <Italic>y</Italic>-values is −5 and the
        change in <Italic>x</Italic>-values is +3, then the slope is −
        <Fraction numerator="5" denominator="3" />. The line crosses the{" "}
        <Italic>y</Italic>-axis at <Italic>y</Italic> = 1. The equation of the
        line is <Italic>y</Italic> = <Fraction numerator="5" denominator="3" />
        <Italic>x</Italic> + 1.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[0]) {
    contents = (
      <div className={css.text}>
        Try again! The change in <Italic>y</Italic>-values is greater than the
        change in <Italic>x</Italic>-values.
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        Try again! The line slopes down from left to right. Is this a positive
        or a negative slope?
      </div>
    );
  } else if (props.data.selected[4]) {
    contents = (
      <div className={css.text}>
        Try again! The y-intercept is on the y-axis.
      </div>
    );
  } else if (props.data.selected[5]) {
    contents = (
      <div className={css.text}>
        Try again! Make sure the equation you selected has the correct slope.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        You are almost there! You chose at least one correct answer. Select all
        correct answers.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
