import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

const Done = (props) => {
  let contents;

  console.log(props.data);

  // correct
  if (
    props.data.complete &&
    props.data.indices.length == 2 &&
    ((props.data.indices[0].value == correctAnswers[0].value &&
      props.data.indices[0].crossValue == correctAnswers[0].crossValue &&
      props.data.indices[1].value == correctAnswers[1].value &&
      props.data.indices[1].crossValue == correctAnswers[1].crossValue) ||
      (props.data.indices[1].value == correctAnswers[0].value &&
        props.data.indices[1].crossValue == correctAnswers[0].crossValue &&
        props.data.indices[0].value == correctAnswers[1].value &&
        props.data.indices[0].crossValue == correctAnswers[1].crossValue))
  ) {
    contents = (
      <div className={css.text}>
        Good job! You drew a vertical line of symmetry. The figure can be folded
        along the line so that the left and right halves match up exactly.
      </div>
    );
  }
  // empty
  else if (props.data.indices.length < 2) {
    contents = <div className={css.text}>Please draw your answer!</div>;
  } else {
    contents = (
      <Fragment>
        <div className={css.text}>
          The figure has a vertical line of symmetry that divides it in half. To
          draw the line of symmetry, click or tap two points in the middle of
          the figure where the line crosses the figure. The figure can be folded
          along the line so that the left and right halves match up exactly.
        </div>
        <div
          className={css.circle}
          style={{ visibility: "hidden", top: "-85px", left: "318px" }}
        />
        <div
          className={css.circle}
          style={{ visibility: "hidden", top: "-145px", left: "318px" }}
        />
      </Fragment>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
