import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Awesome work! The graph of the relationship is a straight line that passes through the origin, so the relationship is proportional.",
    sp: "¡Estupendo! La gráfica de la relación es una línea recta que pasa por el origen, por lo tanto, la relación es proporcional.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Try again! Is the graph of the relationship a straight line that passes through (0, 0)? If so, it is proportional.",
    sp: "¡Inténtalo otra vez! ¿Es la gráfica de la relación una línea recta que pasa por (0, 0)? Si lo es, la relación es proporcional.",
  },
  3: {
    en: "Try again! Is the graph of the relationship a straight line that passes through (0, 0)? If so, it is proportional.",
    sp: "¡Inténtalo otra vez! ¿Es la gráfica de la relación una línea recta que pasa por (0, 0)? Si lo es, la relación es proporcional.",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
