import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_4_0.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>What's a Mother to Do?</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.caption}>
        Some fish hold their eggs in the mouth to keep them safe.
      </div>
      <Paragraph index="1">
        The wild can be a dangerous place for a young creature of any species.
        With predators and other hazards lurking around every corner, it is
        enough to make most parents very worried about the safety of their{" "}
        <Underline>offspring</Underline>. While many species try to{" "}
        <Underline>protect</Underline> their young, one type of fish called a
        cichlid (pronounced sick-lid) has a very <Underline>unusual</Underline>{" "}
        <Underline>strategy</Underline> for shielding its young from harm:
        keeping them inside of its mouth!
      </Paragraph>
      <div className={css.subtitle}>How One Fish Protects Its Young</div>
      <Paragraph index="2">
        What is a cichlid? It is any fish that belongs to a family that includes
        more than one thousand species. Most cichlids live in the waters of
        Africa and South America. One cichlid is the tilapia, a fish that is
        often farmed and eaten in the United States. If you have an aquarium,
        you may be familiar with oscars or angelfish, which are also cichlids.
        The cichlid family includes many fish that look very different. Some
        cichlids are very large, and some are tiny. Some have bright colors and
        beautifully patterned scales, while other cichlids look much more
        ordinary. So what makes a cichlid a cichlid? All cichlids are freshwater
        fish and, unlike many other fish, all cichlids take care of their young
        after they hatch.
      </Paragraph>
      <Paragraph index="3">
        Another trait that most, but not all, cichlids have in common is that
        they are <Underline>aggressive</Underline>. Cichlids do not get along
        very well with other fish, and they tend to pick fights. What makes
        cichlids so aggressive? They have to be aggressive to survive. Cichlids
        have many <Underline>predators</Underline>, or other fish that will eat
        them. These predators are often the ones that attack, and the cichlids
        must fight them off constantly. Because of this, the cichlids'{" "}
        <Underline>fry</Underline>, or newly hatched fish, can have a hard time
        surviving. That is why the parents have to step in.
      </Paragraph>
      <div className={css.subtitle}>
        Staying Safe: Mom, Please Close Your Mouth
      </div>
      <Paragraph index="4">
        All cichlids take care of their fry after they are hatched, sometimes
        for as long as several months. In many species, both the father and
        mother care for the little fish, while in others, caring for the fry is
        entirely the mother's job. Many female cichlids brood, or care for their
        eggs before they hatch, in their mouths, where the young fry remain for
        some time after they are hatched. These fish are called{" "}
        <Underline>mouthbrooders</Underline>. In this way, the parent fish are
        able to protect their young when the fry are most at risk of being
        harmed by predators or other dangers.
      </Paragraph>
      <Paragraph index="5">
        One species of mouthbrooder cichlid will lay her eggs in the water. Then
        she will gather them up in her mouth. There, the eggs are protected from
        the dangers outside, but they still must be cared for. The mother turns
        them over often to make sure they will get enough of the{" "}
        <Underline>nutrients</Underline> they need. This also allows them to
        receive enough fresh, clean water to grow properly. Eggs that are not
        turned over may die before they hatch. They might also develop diseases
        that will kill the other eggs.
      </Paragraph>
      <Paragraph index="6">
        As long as the mother keeps the eggs in her mouth, they will be
        protected. But this is easier said than done! There can be{" "}
        <Underline>consequences</Underline> if she opens her mouth, because it
        exposes the eggs to predators. For this reason, the mother in some
        species of cichlids does not eat at all while she is brooding her eggs.
        This can be very hard for her, but it is most important to her that her
        babies survive.
      </Paragraph>
      <div className={css.subtitle}>Time to Go: Mom, Please Open Wide</div>
      <Paragraph index="7">
        The mother cichlid in some species can go a long time without eating.
        Her eggs will remain in her mouth until they hatch, which usually
        happens in a matter of weeks, depending on the species. The mother can
        tell when the newborn fry, or wrigglers, are ready to take to the water
        on their own when she feels them swimming in her mouth. When this
        happens, she opens her mouth wide, and they are{" "}
        <Underline>released</Underline> into the world.
      </Paragraph>
      <Paragraph index="8">
        Although the fry are released to swim on their own, the mother fish
        keeps a watchful eye on them. The young fish are born colorless, which
        allows them to hide from predators that might try to eat them. But the
        fry are still not completely able to defend themselves. After her fry
        are released, the mother will continue to take them back into her mouth
        for protection when a predator approaches. These are some hardworking
        mothers!
      </Paragraph>
    </div>
  );
};

export default Reader;
