import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (
    props.data.items[0] == correctAnswers[0] &&
    props.data.items[1] == correctAnswers[1] &&
    props.data.items[2] == correctAnswers[2]
  ) {
    contents = (
      <div className={css.text}>548 has 5 hundreds, 4 tens, and 8 ones.</div>
    );
  }
  // empty
  else if (props.data.items.filter((x) => x > 0).length == 0) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.items[0] == 5) {
    contents = (
      <div className={css.text}>
        548 has 5 hundreds. How many tens are in 548? How many ones?
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        548 has 5 hundreds, 4 tens, and 8 ones. Count 5 hundreds models, 4 tens
        models, and 8 ones models.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
