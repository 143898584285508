import React from "react";
import css from "./Button.module.css";

const Button = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div
      className={css.root + (props.selected ? " " + css.selected : "")}
      onMouseDown={clickHandler}
    >
      <div className={css.text}>{props.text}</div>
    </div>
  );
};

export default Button;
