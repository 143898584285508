import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Carla sets up a lemonade stand. She has 59 fluid ounces of pink
        lemonade. She pours an equal amount of lemonade into each of 8 glasses.
        How many fluid ounces of pink lemonade will be in each glass?
      </div>
      <div className={css.title}>
        Choose an equation to represent the problem. Then type in the correct
        answer.
      </div>
      <div className={css.instructions}>
        Click or tap the correct equation from the drop-down menu. Type the
        missing number into each blank.
      </div>
    </div>
  );
};

export default Sidebar;
