import React from "react";
import css from "./CheckBox.module.css";

const CheckBox = (props) => {
  const classes = [css.root];
  if (props.selected) classes.push(css.selected);

  if (props.circle) classes.push(css.circle);

  const mouseDownHandler = () => {
    if (props.onClick) props.onClick(props);
  };

  return <div onMouseDown={mouseDownHandler} className={classes.join(" ")} />;
};

export default CheckBox;
