import React, { Fragment } from "react";

import Button from "../../../Button";
import XButton from "../../../XButton";

import css from "./Body.module.css";

const Body = (props) => {
  let xButton = props.tip.hasCloseButton ? (
    <XButton onMouseUp={props.closeTip} />
  ) : null;

  let buttons;
  if (props.tip.next && props.tip.prev) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.nextTip}>{props.buttonText || "Next"}</Button>
        <Button onMouseUp={props.prevTip}>
          {props.buttonTextAlt || "Prev"}
        </Button>
      </Fragment>
    );
  } else if (props.tip.next) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.nextTip}>{props.buttonText || "Next"}</Button>
      </Fragment>
    );
  } else if (props.tip.prev) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.closeTip}>
          {props.tip.buttonText || "Close"}
        </Button>
        <Button onMouseUp={props.prevTip}>
          {props.tip.buttonTextAlt || "Prev"}
        </Button>
      </Fragment>
    );
  } else {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.closeTip}>
          {props.buttonText || "Close"}
        </Button>
      </Fragment>
    );
  }

  return (
    <div className={css.background}>
      {xButton}
      <div
        className={css.title}
        dangerouslySetInnerHTML={{ __html: props.tip.title }}
      />
      <div
        className={css.text}
        dangerouslySetInnerHTML={{ __html: props.tip.text }}
      />
      <div className={css.buttons}>{buttons}</div>
    </div>
  );
};

export default Body;
