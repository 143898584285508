import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good work! The caption gives you information about the specific arch
        called "Delicate Arch" in Arches National Park.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.selected == 0) {
    contents = (
      <div className={css.text}>
        The subhead “Sandstone Arches” is in bold print, but it does not tell
        you about one special arch. It tells you that the text underneath it
        will be about arches made of sandstone. Look for text features that give
        specific information about one special arch. Try again!
      </div>
    );
  } else if (props.data.selected == 1) {
    contents = (
      <div className={css.text}>
        You have selected a word in bold print. Bold print lets you know that
        the word is important to know. But it does not give you information
        about one special arch. Try again
      </div>
    );
  } else if (props.data.selected == 2) {
    contents = (
      <div className={css.text}>
        You have selected the photograph. The photograph shows the arch, but it
        does not give information about it. Which text feature gives you
        information? Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
