import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [
  [0, 1, 5],
  [2, 3, 4],
  [0, 1, 2, 3, 4, 5],
];

export const getDefaultState = () => {
  return {
    items: [[], [], []],
  };
};

export const isCorrect = (data) => {
  if (
    data.items[0].length != correctAnswers[0].length ||
    data.items[1].length != correctAnswers[1].length ||
    data.items[2].length != correctAnswers[2].length
  )
    return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      if (data.items[i].filter((x) => x == correctAnswers[i][j]).length == 0) {
        return false;
      }
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return (
    data.items[0].length == 0 &&
    data.items[1].length == 0 &&
    data.items[2].length == 0
  );
};
export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Skim the section “Parts of Food Chains.” Look for details that discuss the
    eating habits of carnivores, herbivores, and omnivores.
  </div>,
  <div>
    Reread paragraph 6 of the passage. How do carnivores, herbivores, and
    omnivores differ?
  </div>,
  <div>
    Look at each food item. Is the food item a plant or an animal? Use what you
    learned from the passage about carnivores, herbivores, and omnivores to sort
    the food items correctly.
  </div>,
  <div>
    Read the instructions carefully. Some food items will go into more than one
    of the bins. Which type of organism would be able to eat all of the food
    items?
  </div>,
];

export const showReader = () => {
  return true;
};
