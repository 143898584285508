import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "Count each unit square in the rectangle.",
    sp: "Cuenta cada cuadrado de una unidad que hay en el rectángulo.",
  },
  1: {
    en: "The number of unit squares in the rectangle is equal to the area in square meters.",
    sp: "El número de cuadrados de una unidad que hay en el rectángulo es igual al área en metros cuadrados.",
  },
};

const languageLookup = (idx) => {
  console.log("language: " + getLanguage());
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};
export const correctAnswers = ["32", "32"];

export const getAnswers = () => {
  return { inputs: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    inputs: ["", ""],
  };
};

export const isEmpty = (data) => {
  return data.inputs.filter((x) => x.length == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.inputs.length; i++) {
    if (data.inputs[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  console.log("getTitle");

  if (isCorrect(data)) return languageLookup("correct");
  else return languageLookup("incorrect");
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
