import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Models to Subtract Fractions</span>
      </div>
      <div className={css.contents}>
        <div>
          <span>
            Xu’s mom made a sandwich cut in four equal pieces. She put
          </span>
          <Fraction numerator="3" denominator="4" />
          <span>of the sandwich in Xu’s lunchbox. He eats</span>
          <Fraction numerator="2" denominator="4" />
          <span>
            of his sandwich. How much sandwich does Xu have left in his
            lunchbox?
          </span>
        </div>
        <div className={css.instructions}>
          You can use a model to find the difference.
        </div>
        <div>
          <span>
            <Bold>STEP 1: </Bold>Line up three
          </span>
          <Fraction numerator="1" denominator="4" />
          <span>
            -strips under a 1 -whole strip to show the amount of sandwich Xu
            has.
          </span>
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/24799a638769f2ff9bf295dc683ac1ba.svg"
            width="50%"
          />
        </Center>
        <div>
          <span>
            <Bold>STEP 2: </Bold>Find how many
          </span>
          <Fraction numerator="1" denominator="4" />
          <span>-strips to take away. Xu eats</span>
          <Fraction numerator="2" denominator="4" />
          <span>of his sandwich. Cross out 2 of the</span>
          <Fraction numerator="1" denominator="4" />
          <span>-strips.</span>
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/2e6002febe2d6a5270cd9734cc7e5845.svg"
            width="50%"
          />
        </Center>
        <div>
          <span>One</span>
          <Fraction numerator="1" denominator="4" />
          <span>-strip is left.</span>
        </div>
        <div>
          <span>Xu has</span>
          <Fraction numerator="1" denominator="4" />
          <span>of a sandwich left.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
