import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import sh from "../../assets/art/5d7bf52809e143e31d38e4ef5cd70841.png";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";

import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox, ANSWER_STATES } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { MultiPromptBox } from "../MultiPromptBox";
import { VOPrompt } from "../VOPrompt";
import { PromptBox } from "../PromptBox";

import shadow from "../../assets/art/shadow.png";
import snuggle from "../../assets/art/snuggle.png";
import rocket from "../../assets/art/rocket.png";
import shower from "../../assets/art/shower.png";
import zipper from "../../assets/art/zipper.png";
import { useMultiMCFeedback } from "../useMultiMCFeedback";

const options = [
  {
    text: "shadow",
    vo: "s8_opt1_GK_078D2_ela_vo_en",
    correct: false,
    img: shadow,
  },
  {
    text: "snuggle",
    vo: "s8_opt2_GK_078D2_ela_vo_en",
    correct: false,
    img: snuggle,
  },
  {
    text: "rocket",
    vo: "s8_opt3_GK_078D2_ela_vo_en",
    correct: false,
    img: rocket,
  },
  {
    text: "shower",
    vo: "s8_opt4_GK_078D2_ela_vo_en",
    correct: false,
    img: shower,
  },
  {
    text: "zipper",
    vo: "s8_opt5_GK_078D2_ela_vo_en",
    correct: false,
    img: zipper,
  },
];

export const Screen9 = (props) => {
  const history = useHistory();
  const onNextScreen = () => history.push("10");
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    feedbackState,
    selectedOptions,
    onCheck,
    chooseAnswer,
    continueState,
    checkState,
  } = useMultiMCFeedback(options, setFoxState, {});

  const playInstruction = useCallback(
    () => playVoiceover("s8_instruction_GK_078D2_ela_vo_en", setFoxState),
    [setFoxState]
  );

  const playTargetSound = useCallback(() => {
    // playSoundEffect("sh");
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <MultiPromptBox
        state={feedbackState}
        selectedOptions={selectedOptions}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.hidden}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onNextScreen}
        onInstruction={playInstruction}
      />
    </React.Fragment>
  );
};
