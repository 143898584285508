import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Determine Connotative Meanings</span>
      </div>
      <div className={css.contents}>
        <span>In this lesson, you will ...</span>
        <BulletPoints>
          <li>distinguish between denotations and connotations</li>
          <li>identify positive, negative, and neutral connotations</li>
          <li>learn to analyze how connotations affect meaning</li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
