import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        The word <Italic>fry</Italic> means "newly hatched fish." The small,
        young fish in the photograph will need to be protected by its parents
        before it is ready to survive on its own.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.selected == 0) {
    contents = (
      <div className={css.text}>
        This photograph shows a group of large sharks. How big is a fish at the
        stage in its life when it is a <Italic>fry</Italic>? Try again.
      </div>
    );
  } else if (props.data.selected == 1) {
    contents = (
      <div className={css.text}>
        This photograph shows a small shrimp. Shrimp live in water, but they are
        not fish. Look for an image of fish that shows how large and old a{" "}
        <Italic>fry</Italic> is. Try again.
      </div>
    );
  } else if (props.data.selected == 2) {
    contents = (
      <div className={css.text}>
        This photograph shows a very large fish. <Italic>Fry</Italic> are newly
        hatched fish, so how could a <Italic>fry</Italic> be this big? Try
        again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
