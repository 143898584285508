import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Divide a Decimal by a Decimal</span>
      </div>
      <div className={css.contents}>
        <div>
          When dividing by a decimal, first change the divisor to a whole number
          by multiplying by a power of 10. (Powers of 10 are 10,100,1,000, etc.)
          Then multiply the dividend by the same power of 10.
        </div>
        <div>For example, find the quotient of 3.25 ÷ 0.5.</div>
        <Instructions>
          Click or tap each step to divide the decimal numbers.
        </Instructions>
        <div className={css.row}>
          <FlipBox
            style={{ color: "black", fontWeight: "normal" }}
            padding="20px"
            width="290px"
            height="230px"
          >
            <div>
              <Bold>STEP 1:</Bold> The divisor has 1 decimal place, so multiply
              the divisor and dividend by 10.
            </div>
            <Center>
              <img
                style={{ marginTop: "30px" }}
                src="https://d16qzots3do3wk.cloudfront.net/images/4ad2171a0aa3cef9369d825facec5f64.svg"
                width="80%"
              />
            </Center>
          </FlipBox>
          <FlipBox
            style={{ color: "black", fontWeight: "normal", textAlign: "left" }}
            padding="20px"
            width="290px"
            height="230px"
          >
            <div>
              <Bold>STEP 2:</Bold> Divide the numbers.
            </div>
            <div>
              <Center>
                <img
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                  src="https://d16qzots3do3wk.cloudfront.net/images/8c2847ed35ef5422768247f8498ad0c6.svg"
                  width="20%"
                />
              </Center>
              <Center>
                <div>
                  3.25 ÷ 0.5 = <Bold>6.5</Bold>
                </div>
              </Center>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
