import React from "react";
import css from "./GradeSelection.module.css";

import DropDown from "../../../components/DropDown";

const GradeSelection = (props) => {
  const data =
    props.subject == "ela"
      ? [
          { text: "Grade - 1", grade: 1 },
          { text: "Grade - 3", grade: 3 },
          { text: "Grade - 4", grade: 4 },
          { text: "Grade - 5", grade: 5 },
          { text: "Grade - 6", grade: 6 },
          { text: "Grade - 7", grade: 7 },
          { text: "Grade - 7 (SP)", grade: 7, language: "sp" },
          { text: "Grade - 8", grade: 8 },
        ]
      : [
          { text: "Grade - 1", grade: 1 },
          { text: "Grade - 3", grade: 3 },
          { text: "Grade - 3 (SP)", grade: 3, language: "sp" },
          { text: "Grade - 4", grade: 4 },
          { text: "Grade - 5", grade: 5 },
          { text: "Grade - 6", grade: 6 },
          { text: "Grade - 7", grade: 7 },
          { text: "Grade - 7 (SP)", grade: 7, language: "sp" },
          { text: "Grade - 8", grade: 8 },
        ];

  const grade = props.selectedIndex + 2;
  const language = props.language == "en" ? null : props.language;

  const selectIndexHandler = (index) => {
    props.selectIndex(data[index].grade - 2, data[index].language);
  };

  const selectedIndex = data.findIndex(
    (x) => x.grade == grade && x.language == language
  );

  return (
    <div className={css.root}>
      <DropDown
        width="175px"
        data={data}
        selectedIndex={selectedIndex}
        selectIndex={selectIndexHandler}
      />
    </div>
  );
};

export default GradeSelection;
