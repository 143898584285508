import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That's right! Writers can use connotative meanings to show a range of
        feelings and ideas.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Try again. How do authors use connotative meanings in their writing?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
