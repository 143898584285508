import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  0: {
    en: "Good thinking! You can use the equation 3×9=27 to find the area. So, the area of the paper is 27 square inches.",
    sp: "¡Bien pensado! Puedes usar la ecuación 3×9=27 para hallar el área. Entonces, el área del trozo de cartulina mide 27 pulgadas cuadradas.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Give it another try! Since you know that the width of the rectangle is 3 inches and the length is 9 inches, you can multiply to find the area.",
    sp: "¡Inténtalo otra vez! Dado que sabes que el ancho del rectángulo es de 3 pulgadas y que la longitud es de 9 pulgadas, puedes multiplicar para hallar el área.",
  },
  3: {
    en: "Give it another try! Use multiplication to find the area. You can use the equation 3×9=?",
    sp: "¡Inténtalo otra vez! Usa la multiplicación para hallar el área. Puedes usar la ecuación 3x9=?.",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
