import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        <div>
          Way to go! You plotted the ordered pairs from each table on a
          coordinate grid.
        </div>
        <div>
          Then you analyzed each graph to determine which one represents a
          proportional relationship.
        </div>
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please draw your answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        <div className={css.line}>
          Plot and label the ordered pairs from each table on a coordinate grid.
          Analyze each graph.
        </div>
        <div className={css.line}>
          <div className={css.circle} />
          The graph of the data in Table 1 is not a line, so the relationship
          between the values in the table is not proportional.
        </div>
        <div className={css.line}>
          <div className={css.circle} />
          The graph of the data in Table 2 is a line that passes through the
          origin. Since the line passes through the origin, the relationship
          between the values in the table is proportional.
        </div>
        <div className={css.line}>
          <div className={css.circle} />
          The graph of the data in Table 3 is not a line, so the relationship
          between the values in the table is not proportional.
        </div>
        <div className={css.line}>
          <div className={css.circle} />
          The graph of the data in Table 4 is a line that does not pass through
          the origin. Since the line does not pass through the origin, the
          relationship between the values in the table is not proportional.
        </div>
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
