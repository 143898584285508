import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Great job! There are 4 rows of 10 unit squares. So, you can use repeated
        addition to add 10+10+10+10=40 or multiply 4×10=40 to find that the area
        of the rectangle is 40 square feet.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Find the number of rows again! Remember that in an array, rows go
        across.
      </div>
    );
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Give the addition another try! Since there are 4 rows, add the number of
        squares in each row four times.
      </div>
    );
  } else if (props.data.indices[2] != correctAnswers[2]) {
    contents = (
      <div className={css.text}>
        Try the multiplication again! Multiply the number of rows by the number
        of squares in each row.
      </div>
    );
  } else if (props.data.indices[3] == 1) {
    contents = (
      <div className={css.text}>
        Find the area again! Four is the number of rows. You need to multiply
        the number of rows by the number of squares in each row.
      </div>
    );
  } else if (props.data.indices[3] == 2) {
    contents = (
      <div className={css.text}>
        Find the area again! Four is the number of rows. You need to multiply
        the number of rows by the number of squares in each row.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
