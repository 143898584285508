import React from "react";
import css from "./Instructions.module.css";

const Instructions = (props) => {
  let contents = <div className={css.root}>{props.children}</div>;

  if (props.span) contents = <span className={css.root}>{props.children}</span>;

  return contents;
};

export default Instructions;
