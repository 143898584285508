import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That’s right! Sentence 2, Sentence 4, and Sentence 5 can be combined
        because they all have the same subject.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Take another look! Which sentences have the same subject?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
