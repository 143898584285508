import React, { Fragment } from "react";

import Button from "../../../Button";

import css from "./Body.module.css";

const Body = (props) => {
  let buttons;
  if (props.tip.next && props.tip.prev) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.nextTip}>
          {props.tip.buttonText || "Next"}
        </Button>
        <Button onMouseUp={props.prevTip}>
          {props.tip.buttonTextAlt || "Prev"}
        </Button>
      </Fragment>
    );
  } else if (props.tip.next) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.nextTip}>
          {props.tip.buttonText || "Next"}
        </Button>
      </Fragment>
    );
  } else if (props.tip.prev) {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.closeTip}>
          {props.tip.buttonText || "Close"}
        </Button>
        <Button onMouseUp={props.prevTip}>
          {props.tip.buttonTextAlt || "Prev"}
        </Button>
      </Fragment>
    );
  } else {
    buttons = (
      <Fragment>
        <Button onMouseUp={props.closeTip}>
          {props.tip.buttonText || "Close"}
        </Button>
      </Fragment>
    );
  }

  return (
    <div className={css.background}>
      <div
        className={css.text}
        dangerouslySetInnerHTML={{ __html: props.tip.text }}
      />
      <div className={css.buttons}>{buttons}</div>
    </div>
  );
};

export default Body;
