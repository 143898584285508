import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        <span>
          Great job! <Fraction numerator="3" denominator="3" /> −{" "}
          <Fraction numerator="3" denominator="3" /> ={" "}
          <Fraction numerator="1" denominator="3" />,{" "}
          <Fraction numerator="10" denominator="12" /> −{" "}
          <Fraction numerator="4" denominator="12" /> ={" "}
          <Fraction numerator="6" denominator="12" />,{" "}
          <Fraction numerator="2" denominator="2" /> −{" "}
          <Fraction numerator="1" denominator="2" /> ={" "}
          <Fraction numerator="1" denominator="2" />
        </span>
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.inputs[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Try again! Look at the first subtraction sentence. What is the
        difference of 3−2?
      </div>
    );
  } else if (props.data.inputs[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Try again! Look at the second subtraction sentence. When subtracting
        with like denominators, the denominator in the difference stays the
        same.
      </div>
    );
  } else if (props.data.inputs[2] != correctAnswers[2]) {
    contents = (
      <div className={css.text}>
        Try again! Look at the third subtraction sentence. What is the
        difference of 2−1?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
