import React from "react";

export default (data, setData) => {};

export const correctAnswers = [
  { value: 1, crossValue: 6 },
  { value: 2, crossValue: 12 },
  { value: 3, crossValue: 18 },
  { value: 4, crossValue: 24 },
  { value: 5, crossValue: 30 },
];

export const isEmpty = (data) => {
  return data.indices.length == 0;
};

export const isCorrect = (data) => {
  if (data.indices.length != correctAnswers.length) return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    const answer = correctAnswers[i];

    if (
      data.indices.filter(
        (x) => x.value == answer.value && x.crossValue == answer.crossValue
      ).length == 0
    )
      return false;
  }

  return true;
};

export const getDefaultState = () => {
  return {
    indices: [],
    highlight: null,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Plotting the points from each table on a coordinate grid will help you
    determine which table represents a proportional relationship.
  </div>,
  <div>
    The graph of a proportional relationship is straight line that passes
    through the origin.
  </div>,
  <div>
    Determine which graphs form a straight line. If the graph of a table does
    not form a straight line, the table does not represent a proportional
    relationship.
  </div>,
  <div>
    Determine which graphs pass through the origin. If the graph of a table
    forms a straight line that does not pass through the origin, the table does
    not show a proportional relationship.
  </div>,
];

export const showReader = () => {
  return false;
};
