import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Bold from "../../../../Bold";
import { Fragment } from "react";

import SpanishImg from "../Assets/art.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Use Graphic Organizers and Outlines",
    sp: "Usa organizadores gráficos y esquemas",
  },
  1: {
    en: "As you read an informational text, you can use a graphic organizer or outline to help keep track of the main ideas and supporting details.",
    sp: "A medida que lees un texto informativo, puedes usar un organizador gráfico o un esquema para ir anotando las ideas principales y los detalles de apoyo.",
  },
  2: {
    en: (
      <Fragment>
        A <Bold>graphic organizer</Bold> visually represents the importance of a
        main idea and its relationship to the details that support it.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Un <Bold>organizador gráfico</Bold> representa de manera visual la
        importancia de una idea principal y su relación con los detalles que la
        apoyan.
      </Fragment>
    ),
  },
  3: {
    en: (
      <Fragment>
        An <Bold>outline</Bold> is a bulleted list of main ideas and supporting
        details. It is especially helpful to keep track of main ideas when
        you're reading longer texts.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Un <Bold>esquema</Bold> es una lista de viñetas con las ideas
        principales y los detalles de apoyo. Es particularmente útil para anotar
        las ideas principales cuando lees textos largos.
      </Fragment>
    ),
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "When you’re creating a graphic organizer or outline, you don’t need to include transitional words and phrases, such as “on the other hand” or “lastly.” You don’t need to include any information that further explains a supporting detail. Finally, you don’t need to include any information from the passage that does not support the main ideas.",
    sp: "Al hacer un organizador gráfico o un esquema, no hace falta que incluyas palabras y frases de transición, como “por otra parte” o “por último”. Tampoco es necesario que incluyas información que explique en profundidad un detalle de apoyo. Por último, no es necesario que incluyas información del fragmento que no apoye las ideas principales.",
  },
  8: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/20f3ea7b19ecef955abcc9617517a774.svg",
    sp: SpanishImg,
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <div>{languageLookup(2)}</div>
        <div>{languageLookup(4)}</div>
        <div>{languageLookup(7)}</div>
        <div></div>
        <img src={`${languageLookup(8)}`} />
      </div>
    </div>
  );
};

export default Contents;
