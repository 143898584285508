import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Plot the ordered pairs from the table that shows a proportional
        relationship.
      </div>
      <div className={css.instructions}>
        Click or tap the points. Click or tap the Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
