import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the passage. Notice how each bolded word has a meaning similar to{" "}
          <Italic>honest</Italic>. Then answer the question.
        </div>
        <div>
          When Carson hurried out of the restroom at school, his friend Sage
          noticed a piece of long white tissue paper stuck to the bottom of his
          left shoe. “Um, Carson, can I please talk to you for a minute?” Sage
          said with a <Bold>sincere</Bold> expression. She didn’t want to
          embarrass him. Carson dashed by her, waved and said, “Hey, Sage!” as
          he kept on walking. Carson was on his way to present his big science
          project; he didn’t want to be late.
        </div>
        <div>
          His friend Jan, trying not to make the situation worse, called Carson
          over and said, “I know you’re in a hurry. But let me be{" "}
          <Bold>frank</Bold>: There’s some paper stuck to your shoe!” Carson
          grinned at Jan and replied, “Oh, I’m not falling for that old joke!”
          as he kept on walking. The tissue paper dragged behind him, picking up
          dust from the hallway. Kyle ran over to Carson, and tried to be even
          more <Bold>candid</Bold>. “Carson, your left shoe has a long piece of
          T.P. stuck to it. You should take it off before your big
          presentation.” Carson chuckled. “Kyle, Jan already tried to fool me
          with that one. I have got to focus on my . . . ” While he was standing
          there, Alexandra, his best friend, said “Sorry, but I have to be{" "}
          <Bold>blunt</Bold>, Carson. If you want to look sharp for your
          presentation, you’ve <Italic>got</Italic> to lose this.” Using her
          foot, she ripped away the paper from Carson’s shoe, and said with a
          chuckle, “<Italic>now</Italic> you’re ready!”
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Contents;
