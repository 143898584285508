import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

import ImageSP from "../Assets/math_3_8.svg";

const lines = {
  0: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/7486f9aba728954d1689da8164f9b793.svg",
    sp: ImageSP,
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <img width="100%" src={languageLookup(0)} />
    </div>
  );
};

export default Sidebar;
