import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (
    props.data.highlights[0] == correctAnswers[0] &&
    props.data.highlights[1] == correctAnswers[1] &&
    props.data.highlights[2] == correctAnswers[2] &&
    props.data.highlights[3] == correctAnswers[3] &&
    props.data.highlights[4] == correctAnswers[4] &&
    props.data.highlights[5] == correctAnswers[5] &&
    props.data.highlights[6] == correctAnswers[6] &&
    props.data.highlights[7] == correctAnswers[7]
  ) {
    contents = (
      <div className={css.text}>
        Correct. You have selected the two sentences that show what Jahi does to
        solve his problem: he picks up sponges from the sea, and he fills the
        donkey's bags with them. Good job.
      </div>
    );
  }
  // empty
  else if (props.data.highlights.filter((x) => x == true).length == 0) {
    contents = <div className={css.text}>Please highlight your answer!</div>;
  } else if (props.data.highlights[3]) {
    contents = (
      <div className={css.text}>
        Good start. The sentence “When they came to the wide, calm seashore,
        Jahi picked up sponges from the sea instead of collecting salt” explains
        one thing Jahi does to solve the problem. Look for one more sentence.
      </div>
    );
  } else if (props.data.highlights[4]) {
    contents = (
      <div className={css.text}>
        Good start. The sentence “He filled the donkey’s bags with the sponges”
        explains one thing Jahi does to solve the problem. Look for one more
        sentence.
      </div>
    );
  } else if (props.data.highlights[0]) {
    contents = (
      <div className={css.text}>
        The sentence "As they walked along, Jahi looked at the donkey" does not
        show what Jahi does to solve his problem. Try again.
      </div>
    );
  } else if (props.data.highlights[1]) {
    contents = (
      <div className={css.text}>
        The sentence "Then he laughed to himself" does not show what Jahi does
        to solve his problem. Try again.
      </div>
    );
  } else if (props.data.highlights[2]) {
    contents = (
      <div className={css.text}>
        The sentence "He thought he knew a way to stop the donkey from losing
        salt in the water" shows that Jahi has thought of a plan, but it does
        not show what he does to solve his problem. Try again.
      </div>
    );
  } else if (props.data.highlights[5]) {
    contents = (
      <div className={css.text}>
        The sentence "Jahi knew this meant he wouldn't have a load of salt to
        sell at the market" describes what Jahi is thinking as he solves his
        problem. Look for two sentences that describe what Jahi does to solve
        his problem.
      </div>
    );
  } else if (props.data.highlights[6]) {
    contents = (
      <div className={css.text}>
        The sentence "However, at the moment, he believed teaching the donkey a
        lesson was more important" describes what Jahi is thinking as he solves
        his problem. Look for two sentences that describe what Jahi does to
        solve his problem.
      </div>
    );
  } else if (props.data.highlights[7]) {
    contents = (
      <div className={css.text}>
        The sentence "After the sponges were loaded, they started for home"
        describes what happens after Jahi takes action to solve his problem.
        Look for two sentences that describe what Jahi does to solve his
        problem.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
