import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Bold from "../../../../Bold";
import BlockQuote from "../../../../BlockQuote";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Identify Supporting Details",
    sp: "Identifica los detalles de apoyo",
  },
  1: {
    en: (
      <Fragment>
        <Bold>Supporting details</Bold> reinforce the main idea with reasons,
        facts, statistics, or examples. Supporting details are relevant to the
        main idea. <Bold>Relevant</Bold> information is related to the main idea
        and shows how it is true.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Los <Bold>detalles de apoyo</Bold> refuerzan la idea principal con
        razones, datos, estadísticas o ejemplos. Los detalles de apoyo son
        relevantes para la idea principal. La información <Bold>relevante</Bold>{" "}
        es la que se relaciona con la idea principal y demuestra por qué esta es
        verdadera.
      </Fragment>
    ),
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: (
      <Fragment>
        <Bold>
          Read the following paragraph. Notice how the first sentence states the
          claim and the others serve as supporting details.
        </Bold>
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>
          Lee el siguiente párrafo. Observa que la primera oración expresa una
          afirmación y las demás sirven como detalles de apoyo.
        </Bold>
      </Fragment>
    ),
  },
  6: {
    en: (
      <Fragment>
        The Aztecs, a people who flourished in central Mexico from the 14th to
        16th centuries, placed great value on music and poetry. They created
        several genres of music. <Italic>Yaocuicatl</Italic> were songs of war.
        Sacred and devotional music was <Italic>teocuicatl</Italic>. Even
        flowers had their own tunes called <Italic>xochicuicatl</Italic>. Most
        Aztec festivals featured poetry contests and dramatic presentations of
        music and acrobats. With its range of topics and expression, Aztec
        poetry survives in collections today.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Los aztecas, un pueblo que floreció en la región central de México desde
        el siglo XIV hasta el siglo XVI, valoraban mucho la música y la poesía.
        Crearon varios géneros musicales. Los <Italic>yaocuicatl</Italic> eran
        cantos de guerra. La música sagrada y religiosa era llamada{" "}
        <Italic>teocuicatl</Italic>. Incluso las flores tenían sus propias
        melodías, llamadas <Italic>xochicuicatl</Italic>. La mayoría de los
        festivales aztecas tenían concursos de poesía y presentaciones
        dramáticas con música y acrobacia. Con su diversidad de temas y formas
        de expresión, la poesía azteca sobrevive en la actualidad en distintas
        colecciones.
      </Fragment>
    ),
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <div className={css.instructions}>{languageLookup(5)}</div>
        <BlockQuote>{languageLookup(6)}</BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
