import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_2_2.JPG";
import Bold from "../../../../Bold";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>To Be a Bird or a Bee or Me</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        Not long ago, I took my puppy, Rex, for a walk through the forest. We
        were walking along, staring at the leaves and the trees when I saw the
        most amazing bird!
      </Paragraph>
      <Paragraph index="2">
        It had great big blue wings. It stopped for a second. Then it took off
        into the sky. I watched it fly! Then it came back and landed next to me.
        I closed my eyes and imagined being a bird. I imagined I could fly, and
        tweet, and build nests. I thought, "Life would be more fun if only I
        were a bird instead of me!" Then Rex chased the bird, and the bird flew
        away.
      </Paragraph>
      <Paragraph index="3">
        We kept walking in the forest, and I saw a deer. The deer trotted in a
        circle, looking strong and swift. It stopped to eat some grass. Rex
        barked at the deer, and the deer ran off. It ran so fast. I imagined
        being a deer. I imagined running fast and ramming my antlers against
        trees. I thought, "Life would be more fun if only I were a deer instead
        of me!"
      </Paragraph>
      <Paragraph index="4">
        Then I saw a bumblebee resting in a flower. Rex sniffed at the bee, but
        the bee was not afraid of Rex. It showed Rex its stinger. Rex backed
        away from the bee. I imagined being a bee, and being able to defend
        myself. Even animals much larger and stronger than a bee know to leave a
        bee alone. I thought, "Life would be more fun if only I were a bee
        instead of me!"
      </Paragraph>
      <Paragraph index="5">
        As I was dreaming of being a bee, I heard a startled bark. Then I heard
        a frightened whimper. "Oh no! Rex is in trouble!" I thought.
      </Paragraph>
      <Paragraph index="6">
        I ran toward the sound. When I found Rex, he had fallen into a hole. He
        was trapped.
      </Paragraph>
      <Paragraph index="7">
        Rex was scared. I needed to help him. I bent down, but couldn't reach
        Rex. I bent down lower and leaned over. I stretched my arms and
        fingertips as far as I could. I was just able to grab Rex. I lifted him
        up out of the hole.
      </Paragraph>
      <Paragraph index="8">
        On our way home I thought about all the different creatures I'd wanted
        to be. A bird wouldn't have been able to rescue Rex from the hole. A
        deer wouldn't have been able to help, and neither would a bee. I
        thought, "Even if it would be fun to be a bird, a deer, or a bee, being
        me is the best thing to be!"
      </Paragraph>
    </div>
  );
};

export default Reader;
