import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import Hotspot from "../../../../Hotspot";
import Underline from "../../../../Underline";

import DropDown from "../../../../DropDown";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.text}>
        The word <Underline>embark</Underline> in the line "Nurse helps me in
        when I <Underline>embark</Underline>" means
      </div>
      <div className={css.dropdown}>
        <DropDown
          activity
          width="200px"
          selectedIndex={props.data.indices[0] - 1}
          selectIndex={(index) => selectIndexHandler(0, index + 1)}
          defaultText="Choose"
          data={[
            { text: "go to bed" },
            { text: "go sailing" },
            { text: "go to the doctor" },
          ]}
          /*rolloverTipTitle="Choose Subject"
            rolloverTip="Choose english or math."*/
        />
      </div>
      <div className={css.text}>
        The line "She girds me in my sailor's coat" means that the nurse helps
        the speaker
      </div>
      <div className={css.dropdown}>
        <DropDown
          activity
          width="225px"
          selectedIndex={props.data.indices[1] - 1}
          selectIndex={(index) => selectIndexHandler(1, index + 1)}
          defaultText="Choose"
          data={[
            { text: "dress up like a sailor" },
            { text: "put on a coat" },
            { text: "get dressed for bed" },
          ]}
          /*rolloverTipTitle="Choose Subject"
            rolloverTip="Choose english or math."*/
        />
      </div>
      <div className={css.text}>
        The line "I shut my eyes and sail away" means that the speaker is
      </div>
      <div className={css.dropdown}>
        <DropDown
          activity
          width="150px"
          selectedIndex={props.data.indices[2] - 1}
          selectIndex={(index) => selectIndexHandler(2, index + 1)}
          defaultText="Choose"
          data={[
            { text: "dreaming" },
            { text: "resting" },
            { text: "sailing" },
          ]}
          /*rolloverTipTitle="Choose Subject"
            rolloverTip="Choose english or math."*/
        />
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
