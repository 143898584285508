import React, { useState } from "react";
import css from "./FlipBox.module.css";

const FlipBox = (props) => {
  const [state, setState] = useState(0);

  const classes = [css.root];
  if (state == 1) classes.push(css.open);

  const mouseDownHandler = () => {
    if (state == 0) setState(1);
    else setState(0);
  };

  const style = { ...props.style };
  style.width = props.width;
  style.height = props.height;
  style.padding = state == 0 ? props.padding : props.backPadding;

  return (
    <div
      style={style}
      onMouseDown={mouseDownHandler}
      className={classes.join(" ")}
    >
      {props.children[state]}
    </div>
  );
};

export default FlipBox;
