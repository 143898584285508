import React from "react";
import css from "./Paragraph.module.css";

const Paragraph = (props) => {
  return (
    <div className={css.root}>
      <div className={css.index}>{props.index}</div>
      <div className={css.contents}>{props.children}</div>
    </div>
  );
};

export default Paragraph;
