import React from "react";

import Header from "./Header";
import Body from "./Body";

import css from "./Popup.module.css";

const Popup = (props) => {
  const className = css.popup + (props.animate ? " " + css.animate : "");

  if (props.animate) {
    const element = document.getElementById("tutorialtip");
    if (element) {
      element.classList.remove(css.animate);
      void element.offsetWidth;
      element.classList.add(css.animate);
    }
  }
  return (
    <div id="tutorialtip" className={className}>
      <Header {...props} />
      <Body {...props} />
    </div>
  );
};

export default Popup;
