/*
  UI flow for the multiple choice quesitons where the options are in a popup that closes once you select one.
  Currently used in the K2 math 1 lesson.
*/

import { useCallback, useState } from "react";
import { BUTTON_STATES } from "../BottomToolbar";
import { FOX_STATE } from "../Fox";
import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../voiceover";
import { ANSWER_STATES } from "./AnswerBox";

/*
   answers format:

   {
       "val": {
           correct: true,
           feedback: "audio snip"
       }
   }
*/

const getCorrect = (answers) =>
  Object.keys(answers).find((k) => answers[k].correct);

export const useMCFeedback = (answers, language = 'en') => {
  const [foxState, setFoxState] = useState(FOX_STATE.idle);
  const [continueState, setContinueState] = useState(BUTTON_STATES.hidden);
  const [checkState, setCheckState] = useState(BUTTON_STATES.disabled);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [open, setOpen] = useState(true);

  const [tries, setTries] = useState(0);
  const [answerState, setAnswerState] = useState(ANSWER_STATES.input);

  const forceOpen = useCallback(() => {
    setAnswerState(ANSWER_STATES.input);
    setOpen(true);
  }, []);

  const selectAnswer = useCallback((val) => {
    setCurrentAnswer(val);
    setOpen(false);
    setCheckState(BUTTON_STATES.enabled);
    setAnswerState(ANSWER_STATES.closed);
  }, []);

  const onCorrect = useCallback((ans) => {
    setCheckState(BUTTON_STATES.hidden);
    setContinueState(BUTTON_STATES.enabled);

    const vo = language === 'en' ?
      ["success", "waytogo", "go_on_arrow"] :
      ["success", "encourage_l0_math_vo_es", "nextQ_l0_math_vo_es"]

    playVoiceovers(vo, setFoxState);
    setAnswerState(ANSWER_STATES.correct);
  }, [language]);

  const onIncorrect = useCallback(
    (ans) => {
      if (tries >= 2) {
        playVoiceover(`ks_l0_math_vo_${language}`, setFoxState);
        setCurrentAnswer(getCorrect(answers));
        setAnswerState(ANSWER_STATES.correct);
        setCheckState(BUTTON_STATES.hidden);
        setContinueState(BUTTON_STATES.enabled);
      } else {
        ans.feedback && playVoiceovers(["error", ans.feedback], setFoxState);
        setOpen(true);
        setCheckState(BUTTON_STATES.disabled);
        setAnswerState(ANSWER_STATES.incorrect);
      }
    },
    [answers, language, tries]
  );

  const onCheck = useCallback(() => {
    const ans = answers[currentAnswer];
    if (!ans) {
      console.error("Not an answer", currentAnswer);
      return;
    }

    setTries((t) => t + 1);

    if (ans.correct) {
      onCorrect(ans);
    } else {
      onIncorrect(ans);
    }
  }, [answers, currentAnswer, onCorrect, onIncorrect]);

  return {
    answerState,
    onCheck,
    continueState,
    checkState,
    currentAnswer,
    foxState,
    setFoxState,
    selectAnswer,
    open,
    forceOpen,
  };
};
