import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import Hotspot from "../../../../Hotspot";
import Underline from "../../../../Underline";

const Contents = (props) => {
  const selectHandler = (hotspot) => {
    const data = { selected: hotspot.index };
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.row}>
          <Hotspot
            index={0}
            selected={props.data.selected == 0}
            onSelected={selectHandler}
          >
            <div className={css.title}>Possible Meanings</div>
            <div className={css.text}>
              Meaning A: In stanza 1, <Underline>charge</Underline> refers to
              cost. The speaker means that listening to the wind is free unlike
              other musical performances that you have to pay to see.
            </div>
          </Hotspot>
        </div>
        <div className={css.row}>
          <Hotspot
            index={1}
            selected={props.data.selected == 1}
            onSelected={selectHandler}
          >
            <div className={css.text}>
              Meaning B: In stanza 1, <Underline>charge</Underline> refers to
              thrill. The speaker is more thrilled by listening to the sounds of
              the wind whistling through the trees than by any other sounds.
            </div>
          </Hotspot>
        </div>
        <div className={css.row}>
          <Hotspot
            index={2}
            selected={props.data.selected == 2}
            onSelected={selectHandler}
          >
            <div className={css.text}>
              Meaning C: In stanza 1, <Underline>charge</Underline> refers to
              duty, like being "in <Underline>charge</Underline>." The speaker
              does not have to be in charge of the wind in the first stanza, but
              she does in another stanza.
            </div>
          </Hotspot>
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
