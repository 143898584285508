import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "Use repeated addition to add the number of squares in each row. How many times will you add 10 squares?",
    sp: "Utiliza la suma repetida para sumar el número de cuadrados de cada fila. ¿Cuántas veces sumarás 10 cuadrados?",
  },
  1: {
    en: "To find the area, multiply the number of rows by the number of squares in each row.",
    sp: "Para hallar el área, multiplica el número de filas por el número de cuadrados de cada fila.",
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};

export const correctAnswers = [2, 2, 1, 3];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return languageLookup("correct");
  else return languageLookup("incorrect");
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
