import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You did it! To change the divisor 0.72 to a whole number, you multiply
        by 100 . Then you multiply the dividend by 100 , too. To multiply 11.52
        by 100 , you move the decimal point 2 places to the right to get 1,152 .
        When you divide 1,152 by 72 , you get 16 . So, 16 is the quotient for
        1,152÷72 and 11.52÷0.72 .
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Try your first answer again. Which power of ten will result in 0.72
        becoming a whole number?
      </div>
    );
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Try your second answer again. Equal groups are shown with different
        shades of color.
      </div>
    );
  } else if (props.data.indices[2] != correctAnswers[2]) {
    contents = (
      <div className={css.text}>
        Try your third answer again. Move the decimal point to the right the
        number of places that will result in a whole number.
      </div>
    );
  } else if (props.data.indices[3] != correctAnswers[3]) {
    contents = (
      <div className={css.text}>
        Check your long division again. Divide as with whole numbers.
      </div>
    );
  } else if (props.data.indices[4] != correctAnswers[4]) {
    contents = (
      <div className={css.text}>
        When you multiply the divisor and dividend by the same number, the
        quotient remains the same.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
