import React, { Fragment } from "react";

export default (data, setData) => {};

export const correctAnswers = [1];

export const isCorrect = (data) => {
  return data.left[0] == correctAnswers[0];
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1],
    showLeft: [false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    showRight: [true, true, true, true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty;
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    The hypotenuse is the longest side of a right triangle, and is opposite the
    right angle.
  </div>,
  <div>
    The Pythagorean theorem states that in a right triangle,{" "}
    <span style={{ fontStyle: "italic" }}>a²</span> +{" "}
    <span style={{ fontStyle: "italic" }}>b²</span> ={" "}
    <span style={{ fontStyle: "italic" }}>c²</span> or side² + side² =
    hypotenuse².
  </div>,
  <div>
    Look closely at how the path is labeled. Which side is the hypotenuse?
  </div>,
  <div>
    Side <span style={{ fontStyle: "italic" }}>x</span> is also a side length of
    the square field. It is 90 ft long. How can you find the length of side{" "}
    <span style={{ fontStyle: "italic" }}>y</span>?
  </div>,
];

export const showReader = () => {
  return false;
};
