import React from "react";

import css from "./K2Lessons.module.css";

export const VOPrompt = ({ img, onClick }) => {
  return (
    <div className={css.voPrompt} onClick={onClick}>
      <img src={img} />
    </div>
  );
};
