import React from "react";
import Italic from "../../../../../Italic";
import css from "./Shape.module.css";

const contents = [
  <div>"the look of consternation still on his face"</div>,
  <div>a great idea</div>,
  <div>"as the screen came alive"</div>,
  <div>showing happiness or joy</div>,
  <div>"best thing since sliced bread"</div>,
  <div>showing anxiety or dread</div>,
  <div>"smile that beamed as bright as the sun"</div>,
  <div>to start up and be really entertaining</div>,
];

const Shape = (props) => {
  console.log(props.style);
  const style = {
    visibility: props.show ? "visible" : "hidden",
    justifyContent: props.center ? "center" : null,
    textAlign: props.center ? "center" : null,
    color: props.blue ? "#1588c9" : null,
    height: props.height || null,
    width: props.width || null,
    ...props.style,
  };

  let content = contents[props.index];
  return (
    <div className={css.root} style={style}>
      <div
        className={css.contents}
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        style={props.innerStyle}
      >
        {content}
      </div>
    </div>
  );
};

export default Shape;
