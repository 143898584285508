import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import Shape from "../Components/Shape";

const Contents = (props) => {
  const dragOverHandler = (ev, index) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, index) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));

    ev.preventDefault();

    var data = {
      left: [
        [...props.data.left[0]],
        [...props.data.left[1]],
        [...props.data.left[2]],
      ],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };
    if (props.data.left[index].find((x) => x.id == dragId)) {
      data.left[index].find((x) => x.id == dragId).show = true;
    } else {
      data.left[index].push({ id: dragId, show: true });
    }

    props.setData(data);
  };

  const dragStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      var data = {
        left: [
          [...props.data.left[0]],
          [...props.data.left[1]],
          [...props.data.left[2]],
        ],
        right: [...props.data.right],
        showRight: [...props.data.showRight],
      };
      data.left[shape.slot].find((x) => x.id == shape.index).show = false;

      props.setData(data);
    }, 10);
  };

  const dragEndHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    var data = {
      left: [
        [...props.data.left[0]],
        [...props.data.left[1]],
        [...props.data.left[2]],
      ],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };

    if (ev.dataTransfer.dropEffect == "none") {
      data.right[shape.index] = shape.index;
      data.showRight[shape.index] = true;
    }

    if (!data.left[shape.slot].find((x) => x.id == shape.index).show)
      data.left[shape.slot] = data.left[shape.slot].filter(
        (x) => x.id != shape.index
      );

    props.setData(data);
  };

  const items = [[], [], []];

  for (let i = 0; i < props.data.left.length; i++) {
    for (let j = 0; j < props.data.left[i].length; j++) {
      items[i].push(
        <Shape
          key={i + "_" + j}
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={props.data.left[i][j].id}
          slot={i}
          show={props.data.left[i][j].show}
        />
      );
    }
  }

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.area}>
          <div className={css.header}>Right</div>
          <div
            className={css.grid}
            onDragOver={(ev) => dragOverHandler(ev, 0)}
            onDrop={(ev) => dropHandler(ev, 0)}
          >
            {items[0]}
          </div>
        </div>
        <div className={css.area}>
          <div className={css.header}>Acute</div>
          <div
            className={css.grid}
            onDragOver={(ev) => dragOverHandler(ev, 1)}
            onDrop={(ev) => dropHandler(ev, 1)}
          >
            {items[1]}
          </div>
        </div>
        <div className={css.area}>
          <div className={css.header}>Obtuse</div>
          <div
            className={css.grid}
            onDragOver={(ev) => dragOverHandler(ev, 2)}
            onDrop={(ev) => dropHandler(ev, 2)}
          >
            {items[2]}
          </div>
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
