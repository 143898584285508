import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import InputField from "../../../../InputField";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const valueHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Find Missing Numerators and Denominators in Subtraction Sentences
        </span>
      </div>
      <div className={css.contents}>
        <div>What missing number makes each subtraction sentence true?</div>
        <Instructions>Type the correct answer into each blank. </Instructions>
        <div>
          <span>
            <Fraction numerator="3" denominator="3" /> −{" "}
            <Fraction numerator="2" denominator="3" /> ={" "}
            <Fraction numerator="?" denominator="3" />
          </span>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <span>? = </span>
          <InputField
            language={props.language}
            value={props.data.inputs[0]}
            onChange={(ev) => valueHandler(0, ev)}
            width="175px"
          ></InputField>
          <span></span>
        </div>
        <div>
          <span>
            <Fraction width="25px" numerator="10" denominator="12" /> −{" "}
            <Fraction width="25px" numerator="4" denominator="12" /> ={" "}
            <Fraction numerator="6" denominator="?" />
          </span>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <span>? = </span>
          <InputField
            language={props.language}
            value={props.data.inputs[1]}
            onChange={(ev) => valueHandler(1, ev)}
            width="175px"
          ></InputField>
          <span></span>
        </div>
        <div>
          <span>
            <Fraction width="25px" numerator="2" denominator="2" /> −{" "}
            <Fraction width="25px" numerator="1" denominator="2" /> ={" "}
            <Fraction numerator="?" denominator="2" />
          </span>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <span>? = </span>
          <InputField
            language={props.language}
            value={props.data.inputs[2]}
            onChange={(ev) => valueHandler(2, ev)}
            width="175px"
          ></InputField>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
