import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Nice job! You correctly identified the real meaning of each example of
        figurative language from the poem.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div className={css.text}>
        Remember that figurative language has a meaning that is different from
        the literal, or dictionary, meaning of a word or phrase. Read stanza 1
        of the poem. Think about what the speaker is really doing, and try the
        first blank again.
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div className={css.text}>
        The nurse is like a nanny or babysitter who takes care of the speaker on
        a daily basis. Read stanza 1 of the poem. Think about what the speaker
        is really doing, and try the first blank again.
      </div>
    );
  } else if (props.data.indices[1] == 1) {
    contents = (
      <div className={css.text}>
        Figurative language is not the same as literal language. It has a hidden
        meaning. Read stanza 1 of the poem. Think about what the speaker is
        really doing and how the nurse is helping. Then try the second blank
        again.
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div className={css.text}>
        {" "}
        Figurative language is not the same as literal language. It has a hidden
        meaning. Read stanza 1 of the poem. Think about what the speaker is
        really doing and how the nurse is helping. Then try the second blank
        again.
      </div>
    );
  } else if (props.data.indices[2] == 2) {
    contents = (
      <div className={css.text}>
        Read stanzas 1 and 2 of the poem, and try the third blank again. What is
        the speaker really doing after shutting his or her eyes?
      </div>
    );
  } else if (props.data.indices[2] == 3) {
    contents = (
      <div className={css.text}>
        Read stanzas 1 and 2 of the poem, and try the third blank again. What is
        the speaker really doing after shutting his or her eyes?
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
