import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};

export const correctAnswers = { selected: 3 };

export const isEmpty = (data) => {
  return data.selected == -1;
};

export const isCorrect = (data) => {
  return data.selected == correctAnswers.selected;
};

export const getDefaultState = () => {
  return {
    selected: -1,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Look carefully at each photograph, and consider what type of animal is
    shown. Which photograph shows an example of a <Italic>fry</Italic>?
  </div>,
  <div>
    There is a photograph of sharks, a photograph of a shrimp, a photograph of a
    large grouper fish, and a photograph of a small, young fish. Which one is an
    example of a <Italic>fry</Italic>?
  </div>,
  <div>
    Consider the size and age of the animals shown in the photographs. Which one
    shows the size and age of a <Italic>fry</Italic>?
  </div>,
  <div>
    The <Italic>fry</Italic> are described as being born colorless. Which
    picture shows a fish that is partially colorless or clear?
  </div>,
];

export const showReader = () => {
  return true;
};
