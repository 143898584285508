import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "Multiply to Find the Area",
    sp: "Multiplicar para hallar el área",
  },
  0: {
    en: "Jana is planning to use this piece of construction paper for an art project.",
    sp: "Jana planea usar este trozo de cartulina para un proyecto de arte.",
  },
  1: {
    en: "Click or tap the correct answer from each drop-down menu.",
    sp: "Pulsa o haz clic en la respuesta correcta de cada menú desplegable.",
  },
  2: {
    en: "Find the area of the paper.",
    sp: "Halla el área del trozo de cartulina.",
  },
  3: {
    en: "Use",
    sp: "Debemos usar",
  },
  4: {
    en: "Choose",
    sp: "Elige",
  },
  5: {
    en: "to find the area.",
    sp: "para hallar el área.",
  },
  6: {
    en: "So, the area of the paper is",
    sp: "Entonces, el área del trozo de cartulina mide",
  },
  7: {
    en: "Choose",
    sp: "Elige",
  },
  8: {
    en: "square inches.",
    sp: "pulgadas cuadradas.",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(0)}</div>
        <div className={css.inst}>
          <Bold>{languageLookup(1)}</Bold>
        </div>
        <Instructions>{languageLookup(2)}</Instructions>
        <div className={css.row}>
          <span>{languageLookup(3)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(4)}</Italic>
                </div>
              }
              data={[
                { text: "3 + 3 = 6" },
                { text: "3 + 9 = 12" },
                { text: "3 x 9 = 27" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(5)}</span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(6)}</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(7)}</Italic>
                </div>
              }
              data={[{ text: "6" }, { text: "12" }, { text: "27" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(8)}</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
