import React from "react";
import css from "./Contents.module.css";

import Shape from "../Components/Shape";
import Reset from "../../../../Icons/Reset";
import Italic from "../../../../Italic";

const Contents = (props) => {
  const dragOverHandler = (ev, idx) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, id) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = {
      items: [
        [...props.data.items[0]],
        [...props.data.items[1]],
        [...props.data.items[2]],
      ],
    };

    data.items[id].push(dragId);
    props.setData(data);
  };

  const dragLeftStartHandler = (ev, slot, id, index) => {
    ev.dataTransfer.setData("text/plain", id);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      const data = {
        items: [
          [...props.data.items[0]],
          [...props.data.items[1]],
          [...props.data.items[2]],
        ],
      };
      data.items[slot].splice(index, 1);
      props.setData(data);
    }, 10);
  };

  const dragRightStartHandler = (ev, shape, ref) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";
  };

  const items = [[], [], []];

  for (let i = 0; i < props.data.items.length; i++) {
    for (let j = 0; j < props.data.items[i].length; j++) {
      const index = props.data.items[i][j];
      items[i].push(
        <Shape
          key={i + "_" + j}
          center
          blue
          height="20px"
          onDragStart={(ev) => dragLeftStartHandler(ev, i, index, j)}
          onDragEnd={() => {}}
          index={index}
          slot={i}
          show
        />
      );
    }
  }

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              <Italic>Carnivores</Italic>
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 0)}
            onDrop={(ev) => dropHandler(ev, 0)}
          >
            {items[0]}
          </div>
        </div>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              <Italic>Herbivores</Italic>
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 1)}
            onDrop={(ev) => dropHandler(ev, 1)}
          >
            {items[1]}
          </div>
        </div>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              <Italic>Omnivores</Italic>
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 2)}
            onDrop={(ev) => dropHandler(ev, 2)}
          >
            {items[2]}
          </div>
        </div>
      </div>
      <div className={css.contents}>
        <div className={css.row}>
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={0}
            slot={0}
            show
          />
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={1}
            slot={1}
            show
          />
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={2}
            slot={2}
            show
          />
        </div>
        <div className={css.row}>
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={3}
            slot={3}
            show
          />
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={4}
            slot={4}
            show
          />
          <Shape
            center
            blue
            height="20px"
            onDragStart={dragRightStartHandler}
            onDragEnd={() => {}}
            index={5}
            slot={5}
            show
          />
        </div>
      </div>
      <Reset style={{ bottom: "19px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
