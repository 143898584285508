import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import css from "./Lessons.module.css";

import GradeSelection from "./GradeSelection";
import Logo from "../../components/Logo";

import Logout from "../../components/Icons/Logout";
import Help from "../../components/Icons/Help";
import Activity from "./Activity";
import Footer from "./Footer";

import Controller from "./Activity/Controller";

import * as activities from "../../components/Activities/Lessons";
import LessonBox from "./LessonBox";
import ApplyBox from "./ApplyBox";
import Complete from "./Complete";

import { setLanguage } from "../../data/language";

const Lessons = (props) => {
  const [activityIndex, setActivityIndex] = useState(0);
  const [hintIndex, setHintIndex] = useState(-1);
  const [done, setDone] = useState(false);
  const [showReader, setShowReader] = useState(true);
  const [numTries, setNumTries] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [forceComplete, setForceComplete] = useState(false);
  const [completedLesson, setCompletedLesson] = useState(false);
  const [closed, setClosed] = useState(false);
  const [lessonIndex, setLessonIndex] = useState(0);

  const history = useHistory();

  setLanguage(props.language);

  const onHome = () => {
    history.push(`home-${props.subject}-${props.grade}`);
  };

  const id = `${props.subject}_${props.grade}_${activityIndex}`;

  const selectHintHandler = (index) => {
    setHintIndex(index);
  };

  const selectIndexHandler = (index, language) => {
    setActivityIndex(0);
    setShowReader(true);
    setLessonIndex(0);
    history.push(
      `lesson-${props.subject}-${index + 2}${language ? "-" + language : ""}`
    );
  };

  const gradeIndex = props.grade - 2;

  let numLessons = props.subject == "ela" ? 8 : 13;
  if (props.subject == "ela" && gradeIndex == 3) numLessons = 10;
  if (props.subject == "ela" && gradeIndex == 4) numLessons = 10;
  if (props.subject == "ela" && gradeIndex == 5) numLessons = 9;
  if (props.subject == "ela" && gradeIndex == 6) numLessons = 6;

  if (props.subject == "math" && (gradeIndex == 1 || gradeIndex == 5))
    numLessons = 9;
  if (props.subject == "math" && gradeIndex == 2) numLessons = 11;
  if (props.subject == "math" && gradeIndex == 4) numLessons = 12;
  if (props.subject == "math" && gradeIndex == 6) numLessons = 12;

  const numApply = 5;
  const lessonsComplete = activityIndex >= numLessons;
  let showArrows = numLessons > 8;

  const doneHandler = (value) => {
    if (value) {
      if ((!isComplete && Controller.IsInteractive(id)) || lessonsComplete) {
        if (!lessonsComplete) setNumTries(numTries + 1);
        setDone(true);
      } else {
        setNumTries(0);
        setIsComplete(false);
        setDone(false);
        setForceComplete(false);
        if (showArrows) nextLessonHandler();
        setActivityIndex(activityIndex + 1);
      }
    } else {
      setDone(false);
    }
  };

  const backHandler = () => {
    setNumTries(0);
    setIsComplete(false);
    setDone(false);
    setForceComplete(false);
    setCompletedLesson(false);
    setClosed(false);
    if (showArrows && activityIndex < numLessons) prevLessonHandler();
    setActivityIndex(activityIndex - 1);
  };

  const onCorrectHandler = () => {
    setIsComplete(true);
  };

  const forceCompleteHandler = () => {
    setForceComplete(true);
  };

  const lessonBoxes = [];
  let count = 0;

  for (let i = lessonIndex; i < numLessons; i++) {
    lessonBoxes.push(
      <LessonBox key={i} index={i} activityIndex={activityIndex}>
        {i + 1}
      </LessonBox>
    );
    count++;

    if (showArrows && count >= 7) break;
  }

  const applyIndex = activityIndex - numLessons;

  const applyBoxes = [];

  for (let i = 0; i < numApply; i++) {
    applyBoxes.push(
      <ApplyBox key={i} index={i} applyIndex={applyIndex}>
        {i + 1}
      </ApplyBox>
    );
  }

  const closeHandler = () => {
    //setCompletedLesson(false);
    //setClosed(true);
    onHome();
  };

  const completed = completedLesson ? (
    <Complete onClose={closeHandler} />
  ) : null;

  const skipHandler = () => {
    if (activityIndex + 1 >= numLessons + numApply) {
      console.log("skip");
      setCompletedLesson(true);
    } else {
      setNumTries(0);
      setIsComplete(false);
      setDone(false);
      if (showArrows) nextLessonHandler();
      setActivityIndex(activityIndex + 1);
    }
  };

  const prevLessonHandler = () => {
    setLessonIndex(Math.max(0, lessonIndex - 1));
  };

  const nextLessonHandler = () => {
    setLessonIndex(Math.min(numLessons - 7, lessonIndex + 1));
  };

  return (
    <div className={css.root}>
      <div className={css.background}>
        <GradeSelection
          {...props}
          activity={activityIndex}
          selectedIndex={gradeIndex}
          language={props.language}
          selectIndex={selectIndexHandler}
          onActivitySelected={setActivityIndex}
        />

        <div className={css.contents}>
          <div className={css.header}>
            <div className={css.left}>
              <div className={css.home} onMouseUp={onHome}>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMzkiIHZpZXdCb3g9IjAgMCA0MiAzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMyIgeT0iOSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjI3IiBmaWxsPSIjRTk1MzU5Ii8+CjxyZWN0IHg9IjIxIiB5PSIxOCIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBmaWxsPSIjRTMyNjJEIi8+CjxyZWN0IHg9IjEyIiB3aWR0aD0iMTgiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIzNiIgd2lkdGg9IjQyIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMTUiIHk9IjYiIHdpZHRoPSIyMSIgaGVpZ2h0PSIzIiBmaWxsPSIjOTU5NTk1Ii8+CjxyZWN0IHg9IjIxIiB5PSIxMiIgd2lkdGg9IjIxIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMTIiIHk9IjMiIHdpZHRoPSIyMSIgaGVpZ2h0PSIzIiBmaWxsPSIjODQ4NDg0Ii8+CjxyZWN0IHg9IjE4IiB5PSI5IiB3aWR0aD0iMjEiIGhlaWdodD0iMyIgZmlsbD0iIzg0ODQ4NCIvPgo8cmVjdCB4PSI5IiB5PSIzIiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSIjOTU5NTk1Ii8+CjxyZWN0IHg9IjYiIHk9IjYiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMyIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIxMiIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIxNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMjEiIHk9IjE1IiB3aWR0aD0iMjEiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMyIgeT0iMTIiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjE4IiB5PSIxMiIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iNiIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMTUiIHk9IjkiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjkiIHk9IjYiIHdpZHRoPSI2IiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjkiIHk9IjIxIiB3aWR0aD0iNiIgaGVpZ2h0PSIxNSIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMjciIHk9IjI0IiB3aWR0aD0iNiIgaGVpZ2h0PSI2IiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K"
                  alt="home"
                />
              </div>
              <div className={css.logo}>
                <Logo />
              </div>
            </div>
            <div className={css.right}>
              <div className={css.logout}>
                <Logout language={props.language} />
              </div>
              <div className={css.help}>
                <Help />
              </div>
            </div>
          </div>
          <div className={css.progress}>
            <div className={css.left}>
              <span>{props.language == "sp" ? "Lección" : "Lesson"}</span>
              {showArrows && lessonIndex > 0 ? (
                <img
                  onMouseDown={prevLessonHandler}
                  className={css.arrow + " " + css.prev}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAYAAAAlBadpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFcSURBVHgBnZQ7boNAEIZZFgkJGsKjJzfAXUr7Bs4JktzAN4hvEOUE9jHSkVRJF3KD7RHSVkAD5B8EloOXhzMS7DKz378DzCzXFprjOKFlWTvTNEVZlpJ8bCnIOY8xDXGJqqo2UkoxCw/A3loBPgM6AD8HYBvSdZ2xKdAwjLhpmmgYg0/Wdb3S/wm276yEkephBkzo+QL2PO+AYTsHkjEF+KgpDF93dQ7+2dn3/ecxEPY0BE87E4i09mNglmVHVYC5rrtjjL1cC5Lp2rQ1U0FeFMWXbduU/loR36IZBNb8KGG65Xn+jkU3mN5dI3CqbQTfsOgW00glgFb8QCuKc+dFbeNfHzE8KAQkmmGTpmkyCncC1ILrOQHl10Y13eO/J4qQgxKNgyCIRnduV8GwS4waiMYymDxJOoFvCISK8OuiY6jLIOx9eCVBHbb4AOwFepAOA22pkQD6YE9j7/sFlUyxt0vvrWAAAAAASUVORK5CYII="
                />
              ) : null}
              <div className={css.lessons}>{lessonBoxes}</div>
              {showArrows && lessonIndex < numLessons - 7 ? (
                <img
                  onMouseDown={nextLessonHandler}
                  className={css.arrow + " " + css.next}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAYCAYAAAAlBadpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFMSURBVHgBnZTNbYNAEIWXHwkBFwQNUALHHO0OkgridOASnA7cQZwKkmtOKBWYEkgBIHIBDvzkDWIjB8YL8ZOA1TLf2xmYXUOM8jwvdBxnb1lWWtd1IVZIk6BhGDGGIa60bdttURTpIjwBpVYZ6NB+ApIGQxh7SrjrulPf91yNoWmaSgNZc4QMYk3TZoGYS5qmoRJmC+h0w3yCDLZcBpiLUMIbt7IuByoDaBMEwctVWBog/QfBazc1MKYRZVmmaJYvDO8Zg8h1XQ8xHyxMqqoqURjc2bZNMZ8svGSAP7CBwbcu1OpVL6/C+DiPeJxYx75/zvP8qN0IHmg8g6nb0BRnwes1y7Kd4NIewXgN+GflC5DbCO8AZ81jLoGokdr2iTH8PQzOCpDdUSR5GHBgqgIHGAFHCvwvOKRNN0p9PAzCCzAVa0UGvu8f6LmW+QG8ocKzUkhosQAAAABJRU5ErkJggg=="
                />
              ) : null}
            </div>
            <div className={css.right}>
              <span>{props.language == "sp" ? "¡Aplícalo!" : "Apply"}</span>
              {applyBoxes}
            </div>
          </div>
          {activities[id] && (
            <Activity
              key={`${activityIndex}_${id}`}
              onSkip={skipHandler}
              onCorrect={onCorrectHandler}
              onForceComplete={forceCompleteHandler}
              lessonsComplete={lessonsComplete}
              forceComplete={forceComplete}
              forceCompleteReady={numTries >= 3}
              showReader={showReader}
              setShowReader={setShowReader}
              done={done && !completedLesson && !closed}
              closeDone={doneHandler}
              hint={hintIndex}
              closeHint={() => setHintIndex(-1)}
              id={id}
              language={props.language}
            />
          )}

          {activities[id] && (
            <Footer
              back={activityIndex > 0}
              onBack={backHandler}
              isClosed={closed}
              isLast={activityIndex + 1 >= numLessons + numApply}
              isComplete={
                isComplete ||
                !Controller.IsInteractive(id) ||
                activityIndex >= numLessons
              }
              text={Controller.HasActivity(id) && Controller.ShowReader(id)}
              setShowReader={setShowReader}
              onDone={doneHandler}
              hint={hintIndex}
              onSelectHint={selectHintHandler}
              id={id}
              language={props.language}
            />
          )}
        </div>
      </div>
      {completed}
    </div>
  );
};

export default Lessons;
