import React from "react";
import css from "./Hint.module.css";

import { hints } from "../Controller";

const Hint = (props) => {
  return <div>{hints()[props.index]}</div>;
};

export default Hint;
