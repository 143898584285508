import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Which text feature gives you information about one special arch in
        Arches National Park?
      </div>
      <div className={css.instructions}>Click or tap the text features.</div>
    </div>
  );
};

export default Sidebar;
