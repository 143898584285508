import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Nice! The quotient of 30.4 ÷ 25 is 1.216 . When you insert two zeros in
        the dividend and continue the division, the remainder will be 0.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Try again. Insert more zeros in the dividend so you can continue
        dividing.
      </div>
    );
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div className={css.text}>
        Almost there! Check your long division again.
      </div>
    );
  } else if (props.data.indices[0] == 4) {
    contents = (
      <div className={css.text}>
        Try again! After you insert zeros in the dividend, the number of decimal
        places in the dividend and the quotient should be the same.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
