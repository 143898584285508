import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Find Context Clues</span>
      </div>
      <div className={css.contents}>
        <div>
          Context clues are hints about a word’s meaning that can be found in
          the surrounding text.
        </div>
        <div>
          Let’s take a look at context clues that can be found in texts about
          several different domains. Remember, these are just examples—you can
          use context clues in any domain of learning.
        </div>
        <Instructions>
          Click or tap each subject to see context clues that explain
          domain-specific language.
        </Instructions>
        <FoldBox>
          <div>Science</div>
          <div>
            <BlockQuote>
              The bright red color <Italic>spread</Italic> as the chemical{" "}
              <Instructions span>permeated</Instructions> the material.
            </BlockQuote>
            <div>
              The word <Italic>spread</Italic> is a context clue that helps
              explain the meaning of “permeated.” This context clue helps
              readers understand that <Italic>to permeate</Italic> means “to
              pass through, spread, or imbue.”
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>Math</div>
          <div>
            <BlockQuote>
              The students measured the{" "}
              <Instructions span>circumference</Instructions> of the globe,
              carefully making sure the measuring tape wrapped{" "}
              <Italic>all the way around</Italic>.
            </BlockQuote>
            <div>
              The phrase <Italic>all the way around the outside</Italic> is a
              context clue that helps explain the meaning of “circumference.”
              This context clue helps readers understand that{" "}
              <Italic>circumference</Italic> means “the distance around a circle
              or sphere.”
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>Literature</div>
          <div>
            <BlockQuote>
              For her book report, Sherri compared two{" "}
              <Instructions span>allegories</Instructions>. She discussed how
              each story <Italic>used characters to stand in for ideas</Italic>{" "}
              the author cared about.
            </BlockQuote>
            <div>
              Look for the context clue that show what an allegory is. The
              phrase <Italic>used characters to stand in for ideas</Italic> is a
              context clue that helps to define “allegories.” The word{" "}
              <Italic>allegories</Italic> means “stories or paintings that use
              characters, objects, or events to symbolize or represent ideas.”
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>Music</div>
          <div>
            <BlockQuote>
              The <Instructions span>dissonant</Instructions> sound of the
              orchestra tuning up filled the music hall. Those{" "}
              <Italic>harsh</Italic> strains <Italic>lacked harmony</Italic>,
              but the noise alerted the audience that the show was about to
              begin.
            </BlockQuote>
            <div>
              The words <Italic>harsh</Italic> and{" "}
              <Italic>lacked harmony</Italic> are context clues that help
              readers understand the meaning of “dissonant.” This context clue
              allows readers to understand that a <Italic>dissonant</Italic>{" "}
              sound does not have harmony and can be jarring to the ears.
            </div>
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
