import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isEmpty, isCorrect } from "../Controller";

import Arrow from "../Assets/math_7_0_arrow.png";
import Underline from "../Assets/math_7_0_underline.JPG";

const Done = (props) => {
  let contents;
  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Well done! You can identify the radius in the figure and then use the
        radius to calculate the area and circumference of the circular pond.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0 || props.data.left[0] == 2) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Read the question again carefully. What does the dotted line
          represent? What is the distance from the buoy to the edge of the pond?
        </div>
        <div style={{ visibility: "hidden" }} className={css.image0}>
          <img src={Arrow} alt="arrow" />
        </div>
        <div style={{ visibility: "hidden" }} className={css.underline0}>
          <img src={Underline} alt="underline" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[1] == 4 || props.data.left[1] == 8) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Look again. You may not have used the correct radius to calculate the
          area. The radius of a circle is the distance from the center to any
          point on the circle.
        </div>
        <div style={{ visibility: "hidden" }} className={css.image1}>
          <img src={Arrow} alt="arrow" />
        </div>
        <div style={{ visibility: "hidden" }} className={css.underline0}>
          <img src={Underline} alt="underline" />
        </div>
      </Fragment>
    );
  } else if (
    props.data.left[2] == 2 ||
    props.data.left[2] == 3 ||
    props.data.left[2] == 6
  ) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Look again. You may not have used the correct radius to calculate the
          circumference of the circle. The radius of a circle is the distance
          from the center to any point on the circle.
        </div>
        <div style={{ visibility: "hidden" }} className={css.image2}>
          <img src={Arrow} alt="arrow" />
        </div>
        <div style={{ visibility: "hidden" }} className={css.underline0}>
          <img src={Underline} alt="underline" />
        </div>
      </Fragment>
    );
  } else {
    contents = (
      <div className={css.text}>
        The dotted line represents the radius of the circle and is equal to 6
        yards. Using the area formula, Area = π
        <span className={css.italic}>r</span>2 = π(62) = 36π square yards. Using
        the circumference formula, Circumference = 2π
        <span className={css.italic}>r</span> = 2π(6) = 12π yards.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
