import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use Fractions to Write Subtraction Sentences
        </span>
      </div>
      <div className={css.contents}>
        <div>Find the number or fraction that completes the sentences.</div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>9 twelfths − 3 twelfths =</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: 3,
                },
                {
                  text: 6,
                },
                {
                  text: 12,
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>twelfths</span>
        </div>
        <div className={css.row}>
          <span>
            <Fraction width="25px" numerator="9" denominator="12" /> −{" "}
            <Fraction width="25px" numerator="3" denominator="12" /> ={" "}
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="6"
                        denominator="24"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="6"
                        denominator="12"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="6"
                        denominator="6"
                      />
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>twelfths</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
