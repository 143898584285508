import * as React from "react"

function NextIcon(props) {
    return (
        <svg
            width={56}
            height={56}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_1127_11710)">
                <circle cx={28} cy={28} r={24} fill="#FBFCFD" />
            </g>
            <path
                d="M36.875 27.626a.62.62 0 010 .748l-11.48 11.5a.618.618 0 01-.747 0l-3.523-3.528a.62.62 0 010-.748l7.71-7.556-7.627-7.64a.503.503 0 010-.748l3.523-3.487a.938.938 0 01.145-.125.312.312 0 01.373.042c.07.056.118.097.145.124l11.48 11.418z"
                fill="#22A7F0"
            />
            <defs>
                <filter
                    id="filter0_d_1127_11710"
                    x={0}
                    y={0}
                    width={56}
                    height={56}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation={2} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1127_11710"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1127_11710"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default NextIcon
