import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

import ImageSP from "../Assets/math_3_12.svg";

const lines = {
  title: {
    en: "Apply: Area of Rectangles",
    sp: "¡Aplícalo! Área de los rectángulos",
  },
  0: {
    en: "Elise drew a rectangle to find the area of her bedroom.",
    sp: "Elise dibujó un rectángulo para hallar el área de su habitación.",
  },
  img: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/af58c7d01a4ed67ef20da0efd909ee95.svg",
    sp: ImageSP,
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(0)}</div>
        <Center>
          <img src={languageLookup("img")} width="50%" />
        </Center>
      </div>
    </div>
  );
};

export default Contents;
