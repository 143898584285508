import React from "react";
import css from "./LessonBox.module.css";

const LessonBox = (props) => {
  const classes = [css.root];

  if (props.activityIndex == props.index) classes.push(css.current);
  else if (props.activityIndex > props.index) classes.push(css.completed);

  return <div className={classes.join(" ")}>{props.children}</div>;
};

export default LessonBox;
