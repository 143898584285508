import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [
  [
    { id: 0, show: true },
    { id: 1, show: true },
    { id: 3, show: true },
  ],
];

export const getDefaultState = () => {
  return {
    items: [[]],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const isCorrect = (data) => {
  if (data.items[0].length != correctAnswers[0].length) return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      if (
        data.items[i].filter((x) => x.id == correctAnswers[i][j].id).length == 0
      ) {
        return false;
      }
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.items[0].length == 0;
};
export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    You can draw a conclusion about a passage by using details from the passage
    to determine an idea that is not directly stated by the author. You should
    be able to support your conclusion with text evidence from the passage.
  </div>,
  <div>
    Look for the details from the passage that support the idea that the
    suffrage movement was popular and that the suffragettes knew what they were
    doing.
  </div>,
  <div>
    Look closely at each answer choice. What does each answer choice tell you
    about the suffragettes?
  </div>,
  <div>
    Look for the answer choices that show either the popularity of the suffrage
    movement or the organizational skills of the suffragettes.
  </div>,
];

export const showReader = () => {
  return true;
};
