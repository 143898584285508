import React from "react";
import css from "./Box.module.css";

const Box = (props) => {
  const style = { ...props.style };
  style.background = props.background;
  style.color = props.color;
  style.cursor = props.pointer ? "pointer" : null;
  style.fontSize = props.fontSize;

  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <div onMouseDown={clickHandler} style={style} className={css.root}>
      {props.children}
    </div>
  );
};

export default Box;
