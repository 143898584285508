import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_7_2_reader.JPG";
import ImageB from "../Assets/ela_7_2_reader_b.JPG";

import Bold from "../../../../Bold";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Henry's Train Ride</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.subtitle}>
        It is 1869. Henry Carlson and his wife, Patricia, live in Sacramento,
        California. Henry reads in a local newspaper that the Central Pacific
        Railroad Company has finished the railroad connecting his home in
        California to his childhood home in Utah, where much of his family still
        lives.
      </div>
      <Paragraph index="1">
        Henry quickly lowered the newspaper and exclaimed, "Yes! It's finally
        completed!"
      </Paragraph>
      <Paragraph index="2">
        Patricia and Jacob ran into the living room to find Henry smiling
        widely. "Why are you so happy, Daddy?" asked Jacob.
      </Paragraph>
      <Paragraph index="3">
        Henry picked up the newspaper and shook it excitedly as he explained,
        "The transcontinental railroad has finally connected Sacramento to Utah.
        This is the first railroad linking this part of the country with areas
        to the east. When I moved here from Utah, I had to travel by horse and
        carriage. It was a very long trip! Now I can see my family again without
        making that very long and difficult journey. I miss them so much."
      </Paragraph>
      <Paragraph index="4">
        "Oh, Henry, this is great news," Patricia said as she walked toward
        Henry to get a better look at the newspaper article.
      </Paragraph>
      <Paragraph index="5">
        Jacob looked hopefully at his father and asked, "Can we go to New York?"
      </Paragraph>
      <Paragraph index="6">
        Henry laughed. "How did you learn about New York?"
      </Paragraph>
      <Paragraph index="7">
        "Mom read me a book about it," replied Jacob.
      </Paragraph>
      <Paragraph index="8">
        Henry thought for a second. "We might be able to go to New York. It will
        only take a few days to get there instead of months. However, I need to
        go to Utah first."
      </Paragraph>
      <Paragraph index="9">
        "You've wanted to go back to Utah for a long time," sighed his wife.
      </Paragraph>
      <Paragraph index="10">
        "You know, my grandfather has been very ill lately. I think he might be
        better off if he lived with us, here in California, where we can give
        him the care and attention he needs. Perhaps I can take a train ride to
        Utah and bring him back with me," Henry replied as he continued to
        glance at the paper.
      </Paragraph>
      <Paragraph index="11">
        Patricia gasped, "But you just got a new job. You can't leave for an
        extended vacation now! If you do, you'll lose your position!"
      </Paragraph>
      <Paragraph index="12">
        Henry looked at his wife as he said, "Making sure that my grandfather is
        healthy and properly cared for is more important than my job. He needs
        to be here, with us. I'll probably have to give up my job, but I am sure
        I can convince Mr. Burns to give me some other work with the company
        once I get back. I won't get paid as much, I'm sure, and we'll have to
        make some changes around here, but at least I'll know that my
        grandfather is safe. I really hope that he decides to come back with
        me."
      </Paragraph>
      <Paragraph index="13">
        That night, Henry further discussed the trip to Utah with his wife,
        hoping to make her understand why he needed to retrieve his grandfather
        as soon as possible. The elderly man would have had trouble traveling by
        horse and carriage and could not have handled such a long journey. But
        now that the trip would take only a few days and would be much more
        comfortable, Henry felt that his grandfather could bear the trip.
      </Paragraph>
      <Paragraph index="14">
        Patricia listened quietly as Henry revealed that he never thought he
        would get an opportunity to see his grandfather, who was like a father
        to him, again. Even though Patricia knew Henry would be away for weeks
        and would miss him terribly, she agreed with her husband that the trip
        was necessary. She knew that he had to see his grandfather and try to
        convince him to move to California. Patricia looked at her husband and
        said, "We will miss you, but I know that your grandfather will be very
        happy to see you. Please write to us, and I will write you back."
      </Paragraph>
      <Paragraph index="15">
        Two weeks later, Patricia and Jacob escorted Henry to the new train
        station and waved as he boarded the train. As the train left the
        station, Patricia and Jacob watched from the platform until the train
        was just a tiny speck on the horizon, both wishing that they, too, were
        experiencing train travel for the first time.
      </Paragraph>
      <Paragraph index="16">
        For the next few days, Patrick and Jacob anxiously awaited the news of
        Henry's safe arrival in Utah. Meanwhile, they prepared their spare room
        for Henry's grandfather. They lived as frugally as they could and saved
        every penny, for they had no income while Henry was gone. Finally, a
        letter arrived from him.
      </Paragraph>
      <div className={css.box}>
        <div className={css.subtitle}>Dear Patricia and Jacob,</div>
        <div className={css.subtitle}>
          I am enjoying my time in Utah with my grandfather, but I do miss the
          two of you terribly. I have the utmost confidence that you are doing
          fine without me. I have news that I hope you will find as splendid as
          I do: Grandfather will be returning with me to California. As you
          know, my brother has too many mouths to feed to take care of him, and
          we have the spare bedroom. It makes sense that Grandfather should come
          to live with us, even though it will mean that we'll have to make some
          sacrifices. Those sacrifices cannot even be compared with the benefits
          of helping family.
        </div>
        <div className={css.subtitle}>
          As for the train, I cannot wait for you and Jacob to ride it. You
          would not believe how quickly the train travels. It felt like we were
          traveling at a speed of one hundred miles per hour! The train went
          through many tunnels that were carved through the mountains of the
          Sierra Nevada, and it was absolutely fascinating to experience going
          through these tunnels. I also traveled on mountain ledges thousands of
          feet in the air. The train has a Pullman sleeping car to make the trip
          more comfortable. The sleeping car is about one hundred feet long and
          contains beds. The train has sofas and beautiful silk curtains. My
          grandfather should have no problem riding on the train, because it is
          definitely more comfortable than traveling by horse and carriage.
        </div>
        <div className={css.subtitle}>
          I will see you in another few weeks, but before then, I hope to hear
          from you. Please prepare the spare bedroom for Grandfather. He is very
          excited to meet you, Jacob!
        </div>
        <div className={css.subtitle}>All my love,</div>
        <div className={css.subtitle}>Henry</div>
      </div>
      <div className={css.line} />
      <div className={css.title}>Tracks of Steel Bring People Together</div>
      <div className={css.image}>
        <img src={ImageB} alt="image" />
      </div>
      <Paragraph index="1">
        The completion of the transcontinental railroad made traveling across
        the country by train possible. It also brought more rail lines to the
        West, making it easier to travel throughout that region. The Central
        Pacific Railroad Company built the rail line that led east from
        Sacramento, California. The Union Pacific Railroad Company built the
        rail line that led west from Omaha, Nebraska. The two companies did not
        originally plan where their two lines would meet but decided after the
        construction had begun. The companies decided to meet in Promontory
        Summit, Utah. A ceremonial golden spike was placed at the spot where the
        two sets of tracks met.
      </Paragraph>
      <Paragraph index="2">
        Construction of the railroad was completed in six years and was done
        almost completely by hand. Over the course of construction, 1,776 miles
        of railroad track were laid. Metal rails were loaded onto small
        horse-drawn carts and pulled along tracks. Workers unloaded the carts
        and laid the rails, ensuring that the rails were correctly spaced and
        leveled. Other workers were responsible for securing the rails to the
        wooden ties with spikes. In addition to the track builders, other men
        were hired to build wooden bunkhouses along the tracks to house the
        track builders. On average, workers were able to build several miles of
        track a day on flat land. Building tracks in the mountains would take
        considerably longer.
      </Paragraph>
      <Paragraph index="3">
        Railroad tracks that crossed the mountain ranges were built on ledges
        that skirted the mountains as well as in tunnels blasted through the
        mountains themselves. In order to lay track that skirted around
        mountains, workers had to create artificial ledges to support them. The
        ledges were blasted out of the mountainsides, thousands of feet above
        sea level. To do this, workers were lowered from ropes. The workers
        would drill holes into the mountainside using hand tools and then would
        fill the holes with black blasting powder. They would light a fuse and
        quickly climb up the rope to safety. The force of the explosion would
        crack the rock, and workers would clear away the rubble. Workers used
        this method to blast through the rock inch by inch. Blasting was also
        used to create tunnels through the mountains. The Central Pacific
        Railroad Company performed the railroad's greatest construction feat
        when it blasted the Summit Tunnel through the hard granite of the Sierra
        Nevada; the tunnel was 124 feet deep and 1,659 feet long. A total of
        fifteen tunnels were blasted through the Sierra Nevada.
      </Paragraph>
      <Paragraph index="4">
        The transcontinental railroad was completed on May 10, 1869. Completion
        of the railroad was central to westward expansion. Before the
        transcontinental railroad was built, people had to travel to the West in
        horse-drawn carriages or sail the dangerous route across the ocean,
        around South America. People could travel from California to New York
        within a week, compared to months. The locomotives had steam-powered
        engines and could reach speeds of 20 to almost 100 miles per hour,
        depending on the terrain. People began populating the frontier
        wilderness of the western states in greater numbers, thus changing the
        landscape of the western United States. In addition to carrying people,
        trains carried supplies, crops, livestock, and machinery to other
        locations in the United States.
      </Paragraph>
      <Paragraph index="5">
        The Central Pacific Railroad trains included Pullman sleeper cars when
        they first started making trips out of California in 1869. Pullman
        sleeper cars made long trips more comfortable for passengers. These cars
        were less than 60 feet long and had velvet curtains, upholstered chairs,
        and patterned carpets. The sleeper cars also had carved wooden interiors
        and windows. Chairs turned into beds that were large enough to
        accommodate two adults. The Pullman sleeper cars gave passengers the
        feeling that they were staying in a hotel.
      </Paragraph>
      <Paragraph index="6">
        The transcontinental railroad did not remain the only railroad in the
        region. By the 1900s, more railroads were built in the West after the
        transcontinental railroad was established. The transcontinental railroad
        changed America forever, and for the better.
      </Paragraph>
    </div>
  );
};

export default Reader;
