import React from "react";

import css from "./Header.module.css";

const Header = (props) => {
  return (
    <div
      style={{ background: props.headerColor || "#f76464" }}
      className={css.background}
    >
      <div
        className={css.text}
        dangerouslySetInnerHTML={{ __html: props.tip.title }}
      />
      <div className={css["x-button"]} onMouseUp={props.closeTip}>
        X
      </div>
    </div>
  );
};

export default Header;
