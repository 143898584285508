import React from "react";
import css from "./Done.module.css";

import { isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Correct! You can multiply the numbers of hours by 60 minutes to find how
        many minutes each student spent reading each night. Alex, Tina, Chung,
        and Sarah each received a bookmark, and Alex, Chung, and Sarah each
        received a book.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Multiply the numbers of hours by 60 minutes to find how many minutes
        each student spent reading each night. Alex read for 120 minutes. Tina
        read for 45 minutes. Chung read for 90 minutes. Sarah read for 105
        minutes. Students who read for 45 minutes or more get a bookmark, so
        Alex, Tina, Chung, and Sarah each received a bookmark. Students who read
        for 90 minutes or more get a book, so Alex, Chung, and Sarah each
        received a book.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
