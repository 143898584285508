import React from "react";
import css from "./Contents.module.css";

import Shape from "../Components/Shape";
import Reset from "../../../../Icons/Reset";
import Italic from "../../../../Italic";

const Contents = (props) => {
  const dragOverHandler = (ev, idx) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, id) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };

    const slotContains = data.left[id];
    // slot already contains item, need to swap
    if (slotContains != undefined) {
      const fromLeftSlot = props.data.left.findIndex((x) => x == dragId);

      // swapping with something already in left area
      if (fromLeftSlot > -1) {
        data.left[fromLeftSlot] = slotContains;
        data.showLeft[fromLeftSlot] = true;
      }
      // swapping with something from the right area
      else {
        data.right[slotContains] = slotContains;
        data.showRight[slotContains] = true;
      }
    }

    data.left[id] = dragId;
    data.showLeft[id] = true;
    props.setData(data);
  };

  const dragLeftStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";
    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.showLeft[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragLeftEndHandler = (ev, shape) => {
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    if (ev.dataTransfer.dropEffect !== "none") {
      data.left[shape.slot] = shape.index;
      props.setData(data);
    } else {
      data.left[shape.slot] = -1;
      data.showLeft[shape.slot] = false;
      data.right[shape.index] = shape.index;
      data.showRight[shape.index] = true;
      props.setData(data);
    }
  };

  const dragRightStartHandler = (ev, shape, ref) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    console.log("Start dragging: " + shape.index);
    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.right[shape.slot] = -1;
      data.showRight[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragRightEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              The <Italic>Nuestra Señora de Atocha</Italic> carried a great
              treasure.
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 0)}
            onDrop={(ev) => dropHandler(ev, 0)}
          >
            <Shape
              innerStyle={{ width: "80%", marginLeft: "15px" }}
              height="85px"
              onDragStart={dragLeftStartHandler}
              onDragEnd={dragLeftEndHandler}
              index={props.data.left[0]}
              slot={0}
              show={props.data.showLeft[0]}
            />
          </div>
        </div>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              The treasure from the <Italic>Atocha</Italic> was lost.
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 1)}
            onDrop={(ev) => dropHandler(ev, 1)}
          >
            <Shape
              innerStyle={{ width: "80%", marginLeft: "15px" }}
              height="85px"
              onDragStart={dragLeftStartHandler}
              onDragEnd={dragLeftEndHandler}
              index={props.data.left[1]}
              slot={1}
              show={props.data.showLeft[1]}
            />
          </div>
        </div>
        <div className={css.box}>
          <div className={css.text}>
            <div>
              Some of the <Italic>{`Atocha's`}</Italic> treasure was eventually
              salvaged.
            </div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 2)}
            onDrop={(ev) => dropHandler(ev, 2)}
          >
            <Shape
              innerStyle={{ width: "80%", marginLeft: "15px" }}
              height="85px"
              onDragStart={dragLeftStartHandler}
              onDragEnd={dragLeftEndHandler}
              index={props.data.left[2]}
              slot={2}
              show={props.data.showLeft[2]}
            />
          </div>
        </div>
      </div>
      <div className={css.contents}>
        <Shape
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="85px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="85px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          innerStyle={{ width: "80%", marginLeft: "15px" }}
          height="85px"
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
      </div>
      <Reset style={{ bottom: "19px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
