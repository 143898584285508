import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Which of the sentences from the passage is a main idea?",
    sp: "¿Cuál de las oraciones del fragmento es una idea principal?",
  },
  1: {
    en: "Click or tap the correct answer.",
    sp: "Pulsa o haz clic en la respuesta correcta.",
  },
  2: {
    en: `These instruments have since been nicknamed “squeezeboxes.”`,
    sp: "Los fuelles se expanden y contraen como pulmones.",
  },
  3: {
    en: "1800 to 1850 was also the era when music was being shaped by the valve.",
    sp: "De 1800 a 1850 también fue una era en la que las válvulas modificaron la música.",
  },
  4: {
    en: "Pieces of a valve mechanism block or open the main column of air.",
    sp: "Las piezas del mecanismo de la válvula bloquean o abren la columna principal de aire.",
  },
  5: {
    en: "Heinrich Stölzel invented the earliest version called a piston valve.",
    sp: "Heinrich Stölzel inventó la primera versión, llamada pistón de válvula.",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>{languageLookup(0)} </div>
      <Instructions>{languageLookup(1)}</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>{languageLookup(2)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>{languageLookup(3)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>{languageLookup(4)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>{languageLookup(5)}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
