import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";
import { Fragment } from "react";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: (
      <Fragment>
        Great job! For Table 1, you can write four ratios for each of the four
        rows in the table as{" "}
        <Fraction width="25px" numerator="10" denominator="2" />,{" "}
        <Fraction width="25px" numerator="20" denominator="3" />,{" "}
        <Fraction width="25px" numerator="25" denominator="4" />, and{" "}
        <Fraction width="25px" numerator="30" denominator="5" />. These ratios
        are not all equivalent, so the relationship is not proportional. For
        Table 2, you can write four ratios for each of the four rows in the
        table as <Fraction numerator="1" denominator="5" />,{" "}
        <Fraction width="25px" numerator="2" denominator="10" />,{" "}
        <Fraction width="25px" numerator="3" denominator="15" />, and{" "}
        <Fraction width="25px" numerator="4" denominator="20" />. Each of these
        ratios is equivalent to <Fraction numerator="1" denominator="5" />, so
        the relationship is proportional.
      </Fragment>
    ),
    sp: (
      <Fragment>
        ¡Buen trabajo! Para la Tabla 1 puedes escribir cuatro razones, una por
        cada una de las cuatro hileras de la tabla:{" "}
        <Fraction width="25px" numerator="10" denominator="2" />,{" "}
        <Fraction width="25px" numerator="20" denominator="3" />,{" "}
        <Fraction width="25px" numerator="25" denominator="4" />, y{" "}
        <Fraction width="25px" numerator="30" denominator="5" />. No todas estas
        razones son equivalentes, así que la relación es no proporcional. Para
        la Tabla 2, puedes escribir cuatro razones, una por cada una de las
        cuatro hileras de la tabla: <Fraction numerator="1" denominator="5" />,{" "}
        <Fraction width="25px" numerator="2" denominator="10" />,{" "}
        <Fraction width="25px" numerator="3" denominator="15" />, y{" "}
        <Fraction width="25px" numerator="4" denominator="20" />. Cada una de
        estas razones es equivalente a{" "}
        <Fraction numerator="1" denominator="5" />, por lo tanto, la relación es
        proporcional.
      </Fragment>
    ),
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  7: {
    en: (
      <Fragment>
        Try again! Are all ratios between the quantities <Italic>x</Italic> and{" "}
        <Italic>y</Italic> given in Table 1 equivalent?
      </Fragment>
    ),
    sp: (
      <Fragment>
        ¡Inténtalo otra vez! ¿Todas las razones entre las cantidades{" "}
        <Italic>x</Italic> y <Italic>y</Italic> dadas en la Tabla 1 son
        equivalentes?
      </Fragment>
    ),
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: (
      <Fragment>
        Try again! Are all ratios between the quantities <Italic>x</Italic> and{" "}
        <Italic>y</Italic> given in Table 2 equivalent? If they are, the
        relationship is proportional.
      </Fragment>
    ),
    sp: (
      <Fragment>
        ¡Inténtalo otra vez! ¿Todas las razones entre las cantidades{" "}
        <Italic>x</Italic> y <Italic>y</Italic> dadas en la Tabla 2 son
        equivalentes? Si lo son, la relación es proporcional.
      </Fragment>
    ),
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div style={{ lineHeight: "30px" }} className={css.text}>
        {languageLookup(0)}
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(6)}</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = <div className={css.text}>{languageLookup(7)}</div>;
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = <div className={css.text}>{languageLookup(10)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
