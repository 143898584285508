import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (
    props.data.icons[0] == correctAnswers[0] &&
    props.data.icons[1] == correctAnswers[1] &&
    props.data.icons[2] == correctAnswers[2] &&
    props.data.icons[3] == correctAnswers[3] &&
    props.data.icons[4] == correctAnswers[4] &&
    props.data.icons[5] == correctAnswers[5] &&
    props.data.icons[6] == correctAnswers[6]
  ) {
    contents = (
      <div className={css.text}>
        You counted the 5 circles and graphed the data correctly.
      </div>
    );
  }
  // empty
  else if (props.data.icons.filter((x) => x == true).length == 0) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.icons.filter((x) => x == true).length == 3) {
    contents = (
      <div className={css.text}>
        There are 3 red triangles in Emma's bag. How many purple circles are
        there? Put that number of symbols in the row for Circles.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 4) {
    contents = (
      <div className={css.text}>
        There are 4 orange rectangles in Emma's bag. How many purple circles are
        there? Put that number of symbols in the row for Circles.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        The number of shapes in the bag should match the number of symbols in
        the picture graph. Put 5 symbols in the row for Circles.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
