import React from "react";
import css from "./K2Lessons.module.css";

export const ANSWER_STATES = {
  input: "input",
  closed: "closed",
  correct: "correct",
  incorrect: "incorrect",
};

export const AnswerBox = ({ answer, state = ANSWER_STATES.input, onClick }) => (
  <div className={css[`outter-${state}`]}>
    <div onClick={onClick} className={`${css.answerBox} ${css[state]}`}>
      <div className={css.inner}>{answer}</div>
    </div>
  </div>
);
