import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That's it! The word <Italic>indebted</Italic> can be used to express the
        idea "so thankful that it feels right to return the kindness.”
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Take another look. Reread the sentences and consider which word would
        best express the idea “so thankful that it feels right to return the
        kindness.”
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
