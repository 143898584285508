import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Highlight TWO sentences that show Jahi's attempt to solve his problem.
      </div>
      <div className={css.instructions}>
        Click or tap on the text to select or deselect it.
      </div>
    </div>
  );
};

export default Sidebar;
