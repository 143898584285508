import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply: Dividing Decimals</span>
      </div>
      <div className={css.contents}>
        <Center>
          <div>
            Corrine has 9.6 pounds of trail mix to divide equally into 12 bags.
          </div>
        </Center>
      </div>
    </div>
  );
};

export default Contents;
