import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Excellent work! There are 32 unit squares. So, the area of the rectangle
        is 32 square meters.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer.</div>;
  } else if (
    props.data.inputs[0] != correctAnswers[0] &&
    props.data.inputs[1] != correctAnswers[1]
  ) {
    contents = (
      <div className={css.text}>
        Try again! Be sure to count the number of squares in the rectangle, not
        the number of rectangles.
      </div>
    );
  } else if (props.data.inputs[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Try again! Be sure to count the number of squares in the whole
        rectangle.
      </div>
    );
  } else if (props.data.inputs[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Try again! There are 32 unit squares. So, the area of the rectangle is ?
        square meters.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
