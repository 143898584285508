import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That's right! The title "Rediscovering an Old Art," the second sentence,
        and the last sentence all suggest the following main idea—that quilting
        is becoming popular again.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[0]) {
    contents = (
      <div className={css.text}>
        Try again. Quilting is described as an "old art," but this is not the
        implied main idea of the entire passage. Look again at the title and the
        supporting details. What do they suggest about the topic of quilting?{" "}
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        Look more closely. The title "Rediscovering an Old Art," the second
        sentence, and the last sentence all suggest the following main idea—that
        quilting is becoming popular again.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        Look again. The passage does state that making quilts is time-consuming,
        but this isn't the implied main idea.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
