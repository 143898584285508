import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Decimal by a Decimal
        </span>
      </div>
      <div className={css.contents}>
        <div>
          The model shown represents a division equation with decimal numbers.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/576812936830d3ee9f4c03e28b68a2bf.svg"
            width="90%"
          />
        </Center>
        <div>Complete the division equation that matches the model.</div>
        <Instructions>
          Click or tap the correct number from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>The shaded grids show the number</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "1.6" },
                { text: "3.02" },
                { text: "3.2" },
                { text: "4" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>.</span>
        </div>
        <div className={css.row}>
          <span>The model shows</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "2" },
                { text: "3.02" },
                { text: "3.2" },
                { text: "4" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>equal groups of</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "1.6" },
                { text: "2" },
                { text: "3.2" },
                { text: "4" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>each.</span>
        </div>
        <div className={css.row}>
          <span>3.2 ÷ 1.6 =</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[3] - 1}
              selectIndex={(index) => selectIndexHandler(3, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "1.6" },
                { text: "2" },
                { text: "3.2" },
                { text: "4" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
      </div>
      <div style={{ height: "150px" }} />
    </div>
  );
};

export default Contents;
