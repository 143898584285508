import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import Bold from "../../../../Bold";
import ImageA from "../Assets/ela_4_0_a.JPG";
import ImageB from "../Assets/ela_4_0_b.JPG";
import ImageC from "../Assets/ela_4_0_c.JPG";
import ImageD from "../Assets/ela_4_0_d.JPG";
import Hotspot from "../../../../Hotspot";

const Contents = (props) => {
  const selectHandler = (hotspot) => {
    const data = { selected: hotspot.index };
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.image}>
          <Hotspot
            index={0}
            selected={props.data.selected == 0}
            onSelected={selectHandler}
          >
            <img src={ImageA} alt="Image A" />
          </Hotspot>
        </div>
        <div className={css.image}>
          <Hotspot
            index={1}
            selected={props.data.selected == 1}
            onSelected={selectHandler}
          >
            <img src={ImageB} alt="Image B" />
          </Hotspot>
        </div>
        <div className={css.image}>
          <Hotspot
            index={2}
            selected={props.data.selected == 2}
            onSelected={selectHandler}
          >
            <img src={ImageC} alt="Image C" />
          </Hotspot>
        </div>
        <div className={css.image}>
          <Hotspot
            index={3}
            selected={props.data.selected == 3}
            onSelected={selectHandler}
          >
            <img src={ImageD} alt="Image D" />
          </Hotspot>
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
