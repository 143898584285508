import React from "react";
import { Fragment } from "react";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "If all the ratios are equivalent, the table represents a proportional relationship.",
    sp: "Si todas las razones son equivalentes, la tabla representa una relación proporcional.",
  },
  1: {
    sp: (
      <Fragment>
        En la Tabla 1, la razón de la primera hilera es{" "}
        <Fraction width="25px" numerator="10" denominator="2" /> o{" "}
        <Fraction numerator="5" denominator="1" />. En la segunda hilera, la
        razón es <Fraction width="25px" numerator="20" denominator="3" />. ¿Es{" "}
        <Fraction width="25px" numerator="20" denominator="3" /> ={" "}
        <Fraction numerator="5" denominator="1" />? Comprueba todas las hileras
        de la tabla.
      </Fragment>
    ),
    en: (
      <Fragment>
        In Table 1 the ratio in the first row is{" "}
        <Fraction width="25px" numerator="10" denominator="2" /> or{" "}
        <Fraction numerator="5" denominator="1" />. In the second row, the ratio
        is <Fraction width="25px" numerator="20" denominator="3" />. Is{" "}
        <Fraction width="25px" numerator="20" denominator="3" /> ={" "}
        <Fraction numerator="5" denominator="1" />? Check all the rows in the
        table.
      </Fragment>
    ),
  },
  2: {
    en: (
      <Fragment>
        In Table 2 the ratio in the first row is{" "}
        <Fraction numerator="1" denominator="5" />. In the second row, the ratio
        is <Fraction width="25px" numerator="2" denominator="10" />. Is{" "}
        <Fraction width="25px" numerator="2" denominator="10" /> ={" "}
        <Fraction numerator="5" denominator="1" />? Check all the rows in the
        table.
      </Fragment>
    ),
    sp: (
      <Fragment>
        En la Tabla 2, la razón de la primera hilera es{" "}
        <Fraction numerator="1" denominator="5" />. En la segunda hilera, la
        razón es <Fraction width="25px" numerator="2" denominator="10" />. ¿Es{" "}
        <Fraction width="25px" numerator="2" denominator="10" /> ={" "}
        <Fraction numerator="5" denominator="1" />? Comprueba todas las hileras
        de la tabla.
      </Fragment>
    ),
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};

export const correctAnswers = [2, 1];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div style={{ lineHeight: "30px" }}>{languageLookup(1)}</div>,
  <div style={{ lineHeight: "30px" }}>{languageLookup(2)}</div>,
];

export const showReader = () => {
  return false;
};
