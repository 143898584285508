import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn How to Use Precise Language</span>
      </div>
      <div className={css.contents}>
        <div>
          You can make your writing more precise by using specific words and
          sensory details.{" "}
        </div>
        <div>
          <div>
            <Bold>Specific words</Bold> refer to exact things. Instead of using
            general words like <Italic>toys</Italic>, <Italic>floor</Italic>,
            and <Italic>food</Italic>, you can use specific words such as{" "}
            <Italic>blocks</Italic>, <Italic>tiles</Italic>, and{" "}
            <Italic>tacos</Italic>. Use specific words so the reader can picture
            the scene more clearly.
          </div>
          <BlockQuote>
            The <Instructions span>food</Instructions> we ate at the{" "}
            <Instructions span>meal</Instructions> was{" "}
            <Instructions span>good</Instructions>.
          </BlockQuote>
          <BlockQuote>
            The <Instructions span>lasagna</Instructions> we ate at{" "}
            <Instructions span>dinner</Instructions> was{" "}
            <Instructions span>delicious</Instructions>.
          </BlockQuote>
        </div>
        <div>
          <div>
            <Bold>Sensory details</Bold> tell about the five senses (sight,
            hearing, smell, taste, and touch). Use sensory words to help the
            reader imagine the experience.
          </div>
          <BlockQuote>I woke up with a headache.</BlockQuote>
          <BlockQuote>
            I woke up with a <Instructions span>throbbing</Instructions>{" "}
            headache{" "}
            <Instructions span>that pounded like a hammer</Instructions>.
          </BlockQuote>
          <div>
            The second sentence shows the senses of sound, sight, and touch.
          </div>
        </div>
        <div>
          Words like <Italic>fantastic</Italic>, <Italic>terrible</Italic>,{" "}
          <Italic>great</Italic>, <Italic>amazing</Italic>, and{" "}
          <Italic>horrible</Italic> can help express emotion, but they do not
          show a connection to the five senses. These words can show how good or
          bad something is, but they are not sensory details.
        </div>
        <Instructions>
          Click or tap each box to see precise language.
        </Instructions>
        <FoldBox index={0} onClick={boxHandler} selected={boxes[0]}>
          <div>
            <Bold>Specific words:</Bold> I bought a snack and a drink.
          </div>
          <div>
            I bought a <Bold>granola bar</Bold> and an{" "}
            <Bold>ice cold water.</Bold>
          </div>
        </FoldBox>
        <FoldBox index={1} onClick={boxHandler} selected={boxes[1]}>
          <div>
            <Bold>Sensory details:</Bold> My dog ate his treats.
          </div>
          <div>
            My <Bold>jumpy</Bold> dog Mumford <Bold>munched</Bold> his{" "}
            <Bold>crunchy</Bold> biscuits.
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
