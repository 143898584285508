import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Bold from "../../../../Bold";

import SpanishImg from "../Assets/art.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Learn about Main Ideas and Supporting Details",
    sp: "Aprende sobre las ideas principales y los detalles de apoyo",
  },
  1: {
    en: (
      <Fragment>
        Every piece of writing has a <Bold>main idea</Bold>, or the most
        important idea or opinion about a topic that a writer wants to convey. A
        main idea can be the central idea of an entire work, a section of that
        work, or a single paragraph. <Bold>Supporting details</Bold> are the
        words, phrases, or sentences that tell more about the main idea.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Todo texto tiene una <Bold>idea principal</Bold>, es decir, la idea u
        opinión más importante sobre un tema que quiere transmitir la persona
        que escribe. Una idea principal puede ser la idea central que recorre un
        texto entero, estar en una sección de ese texto o aparecer en apenas un
        párrafo. Los <Bold>detalles de apoyo</Bold> son las palabras, frases u
        oraciones que dicen más cosas sobre la idea principal.
      </Fragment>
    ),
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "When you identify the main ideas and supporting details of paragraphs and passages, you narrow down and organize its ideas and information in your mind. You can also better understand how they are related.",
    sp: "Cuando identificas las ideas principales y los detalles de apoyo de un párrafo o de un fragmento, simplificas y organizas las ideas y la información en tu mente. También logras comprender mejor cómo estas se relacionan.",
  },
  5: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/d2c1fca62ee58c2a4c36810b8748fd8e.svg",
    sp: SpanishImg,
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <div>{languageLookup(4)}</div>
        <img src={`${languageLookup(5)}`} />
      </div>
    </div>
  );
};

export default Contents;
