import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

import Owls from "../Assets/owls.JPG";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <Center>
        <img style={{ marginTop: "20px" }} src={Owls} />
      </Center>
    </div>
  );
};

export default Contents;
