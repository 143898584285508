import React, { useState } from "react";
import css from "./Contents.module.css";

import Shapes from "../Assets/math_2_1_shapes.png";
import Reset from "../../../../Icons/Reset";

import IconImg from "../Assets/math_2_1_icon.png";

import Icon from "../Components/Icon";

const Contents = (props) => {
  const selectIconHandler = (index) => {
    const data = { icons: [...props.data.icons] };
    data.icons[index] = !data.icons[index];

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.instructions}>
          <div className={css.text}>Emma has a bag of shapes.</div>
          <div className={css.text}>These are the shapes in Emma's bag.</div>
        </div>
        <div className={css.image}>
          <img src={Shapes} alt="shapes" />
        </div>
      </div>
      <div className={css.header}>Shapes in the Bag</div>

      <div className={css.grid}>
        <div className={css.text}>Triangles</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon} />
        <div className={css.icon} />
        <div className={css.icon} />
        <div className={css.icon} />
        <div className={css.text}>Circles</div>
        <div className={css.icon}>
          <Icon
            index={0}
            selected={props.data.icons[0]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={1}
            selected={props.data.icons[1]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={2}
            selected={props.data.icons[2]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={3}
            selected={props.data.icons[3]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={4}
            selected={props.data.icons[4]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={5}
            selected={props.data.icons[5]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            index={6}
            selected={props.data.icons[6]}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.text}>Squares</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon} />
        <div className={css.text}>Rectangles</div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.icon} />
        <div className={css.icon} />
        <div className={css.icon} />
      </div>

      <div className={css.bottom}>
        <div className={css.key}>Key:</div>
        <div className={css.text}>Each</div>
        <div className={css.image}>
          <img src={IconImg} alt="icon" />
        </div>
        <div className={css.text}>= 1 shape</div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
