import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Precise Language to Show Emotion</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question. </Bold>
        </div>
        <ol>
          <li>As we entered the arena, the audience stood up and cheered.</li>
          <li>
            The tennis player threw down his racquet and stomped off the court.
          </li>
          <li>
            Yalitza’s face fell and her eyes filled with tears when she heard
            the news.
          </li>
          <li>
            Keith became red in the face and clenched his fists when he saw the
            damage.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
