import React from "react";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "A graph of a straight line shows that the relationship is changing in a constant way.",
    sp: "La gráfica de una línea recta muestra que la relación va cambiando de manera constante.",
  },
  1: {
    en: "If the graph of a proportional relationship is a straight line that passes through the origin, the relationship is proportional.",
    sp: "Si la gráfica de una relación es una línea recta que pasa por el origen, la relación es proporcional.",
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};

export const correctAnswers = [1, 1];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
