import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        In paragraph 3 of “What’s a Mother to Do?” the author uses the word{" "}
        <Underline>fry</Underline>. Look at each photograph. Based on
        information in the passage, which photograph BEST illustrates the
        meaning of the word <Underline>fry</Underline>?
      </div>
      <div className={css.instructions}>Click or tap the photograph.</div>
    </div>
  );
};

export default Sidebar;
