import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Perfect! You need to divide the length of the whole piece of ribbon, 4.5
        meters, by the length of each piece, 0.25 meter, to find the number of
        pieces. To do this, you multiply both the divisor and dividend by 100.
        So, 4.5 ÷ 0.25 = 18 because 450 ÷ 25 = 18.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[1]) {
    contents = (
      <div className={css.text}>
        Think about trying to find the number of groups of 0.25 meter in 4.5
        meters.
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        To keep the quotient the same, you must multiply the divisor and the
        dividend by the <Bold>same number</Bold>.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        To keep the quotient the same, you must multiply the divisor and the
        dividend by the <Bold>same number</Bold>.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
