import React, { useState } from "react";
import css from "./Activity.module.css";
import Contents from "./Contents";
import Sidebar from "./Sidebar";

import Controller from "./Controller";
import Feedback from "./Feedback";
import Reader from "../../../components/Reader";

const Activity = (props) => {
  const defaultState = Controller.GetDefaultState(props.id);

  const [data, setData] = useState(defaultState);

  const AContents = Controller.GetContents(props.id);
  const ASidebar = Controller.GetSidebar(props.id);
  const AHint = Controller.GetHint(props.id);
  const ADone = Controller.GetDone(props.id);
  const AReader = Controller.GetReader(props.id);

  const feedbackType = Controller.GetFeedbackType(props.id, data, setData);
  const showReader = props.showReader && Controller.ShowReader(props.id);

  const resetHandler = () => {
    setData({ ...defaultState });
  };

  let feedback;

  switch (feedbackType) {
    case "empty":
      feedback = (
        <Feedback close onClose={props.closeShowFeedback}>
          <ADone data={data} setData={setData} />
        </Feedback>
      );
      break;
    case "correct":
      if (!props.correct && props.showFeedback) props.onCorrect(true);

      feedback = (
        <Feedback
          style={{
            background: "#f1f6f0",
            borderTop: "8px solid #378500",
          }}
          audio
          title="Yes!"
          close
          onClose={props.closeShowFeedback}
        >
          <ADone data={data} setData={setData} />
        </Feedback>
      );
      break;
    case "incorrect":
      feedback = (
        <Feedback
          style={{
            background: "#f7eff4",
            borderTop: "8px solid #ab0049",
          }}
          audio
          title="TAKE ANOTHER LOOK"
          close
          onClose={props.closeShowFeedback}
        >
          <ADone data={data} setData={setData} />
        </Feedback>
      );
      break;
  }

  return (
    <div className={css.root}>
      <Contents>
        <AContents data={data} setData={setData} reset={resetHandler} />
      </Contents>
      <Sidebar>
        <ASidebar data={data} setData={setData} reset={resetHandler} />
      </Sidebar>
      {props.hint > -1 ? (
        <Feedback
          audio
          title={`HINT ${props.hint + 1}`}
          close
          index={props.hint}
          onClose={props.closeHint}
        >
          <AHint data={data} setData={setData} index={props.hint} />
        </Feedback>
      ) : null}
      {props.showFeedback ? feedback : null}
      {showReader ? (
        <Reader onClose={() => props.setShowReader(false)}>
          <AReader />
        </Reader>
      ) : null}
    </div>
  );
};

export default Activity;
