import React, { useState } from "react";
import css from "./ClassView.module.css";

import Logo from "../../components/Logo";
import Logout from "../../components/Icons/Logout";
import Help from "../../components/Icons/Help";
import Messages from "../../components/Icons/Messages/Messages";
import Islands from "./Islands";
import Sidebar from "../../components/Sidebar";
import Profiles from "./Profiles";
import ProfilePopup from "./ProfilePopup";

const ClassView = (props) => {
  const [profile, setProfile] = useState(null);

  const selectProfileHandler = (p) => {
    setProfile(p.data);
  };

  const closeProfileHandler = () => {
    setProfile(null);
  };

  const profilePopup = profile ? (
    <ProfilePopup onClose={closeProfileHandler} data={profile} />
  ) : null;

  return (
    <div className={css.root}>
      <div className={css.background}>
        <img
          className={css.planet}
          src="https://practice.gogetwaggle.com/student/app/static/media/redplant-classview.svg"
        />
      </div>
      <div className={css.top}>
        <div className={css.options}>
          <div className={css.logout}>
            <Logout />
          </div>
          <div className={css.help}>
            <Help />
          </div>
          <div className={css.messages}>
            <Messages />
          </div>
        </div>
      </div>
      <div className={css.logo}>
        <Logo />
      </div>
      <div className={css.title}>
        <span>Training60 - ELA</span>
      </div>
      <Islands left="50%" top="225px" />
      <div className={css.bottom}>
        <Profiles onSelect={selectProfileHandler} />
      </div>
      <Sidebar {...props} index={3} />
      {profilePopup}
    </div>
  );
};

export default ClassView;
