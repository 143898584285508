import React from "react";
import css from "./Contents.module.css";

import Shape from "../Components/Shape";
import Reset from "../../../../Icons/Reset";
import Italic from "../../../../Italic";

import Arrow from "../Assets/ela_8_0_arrow.JPG";

const Contents = (props) => {
  const dragOverHandler = (ev, idx) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, id) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };

    const slotContains = data.left[id];
    // slot already contains item, need to swap
    if (slotContains != undefined) {
      const fromLeftSlot = props.data.left.findIndex((x) => x == dragId);

      // swapping with something already in left area
      if (fromLeftSlot > -1) {
        data.left[fromLeftSlot] = slotContains;
        data.showLeft[fromLeftSlot] = true;
      }
      // swapping with something from the right area
      else {
        data.right[slotContains] = slotContains;
        data.showRight[slotContains] = true;
      }
    }

    data.left[id] = dragId;
    data.showLeft[id] = true;
    props.setData(data);
  };

  const dragLeftStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";
    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.showLeft[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragLeftEndHandler = (ev, shape) => {
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    if (ev.dataTransfer.dropEffect !== "none") {
      data.left[shape.slot] = shape.index;
      props.setData(data);
    } else {
      data.left[shape.slot] = -1;
      data.showLeft[shape.slot] = false;
      data.right[shape.index] = shape.index;
      data.showRight[shape.index] = true;
      props.setData(data);
    }
  };

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.row}>
          <div className={css.box}>
            <div className={css.header}>
              <div>Cause</div>
            </div>
            <div className={css.text}>
              A train of roller-coaster cars slowly climbs a hill.
            </div>
          </div>
          <div className={css.arrow}>
            <img src={Arrow} />
          </div>
          <div className={css.box}>
            <div className={css.header}>
              <div>Effect</div>
            </div>
            <div
              className={css.bucket}
              onDragOver={(ev) => dragOverHandler(ev, 0)}
              onDrop={(ev) => dropHandler(ev, 0)}
            >
              <Shape
                blue
                innerStyle={{ width: "80%", marginLeft: "15px" }}
                height="40px"
                onDragStart={dragLeftStartHandler}
                onDragEnd={dragLeftEndHandler}
                index={props.data.left[0]}
                slot={0}
                show={props.data.showLeft[0]}
              />
            </div>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.box}>
            <div className={css.header}>
              <div>Cause</div>
            </div>
            <div className={css.text}>
              A train of roller-coaster cars reaches the end of its run and
              comes to a stop.
            </div>
          </div>
          <div className={css.arrow}>
            <img src={Arrow} />
          </div>
          <div className={css.box}>
            <div className={css.header}>
              <div>Effect</div>
            </div>
            <div
              className={css.bucket}
              onDragOver={(ev) => dragOverHandler(ev, 1)}
              onDrop={(ev) => dropHandler(ev, 1)}
            >
              <Shape
                blue
                innerStyle={{ width: "80%", marginLeft: "15px" }}
                height="65px"
                onDragStart={dragLeftStartHandler}
                onDragEnd={dragLeftEndHandler}
                index={props.data.left[1]}
                slot={1}
                show={props.data.showLeft[1]}
              />
            </div>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.box}>
            <div className={css.header}>
              <div>Cause</div>
            </div>
            <div className={css.text}>
              A train of roller-coaster cars moves toward the bottom of a hill.
            </div>
          </div>
          <div className={css.arrow}>
            <img src={Arrow} />
          </div>
          <div className={css.box}>
            <div className={css.header}>
              <div>Effect</div>
            </div>
            <div
              className={css.bucket}
              onDragOver={(ev) => dragOverHandler(ev, 2)}
              onDrop={(ev) => dropHandler(ev, 2)}
            >
              <Shape
                blue
                innerStyle={{ width: "80%", marginLeft: "15px" }}
                height="65px"
                onDragStart={dragLeftStartHandler}
                onDragEnd={dragLeftEndHandler}
                index={props.data.left[2]}
                slot={2}
                show={props.data.showLeft[2]}
              />
            </div>
          </div>
        </div>
      </div>
      <Reset style={{ bottom: "19px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
