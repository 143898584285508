import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! The quotient is 1.59 . The first digit of the quotient should
        be above the 3 in the dividend, 23.85 , since you used 23÷15 as your
        first step in long division. The decimal point in the quotient should be
        directly above the decimal point in the dividend.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Try again. The first digit of the quotient should be above the 3 in the
        dividend, 23.85 , since you use 23÷15 as your first step in long
        division. The decimal point in the quotient should be directly above the
        decimal point in the dividend.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
