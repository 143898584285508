import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Create a dot plot to display the number of sheets of paper in the
        folders.
      </div>
      <div className={css.title}>
        Be sure to fill boxes above each number from the bottom up. Do not skip
        rows.
      </div>
      <div className={css.instructions}>
        Click or tap the boxes to create the dot plot.
      </div>
    </div>
  );
};

export default Sidebar;
