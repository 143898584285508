import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

import SpanishA from "../Assets/spanish_a.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Determine If Ratios Are Equivalent",
    sp: "Determinar si las razones son equivalentes",
  },
  1: {
    en: (
      <Fragment>
        Each table shows the relationship between two quantities,{" "}
        <Italic>x</Italic> and <Italic>y</Italic>.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Cada tabla muestra la relación entre dos cantidades, <Italic>x</Italic>{" "}
        y <Italic>y</Italic>.
      </Fragment>
    ),
  },
  2: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/038efe094f4de810d50bf9c20eeecf20.svg",
    sp: SpanishA,
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "All the ratios are equivalent in Table 1, so it represents a proportional relationship.",
    sp: "Todas las razones de la Tabla 1 son equivalentes, por lo tanto, se trata de una relación proporcional.",
  },
  6: {
    en: (
      <Fragment>
        The ratios are <Italic>not</Italic> all equivalent in Table 2, so it
        does not represent a proportional relationship.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Italic>No</Italic> todas las razones de la Tabla 2 son equivalentes,
        por lo tanto, <Italic>no</Italic> se trata de una relación proporcional.
      </Fragment>
    ),
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "Tables don’t need to show every value, and they don’t always start with the number 1.",
    sp: "No es necesario que las tablas muestren todos los valores, y no siempre el primer valor es el número 1.",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <Center>
          <img src={languageLookup(2)} width="40%" />
        </Center>
        <div>{languageLookup(5)}</div>
        <div>{languageLookup(6)}</div>
        <div>{languageLookup(9)}</div>
      </div>
    </div>
  );
};

export default Contents;
