import React from "react";
import css from "./PointBox.module.css";

import profileData from "../../../data/profiles.json";

const PointBox = (props) => {
  const index = props.index + (props.correct ? 1 : 0);
  const points = profileData[0].score.points.overall + index * 20;

  return (
    <div className={css.root}>
      <div className={css.plus}>
        <img
          src="https://practice.gogetwaggle.com/student/public/images/headerimages/PointsPlusIcon.png"
          alt="plus"
        />
      </div>
      <div className={css.text}>{`${points} points`}</div>
    </div>
  );
};

export default PointBox;
