import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};

export const correctAnswers = { selected: 1 };

export const isEmpty = (data) => {
  return data.selected == -1;
};

export const isCorrect = (data) => {
  return data.selected == correctAnswers.selected;
};

export const getDefaultState = () => {
  return {
    selected: -1,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Context clues are familiar words and phrases around an unfamiliar word that
    can help you understand its meaning.
  </div>,
  <div>
    Read the first stanza, and try to rephrase it in your own words. Which
    meaning in the chart best fits your rephrasing?
  </div>,
  <div>
    Read through the answer choices. Think about which answer choice makes the
    most sense in the context of the poem.
  </div>,
  <div>
    Reread the whole poem, and look for supporting context clues not only in
    stanza 1 but also in the other stanzas. What is the whole poem about?
  </div>,
];

export const showReader = () => {
  return true;
};
