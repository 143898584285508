import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Whole Number by a Unit Fraction
        </span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          The fraction model shows the quotient of 4 ÷{" "}
          <Fraction numerator="1" denominator="4" />.
        </div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/4708bfd4a0da98a620c1b590a6149d54.svg"
          width="100%"
        />
        <Instructions>
          Choose the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>
            An equivalent multiplication expression for 4 ÷{" "}
            <Fraction numerator="1" denominator="4" /> is
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      4 x{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />
                    </div>
                  ),
                },
                { text: "4 x 4" },
                { text: "4 x 16" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>
            So, 4 ÷ <Fraction numerator="1" denominator="4" /> is
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />
                    </div>
                  ),
                },
                { text: "4" },
                { text: "16" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
