import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "You got it! When you identify the main idea and supporting details, you are no longer looking at the unimportant details.",
    sp: "¡Así es! Cuando identificas la idea principal y los detalles de apoyo, ya no prestas atención a los detalles menores.",
  },
  1: {
    en: "Please select another answer.",
    sp: "Por favor selecciona otra respuesta.",
  },
  2: {
    en: "Please select another answer.",
    sp: "Por favor selecciona otra respuesta.",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
