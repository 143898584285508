import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import videoEN from "../../assets/math_1_en.mp4";
import videoES from "../../assets/math_1_es.mp4";
import numberline from "../../assets/art/eight-minus-two-timeline.svg";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";

import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox, ANSWER_STATES } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { useLanguage } from "./useLanguage";
import { check } from "prettier";

export const Screen7 = () => {
  const history = useHistory();
  const language = useLanguage();
  const video = language === 'en' ? videoEN : videoES
  const onNextScreen = () => history.push(`../8/${language}`)


  const {
    foxState,
    setFoxState,
    continueState,
    answerState,
    checkState,
    currentAnswer,
    onCheck,
    open,
    forceOpen,
    selectAnswer,
  } = useMCFeedback({
    2: { feedback: `dd1error1_G1_010B3_math_vo_${language}` },
    7: { feedback: `dd1error2_G1_010B3_math_vo_${language}` },
    6: { correct: true },
  }, language);

  const playInstruction = useCallback(
    () => playVoiceover(`instruction_G1_010B3_math_vo_${language}`, setFoxState),
    [language, setFoxState]
  );


  const { getHint, playVideo, closeVideo } = useHints(
    [`hint1_G1_010A2_math_vo_${language}`,
    `hint2_G1_010A2_math_vo_${language}`],
    setFoxState,
    language
  );

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.prompt}>
        <img src={numberline} />
        <br />
      </div>
      <div className={css.prompt}>
        <span>8 - 2 = </span>
        <AnswerBox
          state={ANSWER_STATES.input}
          onClick={forceOpen}
          answer={currentAnswer}
        />
      </div>

      <div className={css.prompt}>
        {open && (
          <PopupPromptBox onChange={selectAnswer} options={[2, 6, 7]} />
        )}
      </div>

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onHint={getHint}
        onCheck={onCheck}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
