import React from "react";
import Shape from "../Components/Shape";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  const dragStartHandler = (ev, shape, ref) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.right[shape.slot] = -1;
      data.showRight[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        What are the radius, the area, and the circumference of the pond?
      </div>
      <div className={css.instructions}>
        Drag the numbers to show the correct values. Click or tap the Reset
        button to start over.
      </div>
      <div className={css.grid}>
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={3}
          slot={3}
          show={props.data.showRight[3]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={4}
          slot={4}
          show={props.data.showRight[4]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={5}
          slot={5}
          show={props.data.showRight[5]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={6}
          slot={6}
          show={props.data.showRight[6]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={7}
          slot={7}
          show={props.data.showRight[7]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={8}
          slot={8}
          show={props.data.showRight[8]}
        />
      </div>
    </div>
  );
};

export default Sidebar;
