import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [false, true, false, true, false];

export const getAnswers = () => {
  return { selected: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    selected: [false, false, false, false, false],
  };
};

export const isEmpty = (data) => {
  return data.selected.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.selected.length; i++) {
    if (data.selected[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    There are an equal number of owls on each branch, so a branch represents one
    group of owls. How many branches are there?{" "}
  </div>,
  <div>How many owls are there on each branch? </div>,
  <div>
    You can find the total number of owls by multiplying the number of branches
    by the number of owls on each branch. The product should tell you how many
    owls there are in all.
  </div>,
  <div>
    Since each branch has the same number of owls on it, you can also add the
    number of owls on each branch to find the total. Which addition sentence
    shows the total number of owls?
  </div>,
];

export const showReader = () => {
  return false;
};
