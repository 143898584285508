import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Remember . . .",
    sp: "¡Recuerda!",
  },
  1: {
    en: "The main idea of a text or paragraph is the most important idea that a writer wants to convey.",
    sp: "La idea principal de un texto o de un párrafo es la idea más importante que quiere transmitir la persona que escribe.",
  },
  2: {
    en: "Sometimes, the main idea is directly stated in a text. Other times, the main idea is implied.",
    sp: "A veces, la idea principal aparece explícitamente en un texto. Otras veces, la idea principal es implícita.",
  },
  3: {
    en: "Look for clues to the main idea by reading the title, subheadings, and the first and last sentences.",
    sp: "Lee el título, los subtítulos, la primera oración y la última oración para encontrar pistas de cuál es la idea principal.",
  },
  4: {
    en: "Supporting details are reasons, facts, statistics, examples, and sensory details that tell more about the main idea.",
    sp: "Los detalles de apoyo son razones, datos, estadísticas, ejemplos y características sensoriales que dicen más cosas sobre la idea principal.",
  },
  5: {
    en: "Supporting details should be relevant to the main idea.",
    sp: "Los detalles de apoyo deben ser relevantes para la idea principal.",
  },
  6: {
    en: "Use a graphic organizer or outline to keep track of the main ideas and supporting details.",
    sp: "Usa un organizador gráfico o un esquema para ir anotando las ideas principales y los detalles de apoyo.",
  },
  7: {
    en: "Think about the value of identifying main ideas and supporting details.",
    sp: "Piensa en la utilidad de identificar las ideas principales y los detalles de apoyo.",
  },
  8: {
    en: "Click or tap the correct answer from the drop-down menu.",
    sp: "Pulsa o haz clic en la respuesta correcta del menú desplegable.",
  },
  9: {
    en: "When you identify the main ideas and supporting details, you",
    sp: "Cuando identificas las ideas principales y los detalles de apoyo,",
  },
  10: {
    en: "Choose",
    sp: "Elige",
  },
  11: {
    en: "bulk up",
    sp: "amplías",
  },
  12: {
    en: "narrow down",
    sp: "simplificas",
  },
  13: {
    en: "replace",
    sp: "reemplazas",
  },
  14: {
    en: "and organize a passage’s ideas and information in",
    sp: "y organizas las ideas y la información de un fragmento en",
  },
  15: {
    en: "your mind and see what’s most important at a glance.",
    sp: "tu mente y ves qué es lo más importante de un vistazo.",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>{languageLookup(1)}</li>
          <li>{languageLookup(2)}</li>
          <li>{languageLookup(3)}</li>
          <li>{languageLookup(4)}</li>
          <li>{languageLookup(5)}</li>
          <li>{languageLookup(6)}</li>
        </BulletPoints>
        <div>{languageLookup(7)}</div>
        <Instructions>{languageLookup(8)}</Instructions>
        <div>{languageLookup(9)}</div>
        <div>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(10)}</Italic>
                </div>
              }
              data={[
                { text: `${languageLookup(11)}` },
                { text: `${languageLookup(12)}` },
                { text: `${languageLookup(13)}` },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(14)}</span>
        </div>
        <div>{languageLookup(15)}</div>
      </div>
    </div>
  );
};

export default Contents;
