import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};

export const correctAnswers = [2, 1, 3];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    <Italic>Jog</Italic>, <Italic>sprint</Italic>, and <Italic>gallop</Italic>{" "}
    all mean “to run.” However, each word has a different shade of meaning.
  </div>,
  <div>
    When you hear that someone “jogged,” what image does it bring to mind? What
    about if they “sprinted,” or “galloped”?
  </div>,
  <div>
    <Italic>Shades of meaning</Italic> are subtle differences in meaning.
  </div>,
];

export const showReader = () => {
  return false;
};
