import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import videoEN from "../../assets/math_1_en.mp4";
import videoES from "../../assets/math_1_es.mp4";
import numberline from "../../assets/art/954678722.png";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { useLanguage } from "./useLanguage";


export const Screen4 = ({ }) => {
  const history = useHistory();
  const language = useLanguage();
  const video = language === 'en' ? videoEN : videoES
  const answers = {
    11: { feedback: `dd1error2_G1_010A4_math_vo_${language}` },
    12: { feedback: `dd1error1_G1_010A4_math_vo_${language}` },
    10: { correct: true },
  };


  const onNextScreen = () => history.push(`../5/${language}`);

  const {
    foxState,
    setFoxState,
    continueState,
    answerState,
    checkState,
    currentAnswer,
    onCheck,
    open,
    forceOpen,
    selectAnswer,
  } = useMCFeedback(answers, language);

  const { getHint, playVideo, closeVideo } = useHints(
    [
      `hint1_G1_010A4_math_vo_${language}`,
      `hint2_G1_010A4_math_vo_${language}`],
    setFoxState,
    language
  );

  const playInstruction = useCallback(
    () => playVoiceover(`instruction_G1_010A4_math_vo_${language}`, setFoxState),
    [language, setFoxState]
  );

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.instructionContent}></div>

      <div className={css.prompt}>
        <span>11 - 1 = </span>
        <AnswerBox
          state={answerState}
          onClick={forceOpen}
          answer={currentAnswer}
        />
      </div>

      <div className={css.prompt}>
        {open && (
          <PopupPromptBox onChange={selectAnswer} options={[12, 11, 10]} />
        )}
      </div>

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
