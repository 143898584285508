import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import sh from "../../assets/art/5d7bf52809e143e31d38e4ef5cd70841.png";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";

import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox, ANSWER_STATES } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { MultiPromptBox } from "../MultiPromptBox";
import { VOPrompt } from "../VOPrompt";
import { PromptBox } from "../PromptBox";

import teeth from "../../assets/art/teeth.png";
import moose from "../../assets/art/moose.png";
import shampoo from "../../assets/art/shampoo.png";
import chip from "../../assets/art/chip.png";
import shirt from "../../assets/art/shirt.png";

import { useMultiMCFeedback } from "../useMultiMCFeedback";

const options = [
  {
    text: "teeth",
    vo: "s9_opt1_GK_078D3_ela_vo_en",
    correct: false,
    img: teeth,
  },
  {
    text: "moose",
    vo: "s9_opt2_GK_078D3_ela_vo_en",
    correct: false,
    img: moose,
  },
  {
    text: "shampoo",
    vo: "s9_opt3_GK_078D3_ela_vo_en",
    correct: false,
    img: shampoo,
  },
  {
    text: "chip",
    vo: "s9_opt4_GK_078D3_ela_vo_en",
    correct: false,
    img: chip,
  },
  {
    text: "shirt",
    vo: "s9_opt5_GK_078D3_ela_vo_en",
    correct: false,
    img: shirt,
  },
];

export const Screen10 = (props) => {
  const history = useHistory();
  const onNextScreen = () => history.push("11");
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    feedbackState,
    selectedOptions,
    onCheck,
    chooseAnswer,
    continueState,
    checkState,
  } = useMultiMCFeedback(options, setFoxState, {});

  const playInstruction = useCallback(
    () => playVoiceover("s9_instruction_GK_078D3_ela_vo_en", setFoxState),
    [setFoxState]
  );

  const playTargetSound = useCallback(() => {
    // playSoundEffect("sh");
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <MultiPromptBox
        state={feedbackState}
        selectedOptions={selectedOptions}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.hidden}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onNextScreen}
        onInstruction={playInstruction}
      />
    </React.Fragment>
  );
};
