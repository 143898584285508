import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Divide a Decimal by a Decimal</span>
      </div>
      <div className={css.contents}>
        <div>Complete the steps to find the quotient of 11.52 ÷ 0.72.</div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/263d9a177a72a0827b819890b1daa612.svg"
            width="30%"
          />
        </Center>
        <Instructions>
          Click or tap the correct number from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <div className={css.tall}>
            <Bold>STEP 1:</Bold> The divisor, 0.72 , has 2 decimal places. So,
            to change it to a whole
          </div>
          <span className={css.tall}>number, multiply by</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "10" }, { text: "100" }, { text: "1,000" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span className={css.tall}>.</span>
          <div className={css.tall}>
            Now multiply the dividend by the same power of 10.
          </div>
          <span className={css.tall}>11.52 × 100 =</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "11.52" }, { text: "115.2" }, { text: "1,152" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/967f2c9737c8652cc5d41a5b99b7c238.svg"
            width="30%"
          />
        </Center>
        <div className={css.row}>
          <span className={css.tall}>Each decimal point moved</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "1" }, { text: "2" }, { text: "3" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span className={css.tall}>places to the right.</span>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Bold>STEP 2:</Bold> Divide the whole numbers.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/d7eeb359613f37db8851d837f82b818b.svg"
            width="30%"
          />
        </Center>
        <div className={css.row}>
          <span className={css.tall}>Since 1,152 ÷ 72 =</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[3] - 1}
              selectIndex={(index) => selectIndexHandler(3, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "1.6" }, { text: "16" }, { text: "160" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span className={css.tall}>, then 11.52 ÷ 0.72 =</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[4] - 1}
              selectIndex={(index) => selectIndexHandler(4, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "1.6" }, { text: "16" }, { text: "160" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
        </div>
      </div>
      <div style={{ height: "150px" }} />
    </div>
  );
};

export default Contents;
