export default (data, setData) => {};

export const correctAnswers = [5, 4, 8];

export const getDefaultState = () => {
  return {
    items: [0, 0, 0],
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.items.filter((x) => x > 0).length == 0) return "empty";
  else if (
    data.items[0] == correctAnswers[0] &&
    data.items[1] == correctAnswers[1] &&
    data.items[2] == correctAnswers[2]
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.icons.filter((x) => x > -1).length > 0;
};

export const getTitle = (data, setData) => {
  if (data.items.filter((x) => x > 0).length == 0) return "";

  if (
    data.items[0] == correctAnswers[0] &&
    data.items[1] == correctAnswers[1] &&
    data.items[2] == correctAnswers[2]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "How many hundreds are in 548?",
  "How many tens are in 548?",
  "How many ones are in 548?",
];

export const showReader = () => {
  return false;
};
