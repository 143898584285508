import { tutorialTips } from "@indieasy.software/indieasy-engine";

const data = `*OOKA								
*							note: developer use only	
Type	ID	Headline	Body Copy	Back ID	Next ID	Button Name	Element Selector	JM Notes
popup	explore	Welcome to Ms. Kassam's Class Reports.	Here you’ll experience Scholastic F.I.R.S.T. reports as a Grade 2 teacher with a group of students who range from striving through above grade level. Let’s dig into the data!		ex_data1	Next		I moved "Welcome to Ms. Kassam’s class reports." from body copy to header. 
tip	ex_data1	Overview Report 	The overview report gives Ms. Kassam a snapshot of her whole class at any given moment. She can view overall averages for program usage as well as scores for phonological and comprehension skills.	explore	ex_data2	Next		Edited headline, changed this to a tip, added Next button.
tip	ex_data2	Leaderboard	On the leaderboard on the left, Ms. Kassam can view her class averages in the blue bar. She can also sort her students by overall average score or minutes spent on the program. Ms. Kassam would like to sort her students by average score. 	ex_data1		Close		Edited body copy, added Next button.  Leaderboard data should be sorted alphabetically by name
tip	minutes	Time Spent Report	The time spent report helps Ms. Kassam understand her students usage in Scholastic F.I.R.S.T.. She likes knowing that they are using the program a good amount of time per week and how their usage breaks down across home and school. 		min_data1	Next		Edited headline and body copy, changed this to a tip, added Next button.
tip	min_data1	In and Out of School 	In Scholastic F.I.R.S.T., we identify "out of school" as the hours before or after 8:30 a.m. to 3:30 p.m. and weekends. Ms. Kassam’s students are regularly using the program in and out of school.	minutes	min_data2	Next		Edited headline and added Next button. 
tip	min_data2	Leaderboard	Ms. Kassam uses the leaderboard to sort students by total time spent on the program. She takes note of which students could use some extra time in school. 	min_data1		Close		
tip	overall	Proficiency Report	Using the proficiency report, Ms. Kassam gathers valuable information about her students and class as a whole. She’s able to see the breakdown of scores by phonological and comprehension skills.		ov_data1	Next		Edited headline and body copy, changed this to a tip
tip	ov_data1	Inform Classroom Instruction	With the ability to sort by skill, Ms. Kassam can differentiate her classroom instruction and provide intervention or enrichment to specific students or groups of students.	overall	ov_data2	Next		Edited headline and body copy
tip	ov_data2	Group Students by Specific Skills	Ms. Kassam decides to sort her students by blending and can now see her classroom organized by the highest to lowest scores. She uses this information to create flexible small groups targeting specific skills.	ov_data1		Close		Edited headline and body copy  Data should be sorted by highest to lowest score in the Blending column with Chloe L at the top, added a Next button. 
tip	phonoavg	Phonological Skills Report	This report gives Ms. Kassam the ability to see the distribution of her students' scores in each phonological skill. She uses this information to target the needs of specific students.		phonoavg_1	Next		Edited headline & body copy, changed this to a tip
tip	phonoavg_1	Distribution of Students  by Phonological Score	Scroll down to the second graph and notice how Ms. Kassam can hover over the different colors for more information. She uses this information to determine how many students are in the learning, practicing, or proficient phase for each skill.	phonoavg	phonoavg_2	Next		
tip	phonoavg_2	Phonological Progress Over Time	In the bottom chart, Ms. Kassam can see that after 20 levels in the program there has been an upward trend in her students' phonological scores.	phonoavg_1		Close		
tip	compavg	Overall Comprehension Skills Report	This report gives Ms. Kassam the ability to see the distribution of her students' scores in each comprehension skill. She uses this information to target the needs of specific students.		compavg_1	Next		Edited headline & body copy, changed this to a tip
tip	compavg_1	Distribution of Students by Comprehension Score	Scroll down to the second graph and notice how Ms. Kassam can hover over the different colors for more information. She uses this information to determine how many students are in the learning, practicing, or proficient phase for each skill.	compavg	compavg_2	Next		
tip	compavg_2	Comprehension Progress Over Time	In the bottom chart, Ms. Kassam can see overall trends in her students’ comprehension scores over the course of the 85 books in the program.	compavg_1		Close		
*								
tip	jactab_ov	How is Jacob S. doing?	Jacob’s overview report allows Ms.  Kassam to get a quick measure of his  program usage as well as his overall  phonological and comprehension  skills progression.		jactab_ov2	Next		Edited headline. Treat this as a tip, not a popup. And that when you click Next you are moved down the report into the Phonological Progress section. 
tip	jactab_ov2	Overall Phonological Progress	Scroll down to the phonological progress chart and notice that while most of Jacob’s skills are above the proficiency level of 70%, he has been striving to make progress with blending. 	jactab_ov	jactab_ov_bp	Next		Meant to be on the overview avg page for jacob
tip	jactab_ov_bp	Accuracy Analysis Overview	Hover over Level 15 for Blending and click to see Jacob’s responses.	jactab_ov2	jactab_ov3			
		Take a Closer Look	Hover over level 15 for Blending and click to see Jacob's responses. 					
tip	jactab_ov3	Overall Comprehension Progress	In the bottom chart, Ms. Kassam can see trends in Jacob’s comprehension skills. Jacob is striving to make progress with sequencing skills and may need extra practice.	jactab_ov_bp		Close		Meant to be on the overview avg page for jacob
tip	jactab_min	Jacob's Time Spent Report	Using the time spent report, Ms. Kassam can see that Jacob regularly uses Scholastic F.I.R.S.T. both in and out of school.			Close		
tip	jactab_phono	Jacob's Phonological Progress Over Time	Ms. Kassam can hover over any point on the phonological progress graph to view the specific phonemes and/or skills Jacob worked on in that level.		jactab_phono_bp			Edited headline & body copy. 
tip	jactab_phono_bp	Accuracy Analysis Overview	Hover over Level 15 for Blending and click to see Jacob’s responses.	jactab_phono		Close		
tip	jactab_comp	Jacob's Comprehension Progress Over Time	Ms. Kassam can hover over any point on the comprehension progress graph to view the scores of specific skills Jacob worked on in that level.			Close		Edited headline and body copy.  
tip	tools	Tools & Resources	Explore the additional resources  available for educators in Scholastic F.I.R.S.T.					
tip	not_avail	Please Note	This feature is not available in the demo.			Close		On the overview tab, under Total Time Spent, nothing changes when you select School Year to Date        When "School Year to Date" is selected, have a tip appear that says: "This feature is not available in the demo." Minutes Spent Total Tab
 When "School Year to Date" is selected, have a tip appear that says: "This feature is not available in the demo." 
tip	select_jacob	Please Note	Select Jacob S for the purpose of this demo.			Close		Overview Tab For each student, except Jacob, have a diff tip appear that says: "Select Jacob S for the purpose of this demo." Phonological Avg tab (class view)  For each student, except Jacob, have a diff tip appear that says: "Select Jacob S for the purpose of this demo." Comprehension Avg tab (class view) 
 For each student, except Jacob, have a diff tip appear that says: "Select Jacob S for the purpose of this demo."
tip	resources	Resources	F.I.R.S.T. includes additional resources for educators. Resources are not available within the demo.			close		
tip	overview_blending	Accuracy Analysis Overview	Hover over Level 15 for Blending and click to see Jacob’s responses					
tip	phono_blending	Accuracy Analysis Overview	Hover over Level 15 for Blending and click to see Jacob’s responses					`;

const lines = data.split("\n");

for (var i = 3; i < lines.length; i++) {
  const line = lines[i];
  const tokens = line.split("\t");

  if (!tokens[1]) continue;

  //console.log(tokens[1]);
  // console.log(`id: ${tokens[1]}, prev: ${tokens[4]}, next: ${tokens[5]}`);

  tutorialTips.manager.addTip(
    new tutorialTips.TutorialTip(tokens[1], tokens[2], tokens[3], {
      modal: tokens[0].toUpperCase() === "POPUP",
      type: tokens[0],
      prev: tokens[4] ? tokens[4] : null,
      next: tokens[5] ? tokens[5] : null,
      hasCloseButton: true,
    })
  );
}
