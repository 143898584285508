import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Whole Number by a Unit Fraction
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Divide 2 ÷ <Fraction numerator="1" denominator="3" />.
        </div>
        <div>
          You can use fraction strips to help you divide a whole number by a
          fraction.
        </div>
        <Instructions>Click or tap each step.</Instructions>
        <FoldBox width="80%">
          <div>
            <Bold>Step 1:</Bold> Place two one-whole strips side by side to show
            2 wholes.
          </div>
          <img
            width="100%"
            src="https://d16qzots3do3wk.cloudfront.net/images/c48a660f2c4bdf45ded7a55fcb8344b6.svg"
          />
        </FoldBox>
        <FoldBox width="80%">
          <div>
            <Bold>Step 2:</Bold> Place enough{" "}
            <Fraction numerator="1" denominator="3" /> strips to fit exactly
            under the two holes.
          </div>
          <div style={{ fontSize: "14px", lineHeight: "30px" }}>
            <img
              width="100%"
              src="https://d16qzots3do3wk.cloudfront.net/images/a838581e1e25a6080088a3a6a7c87024.svg"
            />
            <div>There are 3 thirds in 1 whole.</div>
            <div>There are 2 × 3, or 6, thirds in 2 wholes.</div>
            <Center>
              2 ÷
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              = 6
            </Center>
          </div>
        </FoldBox>
        <FoldBox width="80%">
          <div>
            <Bold>Step 3:</Bold> Use multiplication to check your answer.
          </div>
          <div style={{ fontSize: "14px", lineHeight: "30px" }}>
            <img
              width="100%"
              src="https://d16qzots3do3wk.cloudfront.net/images/a838581e1e25a6080088a3a6a7c87024.svg"
            />
            <div>
              Six
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              strips are equal to the 2 one-whole strips.
            </div>
            <div>
              2 ÷
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              = 6 because 6 x
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              =
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="6"
                denominator="3"
              />{" "}
              = 2
            </div>
          </div>
        </FoldBox>
        <div style={{ height: "200px" }} />
      </div>
    </div>
  );
};

export default Contents;
