import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";

import ImageSP from "../Assets/math_3_4.svg";

const lines = {
  title: {
    en: "Use Repeated Addition to Find the Area of a Rectangle",
    sp: "Utilizar la suma repetida para hallar el área de un rectángulo",
  },
  0: {
    en: "Anita has a rug that is shaped like the rectangle shown.",
    sp: "Anita tiene un tapete con la forma del rectángulo que se muestra a continuación.",
  },
  1: {
    en: "Each unit square represents 1 square foot.",
    sp: "Cada cuadrado de una unidad representa 1 pie cuadrado.",
  },
  2: {
    en: "What is the area of Anita’s rug?",
    sp: "Cada cuadrado de una unidad representa 1 pie cuadrado.",
  },
  3: {
    en: "You can use repeated addition to find the area of the rectangle.",
    sp: "Puedes utilizar la suma repetida para hallar el área del rectángulo.",
  },
  4: {
    en: "Click or tap the boxes.",
    sp: "Pulsa o haz clic en los recuadros.",
  },
  5: {
    en: "Step 1:",
    sp: "PASO 1:",
  },
  6: {
    en: "Count the number of rows. Then, count the unit squares in each row.",
    sp: "Cuenta el número de filas. Luego, cuenta los cuadrados de una unidad que hay en cada fila.",
  },
  7: {
    en: "Step 2:",
    sp: "PASO 2:",
  },
  8: {
    en: "Write an additional sentence.",
    sp: "Escribe un enunciado de suma.",
  },
  9: {
    en: "6+6+6+6+6=30",
    sp: "6+6+6+6+6=30",
  },
  10: {
    en: "So, the area is 30 square feet.",
    sp: "Entonces, el área mide 30 pies cuadrados.",
  },
  11: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/8d3113f085258798dfa9b38bd278b7cb.svg",
    sp: ImageSP,
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>{languageLookup(0)}</div>
          <div>{languageLookup(1)}</div>
          <div>{languageLookup(2)}</div>
        </div>
        <div>{languageLookup(3)}</div>
        <img
          width="286px"
          src="https://d16qzots3do3wk.cloudfront.net/images/7b4082d1dc1d4c84c7661daef88c825e.svg"
        />
        <Instructions>{languageLookup(4)}</Instructions>
        <div className={css.row}>
          <FoldBox
            fontSize="16px"
            width="48%"
            height={props.language == "en" ? "35px" : "55px"}
          >
            <div>
              <Bold>{languageLookup(5)}</Bold> {languageLookup(6)}
            </div>
            <img width="100%" src={languageLookup(11)} />
          </FoldBox>
          <FoldBox fontSize="16px" width="48%" height="35px">
            <div>
              <Bold>{languageLookup(7)}</Bold> {languageLookup(8)}
            </div>
            <div>
              <div style={{ textAlign: "center" }}>{languageLookup(9)}</div>
              <div>{languageLookup(10)}</div>
            </div>
          </FoldBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
