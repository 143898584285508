import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

import Arrow from "../Assets/arrow.png";

const Done = (props) => {
  let contents;

  const arrows = [
    <img
      src={Arrow}
      style={{
        visibility: "hidden",
        position: "absolute",
        left: "120px",
        top: "-350px",
        width: "75px",
        height: "75px",
      }}
    />,
    <img
      src={Arrow}
      style={{
        visibility: "hidden",
        position: "absolute",
        left: "325px",
        top: "-350px",
        width: "75px",
        height: "75px",
      }}
    />,
    <img
      src={Arrow}
      style={{
        visibility: "hidden",
        position: "absolute",
        left: "120px",
        top: "-190px",
        width: "75px",
        height: "75px",
      }}
    />,
    <img
      src={Arrow}
      style={{
        visibility: "hidden",
        position: "absolute",
        left: "325px",
        top: "-190px",
        width: "75px",
        height: "75px",
      }}
    />,
  ];

  // correct
  if (
    props.data.left[0] == correctAnswers[0] &&
    props.data.left[1] == correctAnswers[1] &&
    props.data.left[2] == correctAnswers[2] &&
    props.data.left[3] == correctAnswers[3]
  ) {
    contents = (
      <div className={css.text}>
        <div>The yellow triangle has 3 straight sides.</div>
        <div>The red square has 4 straight sides.</div>
        <div>The green pentagon has 5 straight sides.</div>
        <div>The blue hexagon has 6 straight sides.</div>
      </div>
    );
  }
  // empty
  else if (props.data.left.filter((x) => x > -1).length == 0) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (
    props.data.left[0] > -1 &&
    props.data.left[0] != correctAnswers[0]
  ) {
    switch (props.data.left[0]) {
      case 0:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 5 straight sides.</div>*/}
            <div>Which shape has 3 straight sides and 3 angles?</div>
            {arrows[0]}
          </div>
        );
        break;
      case 1:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 3 straight sides.</div>*/}
            <div>Which shape has 3 straight sides and 3 angles?</div>
            {arrows[0]}
          </div>
        );
        break;
      case 2:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 6 straight sides.</div>*/}
            <div>Which shape has 3 straight sides and 3 angles?</div>
            {arrows[0]}
          </div>
        );
        break;
      case 3:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 4 straight sides.</div>*/}
            <div>Which shape has 3 straight sides and 3 angles?</div>
            {arrows[0]}
          </div>
        );
        break;
    }
  } else if (
    props.data.left[1] > -1 &&
    props.data.left[1] != correctAnswers[1]
  ) {
    switch (props.data.left[1]) {
      case 0:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 5 straight sides.</div>*/}
            <div>Which shape has 4 straight sides and 4 angles?</div>
            {arrows[1]}
          </div>
        );
        break;
      case 1:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 3 straight sides.</div>*/}
            <div>Which shape has 4 straight sides and 4 angles?</div>
            {arrows[1]}
          </div>
        );
        break;
      case 2:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 6 straight sides.</div>*/}
            <div>Which shape has 4 straight sides and 4 angles?</div>
            {arrows[1]}
          </div>
        );
        break;
      case 3:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 4 straight sides.</div>*/}
            <div>Which shape has 4 straight sides and 4 angles?</div>
            {arrows[1]}
          </div>
        );
        break;
    }
  } else if (
    props.data.left[2] > -1 &&
    props.data.left[2] != correctAnswers[2]
  ) {
    switch (props.data.left[2]) {
      case 0:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 5 straight sides.</div>*/}
            <div>Which shape has 5 straight sides and 5 angles?</div>
            {arrows[2]}
          </div>
        );
        break;
      case 1:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 3 straight sides.</div>*/}
            <div>Which shape has 5 straight sides and 5 angles?</div>
            {arrows[2]}
          </div>
        );
        break;
      case 2:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 6 straight sides.</div>*/}
            <div>Which shape has 5 straight sides and 5 angles?</div>
            {arrows[2]}
          </div>
        );
        break;
      case 3:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 4 straight sides.</div>*/}
            <div>Which shape has 5 straight sides and 5 angles?</div>
            {arrows[2]}
          </div>
        );
        break;
    }
  } else if (
    props.data.left[3] > -1 &&
    props.data.left[3] != correctAnswers[3]
  ) {
    switch (props.data.left[3]) {
      case 0:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 5 straight sides.</div>*/}
            <div>Which shape has 6 straight sides and 6 angles?</div>
            {arrows[3]}
          </div>
        );
        break;
      case 1:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 3 straight sides.</div>*/}
            <div>Which shape has 6 straight sides and 6 angles?</div>
            {arrows[3]}
          </div>
        );
        break;
      case 2:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 6 straight sides.</div>*/}
            <div>Which shape has 6 straight sides and 6 angles?</div>
            {arrows[3]}
          </div>
        );
        break;
      case 3:
        contents = (
          <div className={css.text}>
            {/*<div>This shape has 4 straight sides.</div>*/}
            <div>Which shape has 6 straight sides and 6 angles?</div>
            {arrows[3]}
          </div>
        );
        break;
    }
  } else {
    contents = (
      <div className={css.text}>
        The yellow shape has 3 straight sides. It is a triangle. The red shape
        has 4 straight sides. It is a square. The green shape has 5 straight
        sides. It is a pentagon. The blue shape has 6 straight sides. It is a
        hexagon.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
