import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Center from "../../../../Center";

import ImageSP from "../Assets/math_3_7.svg";

const lines = {
  title: {
    en: "Multiply to Find the Area",
    sp: "Multiplicar para hallar el área",
  },
  0: {
    en: "Malik made this name tag for his desk:",
    sp: "Malik hizo esta tarjeta con su nombre para su pupitre:",
  },
  1: {
    en: "Length",
    sp: "La longitud",
  },
  2: {
    en: "is the distance from one end to the other, or how long something is.",
    sp: "es la distancia de un extremo a otro, o qué tan largo es algo.",
  },
  3: {
    en: "Width",
    sp: "El ancho",
  },
  4: {
    en: "is the distance across, or how wide something is.",
    sp: "es la distancia de un lado a otro, o cuánto mide algo de lado a lado.",
  },
  5: {
    en: "If you know a rectangle’s length and width, you can multiply to find the area:",
    sp: "Si conoces la longitud y el ancho de un rectángulo, puedes multiplicar para hallar el área:",
  },
  6: {
    en: "Length = 8 centimeters",
    sp: "Longitud = 8 centímetros",
  },
  7: {
    en: "Width = 4 centimeters",
    sp: "Ancho = 4 centímetros",
  },
  8: {
    en: "8 × 4 = 32",
    sp: "8 × 4 = 32",
  },
  9: {
    en: "So, the area of the name tag is 32 square centimeters.",
    sp: "Entonces, el área de la tarjeta con el nombre mide 32 centímetros cuadrados.",
  },
  img: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/41d25552ee8d3ec0aaf90a2c8b304296.svg",
    sp: ImageSP,
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(0)}</div>
        <Center>
          <img width="50%" src={languageLookup("img")} />
        </Center>
        <div>
          <Bold>{languageLookup(1)}</Bold> {languageLookup(2)}
        </div>
        <div>
          <Bold>{languageLookup(3)}</Bold> {languageLookup(4)}
        </div>
        <div>{languageLookup(5)}</div>
        <BulletPoints>
          <li>{languageLookup(6)}</li>
          <li>{languageLookup(7)}</li>
        </BulletPoints>
        <Center>
          <div>{languageLookup(8)}</div>
        </Center>
        <div>{languageLookup(9)}</div>
      </div>
    </div>
  );
};

export default Contents;
