import React from "react";
import css from "./ProgressBar.module.css";

const ProgressBar = (props) => {
  const style = { ...props.style };
  style.background = props.background;
  style.border = props.border;
  style.width = props.width;
  style.height = props.height;

  const contents = props.data.map((x, index) => {
    const barStyle = { ...x.style };
    barStyle.background = x.background;
    barStyle.width = `${x.value * 100}%`;
    barStyle.height = "100%";

    return <div key={index} style={barStyle} />;
  });

  return (
    <div className={css.root} style={style}>
      <div className={css.inner}>{contents}</div>
    </div>
  );
};

export default ProgressBar;
