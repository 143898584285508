import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Consider Shades of Meaning</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the sentences. Each bolded word has a meaning similar to
          thankful. Think about the connotation of each word. Then answer the
          question.
        </div>
        <BlockQuote>
          Hector was <Instructions span>grateful</Instructions> Ms. Silvio did
          not lower his grade, considering he had forgotten his homework twice
          this month already.
        </BlockQuote>
        <BlockQuote>
          I am <Instructions span>indebted</Instructions> to my friend Jarek for
          helping me study for the geography test. I already told him to see me
          any time for algebra support.
        </BlockQuote>
        <BlockQuote>
          Alana was <Instructions span>appreciative</Instructions> of the gift
          from her grandfather, and she spent the afternoon admiring her new
          violin.
        </BlockQuote>
        <BlockQuote>
          “I’m <Instructions span>glad</Instructions> you could join us!” Eva
          said with a smile when she opened the door. Franco was standing there
          wearing a party hat.
        </BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
