import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Identify Compound Sentences</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>Holly is hungry, so she is making a sandwhich.</li>
          <li>Jake loves playing chess.</li>
          <li>Anna and Alan own a dog named Ralph.</li>
          <li>Ji-won brought pencils, but Mr. Otis didn’t bring paper.</li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
