import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Way to go! The height of the bridge has a 7 in the tenths place and a 5
        in the hundredths place. The height of the truck has an 8 in the tenths
        place and a 2 in the hundredths place. Because 0.7 is less than 0.8, the
        height of the bridge is less than the height of the truck. The truck
        cannot safely pass under the bridge.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <Fragment>
        <div className={css.text}>
          {
            "Be careful. When you use < or >, make sure the smaller end of the symbol points to the lesser number."
          }
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "130px",
            top: "-90px",
            width: "125px",
            height: "50px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  } else if (props.data.indices[0] == 2 && props.data.indices[1] == 1) {
    contents = (
      <Fragment>
        <div className={css.text}>
          {
            "The height of the truck must be less than the height of the bridge for the truck to pass safely under the bridge. Does your conclusion match your comparison of the heights?"
          }
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "385px",
            top: "-80px",
            width: "125px",
            height: "50px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  } else {
    contents = (
      <div className={css.text}>
        Look at the place-value chart. The height of the bridge has a 7 in the
        tenths place and a 5 in the hundredths place. The height of the truck
        has an 8 in the tenths place and a 2 in the hundredths place. Because
        0.7 is less than 0.8, the height of the bridge is less than the height
        of the truck. The truck cannot safely pass under the bridge.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
