import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly chose whether one or both passages contain each text
        structure listed. Writers use different text structures to address
        various needs. A writer might choose to compare two or more things, to
        describe an action and the effects of that action, or to describe an
        event or a story in time order, or chronologically. A writer might
        choose other text structures as well.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (
    props.data.indices[0] == 2 ||
    props.data.indices[0] == 3 ||
    props.data.indices[0] == 4
  ) {
    contents = (
      <div className={css.text}>
        Reread both passages. Look for paragraphs that give dates in
        chronological order. Then try the first sentence again.
      </div>
    );
  } else if (
    props.data.indices[1] == 1 ||
    props.data.indices[1] == 2 ||
    props.data.indices[1] == 4
  ) {
    contents = (
      <div className={css.text}>
        Which passage tells about endangered animals? Skim the passages for the
        key word <Italic>endangered</Italic>. Then try the second sentence
        again.
      </div>
    );
  } else if (
    props.data.indices[2] == 2 ||
    props.data.indices[2] == 3 ||
    props.data.indices[2] == 4
  ) {
    contents = (
      <div className={css.text}>
        “Africa’s Unicorn” describes the mythological unicorn. “Mermaid or
        Manatee” describes the mythological mermaid. Try the third sentence
        again.
      </div>
    );
  } else if (
    props.data.indices[3] == 1 ||
    props.data.indices[3] == 3 ||
    props.data.indices[3] == 4
  ) {
    contents = (
      <div className={css.text}>
        Read the passages again. Which passage discusses an animal that can look
        like a human? Try the fourth sentence again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
