import React from "react";
import css from "./Icon.module.css";

import IconImg from "../../Assets/math_2_1_icon.png";

const Icon = (props) => {
  const contents = props.selected ? (
    <img src={IconImg} alt="icon" />
  ) : (
    <div className={css.empty} />
  );
  return (
    <div className={css.root} onMouseDown={() => props.onSelect(props.index)}>
      {contents}
    </div>
  );
};

export default Icon;
