import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Insert Zeros in the Dividend</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>What is the quotient for the division shown?</div>
          <div>
            Continue inserting zeros in the dividend. Divide until there is no
            remainder.
          </div>
        </div>
        <Instructions>
          Click or tap the correct number from the drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>The quotient of 30.4 ÷ 25 is</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "1.2" },
                { text: "1.206" },
                { text: "1.216" },
                { text: "12.16" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
