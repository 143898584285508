import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Great! The number sentences 6 + 6 + 6 = 18 and 3 × 6 = 18 both show the
        number of owls in the picture. When you want to find the total of two or
        more equal groups, you can use either addition or multiplication.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[0]) {
    contents = (
      <div className={css.text}>
        There are 3 branches with the same number of owls on each, so you know
        that one of the factors in the multiplication sentence is 3. The other
        factor should tell how many owls there are on each branch. How many owls
        are there on each branch?
      </div>
    );
  } else if (props.data.selected[1]) {
    contents = (
      <div className={css.text}>
        You correctly found one of the number sentences that shows the total
        number of owls on the branches. Which other number sentence also shows
        the total number of owls?
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        An equal number of owls are on each branch, so each branch represents
        one group of owls. There are 3 branches with 6 owls on each branch, so
        the factors in the multiplication sentence are 3 and 6. You can also add
        6 three times to find the total number of owls. The sum in the addition
        sentence 6 + 6 + 6 = 18 and the product in the multiplication sentence 3
        × 6 = 18 both represent the total number of owls in the picture.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        You correctly found one of the number sentences that shows the total
        number of owls on the branches. Which other number sentence also shows
        the total number of owls?
      </div>
    );
  } else if (props.data.selected[4]) {
    contents = (
      <div className={css.text}>
        An equal number of owls are on each branch, so each branch represents
        one group of owls. There are 3 branches with 6 owls on each branch, so
        the factors in the multiplication sentence are 3 and 6. You can also add
        6 three times to find the total number of owls. The sum in the addition
        sentence 6 + 6 + 6 = 18 and the product in the multiplication sentence 3
        × 6 = 18 both represent the total number of owls in the picture.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
