import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

import SpanishA from "../Assets/spanish_a.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Understand Proportional Relationships in Different Forms",
    sp: "Comprender las relaciones proporcionales representadas de diferentes formas",
  },
  1: {
    en: "The list shows the number of pages Mikel reads over 10 hours:",
    sp: "La lista muestra el número de páginas que Mikel lee en 10 horas:",
  },
  2: {
    en: "195 pages in 3 hours",
    sp: "195 páginas en 3 horas",
  },
  3: {
    en: "325 pages in 5 hours",
    sp: "325 páginas en 5 horas",
  },
  4: {
    en: "585 pages in 9 hours",
    sp: "585 páginas en 9 horas",
  },
  5: {
    en: "650 pages in 10 hours",
    sp: "650 páginas en 10 horas",
  },
  6: {
    en: "This proportional relationship can be represented as ordered pairs and as a graph.",
    sp: "Esta relación proporcional puede representarse como pares ordenados y como una gráfica.",
  },
  7: {
    en: "Click or tap each step to show how to graph the relationship.",
    sp: "Pulsa o haz clic en cada paso para mostrar cómo representar gráficamente la relación.",
  },
  8: {
    en: (
      <Fragment>
        <Bold>STEP 1:</Bold> Write the proportional relationship as ordered
        pairs.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>PASO 1:</Bold> Escribe la relación proporcional como pares
        ordenados.
      </Fragment>
    ),
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: (
      <Fragment>
        Use time (in hours) for the <Italic>x</Italic>-value and number of pages
        for the <Italic>y</Italic>-value.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Usa “Tiempo (en horas)” para los valores de <Italic>x</Italic> y “Número
        de páginas” para los valores de <Italic>y</Italic>.
      </Fragment>
    ),
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: (
      <Fragment>
        <Bold>STEP 2:</Bold> Graph the ordered pairs.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>PASO 2:</Bold> Representa gráficamente los pares ordenados.
      </Fragment>
    ),
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/128e0b3b2212322f5f0910ff5c246f9d.svg",
    sp: SpanishA,
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <BulletPoints>
          <li>{languageLookup(2)}</li>
          <li>{languageLookup(3)}</li>
          <li>{languageLookup(4)}</li>
          <li>{languageLookup(5)}</li>
        </BulletPoints>
        <div>{languageLookup(6)}</div>
        <Instructions>{languageLookup(7)}</Instructions>

        <div className={css.entries}>
          <FlipBox
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "normal",
              fontFamily: "Open Sans",
            }}
            padding="10px"
            width="288px"
            height="232px"
          >
            <div>{languageLookup(8)}</div>
            <div>
              <div>{languageLookup(10)}</div>
              <div>(3, 195)</div>
              <div>(5, 325)</div>
              <div>(9, 585)</div>
              <div>(10, 650)</div>
            </div>
          </FlipBox>
          <FlipBox
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "normal",
              fontFamily: "Open Sans",
            }}
            padding="10px"
            backPadding="20px"
            width="288px"
            height="232px"
          >
            <div>{languageLookup(13)}</div>
            <div>
              <img width="70%" src={`${languageLookup(15)}`} />
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
