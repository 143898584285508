import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import sh from "../../assets/art/5d7bf52809e143e31d38e4ef5cd70841.png";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";

import {
  playSoundEffect,
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox, ANSWER_STATES } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { MultiPromptBox } from "../MultiPromptBox";
import { VOPrompt } from "../VOPrompt";
import { PromptBox } from "../PromptBox";

import fork from "../../assets/art/fork.png";
import yawn from "../../assets/art/yawn.png";
import dime from "../../assets/art/dime.png";
import shelf from "../../assets/art/shelf.png";

const options = [
  {
    text: "fork",
    vo: "s7_opt1_GK_078D1_ela_vo_en",
    correct: false,
    img: fork,
  },
  {
    text: "yawn",
    vo: "s7_opt2_GK_078D1_ela_vo_en",
    correct: false,
    img: yawn,
  },
  {
    text: "dime",
    vo: "s7_opt3_GK_078D1_ela_vo_en",
    correct: false,
    img: dime,
  },
  {
    text: "shelf",
    vo: "s7_opt4_GK_078D1_ela_vo_en",
    correct: false,
    img: shelf,
  },
];

export const Screen8 = () => {
  const history = useHistory();
  const onNextScreen = () => history.push("9");

  const [foxState, setFoxState] = useState(FOX_STATE.idle);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  const chooseAnswer = (option) => {
    playVoiceover(option.vo, setFoxState);
    setCurrentAnswer(option);
  };

  const playInstruction = useCallback(
    () =>
      playVoiceovers(
        ["startApply", "s7_instruction_GK_078D1_ela_vo_en"],
        setFoxState
      ),
    [setFoxState]
  );
  const playTargetSound = useCallback(() => {
    playSoundEffect("s1_tapButton_GK_078A_ela_vo_en", setFoxState);
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.instructionContent}>
        <VOPrompt img={sh} onClick={playTargetSound} />
      </div>

      <PromptBox
        state={null}
        currentValue={currentAnswer}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.hidden}
        checkState={
          currentAnswer === null
            ? BUTTON_STATES.disabled
            : BUTTON_STATES.enabled
        }
        continueState={BUTTON_STATES.hidden}
        onContinue={onNextScreen}
        onCheck={onNextScreen}
        onInstruction={playInstruction}
      />
    </React.Fragment>
  );
};
