import React from "react";
import css from "./EditAvatar.module.css";

import Avatar from "../../../assets/avatar.png";
import Logo from "../../../components/Logo";
import Logout from "../../../components/Icons/Logout";
import Help from "../../../components/Icons/Help";
import Messages from "../../../components/Icons/Messages/Messages";

import Box from "./Box";

import A0 from "./assets/a0.png";
import A1 from "./assets/a1.png";
import A2 from "./assets/a2.png";
import A3 from "./assets/a3.png";
import A4 from "./assets/a4.png";
import B0 from "./assets/b0.png";
import B1 from "./assets/b1.png";
import B2 from "./assets/b2.png";
import B3 from "./assets/b3.png";
import B4 from "./assets/b4.png";
import H0 from "./assets/h0.png";
import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import S0 from "./assets/s0.png";
import S1 from "./assets/s1.png";
import S2 from "./assets/s2.png";
import ArrowLeft from "./assets/arrow_left.png";
import ArrowRight from "./assets/arrow_right.png";
import Timer from "./assets/timer.png";
import OverlayLeft from "./assets/overlay_left.png";
import OverlayRight from "./assets/overlay_right.png";

const EditAvatar = (props) => {
  const saveHandler = () => {
    props.onClose();
  };

  return (
    <div className={css.root}>
      <div className={css.background} />
      <div className={css.overlays}>
        <div className={css.overlayleft}>
          <img src={OverlayLeft} />
          <div className={css.avatar}>
            <img src={Avatar} alt="avatar" />
          </div>
        </div>
        <div className={css.overlayright}>
          <img src={OverlayRight} />
          <div className={css.header}>
            {/*<img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIGZpbGw9IiNFREVERUQiLz4KPHJlY3QgeD0iNTc2IiB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjRURFREVEIi8+CjxyZWN0IHg9IjQwIiB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAxMDBMNDAgODBINTc2TDYxNiAxMDBMNTc2IDEyMEg0MEwwIDEwMFoiIGZpbGw9IiNEREREREQiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OEg1NzZMNjAwIDEwMEw1NzYgMTEySDQwTDE2IDEwMFoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OFYxMTJMMTYgMTAwWiIgZmlsbD0iI0JBQkFCQSIvPgo8cGF0aCBkPSJNNjAwIDEwMEw1NzYgODhWMTEyTDYwMCAxMDBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo=" alt="header"/>*/}
            <div className={css.title}>Build Your Avatar!</div>
            <div className={css.content}>
              <div className={css.inner}>
                <Box title="Body">
                  <div className={css.row}>
                    <img className={css.item} src={A0} />
                    <img className={css.item} src={A1} />
                    <img className={css.item} src={A2} />
                    <img className={css.item} src={A3} />
                    <img className={css.item} src={A4} />
                  </div>
                  {/*<img className={css.al} src={ArrowLeft}/>
                        <img className={css.ar} src={ArrowRight}/>*/}
                </Box>
                <Box title="Hair">
                  <div className={css.row}>
                    <img className={css.item} src={H0} />
                    <img className={css.item} src={H1} />
                    <img className={css.item} src={H2} />
                    <img className={css.item} src={H3} />
                    <img className={css.item} src={H4} />
                  </div>
                  <div className={css.row}>
                    <div
                      style={{ background: "#9966FF" }}
                      className={css.dot}
                    />
                    <div
                      style={{ background: "#663300" }}
                      className={css.dot}
                    />
                    <div
                      style={{ background: "#0099FF" }}
                      className={css.dot}
                    />
                    <div
                      style={{ background: "#FF6633" }}
                      className={css.dot}
                    />
                    <div
                      style={{ background: "#00BB00" }}
                      className={css.dot}
                    />
                    <div
                      style={{ background: "#FFCC33" }}
                      className={css.dot}
                    />
                  </div>
                  {/*<img className={css.al} src={ArrowLeft}/>
                        <img className={css.ar} src={ArrowRight}/>*/}
                </Box>
                <Box title="Uniform">
                  <div className={css.row}>
                    <img className={css.item} src={B0} />
                    <img className={css.item} src={B1} />
                    <img className={css.item} src={B2} />
                    <img className={css.item} src={B3} />
                    <img className={css.item} src={B4} />
                  </div>
                  {/*<img className={css.al} src={ArrowLeft}/>
                        <img className={css.ar} src={ArrowRight}/>*/}
                </Box>
              </div>
            </div>
          </div>
          <div className={css.button} onMouseDown={saveHandler}>
            Save
          </div>
        </div>
      </div>
      <div className={css.top}>
        <div className={css.options}>
          <div>
            <Logout />
          </div>
          <div>
            <Help />
          </div>
          <div>
            <Messages />
          </div>
        </div>
      </div>

      <div className={css.sidebar}>
        <img className={css.item} src={S0} />
        <div className={css.text}>Clear</div>
        <img className={css.item} src={S1} />
        <div className={css.text}>Undo</div>
        <img className={css.item} src={S2} />
        <div className={css.text}>Random</div>
      </div>

      <div className={css.timer}>
        <div className={css.text}>00:25</div>
        <img src={Timer} width="26px" />
      </div>
    </div>
  );
};

export default EditAvatar;
