import React, { Fragment } from "react";
import css from "./MissionBox.module.css";

import Entry from "./Entry";
import { string } from "prop-types";

const MissionBox = (props) => {
  var entries = props.data
    .filter((x) => x.subtitle[props.grade - 1])
    .filter((x) => x)
    .map((x, index) => {
      var nameOfEntry = x.subtitle[props.grade - 1];
      if (typeof nameOfEntry === "string") {
        return <Entry {...props} key={index} data={x} />;
      } else {
        let subentries = nameOfEntry.map((y, index2) => (
          <Entry
            {...props}
            key={index + index2 * 100}
            data={x}
            subEntries={true}
            index={index2}
          />
        ));
        return subentries;
      }
    });

  return (
    <div className={css.root}>
      <div className={css.title}>{props.title}</div>
      {entries}
    </div>
  );
};

export default MissionBox;
