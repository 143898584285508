import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Apply",
    sp: "¡Aplícalo!",
  },
  1: {
    en: "Read the passage. Then answer the question.",
    sp: "Lee el fragmento. Luego, contesta la pregunta.",
  },
  2: {
    en: "1800-1850: A Time for New Instruments",
    sp: "1800–1850: Una época de nuevos instrumentos",
  },
  3: {
    en: "The first fifty years of the 1800s were an inventive period, especially for musical instruments. In 1805, for example, one of Beethoven’s friends designed a massive machine called the Panharmonicon. This organ could sound like all the instruments of an orchestra—and even create special effects, like canons firing. By the late 1820s, two similar instruments called the concertina and the accordion <Italic>squeezed</Italic> their way into history. To play these box instruments, a person uses both hands to press buttons on the ends. The buttons change the pitch of air pushing out from the leather bellows. The bellows expand and contract like lungs, and are stretched open and pushed closed by the player’s hands. These instruments have since been nicknamed “squeezeboxes.” Later in this era, a pair of Prussian inventors designed the first tuba. And those are only four examples from this chapter of musical instrument history.",
    sp: "Los primeros cincuenta años del siglo XIX fueron un período de mucha invención, especialmente en el campo de los instrumentos musicales. En 1805, por ejemplo, un amigo de Beethoven diseñó una máquina enorme llamada panarmónico. Este órgano podía sonar como cualquiera de los instrumentos de una orquesta, e incluso crear efectos especiales, como el disparo de cañones. Para fines de la década 1820, dos instrumentos similares, la concertina y el acordeón, aparecieron en la historia. Para tocar estos instrumentos de caja, se usan las dos manos para pulsar los botones que hay en los extremos. Los botones cambian el tono del aire que sale de los fuelles de cuero. Los fuelles se expanden y contraen como pulmones mientras las manos del músico los estiran para que se abran y los empujan para que se cierren. Más adelante, aún en esta era, dos inventores prusianos diseñaron la primera tuba. Y estos son apenas cuatro ejemplos de este capítulo de la historia de los instrumentos musicales.",
  },
  4: {
    en: "The Era of the Valve",
    sp: "La era de la válvula",
  },
  5: {
    en: "The invention of the valve made instruments like the tuba possible. 1800 to 1850 was an era when new valves changed how people made music. Simply put, valves control how long or short the tube of vibrating air can be when an instrument is being played.  Pieces of a valve mechanism block or open the main column of air. This action, often in combination with other valves, creates the scale of music notes. Heinrich Stölzel invented the earliest version called a piston valve. He first applied these valves to a French horn in 1814. This groundbreaking device now bears his name: the Stölzel valve. Next, the earliest trumpet with valves was played in 1821. It had double-piston valves, which bent air through the two ninety-degree angles of a valve loop. Such loops are now regular features of trumpet design. Lastly, valves were a key ingredient for a Belgian musician, with the last name of Sax, who developed the saxophone and its many variations. The saxophone would go on to become a respected instrument in the hands of legendary jazz musicians. After this fifty-year span, the brass instrument section of bands and orchestras around the world would never sound the same.",
    sp: "La invención de la válvula hizo que fueran posibles instrumentos como la tuba. El período que va desde 1800 hasta 1850 fue una era en la que las nuevas válvulas cambiaron el modo de hacer música. Dicho de manera sencilla, las válvulas controlan qué tan largo o corto es el tubo de aire que vibra cuando se toca un instrumento. Las piezas del mecanismo de la válvula bloquean o abren la columna principal de aire. Este efecto, que a menudo se combina con el de otras válvulas, crea la escala de notas musicales. Heinrich Stölzel inventó la primera versión, llamada válvula de pistón. Las aplicó por primera vez a un corno francés en 1814. Este dispositivo revolucionario hoy lleva su nombre: válvula Stölzel. Luego, en 1821, se tocó la primera trompeta con válvulas. Tenía válvulas de doble pistón que hacían pasar el aire a través de dos ángulos de noventa grados de un bucle de válvulas. Tales bucles hoy son comunes en el diseño de trompetas. Por último, las válvulas fueron un ingrediente clave para un músico belga cuyo apellido era Sax, quien desarrolló el saxofón y sus muchas variantes. El saxofón se convertiría en un instrumento muy respetado en las manos de legendarios músicos de jazz. Al cabo de este período de cincuenta años, la sección de bronces de las bandas y orquestas de todo el mundo nunca sonaría igual.",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>{languageLookup(1)}</div>
        <div className={css.title}>{languageLookup(2)}</div>
        <div className={css.text}>{languageLookup(3)}</div>
        <div className={css.title}>{languageLookup(4)}</div>
        <div className={css.text}>{languageLookup(5)}</div>
      </div>
    </div>
  );
};

export default Contents;
