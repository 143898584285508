import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "Multiply the length and width of a rectangle to find its area.",
    sp: "Multiplica la longitud por el ancho de un rectángulo para hallar su área.",
  },
  1: {
    en: "The length of the paper is 9 inches. The width of the paper is 3 inches.",
    sp: "La longitud del trozo de cartulina es de 9 pulgadas. El ancho del trozo de cartulina es de 3 pulgadas.",
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};

export const correctAnswers = [3, 3];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return languageLookup("correct");
  else return languageLookup("incorrect");
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
