import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Paragraph from "../../../../Reader/Paragraph";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.title}>Robots at School!</div>
        <Paragraph index="1">
          Picture yourself as a teacher in a fifth-grade classroom. How do you
          get your students interested in STEM? STEM stands for “science,
          technology, engineering, and math.” One way to interest people in a
          field of study is to have them participate in it. What activities
          could students complete that combine science, technology, engineering,
          and math? For many teachers nowadays, the answer involves robots.
        </Paragraph>
        <div className={css.title}>How Do You Build a Robot?</div>
        <Paragraph index="2">
          A robot is a device that automatically performs complicated, often
          repetitive tasks. (When you program a robot, you give it a set of
          instructions to follow.) Each robot in the photograph has been
          programmed to add a certain part to the car as it moves down the
          assembly line. Suppose you wanted to build a robot car. How would you
          begin? First, you would need to decide exactly what you want the car
          to do. The task you choose for your robot determines how you will
          build it. Suppose you want your car to navigate, or move through, an
          obstacle course, pick up a coin, and bring it back to you. To do this,
          your car will need a source of power and parts for moving (for
          example, wheels). It will also need parts for picking up and carrying
          a coin. Finally, it will need a computer that you can program with the
          necessary instructions.
        </Paragraph>
        <div className={css.title}>How Do You Program a Robot?</div>
        <Paragraph index="3">
          If you were to make a robot in your classroom, your teacher would
          probably provide you with a kit that includes all the parts you would
          need to build it. The kit would probably include a simple computer
          that you could program with specific instructions for your robot to
          follow. You can get a sense of what programming a robot is like by
          working with another student. One student takes on the role of the
          programmer, and the other will be the “robot.” The programmer should
          decide on a task for the robot. For example, your partner might walk
          from one corner of the classroom to the other, remove a book from a
          shelf, and open the book to a certain page. The trick is that your
          partner must follow the directions literally. Imagine how specific
          your instructions will need to be. You will need to tell the “robot”
          how many steps to take and in which direction. You will also need to
          tell the robot how to move its arms and hands to pick up and open the
          book. What other instructions might you need to give?
        </Paragraph>
        <div className={css.title}>What Can You Learn from Robots?</div>
        <Paragraph index="4">
          Why are building and programming robots effective ways to study STEM
          topics? Robots are examples of technology. When you use technology to
          solve a problem, you are acting like an engineer. Programming robots
          often involves math concepts. Your robot car will need to know the
          length and width of the obstacle course and the distance of the coin
          from the starting point. Finally, you can design scientific
          experiments involving robots. You might use them to gather data. You
          could measure the speed and velocity at which the robot car best
          navigates through certain obstacles. Robots may be a long way from
          actually teaching your class. But with the right equipment, you can
          still learn a lot from them!
        </Paragraph>
        <div className={css.title}>How Do You Program a Robot?</div>
        <Paragraph index="5">
          If you were to make a robot in your classroom, your teacher would
          probably provide you with a kit that includes all the parts you would
          need to build it. The kit would probably include a simple computer
          that you could program with specific instructions for your robot to
          follow. You can get a sense of what programming a robot is like by
          working with another student. One student takes on the role of the
          programmer, and the other will be the “robot.” The programmer should
          decide on a task for the robot. For example, your partner might walk
          from one corner of the classroom to the other, remove a book from a
          shelf, and open the book to a certain page. The trick is that your
          partner must follow the directions literally. Imagine how specific
          your instructions will need to be. You will need to tell the “robot”
          how many steps to take and in which direction. You will also need to
          tell the robot how to move its arms and hands to pick up and open the
          book. What other instructions might you need to give?
        </Paragraph>
      </div>
    </div>
  );
};

export default Contents;
