import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import { components } from "@indieasy.software/indieasy-components";

import Tables from "../Assets/math_7_2_table.JPG";

const { LineGraph } = components;

const Contents = (props) => {
  const selectIconHandler = (index) => {
    const data = { icons: [...props.data.icons] };
    data.icons[index] = !data.icons[index];

    props.setData(data);
  };

  const rolloverPositions = [];

  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 17; j++) {
      rolloverPositions.push({ value: i, crossValue: j * 3 });
    }
  }

  return (
    <div className={css.root}>
      <div className={css.text}>
        Rachel is going to graph the data in each table. She wants to determine
        which table represents a proportional relationship.
      </div>
      <div className={css.contents}>
        <div className={css.image}>
          <img src={Tables} alt="tables" />
        </div>
        <div
          className={css.linegraph}
          onMouseLeave={() => {
            const data = { indices: [...props.data.indices], highlight: null };
            props.setData(data);
          }}
        >
          <LineGraph
            width="100px"
            height="300px"
            xAxis="BOTTOM"
            yAxis="LEFT"
            border="1px solid rgba(0, 0, 0, 0.2)"
            gridLine="1px solid rgba(0, 0, 0, 0.2)"
            //horizontal
            onHighlightItem={(event) => {
              const data = {
                indices: [...props.data.indices],
                highlight: event.position,
              };
              props.setData(data);
            }}
            onSelectItem={(item, position, ev) => {
              if (
                position.value == undefined ||
                position.crossValue == undefined
              )
                return;
              if (
                props.data.indices.filter(
                  (x) =>
                    x.value == position.value &&
                    x.crossValue == position.crossValue
                ).length > 0
              )
                return;
              const indices = [...props.data.indices];
              indices.push(position);

              const data = {
                indices: indices,
                highlight: { ...props.highlight },
              };
              props.setData(data);
            }}
            maxValue={5}
            values={[
              {
                name: "0",
                value: 0,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "1",
                value: 1,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "2",
                value: 2,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "3",
                value: 3,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "4",
                value: 4,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "5",
                value: 5,
                style: { fontSize: "12px", fontWeight: "700" },
              },
            ]}
            valueMargin="10px"
            valueRotate="0"
            valueTextAlign="center"
            valueLines
            //valueReverse
            maxCrossValue={48}
            crossValues={[
              {
                name: " ",
                value: 0,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "3",
                value: 3,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "6",
                value: 6,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "9",
                value: 9,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "12",
                value: 12,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "15",
                value: 15,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "18",
                value: 18,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "21",
                value: 21,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "24",
                value: 24,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "27",
                value: 27,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "30",
                value: 30,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "33",
                value: 33,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "36",
                value: 36,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "39",
                value: 39,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "42",
                value: 42,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "45",
                value: 45,
                style: { fontSize: "12px", fontWeight: "700" },
              },
              {
                name: "48",
                value: 48,
                style: { fontSize: "12px", fontWeight: "700" },
              },
            ]}
            crossValueMargin="10px"
            crossValueRotate="0"
            crossValueTextAlign="right"
            crossValueLines
            data={[
              {
                name: "default",
                color: `#368fcb`,
                innerColor: "#368fcb",
                size: 9,
                innerSize: 4.5,
                positions: props.data.indices,
              },
              {
                name: "rollovers",
                color: `transparent`,
                innerColor: "transparent",
                highlightColor: "transparent",
                innerHighlightColor: "transparent",
                positions: rolloverPositions,
                size: 24,
                innerSize: 12,
              },
              {
                name: "highlight",
                color: `#368fcb`,
                innerColor: "#368fcb",
                size: 9,
                innerSize: 4.5,
                positions: props.data.highlight ? [props.data.highlight] : [],
              },
            ]}
          />
        </div>
      </div>

      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
