import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(0)}
        Awesome work! The graph of the relationship is a straight line that
        passes through the origin, so the relationship is proportional.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        {languageLookup(2)}
        Try again! Is the graph of the relationship a straight line that passes
        through (0, 0)? If so, it is proportional.
      </div>
    );
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        {languageLookup(3)}
        Try again! Is the graph of the relationship a straight line that passes
        through (0, 0)? If so, it is proportional.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
