import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Analyze Connotations</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the passage. Notice how each bolded word has a meaning similar to
          large. Then answer the question.
        </div>
        <BlockQuote>
          Robin, Jia, and Andres came to a stop on the sidewalk and looked up at
          the <Instructions span>massive</Instructions> skyscraper.
        </BlockQuote>
        <BlockQuote>
          “Quite a <Instructions span>hulking</Instructions> and intimidating
          chunk of orange cement, if you ask me,” sighed Andres, squinting.
        </BlockQuote>
        <BlockQuote>
          Jia caught her breath and said, “So, it’s true. We’re moving into this
          new office building next week, and–wow–it’s really{" "}
          <Instructions span>big</Instructions>.”
        </BlockQuote>
        <BlockQuote>
          “Well, I took the tour yesterday, and it’s a solid, light-filled, and{" "}
          <Instructions span>spacious</Instructions> work environment!” Robin
          declared.
        </BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
