import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You identified the correct part of the diagram. The magnet moves through
        the generator to produce electricity.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.selected == 0) {
    contents = (
      <div className={css.text}>
        The dam is the wall that holds back water. It is not what the magnet
        moves through in order to produce electricity. Try again.
      </div>
    );
  } else if (props.data.selected == 2) {
    contents = (
      <div className={css.text}>
        The turbine is what spins to move the magnet, but it is not what the
        magnet moves through in order to produce electricity. Try again.
      </div>
    );
  } else if (props.data.selected == 3) {
    contents = (
      <div className={css.text}>
        The power lines are what electricity moves through to reach businesses
        and homes from the power plant. It is not what the magnet moves through
        in order to produce electricity. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
