import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/g3_11c_area.mp4";
import VideoSP from "../Assets/g3_11c_area_Spanish.mp4";

const lines = {
  title: {
    en: "Area of Rectangles",
    sp: "Área de los rectángulos",
  },
  0: {
    en: "Click or tap to watch the video.",
    sp: "Pulsa o haz clic en la pantalla para mirar el video.",
  },
  Video: {
    en: Video,
    sp: VideoSP,
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <Instructions>{languageLookup(0)}</Instructions>
        <video
          width="320"
          height="240"
          controls
          src={languageLookup("Video")}
        />
      </div>
    </div>
  );
};

export default Contents;
