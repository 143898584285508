import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Unit Fraction by a Whole Number
        </span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          The fraction model shows <Fraction numerator="1" denominator="3" /> ÷
          2.
        </div>
        <Instructions>
          Choose the correct number from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>
            <Fraction numerator="1" denominator="3" /> ÷ 2 ={" "}
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="1"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="3"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>Check your work:</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="1"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="3"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>
            {" "}
            x 2 = <Fraction numerator="2" denominator="6" /> ={" "}
            <Fraction numerator="1" denominator="3" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
