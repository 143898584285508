import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That’s correct! Sentence 2 and Sentence 4 show anger most precisely.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[0]) {
    contents = (
      <div className={css.text}>
        Try again. Choose only the sentences that show anger.
      </div>
    );
  } else if (props.data.selected[1]) {
    contents = (
      <div className={css.text}>
        You have chosen at least one correct answer. Keep trying!
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        Try again. Choose only the sentences that show anger.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        You have chosen at least one correct answer. Keep trying!
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
