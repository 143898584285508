import React, { Fragment } from "react";
import css from "./Done.module.css";

import Fraction from "../../../../Fraction";

import Arrow from "../Assets/math_4_2_arrow.JPG";

import { isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  console.log(props.data.left);

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        Good job! Angles H and I are right angles, angles G and J are acute
        angles, and angles F and K are obtuse angles. To classify an angle
        without measuring it, you can compare it to a right angle to see if its
        measure is less than, equal to, or greater than 90°.{" "}
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.left[1].find((x) => x.id == 0 || x.id == 5)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          An acute angle is smaller than a right angle because its measure is
          less than 90°. Angle F is larger than a right angle, so it cannot be
          an acute angle. Try again.
        </div>
        <div
          className={css.arrow}
          style={{ position: "absolute", top: "-380px", left: "210px" }}
        >
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[2].find((x) => x.id == 1)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          An obtuse angle is larger than a right angle because its measure is
          greater than 90°. Angle G is smaller than a right angle, so it cannot
          be an obtuse angle. Try again.
        </div>
        <div
          className={css.arrow}
          style={{ position: "absolute", top: "-380px", left: "420px" }}
        >
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[2].find((x) => x.id == 4)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          An obtuse angle is larger than a right angle because its measure is
          greater than 90°. Angle J is smaller than a right angle, so it cannot
          be an obtuse angle. Try again.
        </div>
        <div
          className={css.arrow}
          style={{ position: "absolute", top: "-380px", left: "420px" }}
        >
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else if (props.data.left[2].find((x) => x.id == 2 || x.id == 3)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Remember that a right angle has a measure of exactly 90° and appears
          to form a square corner. Does angle I appear to form a square corner?
          An obtuse angle is larger than a right angle because its measure is
          greater than 90°. Try again.
        </div>
        <div
          className={css.arrow}
          style={{ position: "absolute", top: "-360px", left: "420px" }}
        >
          <img src={Arrow} alt="arrow" />
        </div>
      </Fragment>
    );
  } else {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        You can compare an angle's measure to 90° to determine if it is acute,
        right, or obtuse. Since angles H and I both form square corners, they
        are right angles that have a measure of exactly 90°. An acute angle has
        a measure that is greater than 0° and less than 90°. Because an acute
        angle is smaller than a right angle, you can classify angles G and J as
        acute angles. An obtuse angle has a measure greater than 90°, but less
        than 180°. Because an obtuse angle is larger than a right angle, you can
        classify angles F and K as obtuse angles.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
