import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [1, 1, 1, 1];

export const getAnswers = () => {
  return { indices: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0, 0],
  };
};

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.indices.length; i++) {
    if (data.indices[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [];

export const showReader = () => {
  return false;
};
