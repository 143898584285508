import React from "react";
import css from "./InputField.module.css";

const InputField = (props) => {
  return (
    <input
      {...props}
      placeholder={props.language == "sp" ? "Escribe" : "Type"}
      className={css.root}
      type="text"
    >
      {props.children}
    </input>
  );
};

export default InputField;
