import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Write an Equation in the Form <Italic>y</Italic> = <Italic>mx</Italic>{" "}
          + <Italic>b</Italic>
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Recall that in an equation in the form <Italic>y</Italic> ={" "}
          <Italic>mx</Italic> + <Italic>b</Italic>, <Italic>m</Italic> is the
          slope and <Italic>b</Italic> is the <Italic>y</Italic>‑intercept. The{" "}
          <Italic>y</Italic>‑intercept tells you the point where the line
          crosses the <Italic>y</Italic>‑axis.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/2f4e9e747dec695505fd2cc0135c82cc.svg"
            width="30%"
          />
        </Center>
        <div>
          Write the equation for the line shown on the coordinate plane.
        </div>
        <Instructions>
          Click or tap each step to learn how to write the equation of the line.
        </Instructions>
        <div className={css.bottom}>
          <div className={css.left}>
            <FoldBox>
              <div>
                <Bold>STEP 1:</Bold> Find the slope using coordinate pairs.
              </div>
              <div>
                <div style={{ marginBottom: "20px" }}>
                  Notice that two points on the line have the coordinates (−3,6)
                  and (3,2) . Use the slope formula to find the slope.
                </div>
                <Center>
                  <span>
                    <Italic>m</Italic> =
                    <Fraction
                      width="60px"
                      numerator={
                        <span>
                          <Italic>y</Italic>
                          <sup>2</sup> - <Italic>y</Italic>
                          <sup>1</sup>
                        </span>
                      }
                      denominator={
                        <span>
                          <Italic>x</Italic>
                          <sup>2</sup> - <Italic>x</Italic>
                          <sup>1</sup>
                        </span>
                      }
                    />{" "}
                    ={" "}
                    <Fraction
                      width="50px"
                      numerator="2-6"
                      denominator="3-(-3)"
                    />{" "}
                    = <Fraction width="25px" numerator="-4" denominator="6" /> =
                    -<Fraction numerator="2" denominator="3" />
                  </span>
                </Center>
              </div>
            </FoldBox>
            <FoldBox>
              <div>
                <Bold>STEP 2:</Bold> Find the <Italic>y</Italic>-intercept.
              </div>
              <div>
                <div>
                  The line crosses the y-axis at (0,4) . The y-intercept is 4.
                </div>
              </div>
            </FoldBox>
            <FoldBox>
              <div>
                <Bold>STEP 3:</Bold> Write the equation.
              </div>
              <div>
                <div>
                  The slope is −<Fraction numerator="2" denominator="3" />, and
                  the <Italic>y</Italic>-intercept is 4. The equation of the
                  line is:
                </div>
                <Center>
                  <span>
                    <Italic>y</Italic> = -
                    <Fraction numerator="2" denominator="3" />
                    <Italic>x</Italic> + 4
                  </span>
                </Center>
              </div>
            </FoldBox>
          </div>
          <div className={css.right}>
            <img
              style={{ marginLeft: "40px" }}
              width="80%"
              src="https://d16qzots3do3wk.cloudfront.net/images/600e4b0547cf8d7ec35b2d56d6d0feaf.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contents;
