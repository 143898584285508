export default (data, setData) => {};

export const correctAnswers = { index: 3, fraction: ["7", "3", "8"] };

export const getDefaultState = () => {
  return {
    index: 0,
    fraction: ["", "", ""],
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.index == 0 || data.fraction.filter((x) => x !== "").length == 0)
    return "empty";
  else if (
    data.index == correctAnswers.index &&
    data.fraction[0] == correctAnswers.fraction[0] &&
    data.fraction[1] == correctAnswers.fraction[1] &&
    data.fraction[2] == correctAnswers.fraction[2]
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.index == 0 || data.fraction.filter((x) => x !== "").length > 0;
};

export const getTitle = (data, setData) => {
  if (data.index == 0 || data.fraction.filter((x) => x !== "").length == 0)
    return "";

  if (
    data.index == correctAnswers.index &&
    data.fraction[0] == correctAnswers.fraction[0] &&
    data.fraction[1] == correctAnswers.fraction[1] &&
    data.fraction[2] == correctAnswers.fraction[2]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "Carla is pouring 59 fluid ounces into 8 equal groups. Which operation can you use to solve for n?",
  "Is 59 a multiple of 8? If not, then the answer to a division will include a remainder.",
  "Compare 59 to multiples of 8: 8, 16, 24, …. Which whole numbers is the quotient between?",
];

export const showReader = () => {
  return false;
};
