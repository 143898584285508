import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Fraction from "../../../../Fraction";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        What subtraction sentence does the model show?
      </div>
      <Instructions>Click or tap the correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />{" "}
            −{" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />{" "}
            ={" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="0"
              denominator="3"
            />
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="2"
              denominator="3"
            />{" "}
            −{" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />{" "}
            ={" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="3"
              denominator="3"
            />
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="2"
              denominator="3"
            />{" "}
            −{" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />{" "}
            ={" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="2"
              denominator="3"
            />
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="2"
              denominator="3"
            />{" "}
            −{" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />{" "}
            ={" "}
            <Fraction
              verticalStyle={{ fontSize: "14px" }}
              numerator="1"
              denominator="3"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
