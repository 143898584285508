import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const lines = {
  title: {
    en: "Which multiplication sentence can you use to find the area of the rectangle?",
    sp: "¿Qué enunciado de multiplicación puedes utilizar para hallar el área del rectángulo?",
  },
  0: {
    en: "Click or tap the correct answer.",
    sp: "Pulsa o haz clic en la respuesta correcta.",
  },
  1: {
    en: "9 × 9 = 81 square centimeters",
    sp: "9 × 9 = 81 centímetros cuadrados",
  },
  2: {
    en: "9 × 8 = 72 square centimeters",
    sp: "9 × 8 = 72 centímetros cuadrados",
  },
  3: {
    en: "8 × 8 = 64 square centimeters",
    sp: "8 × 8 = 64 centímetros cuadrados",
  },
  4: {
    en: "9 + 8 = 17 square centimeters",
    sp: "9 + 8 = 17 centímetros cuadrados",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const checkHandler = (p) => {
    const selected = [];
    for (let i = 0; i < props.data.selected.length; i++) {
      selected.push(false);
    }

    const data = { selected: selected };
    data.selected[p.index] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>{languageLookup("title")}</div>
      <Instructions>{languageLookup(0)}</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            circle
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>{languageLookup(1)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>{languageLookup(2)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>{languageLookup(3)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            circle
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>{languageLookup(4)}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
