import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const lines = {
  0: {
    en: "Which two rugs did Gina buy?",
    sp: "¿Cuáles son los dos tapetes que compró Gina?",
  },
  1: {
    en: "Click or tap each correct answer.",
    sp: "Pulsa o haz clic en las respuestas correctas.",
  },
  2: {
    en: "Rug A",
    sp: "Tapete A",
  },
  3: {
    en: "Rug B",
    sp: "Tapete B",
  },
  4: {
    en: "Rug C",
    sp: "Tapete C",
  },
  5: {
    en: "Rug D",
    sp: "Tapete D",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const checkHandler = (p) => {
    const data = { selected: [...props.data.selected] };
    data.selected[p.index] = !data.selected[p.index];
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>{languageLookup(0)}</div>
      <Instructions>{languageLookup(1)}</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>{languageLookup(2)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>{languageLookup(3)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>{languageLookup(4)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>{languageLookup(5)}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
