import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            Read the passage. Look at the bolded words. Then answer the
            question.
          </Bold>
        </div>
        <div>
          Waiting at the finish line, Langston cheered as his twin sister Aiysha
          hobbled towards him with unswerving confidence and{" "}
          <Bold>resolute</Bold> determination. Yes, she had tripped and, yes,
          she fell during the race, but that wasn’t going to hold her back.
          Aiysha’s mind was <Bold>firm</Bold> and laser-focused on one thing:
          She would finish this 10k race to raise money for the hospital that
          saved her best friend’s life.
        </div>
        <div>
          Langston recalled Aiysha’s bold stick-to-itiveness even when they were
          little. Grandpa Ed sometimes found Aiysha to be <Bold>obstinate</Bold>
          . She would interrupt him constantly with questions, especially when
          he asked her to do a chore or help out. Grandpa felt like asking, “Why
          are you so <Bold>contrary</Bold>, Aiysha?” But he didn’t want to
          discourage her. Though her many questions kept Grandpa on his toes, he
          believed that her strong will would help her achieve any goal she set
          for herself.
        </div>
      </div>
    </div>
  );
};

export default Contents;
