import s1_opt1_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/opt1_GK_078A1_ela_vo_en.mp3";
import s1_opt2_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/opt2_GK_078A1_ela_vo_en.mp3";
import s1_opt3_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/opt3_GK_078A1_ela_vo_en.mp3";
import s1_opt4_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/opt4_GK_078A1_ela_vo_en.mp3";
import s1_successAnswer_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/successAnswer_GK_078A1_ela_vo_en.mp3";
import s1_tapButton_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen1/tapButton_GK_078A_ela_vo_en.mp3";
import s1_error1_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/error1_GK_078A1_ela_vo_en.mp3";
import s1_error2_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/error2_GK_078A1_ela_vo_en.mp3";
import s1_error3_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/error3_GK_078A1_ela_vo_en.mp3";
import s1_hint2_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen1/hint2_GK_078A_ela_vo_en.mp3";
import s1_instruction_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen1/instruction_GK_078A_ela_vo_en.mp3";
import s1_ks_GK_078A1_ela_vo_en from "./assets/vo/ela-1/screen1/ks_GK_078A1_ela_vo_en.mp3";

import s2_opt1_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/opt1_GK_078A2_ela_vo_en.mp3";
import s2_opt2_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/opt2_GK_078A2_ela_vo_en.mp3";
import s2_opt3_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/opt3_GK_078A2_ela_vo_en.mp3";
import s2_opt4_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/opt4_GK_078A2_ela_vo_en.mp3";
import s2_successAnswer_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/successAnswer_GK_078A2_ela_vo_en.mp3";
import s2_error1_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/error1_GK_078A2_ela_vo_en.mp3";
import s2_error2_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/error2_GK_078A2_ela_vo_en.mp3";
import s2_error3_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/error3_GK_078A2_ela_vo_en.mp3";
import s2_hint2_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen2/hint2_GK_078A_ela_vo_en.mp3";
import s2_instruction_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen2/instruction_GK_078A_ela_vo_en.mp3";
import s2_ks_GK_078A2_ela_vo_en from "./assets/vo/ela-1/screen2/ks_GK_078A2_ela_vo_en.mp3";

import s3_successAnswer_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/successAnswer_GK_078A3_ela_vo_en.mp3";
import s3_error1_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/error1_GK_078A3_ela_vo_en.mp3";
import s3_error2_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/error2_GK_078A3_ela_vo_en.mp3";
import s3_error3_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/error3_GK_078A3_ela_vo_en.mp3";
import s3_instruction_GK_078A_ela_vo_en from "./assets/vo/ela-1/screen3/instruction_GK_078A_ela_vo_en.mp3";
import s3_ks_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/ks_GK_078A3_ela_vo_en.mp3";
import s3_opt1_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/opt1_GK_078A3_ela_vo_en.mp3";
import s3_opt2_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/opt2_GK_078A3_ela_vo_en.mp3";
import s3_opt3_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/opt3_GK_078A3_ela_vo_en.mp3";
import s3_opt4_GK_078A3_ela_vo_en from "./assets/vo/ela-1/screen3/opt4_GK_078A3_ela_vo_en.mp3";

import s4_successAnswer_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/successAnswer_GK_078B1_ela_vo_en.mp3";
import s4_error1_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/error1_GK_078B1_ela_vo_en.mp3";
import s4_hint2_GK_078B_ela_vo_en from "./assets/vo/ela-1/screen4/hint2_GK_078B_ela_vo_en.mp3";
import s4_instruction_GK_078B_ela_vo_en from "./assets/vo/ela-1/screen4/instruction_GK_078B_ela_vo_en.mp3";
import s4_ks_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/ks_GK_078B1_ela_vo_en.mp3";
import s4_opt1_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/opt1_GK_078B1_ela_vo_en.mp3";
import s4_opt2_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/opt2_GK_078B1_ela_vo_en.mp3";
import s4_opt3_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/opt3_GK_078B1_ela_vo_en.mp3";
import s4_opt4_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/opt4_GK_078B1_ela_vo_en.mp3";
import s4_opt5_GK_078B1_ela_vo_en from "./assets/vo/ela-1/screen4/opt5_GK_078B1_ela_vo_en.mp3";

import s5_successAnswer_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/successAnswer_GK_078B2_ela_vo_en.mp3";
import s5_error1_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/error1_GK_078B2_ela_vo_en.mp3";
import s5_hint2_GK_078B_ela_vo_en from "./assets/vo/ela-1/screen5/hint2_GK_078B_ela_vo_en.mp3";
import s5_instruction_GK_078B_ela_vo_en from "./assets/vo/ela-1/screen5/instruction_GK_078B_ela_vo_en.mp3";
import s5_ks_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/ks_GK_078B2_ela_vo_en.mp3";
import s5_opt1_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/opt1_GK_078B2_ela_vo_en.mp3";
import s5_opt2_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/opt2_GK_078B2_ela_vo_en.mp3";
import s5_opt3_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/opt3_GK_078B2_ela_vo_en.mp3";
import s5_opt4_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/opt4_GK_078B2_ela_vo_en.mp3";
import s5_opt5_GK_078B2_ela_vo_en from "./assets/vo/ela-1/screen5/opt5_GK_078B2_ela_vo_en.mp3";

import s6_opt5_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/opt5_GK_078B3_ela_vo_en.mp3";
import s6_successAnswer_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/successAnswer_GK_078B3_ela_vo_en.mp3";
import s6_error1_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/error1_GK_078B3_ela_vo_en.mp3";
import s6_ks_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/ks_GK_078B3_ela_vo_en.mp3";
import s6_opt1_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/opt1_GK_078B3_ela_vo_en.mp3";
import s6_opt2_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/opt2_GK_078B3_ela_vo_en.mp3";
import s6_opt3_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/opt3_GK_078B3_ela_vo_en.mp3";
import s6_opt4_GK_078B3_ela_vo_en from "./assets/vo/ela-1/screen6/opt4_GK_078B3_ela_vo_en.mp3";

import s7_opt3_GK_078D1_ela_vo_en from "./assets/vo/ela-1/screen7/opt3_GK_078D1_ela_vo_en.mp3";
import s7_opt4_GK_078D1_ela_vo_en from "./assets/vo/ela-1/screen7/opt4_GK_078D1_ela_vo_en.mp3";
import s7_tapButton_GK_078D_ela_vo_en from "./assets/vo/ela-1/screen7/tapButton_GK_078D_ela_vo_en.mp3";
import s7_instruction_GK_078D1_ela_vo_en from "./assets/vo/ela-1/screen7/instruction_GK_078D1_ela_vo_en.mp3";
import s7_opt1_GK_078D1_ela_vo_en from "./assets/vo/ela-1/screen7/opt1_GK_078D1_ela_vo_en.mp3";
import s7_opt2_GK_078D1_ela_vo_en from "./assets/vo/ela-1/screen7/opt2_GK_078D1_ela_vo_en.mp3";

import s8_opt2_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/opt2_GK_078D2_ela_vo_en.mp3";
import s8_opt3_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/opt3_GK_078D2_ela_vo_en.mp3";
import s8_opt4_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/opt4_GK_078D2_ela_vo_en.mp3";
import s8_opt5_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/opt5_GK_078D2_ela_vo_en.mp3";
import s8_instruction_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/instruction_GK_078D2_ela_vo_en.mp3";
import s8_opt1_GK_078D2_ela_vo_en from "./assets/vo/ela-1/screen8/opt1_GK_078D2_ela_vo_en.mp3";

import s9_opt3_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/opt3_GK_078D3_ela_vo_en.mp3";
import s9_opt4_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/opt4_GK_078D3_ela_vo_en.mp3";
import s9_opt5_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/opt5_GK_078D3_ela_vo_en.mp3";
import s9_instruction_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/instruction_GK_078D3_ela_vo_en.mp3";
import s9_opt1_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/opt1_GK_078D3_ela_vo_en.mp3";
import s9_opt2_GK_078D3_ela_vo_en from "./assets/vo/ela-1/screen9/opt2_GK_078D3_ela_vo_en.mp3";

import videoIntro_l0_vo_en from "./assets/vo/ela-1/videoIntro_l0_vo_en.mp3";

export default {
  videoIntro_l0_vo_en,
  s1_opt2_GK_078A1_ela_vo_en,
  s1_opt3_GK_078A1_ela_vo_en,
  s1_opt4_GK_078A1_ela_vo_en,
  s1_successAnswer_GK_078A1_ela_vo_en,
  s1_tapButton_GK_078A_ela_vo_en,
  s1_error1_GK_078A1_ela_vo_en,
  s1_error2_GK_078A1_ela_vo_en,
  s1_error3_GK_078A1_ela_vo_en,
  s1_hint2_GK_078A_ela_vo_en,
  s1_instruction_GK_078A_ela_vo_en,
  s1_ks_GK_078A1_ela_vo_en,
  s1_opt1_GK_078A1_ela_vo_en,

  s2_opt2_GK_078A2_ela_vo_en,
  s2_opt3_GK_078A2_ela_vo_en,
  s2_opt4_GK_078A2_ela_vo_en,
  s2_successAnswer_GK_078A2_ela_vo_en,
  s2_error1_GK_078A2_ela_vo_en,
  s2_error2_GK_078A2_ela_vo_en,
  s2_error3_GK_078A2_ela_vo_en,
  s2_hint2_GK_078A_ela_vo_en,
  s2_instruction_GK_078A_ela_vo_en,
  s2_ks_GK_078A2_ela_vo_en,
  s2_opt1_GK_078A2_ela_vo_en,

  s3_successAnswer_GK_078A3_ela_vo_en,
  s3_error1_GK_078A3_ela_vo_en,
  s3_error2_GK_078A3_ela_vo_en,
  s3_error3_GK_078A3_ela_vo_en,
  s3_instruction_GK_078A_ela_vo_en,
  s3_ks_GK_078A3_ela_vo_en,
  s3_opt1_GK_078A3_ela_vo_en,
  s3_opt2_GK_078A3_ela_vo_en,
  s3_opt3_GK_078A3_ela_vo_en,
  s3_opt4_GK_078A3_ela_vo_en,

  s4_successAnswer_GK_078B1_ela_vo_en,
  s4_error1_GK_078B1_ela_vo_en,
  s4_hint2_GK_078B_ela_vo_en,
  s4_instruction_GK_078B_ela_vo_en,
  s4_ks_GK_078B1_ela_vo_en,
  s4_opt1_GK_078B1_ela_vo_en,
  s4_opt2_GK_078B1_ela_vo_en,
  s4_opt3_GK_078B1_ela_vo_en,
  s4_opt4_GK_078B1_ela_vo_en,
  s4_opt5_GK_078B1_ela_vo_en,

  s5_successAnswer_GK_078B2_ela_vo_en,
  s5_error1_GK_078B2_ela_vo_en,
  s5_hint2_GK_078B_ela_vo_en,
  s5_instruction_GK_078B_ela_vo_en,
  s5_ks_GK_078B2_ela_vo_en,
  s5_opt1_GK_078B2_ela_vo_en,
  s5_opt2_GK_078B2_ela_vo_en,
  s5_opt3_GK_078B2_ela_vo_en,
  s5_opt4_GK_078B2_ela_vo_en,
  s5_opt5_GK_078B2_ela_vo_en,

  s6_opt5_GK_078B3_ela_vo_en,
  s6_successAnswer_GK_078B3_ela_vo_en,
  s6_error1_GK_078B3_ela_vo_en,
  s6_ks_GK_078B3_ela_vo_en,
  s6_opt1_GK_078B3_ela_vo_en,
  s6_opt2_GK_078B3_ela_vo_en,
  s6_opt3_GK_078B3_ela_vo_en,
  s6_opt4_GK_078B3_ela_vo_en,

  s7_opt3_GK_078D1_ela_vo_en,
  s7_opt4_GK_078D1_ela_vo_en,
  s7_tapButton_GK_078D_ela_vo_en,
  s7_instruction_GK_078D1_ela_vo_en,
  s7_opt1_GK_078D1_ela_vo_en,
  s7_opt2_GK_078D1_ela_vo_en,

  s8_opt2_GK_078D2_ela_vo_en,
  s8_opt3_GK_078D2_ela_vo_en,
  s8_opt4_GK_078D2_ela_vo_en,
  s8_opt5_GK_078D2_ela_vo_en,
  s8_instruction_GK_078D2_ela_vo_en,
  s8_opt1_GK_078D2_ela_vo_en,

  s9_opt3_GK_078D3_ela_vo_en,
  s9_opt4_GK_078D3_ela_vo_en,
  s9_opt5_GK_078D3_ela_vo_en,
  s9_instruction_GK_078D3_ela_vo_en,
  s9_opt1_GK_078D3_ela_vo_en,
  s9_opt2_GK_078D3_ela_vo_en,
};
