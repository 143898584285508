import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Which number makes each statement true?</div>
      <div className={css.instructions}>
        Type the missing number into each blank.
      </div>
    </div>
  );
};

export default Sidebar;
