import React from "react";

import Italic from "../../../../Italic";
import Fraction from "../../../../Fraction";

export default (data, setData) => {};

export const correctAnswers = { indices: [3, 1, 1], inputs: ["40"] };

export const isCorrect = (data) => {
  return (
    data.indices[0] == correctAnswers.indices[0] &&
    data.indices[1] == correctAnswers.indices[1] &&
    data.indices[2] == correctAnswers.indices[2] &&
    data.inputs[0] == correctAnswers.inputs[0]
  );
};

export const isEmpty = (data) => {
  console.log(
    "is empty " +
      data.indices.filter((x) => x == 0).length +
      ", " +
      data.inputs[0].length
  );
  return (
    data.indices.filter((x) => x == 0).length > 0 || data.inputs[0].length == 0
  );
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0],
    inputs: ["", "", "", ""],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    The linear model uses the slope-intercept form, <Italic>y</Italic> ={" "}
    <Italic>mx</Italic> + <Italic>b</Italic>.
  </div>,
  <div>
    <div>
      To find the <Italic>y</Italic>-intercept, extend the trend line to see
      where it crosses the <Italic>y</Italic>-axis.
    </div>
    <div>What does the intercept tell you about the memory card?</div>
  </div>,
  <div style={{ width: "110%" }}>
    <div>
      Locate two points that the trend line passes through to calculate the
      slope
    </div>
    <div style={{ marginTop: "10px" }}>
      using <Italic>m</Italic> =
      <Fraction
        numerator={
          <span>
            change in <Italic>y</Italic>
          </span>
        }
        denominator={
          <span>
            change in <Italic>x</Italic>
          </span>
        }
        width="100px"
      />
      .
    </div>
  </div>,
];

export const showReader = () => {
  return false;
};
