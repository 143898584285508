import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_8_2_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Tamlane</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        Tamlane was a <Underline>gallant</Underline> young knight who lived in
        an ancient kingdom. His father was a knight of great{" "}
        <Underline>renown</Underline>, famous throughout the land for his
        bravery, and his mother was known for her{" "}
        <Underline>benignity</Underline> and kindness. Tamlane was both
        courageous and kind, like his parents, and was adored by all in the
        kingdom.
      </Paragraph>
      <Paragraph index="2">
        One cold night, Tamlane left with his uncle on an excursion to a
        neighboring kingdom. The party of stalwart travelers climbed on their
        horses and rode into the wintry darkness as the north wind roared across
        the frigid woods of Caterhaugh. Tamlane had become chilled, frozen stiff
        as an icicle, and was spent. He dismounted his horse to lie under a
        blanket on the hard earth.
      </Paragraph>
      <Paragraph index="3">
        The moment he closed his eyes in sleep, the queen of Elfland carried
        Tamlane away to make him her knight. Tamlane awoke, changed into a tiny
        elfin form. An elf was to a person in stature and size as a duck is to a
        swan. Henceforth, he was bound to live as a little elf in the unfamiliar
        Elfland, required to follow the perplexing habits and ways of all elves.
      </Paragraph>
      <Paragraph index="4">
        No one knew what had become of dear Tamlane. His poor mother and father
        were debilitated by their grief. Tamlane's friends grew sad. The entire
        kingdom mourned his loss for months without end.
      </Paragraph>
      <Paragraph index="5">
        On moonlit nights, the elves departed Elfland to roam the beautiful
        lands of this world. They danced and played, frolicking over glens,
        hills, and plains. But no place pleased them more than the woods of
        Caterhaugh, where the rivers of Ettrick and Yarrow joined, their
        mingling waters clapping across rocks like an audience applauding the
        elfin dancers. Under the light of the moon, the elves went to
        Caterhaugh, from which Tamlane had been carried away by the queen of
        Elfland.
      </Paragraph>
      <Paragraph index="6">
        Not far from the woods of Caterhaugh lived Lady Janet. Her father warned
        her never to go into those woods, for he was fearful of the elves and
        their otherworldly powers. In her father's company, Janet agreed to obey
        his wishes, but she was a willful daughter. Alone, Janet boldly
        declared, "I am not timid like my father! I will go where I choose, and
        I choose to go into the woods of Caterhaugh."
      </Paragraph>
      <Paragraph index="7">
        One moonlit night, Janet snuck away from her home and made her furtive
        path to Caterhaugh. There she found the most beautiful rosebush in the
        kingdom. While plucking its rich flowers, a tiny figure appeared before
        her. Janet felt no fear in her heart, only elation upon seeing this
        elfin knight, whom she recognized immediately. "It is my dear childhood
        friend!" she exclaimed with joy. "What has happened to you, dear
        Tamlane?"
      </Paragraph>
      <Paragraph index="8">
        "I was carried away by the queen of Elfland and made her elfin knight,"
        he explained.
      </Paragraph>
      <Paragraph index="9">
        "Are you satisfied in your current condition?" Janet demanded of her
        long-lost friend. Tamlane explained how he could do incredible things
        now, like shrink as small as a ladybug to sleep on soft flower petals,
        but that he also missed his family and friends. Then he asked Janet to
        save him from Elfland, explaining how he could be changed back into a
        person with her help. Janet, as brave and generous as her friend,
        agreed.
      </Paragraph>
      <Paragraph index="10">
        On the last moonlit night in October, a grand elfin parade was staged in
        the woods of Caterhaugh. Janet had hidden behind a rosebush as the
        procession of marching elves passed on their colorful elfin steeds. When
        Tamlane passed on his horse, Janet leaped from the bush and took the
        elfin knight in her arms, as Tamlane had instructed her to do.
      </Paragraph>
      <Paragraph index="11">
        The other elves surrounded Tamlane and Janet. They turned Tamlane into
        the form of a lion, a bear, and then a snake so that Janet would
        surrender him in fear. But Janet clutched Tamlane in her arms as he had
        asked her to do. Finally, the elves turned Tamlane into{" "}
        <Underline>scorching</Underline> coal so that Janet would drop him.
        Instead, she threw the burning coal into the waters where the rivers
        Ettrick and Yarrow joined. This was what her friend had told Janet to do
        to save him. In those waters, a metamorphosis occurred as Tamlane once
        again became a human knight. Janet brought Tamlane home, and his parents
        and the kingdom <Underline>reveled</Underline> in happiness.
      </Paragraph>
      <Paragraph index="12">
        After losing their elfin knight, the elves returned to haunt the woods
        of Caterhaugh no more.
      </Paragraph>
    </div>
  );
};

export default Reader;
