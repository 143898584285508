import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job using context clues to determine the meaning of each word.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        The word <Italic>environment</Italic> has been moved to an incorrect
        definition. Reread paragraph 1, and then move{" "}
        <Italic>environment</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        A chemical reaction is often an "event of scientific interest." However,
        the word that matches this definition is more general. There is a better
        answer. Look again.
      </div>
    );
  } else if (props.data.left[0] == 5) {
    contents = (
      <div className={css.text}>
        The word <Italic>bacteria</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>bacteria</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[0] == 6) {
    contents = (
      <div className={css.text}>
        The word <Italic>deception</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>deception</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        Although some illuminated sea creatures might resemble jelly, that is
        not the definition of illuminated. Reread paragraph 1, and then move
        illuminated to another definition.
      </div>
    );
  } else if (props.data.left[1] == 4) {
    contents = (
      <div className={css.text}>
        The word <Italic>continuously</Italic> has been placed in an incorrect
        box. What part of speech is <Italic>continuously</Italic>? Is there a
        definition related to that part of speech? If you aren’t sure, reread
        paragraph 2 and then decide if <Italic>continuously</Italic> is defined
        in any of the boxes.
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        The word <Italic>environment</Italic> has been moved to an incorrect
        definition. Reread paragraph 1, and then move{" "}
        <Italic>environment</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        The word <Italic>reaction</Italic> does not mean "an act intended to
        trick or confuse," even though it contains the root word act. The prefix
        re- means “again” or “once more.” <Italic>Reaction</Italic> means “a
        response to another action.” There is a better word for this box. Look
        again.
      </div>
    );
  } else if (props.data.left[2] == 3) {
    contents = (
      <div className={css.text}>
        The word <Italic>phenomenon</Italic> has been moved to an incorrect
        definition. Reread paragraph 3, and then move{" "}
        <Italic>phenomenon</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[2] == 4) {
    contents = (
      <div className={css.text}>
        The word <Italic>continuously</Italic> has been placed in an incorrect
        box. What part of speech is <Italic>continuously?</Italic> Is there a
        definition related to that part of speech? If you aren’t sure, reread
        paragraph 2 and then decide if <Italic>continuously</Italic> is defined
        in any of the boxes.
      </div>
    );
  } else if (props.data.left[2] == 5) {
    contents = (
      <div className={css.text}>
        The word <Italic>bacteria</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>bacteria</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[3] == 3) {
    contents = (
      <div className={css.text}>
        The word <Italic>phenomenon</Italic> has been moved to an incorrect
        definition. Reread paragraph 3, and then move{" "}
        <Italic>phenomenon</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[3] == 4) {
    contents = (
      <div className={css.text}>
        The word <Italic>continuously</Italic> has been placed in an incorrect
        box. What part of speech is <Italic>continuously</Italic>? Is there a
        definition related to that part of speech? If you aren’t sure, reread
        paragraph 2 and then decide if <Italic>continuously</Italic> is defined
        in any of the boxes.
      </div>
    );
  } else if (props.data.left[3] == 5) {
    contents = (
      <div className={css.text}>
        The word <Italic>bacteria</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>bacteria</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[3] == 6) {
    contents = (
      <div className={css.text}>
        The word <Italic>deception</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>deception</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[4] == 4) {
    contents = (
      <div className={css.text}>
        The word <Italic>continuously</Italic> has been placed in an incorrect
        box. What part of speech is <Italic>continuously</Italic>? Is there a
        definition related to that part of speech? If you aren’t sure, reread
        paragraph 2 and then decide if <Italic>continuously</Italic> is defined
        in any of the boxes.
      </div>
    );
  } else if (props.data.left[4] == 7) {
    contents = (
      <div className={css.text}>
        <Italic>Gelatinous</Italic> does not mean “lit up.” Reread paragraph 4,
        and then move <Italic>gelatinous</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[5] == 1) {
    contents = (
      <div className={css.text}>
        The word <Italic>environment</Italic> has been moved to an incorrect
        definition. Reread paragraph 1, and then move{" "}
        <Italic>environment</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[5] == 3) {
    contents = (
      <div className={css.text}>
        The word <Italic>phenomenon</Italic> has been moved to an incorrect
        definition. Reread paragraph 3, and then move{" "}
        <Italic>phenomenon</Italic> to another definition.
      </div>
    );
  } else if (props.data.left[5] == 4) {
    contents = (
      <div className={css.text}>
        The word <Italic>continuously</Italic> has been placed in an incorrect
        box. What part of speech is <Italic>continuously</Italic>? Is there a
        definition related to that part of speech? If you aren’t sure, reread
        paragraph 2 and then decide if <Italic>continuously</Italic> is defined
        in any of the boxes.
      </div>
    );
  } else if (props.data.left[5] == 6) {
    contents = (
      <div className={css.text}>
        The word <Italic>deception</Italic> has been moved to an incorrect
        definition. Reread paragraph 5, and then move <Italic>deception</Italic>{" "}
        to another definition.
      </div>
    );
  } else if (props.data.left[0] == 3) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else if (props.data.left[1] == 7) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else if (props.data.left[2] == 6) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else if (props.data.left[3] == 1) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else if (props.data.left[4] == 0) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else if (props.data.left[4] == 5) {
    contents = (
      <div className={css.text}>
        You have moved at least one word to the correct definition. Make sure
        you have moved each word to the best definition before checking your
        answer.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        In the top row of boxes, the answers from left to right are phenomenon,
        gelatinous, and deception. In the bottom row of boxes, the answers from
        left to right are environment, illuminated, and bacteria. Drag each word
        to the correct box to move on to the next answer.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
