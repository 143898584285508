import React from "react";
import css from "./Sidebar.module.css";

import { list } from "../Controller";

const Sidebar = (props) => {
  const dragStartHandler = (ev, index) => {
    ev.dataTransfer.setData("text/plain", index);
    ev.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Which students will receive a bookmark, a book, or both prizes? Not
        every student receives a prize.
      </div>
      <div className={css.instructions}>
        Drag the name to the prize. Click or tap the Reset button to start over.
      </div>
      <div className={css.items}>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 0)}
        >
          {list[0]}
        </div>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 1)}
        >
          {list[1]}
        </div>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 2)}
        >
          {list[2]}
        </div>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 3)}
        >
          {list[3]}
        </div>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 4)}
        >
          {list[4]}
        </div>
        <div
          className={css.text}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 5)}
        >
          {list[5]}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
