import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Yes! This detail is a reason that school uniforms are good for students.",
    sp: "¡Sí! Este detalle es una razón por la que los uniformes escolares son buenos para los estudiantes.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Take another look. Make sure your answer is related to school uniforms.",
    sp: "Echa otro vistazo. Asegúrate de que tu respuesta se relacione con los uniformes escolares.",
  },
  3: {
    en: "Take another look. Make sure your answer is related to school uniforms.",
    sp: "Echa otro vistazo. Asegúrate de que tu respuesta se relacione con los uniformes escolares.",
  },
  4: {
    en: "Look again. Make sure your answer states something positive about school uniforms.",
    sp: "Vuelve a mirar. Asegúrate de que tu respuesta diga algo positivo sobre los uniformes escolares.",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.selected[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.selected[2]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.selected[3]) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
