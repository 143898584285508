import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly identified the idiom and its meaning. Groups of people
        tend to have their own idioms, or common sayings, which often reveal how
        they see the world.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        The phrase "the look of consternation still on his face" is supposed to
        be read literally. Try again.
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        You have placed a definition, not an idiom, in the “Idiom” box. Try
        again.
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        "The screen came alive” is an example of personification, not an idiom.
        Try again.
      </div>
    );
  } else if (props.data.left[0] == 3) {
    contents = (
      <div className={css.text}>
        You have placed a definition, not an idiom, in the “Idiom” box. Try
        again.
      </div>
    );
  } else if (props.data.left[0] == 4) {
    contents = (
      <div className={css.text}>
        You identified the idiom, but not its definition. Think about how this
        expression is used in the story. Reread paragraph 16 and try again.
      </div>
    );
  } else if (props.data.left[0] == 5) {
    contents = (
      <div className={css.text}>
        You have placed a definition, not an idiom, in the “Idiom” box. Try
        again.
      </div>
    );
  } else if (props.data.left[0] == 6) {
    contents = (
      <div className={css.text}>
        ”Smile that beamed as bright as the sun” is an example of a simile, not
        an idiom. Try again.
      </div>
    );
  } else if (props.data.left[0] == 7) {
    contents = (
      <div className={css.text}>
        You have placed a definition, not an idiom, in the “Idiom” box. Try
        again.
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        You have placed an expression, not a definition, in the “Meaning” box.
        Try again.
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        The idiom is <Italic>best thing since sliced bread</Italic> and means
        "great idea." Select these answers to move on to the next question.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        You have placed an expression, not a definition, in the “Meaning” box.
        Try again.
      </div>
    );
  } else if (props.data.left[1] == 3) {
    contents = (
      <div className={css.text}>
        The idiom is <Italic>best thing since sliced bread</Italic> and means
        "great idea." Select these answers to move on to the next question.
      </div>
    );
  } else if (props.data.left[1] == 4) {
    contents = (
      <div className={css.text}>
        You have placed an expression, not a definition, in the “Meaning” box.
        Try again.
      </div>
    );
  } else if (props.data.left[1] == 5) {
    contents = (
      <div className={css.text}>
        The idiom is <Italic>best thing since sliced bread</Italic> and means
        "great idea." Select these answers to move on to the next question.
      </div>
    );
  } else if (props.data.left[1] == 6) {
    contents = (
      <div className={css.text}>
        You have placed an expression, not a definition, in the “Meaning” box.
        Try again.
      </div>
    );
  } else if (props.data.left[1] == 7) {
    contents = (
      <div className={css.text}>
        The idiom is <Italic>best thing since sliced bread</Italic> and means
        "great idea." Select these answers to move on to the next question.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
