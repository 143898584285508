import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read this excerpt from Consumer Product Safety Commission: Document
          93. Then answer the question.
        </div>
        <BlockQuote>
          Protective gear—such as slip-resistant, closed shoes; helmets; and
          specially designed padding—may not fully protect skateboarders from
          fractures, but its use is recommended because such gear can reduce the
          number and severity of injuries.
        </BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
