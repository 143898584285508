import React from "react";

export default (data, setData) => {};

export const correctAnswers = [
  [0],
  [0, 1],
  [0],
  [0, 1],
  [0, 1, 2],
  [0, 1, 2],
  [],
  [0, 1],
  [0],
];

export const isEmpty = (data) => {
  return data.icons.filter((x) => x.length > 0).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < correctAnswers.length; i++) {
    if (data.icons[i].length != correctAnswers[i].length) return false;

    for (let j = 0; j < data.icons[i].length; j++) {
      if (correctAnswers[i].filter((x) => x == data.icons[i][j]).length == 0)
        return false;
    }
  }

  return true;
};

export const getDefaultState = () => {
  return {
    icons: [[], [], [], [], [], [], [], [], []],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Make a tally table to record the frequency of each number of sheets of
    paper.
  </div>,
  <div>
    Place a dot or dots above each number on the number line to show the
    frequency for that number.
  </div>,
  <div>
    Be sure the number of dots equals the number of terms in the data set.
  </div>,
  <div>Check your dot plot against the list of data.</div>,
];

export const showReader = () => {
  return false;
};
