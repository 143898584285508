import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Compound Sentences</span>
      </div>
      <div className={css.contents}>
        <div>
          A <Bold>compound sentence</Bold> joins two simple sentences with a{" "}
          <Bold>comma</Bold> and a <Bold>coordinating conjunction</Bold>.
        </div>
        <div>
          Coordinating conjunctions include <Italic>and</Italic>,{" "}
          <Italic>or</Italic>, <Italic>but</Italic>, and <Italic>so</Italic>.
        </div>
        <div>
          If two complete thoughts are put together{" "}
          <Underline>without</Underline> a comma and coordinating conjunction,
          it is a <Bold>run-on sentence</Bold>.
        </div>
        <div>Here are two simple sentences.</div>
        <BlockQuote>Tara likes to run. She is training for a race.</BlockQuote>
        <div>Here is a run-on sentence:</div>
        <BlockQuote>Tara likes to run she is training for a race.</BlockQuote>
        <div>
          Let’s build a compound sentence from these simple sentences, using a
          comma and a coordinating conjunction:
        </div>
        <img
          className={css.image}
          src="https://d16qzots3do3wk.cloudfront.net/images/7ba96a03a16f954ae0c67a5de595b224.svg"
          width="75%"
        />
        <Instructions>
          Click or tap the simple sentences to see them form a compound
          sentence.
        </Instructions>
        <FoldBox index={0} onClick={boxHandler} selected={boxes[0]}>
          <div>It was cold outside. I put on a coat.</div>
          <div>
            It was cold outside<Bold>, so</Bold> I put on a coat.
          </div>
        </FoldBox>
        <FoldBox index={1} onClick={boxHandler} selected={boxes[1]}>
          <div>Wes has a test tomorrow. He doesn’t want to study.</div>
          <div>
            Wes has a test tomorrow<Bold>, but</Bold> he doesn’t want to study.
          </div>
        </FoldBox>
        <FoldBox index={2} onClick={boxHandler} selected={boxes[2]}>
          <div>Olive can wear a raincoat. She can take an umbrella.</div>
          <div>
            Olive can wear a raincoat<Bold>, or</Bold> she can take an umbrella.
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
