import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Select the word or phrase that BEST explains the real meaning of each
        example of figurative language from the poem.
      </div>
      <div className={css.instructions}>
        Click or tap the correct word or phrase from each drop-down menu. Click
        or tap the Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
