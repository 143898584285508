import React from "react";
import css from "./GradeSelection.module.css";

import DropDown from "../../../components/DropDown";

const GradeSelection = (props) => {
  return (
    <div className={css.root}>
      <DropDown
        width="150px"
        data={[
          { text: "Grade 2" },
          { text: "Grade 3" },
          { text: "Grade 4" },
          { text: "Grade 5" },
          { text: "Grade 6" },
          { text: "Grade 7" },
          { text: "Grade 8" },
        ]}
        selectedIndex={props.selectedIndex}
        selectIndex={props.selectIndex}
      />
      <div
        className={
          css.selector + (props.activity == 0 ? " " + css.selected : "")
        }
        onMouseDown={() => props.onActivitySelected(0)}
      >
        1
      </div>
      <div
        className={
          css.selector + (props.activity == 1 ? " " + css.selected : "")
        }
        onMouseDown={() => props.onActivitySelected(1)}
      >
        2
      </div>
      <div
        className={
          css.selector + (props.activity == 2 ? " " + css.selected : "")
        }
        onMouseDown={() => props.onActivitySelected(2)}
      >
        3
      </div>
    </div>
  );
};

export default GradeSelection;
