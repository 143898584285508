import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

import SpanishA from "../Assets/spanish_a.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "Proportional Relationships in Graphs",
    sp: "Relaciones proporcionales en gráficas",
  },
  14: {
    en: "According to the US Department of Labor, veterinarians earn an average of $45 per hour. The table shows the relationship between the cost for an animal hospital to hire a veterinarian at that rate and the number of hours that the veterinarian worked.",
    sp: "Según el Departamento de Trabajo de los Estados Unidos, los veterinarios ganan en promedio $45 por hora. La tabla muestra la relación entre el costo que tiene para un hospital de animales contratar a un veterinario a esa tasa y el número de horas trabajadas por el veterinario.",
  },
  15: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/b6986fce67cf2358689ca3dd9b724d73.svg",
    sp: SpanishA,
  },
  16: {
    en: (
      <Fragment>
        You can write the data from the table as ordered pairs with{" "}
        <Italic>x</Italic>-values for time worked and <Italic>y</Italic>-values
        for total cost.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Puedes escribir los datos de la tabla como pares ordenados (
        <Italic>x</Italic>, <Italic>y</Italic>). Toma el tiempo trabajado como
        valores de <Italic>x</Italic> y el costo total como valores de{" "}
        <Italic>y</Italic>.
      </Fragment>
    ),
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(13)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(14)}</div>
        <Center>
          <img src={`${languageLookup(15)}`} width="70%" />
        </Center>
        <div>{languageLookup(16)}</div>
        <Center>
          <div>(1, 45) (2, 90) (3, 135) (5, 225) (8, 360)</div>
        </Center>
      </div>
    </div>
  );
};

export default Contents;
