import { useEffect, useState } from "react";
import { BUTTON_STATES } from "../BottomToolbar";
import { playVoiceover, playVoiceovers } from "../voiceover";

export const CORRECT_STATES = {
  unknown: 1,
  correct: 2,
  incorrect: 3,
};

export const useDropFeedback = (
  options,
  correct,
  incorrectFeedback,
  setFoxState, language
) => {
  const [resetState, setResetState] = useState(BUTTON_STATES.enabled);
  const [tries, setTries] = useState(0);
  const [drops, setDrops] = useState(options.map((o) => null));
  const [sources, setSources] = useState(options);
  const [continueState, setContinueState] = useState(BUTTON_STATES.hidden);
  const [checkState, setCheckState] = useState(BUTTON_STATES.disabled);
  const [allowPulse, setAllowPulse] = useState(false);
  const [correctStates, setCorrectStates] = useState(
    options.map((o) => CORRECT_STATES.unknown)
  );

  const resetCorrect = () =>
    setCorrectStates(options.map((o) => CORRECT_STATES.unknown));

  const onDropSource = (index) => (item) => {
    setDrops((drops) => drops.map((i) => (i === item ? null : i)));
    setSources((sources) =>
      sources.map((i, idx) => {
        if (idx === index) return item; // Dropping it here
        return i === item ? null : i; // Not here, so clear it out if it was
      })
    );
    resetCorrect();
  };

  const onDropTarget = (index) => (item) => {
    setSources((sources) => sources.map((i) => (i === item ? null : i)));
    setDrops((drops) =>
      drops.map((i, idx) => {
        if (idx === index) return item; // Dropping it here
        return i === item ? null : i; // Not here, so clear it out if it was
      })
    );
    resetCorrect();
  };

  const updateButtons = () => {
    if (continueState == BUTTON_STATES.enabled) {
      setCheckState(BUTTON_STATES.hidden);
    } else {
      setCheckState(
        drops.filter((i) => !i).length === 0
          ? BUTTON_STATES.enabled
          : BUTTON_STATES.disabled
      );
    }
  };

  useEffect(updateButtons, [sources, drops]);

  const showCorrectFeedback = () => {
    console.info("Correct!");
    setCheckState(BUTTON_STATES.hidden);
    setContinueState(BUTTON_STATES.enabled);
    const vo = language === 'en' ? ["success", "waytogo", "go_on_arrow"] : ["success", "encourage_l0_math_vo_es", "nextQ_l0_math_vo_es"];
    playVoiceovers(vo, setFoxState);
    setResetState(BUTTON_STATES.disabled);
  };

  const showIncorrectFeedback = (firstIncorrect) => {
    setTries(tries + 1);
    if (tries >= 2) {
      setDrops(correct);
      setSources(correct.map((c) => null));
      setCorrectStates(correct.map((c) => CORRECT_STATES.correct));
      const vo = language === 'en' ? ["ks_l0_math_vo_en", "go_on_arrow"] :
        ["ks_l0_math_vo_es", "nextQ_l0_math_vo_es"]

      playVoiceovers(vo, setFoxState);
      setCheckState(BUTTON_STATES.hidden);
      setContinueState(BUTTON_STATES.enabled);
      setResetState(BUTTON_STATES.disabled);
      return;
    }

    const feedback =
      incorrectFeedback[firstIncorrect] &&
      incorrectFeedback[firstIncorrect][drops[firstIncorrect]];
    console.info("Incorrect:", firstIncorrect, feedback);
    if (!feedback) {
      console.error("Feedback missing!");
    } else {
      setAllowPulse(true);
      setCheckState(BUTTON_STATES.disabled);
      playVoiceover(feedback, setFoxState).then(() => setAllowPulse(false));
    }
  };

  const onCheck = () => {
    setCorrectStates(
      correct.map((c, i) =>
        c === drops[i] ? CORRECT_STATES.correct : CORRECT_STATES.incorrect
      )
    );
    const firstIncorrect = correct.findIndex((i, idx) => i !== drops[idx]);
    if (firstIncorrect === -1) {
      showCorrectFeedback();
    } else {
      showIncorrectFeedback(firstIncorrect);
    }
  };

  const onReset = () => {
    setDrops(options.map((o) => null));
    setSources(options);
    setCorrectStates(options.map((o) => CORRECT_STATES.unknown));
    setCheckState(BUTTON_STATES.disabled);
  };

  return {
    resetState,
    onReset,
    drops,
    sources,
    onDropSource,
    onDropTarget,
    continueState,
    checkState,
    allowPulse,
    onCheck,
    correctStates,
  };
};
