import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import css from "./LearningGoal.module.css";

import GradeSelection from "./GradeSelection";
import Logo from "../../components/Logo";

import Logout from "../../components/Icons/Logout";
import Help from "../../components/Icons/Help";
import PointBox from "./PointBox";
import ProgressBox from "./ProgressBox";
import CorrectBox from "./CorrectBox";
import Activity from "./Activity";
import Footer from "./Footer";

import Controller from "./Activity/Controller";

import * as activities from "../../components/Activities/LearningGoal";

const Mission = (props) => {
  const [activityIndex, setActivityIndex] = useState(0);
  const [hintIndex, setHintIndex] = useState(-1);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showReader, setShowReader] = useState(true);
  const [correct, setCorrect] = useState(false);

  const showFeedbackHandler = (value) => {
    setShowFeedback(value);
  };

  const history = useHistory();

  const gradeIndex = props.grade - 2;

  const onHome = () => {
    history.push(`home-${props.subject}-${props.grade}`);
  };

  const id = `${props.subject}_${props.grade}_${activityIndex}`;

  const selectHintHandler = (index) => {
    setHintIndex(index);
  };

  const selectIndexHandler = (index) => {
    setActivityIndex(0);
    setShowReader(true);
    history.push(`learninggoal-${props.subject}-${index + 2}`);
  };

  const activityHandler = (index) => {
    setShowFeedback(false);
    setHintIndex(-1);
    setShowFeedback(false);
    setActivityIndex(index);
    setShowReader(true);
    setCorrect(false);
  };

  const doneHandler = () => {
    if (activityIndex < 2) {
      activityHandler(activityIndex + 1);
    } else {
      onHome();
    }
  };

  const inDevelopment = <div className={css.indev}>In Development</div>;

  return (
    <div className={css.root}>
      <div className={css.background}>
        <GradeSelection
          activity={activityIndex}
          selectedIndex={gradeIndex}
          selectIndex={selectIndexHandler}
          onActivitySelected={activityHandler}
        />
        <div className={css.contents}>
          <div className={css.header}>
            <div className={css.left}>
              <div className={css.home} onMouseUp={onHome}>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMzkiIHZpZXdCb3g9IjAgMCA0MiAzOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMyIgeT0iOSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjI3IiBmaWxsPSIjRTk1MzU5Ii8+CjxyZWN0IHg9IjIxIiB5PSIxOCIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiBmaWxsPSIjRTMyNjJEIi8+CjxyZWN0IHg9IjEyIiB3aWR0aD0iMTgiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIzNiIgd2lkdGg9IjQyIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMTUiIHk9IjYiIHdpZHRoPSIyMSIgaGVpZ2h0PSIzIiBmaWxsPSIjOTU5NTk1Ii8+CjxyZWN0IHg9IjIxIiB5PSIxMiIgd2lkdGg9IjIxIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMTIiIHk9IjMiIHdpZHRoPSIyMSIgaGVpZ2h0PSIzIiBmaWxsPSIjODQ4NDg0Ii8+CjxyZWN0IHg9IjE4IiB5PSI5IiB3aWR0aD0iMjEiIGhlaWdodD0iMyIgZmlsbD0iIzg0ODQ4NCIvPgo8cmVjdCB4PSI5IiB5PSIzIiB3aWR0aD0iMyIgaGVpZ2h0PSIzIiBmaWxsPSIjOTU5NTk1Ii8+CjxyZWN0IHg9IjYiIHk9IjYiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IiM5NTk1OTUiLz4KPHJlY3QgeD0iMyIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIxMiIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0iIzk1OTU5NSIvPgo8cmVjdCB5PSIxNSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMjEiIHk9IjE1IiB3aWR0aD0iMjEiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMyIgeT0iMTIiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjE4IiB5PSIxMiIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iNiIgeT0iOSIgd2lkdGg9IjMiIGhlaWdodD0iMyIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMTUiIHk9IjkiIHdpZHRoPSIzIiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjkiIHk9IjYiIHdpZHRoPSI2IiBoZWlnaHQ9IjMiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjkiIHk9IjIxIiB3aWR0aD0iNiIgaGVpZ2h0PSIxNSIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMjciIHk9IjI0IiB3aWR0aD0iNiIgaGVpZ2h0PSI2IiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K"
                  alt="home"
                />
              </div>
              <div className={css.logo}>
                <Logo />
              </div>
            </div>
            <div className={css.right}>
              <div className={css.logout}>
                <Logout />
              </div>
              <div className={css.help}>
                <Help />
              </div>
            </div>
          </div>
          <div className={css.progress}>
            <PointBox correct={correct} index={activityIndex} />
            <ProgressBox correct={correct} index={activityIndex} />
            <CorrectBox correct={correct} index={activityIndex} />
          </div>
          {activities[id] ? (
            <Activity
              key={id}
              onCorrect={() => setCorrect(true)}
              correct={correct}
              showReader={showReader}
              setShowReader={setShowReader}
              showFeedback={showFeedback}
              closeShowFeedback={() => showFeedbackHandler(false)}
              hint={hintIndex}
              closeHint={() => setHintIndex(-1)}
              id={id}
            />
          ) : (
            inDevelopment
          )}
          {activities[id] ? (
            <Footer
              text={Controller.HasActivity(id) && Controller.ShowReader(id)}
              correct={correct}
              onDone={doneHandler}
              setShowReader={setShowReader}
              onShowFeedback={showFeedbackHandler}
              showFeedback={showFeedback}
              hint={hintIndex}
              onSelectHint={selectHintHandler}
              id={id}
              index={activityIndex}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Mission;
