import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Remember . . .</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>
            Domain-specific language means vocabulary relating to specific
            subjects—for example, dance, botany, or algebra.
          </li>
          <li>
            You can use context clues to help you understand the meaning of
            domain-specific language.
          </li>
          <li>
            Context clues are words and phrases that surround a word and give
            you clues to its meaning.
          </li>
        </BulletPoints>
        <div>
          Think about why understanding domain-specific language is important.
        </div>
        <Instructions>
          Click or tap the correct answer in the drop-down menu.
        </Instructions>
        <div>
          Increasing your knowledge of domain-specific language will help you
        </div>
        <div>
          <span>understand different</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: "errors" },
                { text: "subjects" },
                { text: "metaphors" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>, both in school and in your life outside the classroom.</span>
        </div>
      </div>
      <div style={{ height: "150px" }} />
    </div>
  );
};

export default Contents;
