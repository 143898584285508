import React, { useState } from "react";
import css from "./Activity.module.css";
import Contents from "./Contents";
import Sidebar from "./Sidebar";

import Controller from "./Controller";
import Feedback from "./Feedback";
import Reader from "../../../components/Reader";

const lines = {
  correct: {
    en: "Yes!",
    sp: "¡CORRECTO!",
  },
  incorrect: {
    en: "TAKE ANOTHER LOOK",
    sp: "ECHA OTRO VISTAZO",
  },
  effort_0: {
    en: "Good effort!",
    sp: "¡Has hecho un gran esfuerzo!",
  },
  effort_1: {
    en: "Read the correct answer. Click Next to move on.",
    sp: "Lee la respuesta correcta. Haz clic en Siguiente para continuar.",
  },
};

const Activity = (props) => {
  const defaultState = Controller.GetDefaultState(props.id);

  const [data, setData] = useState(defaultState);

  const AContents = Controller.GetContents(props.id);
  const ASidebar = Controller.GetSidebar(props.id);
  const AHint = Controller.GetHint(props.id);
  const ADone = Controller.GetDone(props.id);
  const AReader = Controller.GetReader(props.id);

  const feedbackType = Controller.GetFeedbackType(props.id, data, setData);
  const showReader = props.showReader && Controller.ShowReader(props.id);

  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const resetHandler = () => {
    setData({ ...defaultState });
  };

  if (props.forceCompleteReady) {
    if (!Controller.IsCorrect(props.id, data)) {
      props.onForceComplete();
      setData(Controller.GetAnswers(props.id));
    }
  }

  if (props.done && props.lessonsComplete && feedbackType != "empty") {
    props.onSkip();
  }

  let feedback;

  if (props.forceComplete) {
    feedback = (
      <Feedback
        style={{
          background: "#f7eff4",
          borderTop: "8px solid #ab0049",
        }}
        audio
        title={languageLookup("effort_0")}
        close
        onClose={props.closeDone}
      >
        <div>{languageLookup("effort_1")}</div>
      </Feedback>
    );
  } else {
    switch (feedbackType) {
      case "empty":
        feedback = (
          <Feedback close onClose={props.closeDone}>
            <ADone data={data} setData={setData} language={props.language} />
          </Feedback>
        );
        break;
      case "correct":
        if (props.done) props.onCorrect();

        feedback = (
          <Feedback
            style={{
              background: "#f1f6f0",
              borderTop: "8px solid #378500",
            }}
            audio
            title={languageLookup("correct")}
            close
            onClose={props.closeDone}
          >
            <ADone data={data} setData={setData} language={props.language} />
          </Feedback>
        );
        break;
      case "incorrect":
        feedback = (
          <Feedback
            style={{
              background: "#f7eff4",
              borderTop: "8px solid #ab0049",
            }}
            audio
            title={languageLookup("incorrect")}
            close
            onClose={props.closeDone}
          >
            <ADone data={data} setData={setData} language={props.language} />
          </Feedback>
        );
        break;
    }
  }

  return (
    <div className={css.root}>
      <Contents full={!ASidebar}>
        <AContents
          data={data}
          setData={setData}
          reset={resetHandler}
          language={props.language}
        />
      </Contents>
      {ASidebar ? (
        <Sidebar>
          <ASidebar
            data={data}
            setData={setData}
            reset={resetHandler}
            language={props.language}
          />
        </Sidebar>
      ) : null}
      {props.hint > -1 ? (
        <Feedback
          audio
          title={`${props.language == "sp" ? "PISTA" : "HINT"} ${
            props.hint + 1
          }`}
          close
          index={props.hint}
          onClose={props.closeHint}
        >
          <AHint
            data={data}
            setData={setData}
            index={props.hint}
            language={props.language}
          />
        </Feedback>
      ) : null}
      {props.done ? feedback : null}
      {showReader ? (
        <Reader onClose={() => props.setShowReader(false)}>
          <AReader language={props.language} />
        </Reader>
      ) : null}
    </div>
  );
};

export default Activity;
