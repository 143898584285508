import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/video.mp4";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Vary Sentences for Effect</span>
      </div>
      <div className={css.contents}>
        <div>Sentences can vary by structure, length, and type. </div>
        <div>Vary sentences to make your writing more interesting.</div>
        <Instructions>
          Click or tap the video to learn more about varying sentences for
          effect.
        </Instructions>
        <video width="320" height="240" controls src={Video} />
      </div>
    </div>
  );
};

export default Contents;
