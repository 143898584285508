import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/video.mp4";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Precise Language</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Precise</Bold> means to express an idea specifically and
          accurately.
        </div>
        <div>
          Precise language can help show emotions. You can make your writing
          more precise by using specific words and sensory details.
        </div>
        <Instructions>
          Click or tap the video to learn more about precise language.
        </Instructions>
        <video width="320" height="240" controls src={Video} />
      </div>
    </div>
  );
};

export default Contents;
