import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Write an Equation in the Form <Italic>y</Italic> = <Italic>mx</Italic>
        </span>
      </div>
      <div className={css.contents}>
        <div>
          One way to find the slope of a line is to use the slope formula.
        </div>
        <Center>
          <span>
            <Italic>m</Italic> ={" "}
            <Fraction
              width="70px"
              numerator={
                <span>
                  <Italic>y</Italic>
                  <sup>2</sup> - <Italic>y</Italic>
                  <sup>1</sup>
                </span>
              }
              denominator={
                <span>
                  <Italic>x</Italic>
                  <sup>2</sup> - <Italic>x</Italic>
                  <sup>1</sup>
                </span>
              }
            />
          </span>
        </Center>
        <div>
          For example, calculate the slope <Italic>m</Italic> of the line shown.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/afccefa2ad596296fa073040d148069f.svg"
            width="30%"
          />
        </Center>
        <Instructions>
          Click or tap each step to learn how to write an equation of the line.
        </Instructions>
        <FoldBox>
          <div>
            <Bold>STEP 1:</Bold> Find the coordinates of two points on the line.
          </div>
          <div>
            <div>
              The coordinates of Point <Italic>P</Italic>
              <sup>1</sup> are (<Italic>x</Italic>
              <sup>1</sup>, <Italic>y</Italic>
              <sup>1</sup>) = (3, 2).
            </div>
            <div>
              The coordinates of Point <Italic>P</Italic>
              <sup>2</sup> are (<Italic>x</Italic>
              <sup>2</sup>, <Italic>y</Italic>
              <sup>2</sup>) = (6, 4).
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>STEP 2:</Bold> Find the coordinates of two points on the line.
          </div>
          <div>
            <Center>
              <span>
                <Italic>m</Italic> ={" "}
                <Fraction
                  width="60px"
                  numerator={
                    <span>
                      <Italic>y</Italic>
                      <sup>2</sup> - <Italic>y</Italic>
                      <sup>1</sup>
                    </span>
                  }
                  denominator={
                    <span>
                      <Italic>x</Italic>
                      <sup>2</sup> - <Italic>x</Italic>
                      <sup>1</sup>
                    </span>
                  }
                />{" "}
                ={" "}
                <Fraction width="40px" numerator="4 - 2" denominator="6 - 3" />{" "}
                = <Fraction numerator="2" denominator="3" />
              </span>
            </Center>
            <div>
              The slope of the line is{" "}
              <Fraction numerator="2" denominator="3" />.
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>STEP 3:</Bold> Write an equation.
          </div>
          <div>
            The equation of a line that passes through the origin (0,0) has the
            form <Italic>y</Italic> = <Italic>mx</Italic>. An equation of the
            line is <Italic>y</Italic> ={" "}
            <Fraction numerator="2" denominator="3" />
            <Italic>x</Italic>.
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
