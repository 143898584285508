import React from "react";
import css from "./People.module.css";

const People = (props) => {
  return (
    <div className={css.root}>
      <img
        className={css.icon}
        width="18px"
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNzUgMTBDMTEuNTExNyAxMCAxMy43NSA3Ljc2MTcyIDEzLjc1IDVDMTMuNzUgMi4yMzgyOCAxMS41MTE3IDAgOC43NSAwQzUuOTg4MjggMCAzLjc1IDIuMjM4MjggMy43NSA1QzMuNzUgNy43NjE3MiA1Ljk4ODI4IDEwIDguNzUgMTBaTTEyLjI1IDExLjI1SDExLjU5NzdDMTAuNzMwNSAxMS42NDg0IDkuNzY1NjIgMTEuODc1IDguNzUgMTEuODc1QzcuNzM0MzggMTEuODc1IDYuNzczNDQgMTEuNjQ4NCA1LjkwMjM0IDExLjI1SDUuMjVDMi4zNTE1NiAxMS4yNSAwIDEzLjYwMTYgMCAxNi41VjE4LjEyNUMwIDE5LjE2MDIgMC44Mzk4NDQgMjAgMS44NzUgMjBIMTUuNjI1QzE2LjY2MDIgMjAgMTcuNSAxOS4xNjAyIDE3LjUgMTguMTI1VjE2LjVDMTcuNSAxMy42MDE2IDE1LjE0ODQgMTEuMjUgMTIuMjUgMTEuMjVaIiBmaWxsPSIjRkJGQ0ZEIi8+Cjwvc3ZnPgo="
      />
      <div className={css.circle}>{props.value}</div>
    </div>
  );
};

export default People;
