import React from "react";

export default (data, setData) => {};

export const correctAnswers = [
  false,
  false,
  false,
  true,
  true,
  false,
  false,
  false,
];

export const isEmpty = (data) => {
  return data.highlights.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  return (
    data.highlights[0] == correctAnswers[0] &&
    data.highlights[1] == correctAnswers[1] &&
    data.highlights[2] == correctAnswers[2] &&
    data.highlights[3] == correctAnswers[3] &&
    data.highlights[4] == correctAnswers[4] &&
    data.highlights[5] == correctAnswers[5] &&
    data.highlights[6] == correctAnswers[6] &&
    data.highlights[7] == correctAnswers[7]
  );
};

export const getDefaultState = () => {
  return {
    highlights: [false, false, false, false, false, false, false, false],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>What is Jahi’s problem?</div>,
  <div>What does Jahi do to solve his problem?</div>,
  <div>
    Look for sentences that describe what Jahi does, not what he thinks.
  </div>,
  <div>Remember, you need to highlight two sentences.</div>,
];

export const showReader = () => {
  return true;
};
