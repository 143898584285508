import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import InputField from "../../../../InputField";

const Contents = (props) => {
  const valueHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Divide a Decimal by a Whole Number</span>
      </div>
      <div className={css.contents}>
        <div>What is the quotient for the division shown?</div>
        <Instructions>Type the correct number into the blank.</Instructions>
        <div>
          <span>The quotient of 23.85 ÷ 15 is</span>
          <InputField
            language={props.language}
            value={props.data.inputs[0]}
            onChange={(ev) => valueHandler(0, ev)}
            width="175px"
          ></InputField>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
