import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  let numCorrect = 0;
  if (props.data.left[0] == correctAnswers[0]) numCorrect++;
  if (props.data.left[1] == correctAnswers[1]) numCorrect++;
  if (props.data.left[2] == correctAnswers[2]) numCorrect++;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly selected events that happen at the beginning, middle, and
        end of the story. In the beginning of the story, you learn that Sir Hops
        A. Lot is making trouble with his friends. In the middle, the animals
        try to fix the trouble by having a jumping contest. In the end, Sir Hops
        A. Lot jumps into the sky and can no longer make trouble.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        <Italic>Beginning</Italic> does not match “The animals have a contest.”
        There is an event that happens earlier in the story that causes the
        animals to have a contest. Try again!
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        End does not match “The animals have a contest.” Because of the contest,
        something else then happens. Think about how one event leads to another.
        Try again!
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        Beginning does not match “Sir Hops A. Lot jumps into the sky.” There is
        an event that happens earlier in the story that causes Sir Hops A. Lot
        to jump into the sky. Try again!
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        Middle does not match “Sir Hops A. Lot jumps into the sky.” This event
        happens later in the story. Another event happens before this event. Try
        again!
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        Middle does not match “Sir Hops A. Lot jumps on Squirrel’s home.” This
        event introduces you to Sir Hops A. Lot and lets you know he is causing
        trouble for his friends. When in the story do you learn this? Try again!
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        End does not match “Sir Hops A. Lot jumps on Squirrel’s home.” This
        event happens earlier in the story and causes other events to happen.
        Think about how one event leads to another. Try again!
      </div>
    );
  } else if (numCorrect == 2) {
    contents = (
      <div className={css.text}>
        <div>
          The correct word for "The animals have a contest" is{" "}
          <Bold>middle</Bold>.
        </div>
        <div>
          The correct word for "Sir Hops A. Lot jumps into the sky" is{" "}
          <Bold>end</Bold>.
        </div>
        <div>
          The correct word for "Sir Hops A. Lot jumps on Squirrel's home" is{" "}
          <Bold>beginning</Bold>.
        </div>
        <div>
          Drag each word to the correct event to move on to the next question.{" "}
        </div>
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  } else if (props.data.left[2] == 0) {
    contents = (
      <div className={css.text}>
        Make sure to drag all the words to the chart. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
