import React from "react";

import Fraction from "../../../../Fraction";

export const list = [
  "Isabella - 0.25 hour",
  "Alex - 2 hours",
  "Tina - 0.75 hour",
  "Chung - 1.5 hours",
  "Ethan - 0.5 hour",
  "Sarah - 1.75 hours",
];

export default (data, setData) => {};

export const correctAnswers = [
  [1, 2, 3, 5],
  [1, 3, 5],
];

export const isCorrect = (data) => {
  if (
    data.items[0].length != correctAnswers[0].length ||
    data.items[1].length != correctAnswers[1].length
  )
    return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      if (!data.items[i].find((x) => x == correctAnswers[i][j])) return false;
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.items[0].length == 0 && data.items[1].length == 0;
};

export const getDefaultState = () => {
  return {
    items: [[], []],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "Think about the relationship between hours and minutes. How many minutes are in 1 hour?",
  <div style={{ marginTop: "-3px", lineHeight: "30px" }}>
    Think of the decimals as fractions of an hour. For example, the decimal 0.25
    can be written as the fraction
    <Fraction
      numerator="25"
      denominator="100"
      verticalStyle={{ width: "40px" }}
    />
    , which is equivalent to
    <Fraction numerator="1" denominator="4" />. So 0.25 hour =
    <Fraction numerator="1" denominator="4" /> hour.
  </div>,
  <div style={{ marginTop: "-3px", lineHeight: "30px" }}>
    How many minutes are in 0.5 or
    <Fraction numerator="1" denominator="2" /> hour?
  </div>,
  "Use multiplication to convert a larger unit of measure to its equivalent in smaller units.",
];

export const showReader = () => {
  return false;
};
