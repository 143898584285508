import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Draw the line of symmetry in the figure.</div>
      <div className={css.instructions}>
        Click or tap two points on the line of symmetry. Click or tap the Reset
        button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
