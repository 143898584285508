import React from "react";
import css from "./Contents.module.css";

const Contents = (props) => {
  return (
    <div className={css.root + (props.full ? " " + css.full : "")}>
      {props.children}
    </div>
  );
};

export default Contents;
