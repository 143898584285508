import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [2, 1, 3, -1, -1, -1];

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1, -1, -1, -1],
    showLeft: [false, false, false, false],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.left.length; i++) {
    if (data.left[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Even though two words may have the same denotative meaning, that doesn’t
    necessarily mean that they have the same connotation. If you find a synonym
    for one of the words, look for another synonym; its connotation may be
    closer. Remember that connotation is the feelings or emotions associated
    with a word.
  </div>,
  <div>
    Reread paragraph 7, where the word elation appears. Before the word appears,
    Janet is described as feeling “no fear in her heart.” That should help you
    determine the meaning of the word. Now think about the connotation.
  </div>,
  <div>
    Reread paragraph 11, where the words scorching and reveled appear. Is the
    connotation of scorching negative or positive? What are Tamlane’s parents
    reveling in? That should help you determine the word’s connotation.
  </div>,
];

export const showReader = () => {
  return true;
};
