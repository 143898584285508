import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [
  [
    { id: 2, show: true },
    { id: 3, show: true },
    { id: 5, show: true },
  ],
  [
    { id: 0, show: true },
    { id: 1, show: true },
    { id: 4, show: true },
  ],
];

export const getDefaultState = () => {
  return {
    items: [[], []],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const isCorrect = (data) => {
  if (
    data.items[0].length != correctAnswers[0].length ||
    data.items[1].length != correctAnswers[1].length
  )
    return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      if (
        data.items[i].filter((x) => x.id == correctAnswers[i][j].id).length == 0
      ) {
        return false;
      }
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.items[0].length == 0 && data.items[1].length == 0;
};
export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    <Italic>Amicable</Italic> contains the root <Italic>amic</Italic>, which
    comes from the Latin word for “friend.” (The Italian word for “friend” is{" "}
    <Italic>amico</Italic>, and the Spanish word for “friend” is{" "}
    <Italic>amigo</Italic>.)
  </div>,
  <div>
    According to paragraph 7, how did the English and the Algonquians behave
    toward each other? How do these examples show that their relationship was
    not <Italic>amicable</Italic>?
  </div>,
  <div>
    According to paragraph 8, how did the situation between the English and the
    Algonquians become “extremely <Italic>hostile</Italic>”? Based on these
    events, what does hostile mean?
  </div>,
  <div>
    Which answer choices describe positive events? These belong in the same
    category.
  </div>,
  <div>
    Which answer choices describe negative events? These belong in the same
    category.
  </div>,
];

export const showReader = () => {
  return true;
};
