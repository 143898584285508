import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Understand Subtraction of Fractions</span>
      </div>
      <div className={css.contents}>
        <div style={{ lineHeight: "30px" }}>
          Mariko has <Fraction numerator="6" denominator="8" /> of a pancake.
        </div>
        <div style={{ lineHeight: "30px" }}>
          She gives <Fraction numerator="2" denominator="8" /> of her pancake to
          her brother.
        </div>
      </div>
    </div>
  );
};

export default Contents;
