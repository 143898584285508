import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "That's it! This outline would correctly identify the main idea.",
    sp: "¡Así es! Un esquema así identificaría correctamente la idea principal.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Look again. Make sure the outline begins with the main idea, not a supporting detail.",
    sp: "Vuelve a mirar. Asegúrate de que el esquema comience con la idea principal, no con un detalle de apoyo.",
  },
  3: {
    en: "Try again. This sentence focuses on a detail instead of the main idea.",
    sp: "Inténtalo otra vez. Esta oración se enfoca en un detalle y no en la idea principal.",
  },
  4: {
    en: "Look more closely. This outline would incorrectly begin with a detail instead of the main idea.",
    sp: "Observa con más atención. Un esquema así comenzaría de manera incorrecta, con un detalle en vez de una idea principal.",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.selected[0]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.selected[2]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.selected[3]) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
