import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Graph a Linear Equation</span>
      </div>
      <div className={css.contents}>
        <div>You can graph a linear equation by making a table of values.</div>
        <Instructions>
          Click or tap each equation to learn how to graph linear equations.
        </Instructions>
        <div className={css.row}>
          <FoldBox width="40%">
            <div>
              <Center style={{ marginTop: "30px", marginBottom: "30px" }}>
                <img
                  src="https://d16qzots3do3wk.cloudfront.net/images/0ceca1058874de2781a3ef78a179f3c2.svg"
                  width="80%"
                />
              </Center>
              <div>
                Graph <Italic>y</Italic> = 2<Italic>x</Italic>.
              </div>
            </div>
            <div>
              <div>
                Plot the coordinate pairs (0,0) , (1,2) , (2,4) , and (3,6) .
                Then draw a line through the points.
              </div>
              <Center>
                <img
                  width="70%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/6979657ed9256da434bb3767a72bf5ad.svg"
                />
              </Center>
              <div>
                The graph of <Italic>y</Italic> = 2<Italic>x</Italic> crosses
                the <Italic>y</Italic>‑axis at the origin. This equation is in
                the form <Italic>y</Italic>=<Italic>mx</Italic>, where{" "}
                <Italic>m</Italic> is the slope of the line. The graph of an
                equation in the form <Italic>y</Italic> = <Italic>mx</Italic>{" "}
                passes through (0,0).
              </div>
            </div>
          </FoldBox>
          <FoldBox width="40%">
            <div>
              <Center style={{ marginTop: "30px", marginBottom: "30px" }}>
                <img
                  src="https://d16qzots3do3wk.cloudfront.net/images/6ca57aaae7af8f905fcc74d5c3e39902.svg"
                  width="80%"
                />
              </Center>
              <div>
                Graph <Italic>y</Italic> = 2<Italic>x</Italic> + 2.
              </div>
            </div>
            <div>
              <div>
                On the same coordinate plane, plot the coordinate pairs (0,2),
                (1,4), (2,6), and (3,8). Then draw a line through the points.
              </div>
              <Center>
                <img
                  width="70%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/a8dc5eb464e44fcee6a5511e3580f3d7.svg"
                />
              </Center>
              <div>
                The graph of <Italic>y</Italic> = 2<Italic>x</Italic>+2 crosses
                the <Italic>y</Italic>‑axis at 2. The equation{" "}
                <Italic>y</Italic> = 2<Italic>x</Italic>+2 is in the form{" "}
                <Italic>y</Italic> = <Italic>mx</Italic> + <Italic>b</Italic>,
                where <Italic>m</Italic> represents the slope and{" "}
                <Italic>b</Italic> represents the point at which the line
                crosses the <Italic>y</Italic>‑axis. This is called the{" "}
                <Bold>
                  <Italic>y</Italic>-intercept.
                </Bold>
              </div>
            </div>
          </FoldBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
