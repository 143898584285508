import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Graph a Linear Equation</span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          <Bold>Complete each sentence.</Bold>
        </div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span></span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "All" }, { text: "Some" }, { text: "No" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>
            lines in the form <Italic>y</Italic> = <Italic>mx</Italic> pass
            through (0,0).
          </span>
        </div>
        <div className={css.row}>
          <span></span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "All" }, { text: "Some" }, { text: "No" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>
            lines in the form <Italic>y</Italic> = <Italic>mx</Italic> +{" "}
            <Italic>b</Italic> pass through (0,0).
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
