
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";

import css from "../K2Lessons.module.css";
import timeline from "../../assets/art/screen5-timeline.svg";
import { Fox, FOX_STATE } from "../../Fox";
import {
    playVoiceover,
    playVoiceovers,
    stopActiveVoiceover,
} from "../../voiceover";
import { useParams } from "react-router-dom";
import { useLanguage } from "./useLanguage";

export const Screen5 = () => {
    const history = useHistory();
    const [continueState, setContinueState] = useState(BUTTON_STATES.disabled);
    const [foxState, setFoxState] = useState(FOX_STATE.idle);

    const language = useLanguage();

    const params = useParams();


    const onNextScreen = () => history.push("../6/" + language);

    useEffect(() => {
        const vo = [`instruction_G1_010B1_math_vo_${language}`]
        playVoiceovers(
            vo,
            setFoxState
        ).then(
            () => {
                setContinueState(BUTTON_STATES.enabled)
            }
        )

        return stopActiveVoiceover;
    }, [language]);



    return (
        <React.Fragment>


            <div className={css.prompt}>
                <span>7 - 2 </span>
                <br />
                <img src={timeline} />

            </div>

            <div className={css.prompt}></div>

            <div className={css.prompt}>
                <span>7 - 1 = 6</span>
                <br />
                <span>7 - 2 = 5</span>
            </div>




            <Fox state={foxState} />

            <BottomToolbar
                checkState={BUTTON_STATES.hidden}
                hintState={BUTTON_STATES.disabled}
                continueState={continueState}
                onContinue={onNextScreen}
            />
        </React.Fragment>
    );
};
