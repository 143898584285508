import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import video from "../../assets/ela_1.mp4";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playSoundEffect,
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../useMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { PromptBox } from "../PromptBox";

import bird from "../../assets/art/bird.png";
import shave from "../../assets/art/shave.png";
import share from "../../assets/art/share.png";
import suit from "../../assets/art/suit.png";
import cheese from "../../assets/art/cheese.png";

import { useRouteMatch } from "react-router-dom";
import { useMultiMCFeedback } from "../useMultiMCFeedback";
import { MultiPromptBox } from "../MultiPromptBox";

const options = [
  {
    text: "bird",
    vo: "s6_opt1_GK_078B3_ela_vo_en",
    correct: false,
    img: bird,
  },
  {
    text: "shave",
    vo: "s6_opt2_GK_078B3_ela_vo_en",
    feedback: "",
    correct: true,
    img: shave,
  },
  {
    text: "share",
    vo: "s6_opt3_GK_078B3_ela_vo_en",
    feedback: "",
    correct: true,
    img: share,
  },
  {
    text: "suit",
    vo: "s6_opt4_GK_078B3_ela_vo_en",
    feedback: "",
    correct: false,
    img: suit,
  },
  {
    text: "cheese",
    vo: "s6_opt5_GK_078B3_ela_vo_en",
    feedback: "",
    correct: false,
    img: cheese,
  },
];

export const Screen7 = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const onNextScreen = () => history.push("8");
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    feedbackState,
    selectedOptions,
    onCheck,
    chooseAnswer,
    continueState,
    checkState,
  } = useMultiMCFeedback(options, setFoxState, {
    incorrect: "s6_error1_GK_078B3_ela_vo_en",
    correct: "s6_successAnswer_GK_078B3_ela_vo_en",
    showAnswer: "s6_ks_GK_078B3_ela_vo_en",
  });

  const { getHint, playVideo, closeVideo } = useHints(
    ["s5_hint2_GK_078B_ela_vo_en"],
    setFoxState
  );

  const playInstruction = useCallback(
    () => playVoiceover("s5_instruction_GK_078B_ela_vo_en", setFoxState),
    [setFoxState]
  );

  const playTargetSound = useCallback(() => {
    // The Screen 2 vo was wrong
    playSoundEffect("s1_tapButton_GK_078A_ela_vo_en");
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <MultiPromptBox
        state={feedbackState}
        selectedOptions={selectedOptions}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
