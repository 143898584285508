import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_3_2_img.JPG";
import Bold from "../../../../Bold";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Hydroelectric Power</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.subtitle}>
        In this hydroelectric plant, running water turns a turbine as it flows
        through an opening in the bottom of the dam.
      </div>
      <Paragraph index="1">
        It's almost impossible to go through a day without using electricity. As
        soon as a light switch is flipped or a computer is turned on,
        electricity is being used. But how do we get the electricity that makes
        things turn on and power up?
      </Paragraph>
      <Paragraph index="2">
        One common way to make electricity is by using a turbine. A turbine is
        an engine. It usually has blades like a fan. First, a gas or a liquid
        pushes on the blades. Then, the turbine spins. This spinning moves a
        magnet through a coil of copper wire to create electricity.
      </Paragraph>

      <div className={css.subtitle}>Electricity from Moving Water</div>
      <Paragraph index="3">
        Electricity generated from moving water is called hydroelectric power.
        The term hydro comes from the Greek word meaning "water." It takes
        energy to move a turbine inside a generator. When running water hits the
        blades of a turbine, the turbine spins because of the pressure of the
        water. The more pressure there is, the faster the turbine will turn. As
        the water spins the turbine blades, the generator makes electricity.
      </Paragraph>
      <Paragraph index="4">
        Hydroelectric power plants have to be built close to running water.
        Often, dams are built to control the water's flow. A dam is a wall built
        across a river. This wall stops the water from flowing freely. Water
        builds up behind the dam to create a body of water called a reservoir.
        Dams have openings that let small amounts of water pass out of the
        reservoir. As the water flows from the reservoir, it spins the turbines
        inside generators.
      </Paragraph>
      <Paragraph index="5">
        The Hoover Dam is one of the most famous dams in the United States.
        Built in the 1930s across the Colorado River, it was created to prevent
        flooding as well as to provide hydroelectric power. The dam is located
        between Arizona and Nevada. It powers several cities in California,
        Nevada, and Arizona. Millions of people get power from the Hoover Dam.
      </Paragraph>
      <div className={css.box}>
        <div className={css.subtitle}>Build Your Own Water-Powered Turbine</div>
        <div className={css.text}>
          In this experiment, you will use a milk carton to build your own
          water-powered turbine. You will need:
        </div>
        <ul>
          <li>A clean quart or half gallon milk carton</li>
          <li>String</li>
          <li>A pair of scissors</li>
          <li>Water in a large container</li>
          <li>Masking tape</li>
        </ul>
        <div className={css.subtitle}>Build Your Own Water-Powered Turbine</div>
        <ol>
          <li>
            Using the scissors, punch a hole into each bottom corner of the milk
            carton.
          </li>
          <li>Punch a hole into the center of the top of the carton.</li>
          <li>Tie the string through the top hole.</li>
          <li>Cover each hole with a piece of masking tape.</li>
          <li>Hang the carton outside.</li>
          <li>Fill the carton with water.</li>
          <li>Pull off the tape on two opposite sides of the carton.</li>
          <li>Watch your turbine spin.</li>
          <li>Then pull off the tape on the other two sides.</li>
          <li>The turbine will spin faster.</li>
        </ol>
      </div>
    </div>
  );
};

export default Reader;
