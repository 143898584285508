import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! You can use the coordinates (0,0) and (4,1) to find the slope:{" "}
        <Italic>m</Italic> ={" "}
        <Fraction
          width="60px"
          numerator={
            <span>
              <Italic>y</Italic>
              <sup>2</sup> - <Italic>y</Italic>
              <sup>1</sup>
            </span>
          }
          denominator={
            <span>
              <Italic>x</Italic>
              <sup>2</sup> - <Italic>x</Italic>
              <sup>1</sup>
            </span>
          }
        />{" "}
        = <Fraction width="40px" numerator="1 - 0" denominator="4-0" /> ={" "}
        <Fraction numerator="1" denominator="4" />
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Try again! Use the correct coordinate pairs to find the slope.
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Use the correct coordinate pairs to find the slope.
      </div>
    );
  } else if (props.data.indices[0] == 4) {
    contents = (
      <div style={{ lineHeight: "30px" }} className={css.text}>
        Try again! Be sure to use the <Italic>x</Italic>- and <Italic>y</Italic>
        -coordinates correctly when using the slope formula: <Italic>m</Italic>{" "}
        ={" "}
        <Fraction
          width="60px"
          numerator={
            <span>
              <Italic>y</Italic>2 - <Italic>y</Italic>1
            </span>
          }
          denominator={
            <span>
              <Italic>x</Italic>2 - <Italic>x</Italic>1
            </span>
          }
        />
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
