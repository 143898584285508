import React from "react";
import css from "./Button.module.css";

const Button = (props) => {
  const clickHandler = () => {
    if (props.onClick) props.onClick();
  };
  return (
    <div
      onMouseDown={clickHandler}
      className={css.root + (props.selected ? " " + css.selected : "")}
    >
      <div className={css.background}>
        <div className={css.icon}>
          <img style={props.imgStyle} src={props.image} alt="icon" />
        </div>
      </div>
      <div className={css.text}>{props.text}</div>
    </div>
  );
};

export default Button;
