import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";

const lines = {
  title: {
    en: "Area of Rectangles",
    sp: "Área de los rectángulos",
  },
  0: {
    en: "In this lesson, you will ...",
    sp: "Lo que harás en esta lección:",
  },
  1: {
    en: "Find the area of a rectangle by tiling.",
    sp: "Utilizar fichas cuadradas para hallar el área de un rectángulo.",
  },
  2: {
    en: "Find the area of a rectangle by multiplying side lengths.",
    sp: "Multiplicar longitudes laterales para hallar el área de un rectángulo.",
  },
  3: {
    en: "Show that the area of a rectangle is the same using tiling or multiplying side lengths.",
    sp: "Demostrar que el área de un rectángulo es igual si se utilizan fichas cuadradas o se multiplican las longitudes laterales.",
  },
  4: {
    en: "Click or tap the photo to learn more about the American flag.",
    sp: "Pulsa o haz clic en la foto para aprender más sobre la bandera de los Estados Unidos.",
  },
  5: {
    en: "Gastonia, North Carolina, has the largest flying American flag.",
    sp: "En Gastonia, Carolina del Norte, ondea la bandera más grande de los Estados Unidos.",
  },
  6: {
    en: "The flag is 114 feet wide by 65 feet tall with an area of 7,410 square feet.",
    sp: "La bandera mide 114 pies de ancho por 65 pies de alto y su área mide 7,410 pies cuadrados.",
  },
  7: {
    en: "Each stripe is 5 feet tall!",
    sp: "¡Cada barra mide 5 pies de altura!",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>{languageLookup(0)}</span>
          <BulletPoints>
            <li>{languageLookup(1)}</li>
            <li>{languageLookup(2)}</li>
            <li>{languageLookup(3)}</li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>{languageLookup(4)}</Instructions>
          <FlipBox backPadding="20px" width="400px" height="275px">
            <img src="https://d16qzots3do3wk.cloudfront.net/images/c3de2c86e95dbb2ad803a9462dded1b4.webp" />
            <div>
              <div className={css.detail}>{languageLookup(5)}</div>
              <div className={css.detail}>{languageLookup(6)}</div>
              <div className={css.detail}>{languageLookup(7)}</div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
