import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        The main characters in both “The Most Amazing Man on Earth” and “The
        Furry Trout” are able to find the solution to almost any problem, but
        they find solutions for different reasons. What theme is common to BOTH
        texts? What theme applies to only one or the other text?
      </div>
      <div className={css.instructions}>
        Drag each theme to the correct spot. Click or tap the Reset button to
        start over.
      </div>
    </div>
  );
};

export default Sidebar;
