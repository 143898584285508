import React, { useState } from "react";
import { K2Frame } from "../K2Frame";
import { K2ELA1 } from "./ela-1/K2ELA1";

import { K2Math1 } from "./math-1/K2Math1";

const lessons = {
  math: [null, K2Math1],
  ela: [null, K2ELA1],
};

export const K2Lessons = ({ subject, grade }) => {
  const Lesson = lessons[subject][grade];
  const [progress, setProgress] = useState(0);
  return (
    <K2Frame progress={progress} subject={subject} grade={grade}>
      <Lesson subject={subject} grade={grade} setProgress={setProgress} />
    </K2Frame>
  );
};
