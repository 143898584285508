import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Remember . . .</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>
            Writers use precise language to communicate clearly. Precise means
            to express an idea specifically and accurately.{" "}
          </li>
          <li>
            Precise language helps to show emotions. Words and phrases with
            similar meanings can show different things.
          </li>
          <li>
            You can make your writing more precise by using specific words and
            sensory details. Specific words refer to exact things, and sensory
            details draw on the five senses—sight, smell, touch, hearing, and
            taste.
          </li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
