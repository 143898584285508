import React from "react";
import Italic from "../../../../../Italic";
import css from "./Shape.module.css";

const contents = [
  <div>
    The <Italic>Atocha</Italic> sank in a hurricane off the coast of Florida.
  </div>,
  <div>
    In 1985, a team led by Mel Fisher found the location where the{" "}
    <Italic>Atocha</Italic> sank.
  </div>,
  <div>
    The <Italic>Atocha</Italic> was full of gold, silver, coins, tobacco, and
    jewels.
  </div>,
];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
    justifyContent: props.center ? "center" : null,
    textAlign: props.center ? "center" : null,
    color: props.blue ? "#1588c9" : null,
    height: props.height || null,
    width: props.width || null,
  };

  let content = contents[props.index];
  return (
    <div className={css.root} style={style}>
      <div
        className={css.contents}
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        style={props.innerStyle}
      >
        {content}
      </div>
    </div>
  );
};

export default Shape;
