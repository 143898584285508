export default (data, setData) => {};

export const correctAnswers = [
  [2, 3],
  [1, 4],
  [0, 5],
];

export const isCorrect = (data) => {
  if (data.left[0].length != correctAnswers[0].length) return false;

  if (data.left[1].length != correctAnswers[1].length) return false;

  if (data.left[2].length != correctAnswers[2].length) return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      const answer = correctAnswers[i][j];
      if (!data.left[i].find((x) => x.id == answer)) return false;
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.right.filter((x) => x == -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [[], [], []],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (!isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "An angle can be classified as acute, right, or obtuse depending on how its measure compares to 90°. ",
  "A right angle forms a square corner and has a measure of exactly 90°. ",
  "The measure of an acute angle is greater than 0°, but less than 90°. So, an acute angle is smaller than a right angle.",
  "The measure of an obtuse angle is greater than 90°, but less than 180°. So, an obtuse angle is larger than a right angle.",
];

export const showReader = () => {
  return false;
};
