import intro_G1_010A1_math_vo_en from "./assets/vo/math-1/intro_G1_010A1_math_vo_en-US.mp3";
//Let's learn to count back to subtract.
import instruction_G1_010A2_math_vo_en from "./assets/vo/math-1/instruction_G1_010A2_math_vo_en-US.mp3";
//Subtract 6 – 2. Count back 2 to find the difference. Tap the difference.
import hint1_G1_010A2_math_vo_en from "./assets/vo/math-1/hint1_G1_010A2_math_vo_en-US.mp3";
//Start at 6. Count back 2. After the first jump back, say 5. Jump back 1 more time.
import dd1error1_G1_010A2_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010A2_math_vo_en-US.mp3";
//Try again. When you count back, the number gets smaller.
import dd1error1_G1_010A4_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010A4_math_vo_en-US.mp3";
//Try again. When you count back, the number gets smaller.
import dd1error1_G1_010B2_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010B2_math_vo_en-US.mp3";
//Try again. When you count back, the number gets smaller.
import instruction_G1_010A3_math_vo_en from "./assets/vo/math-1/instruction_G1_010A3_math_vo_en-US.mp3";
//Subtract 9 – 3. Count back 3 to find the difference. Tap the difference.
import hint1_G1_010A3_math_vo_en from "./assets/vo/math-1/hint1_G1_010A3_math_vo_en-US.mp3";
//Start at 9. Count back 3. After the first jump back, say 8. Jump back 2 more times.
import dd1error1_G1_010B3_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010B3_math_vo_en-US.mp3";
//Try again. Subtract 2 from 8. What number do you land on?
import dd1error1_G1_010A3_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010A3_math_vo_en-US.mp3";
//Try again. Count back 3. Start at 9. Ready? 8, 7, 6.
import dd1error1_G1_010B4_math_vo_en from "./assets/vo/math-1/dd1error1_G1_010B4_math_vo_en-US.mp3";
//Try again. When you jump back, the number gets smaller.
import instruction_G1_010A4_math_vo_en from "./assets/vo/math-1/instruction_G1_010A4_math_vo_en-US.mp3";
//Count back to subtract 11 – 1. Tap the difference.
import dd1error2_G1_010A2_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010A2_math_vo_en-US.mp3";
//Try again. Count back 2. Start at 6. Ready? 5, 4.
import dd1error2_G1_010A3_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010A3_math_vo_en-US.mp3";
//Try again. When you count back, the number gets smaller.
import dd1error2_G1_010A4_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010A4_math_vo_en-US.mp3";
//Try again. Start at 11. Count back 1. What number comes before 11?
import dz2error2_G1_010C1_math_vo_en from "./assets/vo/math-1/dz2error2_G1_010C1_math_vo_en-US.mp3";
//Try again. This number line shows 3 jumps. 5 – 2 = 3 should show 2 jumps.
import dz2error2_G1_010C2_math_vo_en from "./assets/vo/math-1/dz2error2_G1_010C2_math_vo_en-US.mp3";
//Try again. This number line shows 1 jump. 4 – 3 = 1 should show 3 jumps.
import instruction_G1_010B2_math_vo_en from "./assets/vo/math-1/instruction_G1_010B2_math_vo_en-US.mp3";
//Now, count back to subtract 7 minus 3. Tap the difference.
import dz3error1_G1_010C1_math_vo_en from "./assets/vo/math-1/dz3error1_G1_010C1_math_vo_en-US.mp3";
//Try again. This number line shows 1 jump. Which equation can you solve by counting back 1?
import dz3error1_G1_010C2_math_vo_en from "./assets/vo/math-1/dz3error1_G1_010C2_math_vo_en-US.mp3";
//Try again. This number line shows 2 jumps. Which equation can you solve by counting back 2?
import dz3error2_G1_010C1_math_vo_en from "./assets/vo/math-1/dz3error2_G1_010C1_math_vo_en-US.mp3";
//Try again. This number line shows 1 jump. 5 – 3 = 2 should show 3 jumps.
import dd1error2_G1_010B2_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010B2_math_vo_en-US.mp3";
//Try again. Count back 3. Start at 7. Ready? 6, 5, 4.
import instruction_G1_010B3_math_vo_en from "./assets/vo/math-1/instruction_G1_010B3_math_vo_en-US.mp3";
//Count back to subtract 8 minus 2. Start at 8. Count back 2. The number you land on is the difference. Tap the difference.
import hint1_G1_010B3_math_vo_en from "./assets/vo/math-1/hint1_G1_010B3_math_vo_en-US.mp3";
//After the first jump back, say 7. Jump back 1 more time.
import dz3error2_G1_010C2_math_vo_en from "./assets/vo/math-1/dz3error2_G1_010C2_math_vo_en-US.mp3";
//Try again. This number line shows 2 jumps. 4 – 3 = 1 should show 3 jumps.
import hint1_G1_010A4_math_vo_en from "./assets/vo/math-1/hint1_G1_010A4_math_vo_en-US.mp3";
//Start at 11. Count back 1.
import dd1error2_G1_010B3_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010B3_math_vo_en-US.mp3";
//Try again. Count back 2. Start at 8. Ready? 7, 6.
import instruction_G1_010B4_math_vo_en from "./assets/vo/math-1/instruction_G1_010B4_math_vo_en-US.mp3";
//Now, count back to subtract 8 – 3. Tap the difference.
import hint1_G1_010B2_math_vo_en from "./assets/vo/math-1/hint1_G1_010B2_math_vo_en-US.mp3";
//Start at 7. Jump back 3 times.
import hint1_G1_010B4_math_vo_en from "./assets/vo/math-1/hint1_G1_010B4_math_vo_en-US.mp3";
//Start at 8. Jump back 3 times.
import hint2_G1_010A2_math_vo_en from "./assets/vo/math-1/hint2_G1_010A2_math_vo_en-US.mp3";
//The number you land on is the difference.
import dd1error2_G1_010B4_math_vo_en from "./assets/vo/math-1/dd1error2_G1_010B4_math_vo_en-US.mp3";
//Try again. Count back 3. Start at 8. Ready? 7, 6, 5.
import hint2_G1_010A3_math_vo_en from "./assets/vo/math-1/hint2_G1_010A3_math_vo_en-US.mp3";
//The number you land on is the difference.
import hint1_G1_010C1_math_vo_en from "./assets/vo/math-1/hint1_G1_010C1_math_vo_en-US.mp3";
//The number you subtract is shown by the number of jumps.
import hint2_G1_010A4_math_vo_en from "./assets/vo/math-1/hint2_G1_010A4_math_vo_en-US.mp3";
//The last number you say is the difference.
import dz1error1_G1_010C1_math_vo_en from "./assets/vo/math-1/dz1error1_G1_010C1_math_vo_en-US.mp3";
//Try again. The top number line shows 2 jumps. 5 – 1 = 4 should show 1 jump.
import dz1error2_G1_010C1_math_vo_en from "./assets/vo/math-1/dz1error2_G1_010C1_math_vo_en-US.mp3";
//Try again. The top number line shows 2 jumps. 5 – 3 = 2 should show 3 jumps.
import dz2error1_G1_010C1_math_vo_en from "./assets/vo/math-1/dz2error1_G1_010C1_math_vo_en-US.mp3";
//Try again. The middle number line shows 3 jumps. 5 – 1 = 4 should show 1 jump.
import hint2_G1_010B2_math_vo_en from "./assets/vo/math-1/hint2_G1_010B2_math_vo_en-US.mp3";
//The number you land on is the difference.
import hint2_G1_010B3_math_vo_en from "./assets/vo/math-1/hint2_G1_010B3_math_vo_en-US.mp3";
//What number does the last arrow land on?
import hint2_G1_010B4_math_vo_en from "./assets/vo/math-1/hint2_G1_010B4_math_vo_en-US.mp3";
//The number you land on is the difference.
import hint2_G1_010C1_math_vo_en from "./assets/vo/math-1/hint2_G1_010C1_math_vo_en-US.mp3";
//The number you land on is the difference.
import hint1_G1_010C2_math_vo_en from "./assets/vo/math-1/hint1_G1_010C2_math_vo_en-US.mp3";
//The number you subtract is shown by the number of jumps.
import hint2_G1_010C2_math_vo_en from "./assets/vo/math-1/hint2_G1_010C2_math_vo_en-US.mp3";
//The number you land on is the difference.
import dz1error1_G1_010C2_math_vo_en from "./assets/vo/math-1/dz1error1_G1_010C2_math_vo_en-US.mp3";
//Try again. The top number line shows 3 jumps. 4 – 2 = 2 should show 2 jumps.
import dz1error2_G1_010C2_math_vo_en from "./assets/vo/math-1/dz1error2_G1_010C2_math_vo_en-US.mp3";
//Try again. The top number line shows 3 jumps. 4 – 1 = 3 should show 1 jump.
import dz2error1_G1_010C2_math_vo_en from "./assets/vo/math-1/dz2error1_G1_010C2_math_vo_en-US.mp3";
//Try again. The middle number line shows 1 jump. 4 – 2 = 2 should show 2 jumps.
import instruction_G1_010B1_math_vo_en from "./assets/vo/math-1/instruction_G1_010B1_math_vo_en-US.mp3";
//Count back to subtract 7 – 2. Start at 7. Count back 1. 7 – 1 = 6. Then, count back one more. 7 – 2 = 5.
import instruction_G1_010C1_math_vo_en from "./assets/vo/math-1/instruction_G1_010C1_math_vo_en-US.mp3";
//Put each equation next to the number line that shows the problem.
import instruction_G1_010C2_math_vo_en from "./assets/vo/math-1/instruction_G1_010C2_math_vo_en-US.mp3";
//Put each equation next to the number line that shows the problem.
import instruction_G1_010D1_math_vo_en from "./assets/vo/math-1/instruction_G1_010D1_math_vo_en-US.mp3";
//Count back to subtract 6 – 1. Tap the difference.
import instruction_G1_010D2_math_vo_en from "./assets/vo/math-1/instruction_G1_010D2_math_vo_en-US.mp3";
//Count back to subtract 10 – 3. Tap the difference.
import instruction_G1_010D3_math_vo_en from "./assets/vo/math-1/instruction_G1_010D3_math_vo_en-US.mp3";
//Count back to subtract 12 – 2. Tap the difference.
import ks_l0_math_vo_en from "./assets/vo/math-1/ks_l0_math_vo_en.mp3";
// Look at the correct answer
import hint1_l0_math_vo_en from "./assets/vo/math-1/hint1_l0_math_vo_en.mp3";

export default {
  intro_G1_010A1_math_vo_en,
  instruction_G1_010A2_math_vo_en,
  hint1_G1_010A2_math_vo_en,
  dd1error1_G1_010A2_math_vo_en,
  dd1error1_G1_010A4_math_vo_en,
  dd1error1_G1_010B2_math_vo_en,
  instruction_G1_010A3_math_vo_en,
  hint1_G1_010A3_math_vo_en,
  dd1error1_G1_010B3_math_vo_en,
  dd1error1_G1_010A3_math_vo_en,
  dd1error1_G1_010B4_math_vo_en,
  instruction_G1_010A4_math_vo_en,
  dd1error2_G1_010A2_math_vo_en,
  dd1error2_G1_010A3_math_vo_en,
  dd1error2_G1_010A4_math_vo_en,
  dz2error2_G1_010C1_math_vo_en,
  dz2error2_G1_010C2_math_vo_en,
  instruction_G1_010B2_math_vo_en,
  dz3error1_G1_010C1_math_vo_en,
  dz3error1_G1_010C2_math_vo_en,
  dz3error2_G1_010C1_math_vo_en,
  dd1error2_G1_010B2_math_vo_en,
  instruction_G1_010B3_math_vo_en,
  hint1_G1_010B3_math_vo_en,
  dz3error2_G1_010C2_math_vo_en,
  hint1_G1_010A4_math_vo_en,
  dd1error2_G1_010B3_math_vo_en,
  instruction_G1_010B4_math_vo_en,
  hint1_G1_010B2_math_vo_en,
  hint1_G1_010B4_math_vo_en,
  hint2_G1_010A2_math_vo_en,
  dd1error2_G1_010B4_math_vo_en,
  hint2_G1_010A3_math_vo_en,
  hint1_G1_010C1_math_vo_en,
  hint2_G1_010A4_math_vo_en,
  dz1error1_G1_010C1_math_vo_en,
  dz1error2_G1_010C1_math_vo_en,
  dz2error1_G1_010C1_math_vo_en,
  hint2_G1_010B2_math_vo_en,
  hint2_G1_010B3_math_vo_en,
  hint2_G1_010B4_math_vo_en,
  hint2_G1_010C1_math_vo_en,
  hint1_G1_010C2_math_vo_en,
  hint2_G1_010C2_math_vo_en,
  dz1error1_G1_010C2_math_vo_en,
  dz1error2_G1_010C2_math_vo_en,
  dz2error1_G1_010C2_math_vo_en,
  instruction_G1_010B1_math_vo_en,
  instruction_G1_010C1_math_vo_en,
  instruction_G1_010C2_math_vo_en,
  instruction_G1_010D1_math_vo_en,
  instruction_G1_010D2_math_vo_en,
  instruction_G1_010D3_math_vo_en,
  ks_l0_math_vo_en,
  hint1_l0_math_vo_en,
};
