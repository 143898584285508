import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";
import { Fragment } from "react";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Define a Proportional Relationship",
    sp: "Definir una relación proporcional",
  },
  1: {
    en: (
      <Fragment>
        A <Bold>proportion</Bold> is a statement that two ratios or rates are
        equivalent.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Una <Bold>proporción</Bold> es un enunciado que establece que dos
        razones o tasas son equivalentes.
      </Fragment>
    ),
  },
  2: {
    en: "For example:",
    sp: "Por ejemplo:",
  },
  3: {
    en: "or",
    sp: "o",
  },
  4: {
    en: (
      <Fragment>
        A <Bold>proportional relationship</Bold> between two quantities is one
        in which the ratio of one quantity to the other is constant.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Una <Bold>relación proporcional</Bold> entre dos cantidades es aquella
        en la que la razón de una cantidad a la otra es constante.
      </Fragment>
    ),
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: (
      <Fragment>
        The ratios <Fraction numerator="1" denominator="2" />,{" "}
        <Fraction numerator="2" denominator="4" />, and{" "}
        <Fraction width="30px" numerator="5" denominator="10" /> show a constant
        relationship because each ratio can be simplified to{" "}
        <Fraction numerator="1" denominator="2" />.
      </Fragment>
    ),
    sp: (
      <Fragment>
        Las razones <Fraction numerator="1" denominator="2" />,{" "}
        <Fraction numerator="2" denominator="4" />, y{" "}
        <Fraction width="30px" numerator="5" denominator="10" /> muestran una
        relación constante porque cada razón puede simplificarse a{" "}
        <Fraction numerator="1" denominator="2" />.
      </Fragment>
    ),
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>{languageLookup(1)}</div>
          <div>{languageLookup(2)}</div>
        </div>
        <Center>
          <div className={css.row}>
            <Fraction width="75px" numerator="6 miles" denominator="2 hours" />
            <span> = </span>
            <Fraction width="75px" numerator="3 miles" denominator="1 hours" />
            <span> {languageLookup(3)} </span>
            <Fraction numerator="6" denominator="2" />
            <span> = </span>
            <Fraction numerator="3" denominator="1" />
          </div>
        </Center>
        <div>{languageLookup(4)}</div>
        <div style={{ lineHeight: "40px" }}>{languageLookup(7)}</div>
        <Center>
          <div className={css.row}>
            <Fraction numerator="2" denominator="4" />
            <span> = </span>
            <Fraction width="50px" numerator="2÷2" denominator="4÷2" />
            <span> = </span>
            <Fraction numerator="1" denominator="2" />
          </div>
        </Center>
        <Center>
          <div className={css.row}>
            <Fraction width="30px" numerator="5" denominator="10" />
            <span> = </span>
            <Fraction width="50px" numerator="5÷5" denominator="10÷5" />
            <span> = </span>
            <Fraction numerator="1" denominator="2" />
          </div>
        </Center>
      </div>
    </div>
  );
};

export default Contents;
