import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [2, 0, 1, -1];

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1, -1],
    showLeft: [false, false, false, false],
    right: [0, 1, 2, 3],
    showRight: [true, true, true, true],
  };
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.left.length; i++) {
    if (data.left[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Reread paragraph 3 to find the information that explains what happens when a
    train of roller-coaster cars climbs a hill, reaches the top a hill, and
    approaches the bottom of a hill.
  </div>,
  <div>
    Paragraph 3 does not specifically explain what happens when roller-coaster
    cars come to a complete stop, so apply what you know about potential and
    kinetic energy to determine the effect.
  </div>,
  <div>
    Remember that mechanical energy is constant, so these causes have no effect
    on it. Focus on potential and kinetic energy.
  </div>,
];

export const showReader = () => {
  return true;
};
