import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Bold from "../../../../Bold";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Understand Domain-Specific Language</span>
      </div>
      <div className={css.contents}>
        <div>
          In an average day, how many words do you think you use? Believe it or
          not, some experts estimate that the number is between 1,500 and 2,000
          words! The more words you know, the better you will understand
          language in different content areas. Other words for{" "}
          <Italic>content areas</Italic> are: subjects or <Bold>domains</Bold>.
        </div>
        <div>
          Think about the subjects you study in school: math, science, language
          arts, and history, for example. Each subject is a different domain. To
          understand each subject, you need to understand the jargon or
          technical terms specific to each domain. Another word for{" "}
          <Italic>jargon</Italic> or <Italic>technical terms</Italic> is:{" "}
          <Bold>domain-specific language</Bold>.
        </div>
        <div>
          One of the best ways to figure out the meaning of domain-specific
          words is to use <Bold>context clues</Bold>. These are words and
          phrases in the surrounding text that give you hints about an
          unfamiliar term.
        </div>
      </div>
    </div>
  );
};

export default Contents;
