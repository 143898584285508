import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_5_1_reader_a.JPG";
import ImageB from "../Assets/ela_5_1_reader_b.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Mermaid or Manatee?</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        In warm waters on both sides of the Atlantic Ocean roams an animal
        called the sea cow. Like cows on land, sea cows are mammals. They may
        grow longer than eight feet and weigh more than half a ton. They graze
        on underwater plants. Yet unlike land cows, which live in herds, sea
        cows are semi-social creatures. They form temporary groups and then
        disperse, using their large flippers and paddle-like tails to propel
        their bodies through the water.
      </Paragraph>
      <Paragraph index="2">
        As you may have guessed, sea cows are not really cows. "Sea cow" is
        another name for the manatee. Some species of manatee live off the coast
        of West Africa. Others live in the Caribbean Sea and the Gulf of Mexico.
        Some even live in rivers in Mexico and Central and South America.
        Throughout history, sailors in these places may have seen manatees and
        believed they were seeing very different creatures. Manatees may be the
        basis for stories about mermaids.
      </Paragraph>
      <Paragraph index="3">
        Up close, it is hard to see how a manatee could be confused with a
        mermaid. A mermaid is a mythical being with the head, arms, and upper
        body of a woman and the tail of a large fish. In contrast, manatees have
        gray or brown skin and round, wrinkled bodies. Yet seen from a distance
        or through several feet of water, a manatee may indeed resemble a human
        with a fish's tail. As a manatee moves through the water, its motion may
        suggest a human swimmer. As a manatee grazes, the thin strands of sea
        grass may suggest flowing hair.
      </Paragraph>
      <Paragraph index="4">
        Sightings of mermaids have been reported in Europe and Asia for more
        than three thousand years. In one story from{" "}
        <Italic>One Thousand and One Nights</Italic>, mermaids lived under the
        sea, looked just like humans, and could breathe underwater. In some
        myths, mermaids married humans and even shared their secrets with
        humankind. In other tales, however, mermaids were different. They led
        ships off course. They tempted sailors to join them in the water below,
        causing them to drown. In Homer's <Italic>Odyssey</Italic>,
        sirens'beings similar to mermaids' used their beautiful singing to lure
        sailors to steer toward rocks and wreck their ships.
      </Paragraph>
      <Paragraph index="5">
        The exploration of the Americas by European adventurers in the fifteenth
        and sixteenth centuries led to new mermaid sightings. Christopher
        Columbus wrote that he saw three mermaids swimming in the Caribbean Sea
        in 1493. According to Columbus, the mermaids were not as beautiful as
        those he had seen in paintings. Most sailors considered mermaids bad
        luck. The famous pirate Blackbeard is said to have avoided all parts of
        the sea where mermaids might live.
      </Paragraph>
      <Paragraph index="6">
        Not all "mermaid" sightings took place long ago. Supposedly in the
        1940s, the fishers of the Kei Islands in Indonesia told Japanese
        soldiers that they often caught half-human, half-fish creatures in their
        nets. One soldier reportedly found one of these creatures and then tried
        to bring it back to Japan, but without success. In 2012, workers
        building reservoirs in Zimbabwe, a country in Africa, claimed to have
        found mermaids in the water. The people were so frightened that they
        refused to continue working on the construction projects.
      </Paragraph>
      <Paragraph index="7">
        These sightings throughout history are fascinating, but are they fact or
        fiction? When Columbus reported seeing three mermaids more than five
        hundred years ago, did he really see manatees? Is it possible that the
        creatures that scared away the workers in Zimbabwe were just large sea
        mammals? There is no way to know for certain, and so people around the
        world will probably continue to tell mermaid tales.
      </Paragraph>
      <div className={css.line} />
      <div className={css.title}>Africa's Unicorn</div>
      <Paragraph index="1">
        Imagine you are a European explorer during the nineteenth century,
        trekking through the rain forest of the African Congo. Stopping by a
        riverbank, you spot a four-legged animal on the other side of the river.
        The animal cranes its neck to graze on the leaves that hang from a
        nearby branch. It darts off quickly after sensing your presence. But
        before it leaves, you notice a few things about this animal. It has the
        black and white stripes of a zebra. Its long, black tongue resembles
        that of a giraffe. And on top of its head, it has two small horns. Or
        does it have only one? It moved so quickly, it is difficult to know for
        sure.
      </Paragraph>
      <Paragraph index="2">
        The animal you just saw is called an okapi, a member of the giraffe
        family that also partially resembles the zebra. When viewed from a
        certain angle, the okapi's two horns appear to be only one horn. This is
        why many explorers believed the okapi was a unicorn. Even today, the
        animal is sometimes referred to as Africa's unicorn.
      </Paragraph>
      <div className={css.image}>
        <img src={ImageB} alt="image" />
      </div>
      <div className={css.caption}>
        How many horns can you see on this okapi?
      </div>
      <Paragraph index="3">
        The people of the Congo region were already very familiar with the
        okapi. But to foreign explorers during the nineteenth century, the okapi
        was like nothing they had ever seen. In 1890, the British explorer and
        writer Sir Henry Morton Stanley reported spotting an okapi, which he
        described as a type of donkey. Ten years later, Sir Harry Johnston, the
        high commissioner of Uganda, began a search for the okapi. After
        Johnston sent pieces of okapi skin to the British Museum, it was
        incorrectly identified as a type of zebra. When a full skeleton was
        finally obtained, scientists were able to determine the okapi is related
        to the giraffe. By 1909, explorers had begun the search for living okapi
        to display in zoos.
      </Paragraph>
      <Paragraph index="4">
        But the okapi proved very difficult to find in its natural habitat. Its
        shy nature makes it difficult to track and study even using modern
        technology. If you wish to see an okapi today, you'll have the best luck
        visiting one of several zoos that have okapi specimens. Some, such as
        the San Diego Zoo, have successfully bred okapi.
      </Paragraph>
      <Paragraph index="5">
        While scientists are able to study okapi in captivity, little is known
        about their lives in the wild. The first photograph of an okapi in its
        natural habitat was not taken until 2008, more than one hundred years
        after the animal was first seen by Europeans. The photograph proves that
        the okapi still roam the rain forests of Africa despite threats to their
        populations.
      </Paragraph>
      <Paragraph index="6">
        The okapi is an endangered animal. Only about 10,000 to 35,000 wild
        okapi remain in Africa. Hunting, destruction of the okapi's environment,
        and civil wars contribute to its decline in numbers. As a result, the
        creature has received government protection since 1933. Conservation
        groups and wildlife reserves have been established to help educate the
        public and to work to protect the remaining okapi population.
      </Paragraph>
      <Paragraph index="7">
        Based on the photograph of an okapi, you can see why some people might
        have confused it with a zebra. But can you see why some people might
        have believed it was a unicorn? From any perspective, this rare creature
        is without a doubt unlike any other on Earth.
      </Paragraph>
    </div>
  );
};

export default Reader;
