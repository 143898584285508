import React from "react";
import css from "./Sidebar.module.css";

import SmallBox from "../Assets/math_2_2_box1.png";
import MediumBox from "../Assets/math_2_2_box10.png";
import LargeBox from "../Assets/math_2_2_box100.png";

const Sidebar = (props) => {
  const dragStartHandler = (ev, index) => {
    ev.dataTransfer.setData("text/plain", index);
    ev.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        How many hundreds, tens, and ones are in 548?
      </div>
      <div className={css.title}>Use models to show the number.</div>
      <div className={css.instructions}>
        Drag the models to the number. Click or tap the Reset button to start
        over.
      </div>
      <div className={css.items}>
        <div
          className={css.small}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 2)}
        >
          <img src={SmallBox} alt="1" />
        </div>
        <div
          className={css.medium}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 1)}
        >
          <img src={MediumBox} alt="10" />
        </div>
        <div
          className={css.large}
          draggable
          onDragStart={(ev) => dragStartHandler(ev, 0)}
        >
          <img src={LargeBox} alt="100" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
