import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";
import Bold from "../../../../Bold";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use Fractions to Write Subtraction Sentences
        </span>
      </div>
      <div className={css.contents}>
        <div>You can use fractions to write subtraction sentences.</div>
        <div>
          <div>
            There is 7 <Bold>eighths</Bold> of a pizza in the refrigerator. Cari
            eats 3 <Bold>eighths</Bold> of the pizza.
          </div>
          <div>How many eighths does she have left?</div>
        </div>
        <Center>
          <span>
            7 <Bold> eighths</Bold> − 3 <Bold> eighths</Bold> = 4{" "}
            <Bold> eighths</Bold>
          </span>
        </Center>
        <Center>
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            numerator="7"
            denominator="8"
          />{" "}
          −{" "}
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            numerator="3"
            denominator="8"
          />{" "}
          ={" "}
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            numerator="4"
            denominator="8"
          />
        </Center>
        <div>
          <div>
            8 <Bold>tenths</Bold> of an orange. He gives his friends 6{" "}
            <Bold>tenths</Bold> of the orange.
          </div>
          <div>How many tenths does he have now?</div>
        </div>
        <Center>
          <span>
            8 <Bold> tenths</Bold> − 6 <Bold> tenths</Bold> = 2{" "}
            <Bold> tenths</Bold>
          </span>
        </Center>
        <Center>
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            width="30px"
            numerator="8"
            denominator="10"
          />{" "}
          −{" "}
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            width="30px"
            numerator="6"
            denominator="10"
          />{" "}
          ={" "}
          <Fraction
            verticalStyle={{ marginTop: "-4px" }}
            width="25px"
            numerator="2"
            denominator="10"
          />
        </Center>
        <div>
          When the denominators are the same, you can subtract the parts the
          same way you would in any subtraction problem!
        </div>
      </div>
    </div>
  );
};

export default Contents;
