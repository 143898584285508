import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Identify Implied Main Ideas",
    sp: "Identifica ideas principales implícitas",
  },
  1: {
    en: "Other times, the main idea isn't stated directly but implied. In such a case, look at the title, subheadings, and all the supporting details. Ask yourself: What do the details add up to?",
    sp: "Otras veces, la idea principal no aparece explícitamente, sino que es implícita. En esos casos, observa el título, los subtítulos y todos los detalles de apoyo. Pregúntate: ¿a qué apuntan los detalles?",
  },
  2: {
    en: "Read the passage. Then answer the question.",
    sp: "Lee el fragmento. Luego, contesta la pregunta.",
  },
  3: {
    en: "Rediscovering an Old Art",
    sp: "Redescubrir un arte antiguo",
  },
  4: {
    en: "These days, learning to quilt is not often passed down from one generation to the next. Some people, though, have taken a new interest in quilting. Many learn to quilt through books and classes. People are discovering what those from past generations knew—that quilting can be difficult but is worth the effort.",
    sp: "En la actualidad, aprender a hacer una colcha de retazos ya no es algo que se transmita de generación en generación. Sin embargo, hay personas que han comenzado a interesarse en hacerlas nuevamente. Muchas aprenden leyendo libros y tomando clases. Estas personas están descubriendo lo que las generaciones pasadas ya sabían: que hacer colchas de retazos puede ser difícil, pero vale la pena.",
  },
  5: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/338f320eb8819fba8be01af587a90e96.webp",
    sp: "https://d16qzots3do3wk.cloudfront.net/images/338f320eb8819fba8be01af587a90e96.webp",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <div className={css.instructions}>{languageLookup(2)}</div>
        <div className={css.title}>{languageLookup(3)}</div>
        <div className={css.details}>{languageLookup(4)}</div>
        <img className={css.image} src={`${languageLookup(5)}`} />
      </div>
    </div>
  );
};

export default Contents;
