import React, { Fragment } from "react";
import css from "./Sidebar.module.css";

import Center from "../../../../Center";
import Underline from "../../../../Underline";

import SpanishB from "../Assets/spanish_b.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "You can also plot the ordered pairs and draw a line to connect them. The graph of a proportional relationship is a straight line that passes through the origin, (0, 0).",
    sp: "También puedes marcar los pares ordenados y trazar una recta para conectarlos. La gráfica de una relación proporcional es una línea recta que pasa por el origen: (0, 0).",
  },
  1: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/47cfbe0fe36b8c1ea2e75ae3b80e9f7c.svg",
    sp: SpanishB,
  },
  2: {
    en: "This graph shows that the relationship is proportional.",
    sp: "Esta gráfica muestra que la relación es proporcional.",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div>{languageLookup(0)}</div>
      <Center>
        <img src={languageLookup(1)} width="80%" />
      </Center>
      <div>{languageLookup(2)}</div>
    </div>
  );
};

export default Sidebar;
