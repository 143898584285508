import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";
import Bold from "../../../../Bold";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Subtraction Sentence to Subtract Fractions
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Xu’s mom made a sandwich cut in four equal pieces. She put{" "}
          <Fraction numerator="3" denominator="4" /> of the sandwich in Xu’s
          lunchbox. He eats <Fraction numerator="2" denominator="4" /> of his
          sandwich. How much sandwich does Xu have left in his lunchbox?
        </div>
        <div>
          You can also write a subtraction sentence to find the difference.
        </div>
        <Center>
          <span>
            <Fraction numerator="3" denominator="4" /> −{" "}
            <Fraction numerator="2" denominator="4" /> = ?
          </span>
        </Center>
        <div>
          Since the denominators are the same, you can subtract the numerators.
          Keep the same denominator, because the size of the parts does not
          change.
        </div>
        <Center>
          <span>
            <Fraction numerator="3" denominator="4" /> −{" "}
            <Fraction numerator="2" denominator="4" /> ={" "}
            <Fraction numerator="?" denominator="4" />
          </span>
        </Center>
        <Center>
          <span>
            <Fraction numerator="3" denominator="4" /> −{" "}
            <Fraction numerator="2" denominator="4" /> ={" "}
            <Fraction numerator="1" denominator="4" />
          </span>
        </Center>
        <div>
          <span>
            Xu has <Fraction numerator="1" denominator="4" /> of a sandwich
            left.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
