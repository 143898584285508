import React from "react";
import Bold from "../../../../Bold";

export default (data, setData) => {};

export const correctAnswers = { selected: 3 };

export const isEmpty = (data) => {
  return data.selected == -1;
};

export const isCorrect = (data) => {
  return data.selected == correctAnswers.selected;
};

export const getDefaultState = () => {
  return {
    selected: -1,
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    The prompt asks you to find the text feature that tells you about one
    special arch, not all of the arches. You are looking for information about
    one arch in particular.
  </div>,
  <div>
    Text features are special parts of text that make it easier for you to find
    and understand information. Text features include bold print, captions,
    subheads, and glossaries.
  </div>,
  <div>
    <div>
      A <Bold>subhead</Bold> is a title for a section of text.
    </div>
    <div>
      <Bold>Bold print</Bold> is darker than the text around it. Important or
      difficult words are often in bold print.
    </div>
    <div>
      A <Bold>caption</Bold> is a phrase or sentence that tells you more about a
      drawing or photograph.
    </div>
    <div>
      A <Bold>glossary</Bold> gives you the meanings of important or difficult
      words.
    </div>
  </div>,
  <div>
    Sometimes you can find details about a topic in text features. These details
    may not be found in the main text.
  </div>,
  <div>
    Find the text features. Which feature tells you about one special arch found
    at Arches National Park?
  </div>,
];

export const showReader = () => {
  return true;
};
