import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Which details would support the main idea of this passage? There is more than one correct answer.",
    sp: "¿Qué detalles apoyarían la idea principal de este fragmento? Hay más de una respuesta correcta.",
  },
  1: {
    en: "Click or tap each correct answer.",
    sp: "Pulsa o haz clic en todas las respuestas correctas.",
  },
  2: {
    en: "While flu symptoms can hit suddenly, cold symptoms generally appear gradually.",
    sp: "Los síntomas de la gripe pueden aparecer súbitamente mientras que los del resfrío suelen aparecer gradualmente.",
  },
  3: {
    en: "Viral infections that infect the digestive tract are called gastrointestinal viruses.",
    sp: "Las infecciones virales que infectan el aparato digestivo son llamadas virus gastrointestinales.",
  },
  4: {
    en: "A fever is a common symptom of the flu, but colds do not usually cause fevers.",
    sp: "La fiebre es un síntoma común de la gripe, pero los resfríos no suelen causar fiebre.",
  },
  5: {
    en: "Other examples of diseases caused by viral infections are chicken pox and AIDS.",
    sp: "Otros ejemplos de enfermedades causadas por infecciones virales son la varicela y el SIDA.",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const checkHandler = (p) => {
    const data = { selected: [...props.data.selected] };
    data.selected[p.index] = !data.selected[p.index];
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>{languageLookup(0)}</div>
      <Instructions>{languageLookup(1)}</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>{languageLookup(2)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>{languageLookup(3)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>{languageLookup(4)}</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>{languageLookup(5)}</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
