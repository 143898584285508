import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        The parallelogram is composed of two triangles and a rectangle. You can
        find the areas of the triangles and rectangle, and then add the areas.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.inputs[0] == "12" || props.data.inputs[2] == "12") {
    contents = (
      <div className={css.text}>
        Did you multiply the product of the base and height of each triangle by{" "}
        <Fraction numerator="1" denominator="2" />? Remember, the formula for
        the area of a triangle is A = <Fraction numerator="1" denominator="2" />
        bh.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        <div>
          The parallelogram is composed of two triangles and a rectangle. Find
          the areas of the triangles and rectangle, and then add the areas.
        </div>
        <div>
          The area of the purple triangle is{" "}
          <span style={{ fontStyle: "italic" }}>A</span> =
          <Fraction numerator="1" denominator="2" />
          <span style={{ fontStyle: "italic" }}> bh</span> =
          <Fraction numerator="1" denominator="2" />
          (3)(4) = 6 cm².
        </div>
        <div>
          The area of the yellow rectangle is{" "}
          <span style={{ fontStyle: "italic" }}>A</span> ={" "}
          <span style={{ fontStyle: "italic" }}>lw</span> = (4)(5) = 20 cm².
        </div>
        <div>
          The area of the green triangle is{" "}
          <span style={{ fontStyle: "italic" }}>A</span> =
          <Fraction numerator="1" denominator="2" />
          <span style={{ fontStyle: "italic" }}> bh</span> =
          <Fraction numerator="1" denominator="2" />
          (3)(4) = 6 cm².
        </div>
        <div>
          The area of the parallelogram is 6 cm² + 6 cm² + 20 cm² = 32 cm².
        </div>
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
