import React, { Fragment } from "react";
import Arrow from "../Assets/math_7_0_arrow.png";

export default (data, setData) => {};

export const correctAnswers = [1, 7, 5];

export const isCorrect = (data) => {
  return (
    data.left[0] == correctAnswers[0] &&
    data.left[1] == correctAnswers[1] &&
    data.left[2] == correctAnswers[2]
  );
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1],
    showLeft: [false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    showRight: [true, true, true, true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty;
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <Fragment>
    <div>
      What part of a circle does the dotted line represent in the diagram?
    </div>
    <div
      style={{
        position: "absolute",
        top: "-285px",
        left: "250px",
        transform: "rotateZ(-45deg)",
        pointerEvents: "none",
      }}
    >
      <img src={Arrow} style={{ width: "100%" }} />
    </div>
  </Fragment>,
  <div>What is the formula for the area of a circle?</div>,
  <div>How do you square a number?</div>,
  <div>What is the formula for the circumference of a circle?</div>,
  <div>
    Is the radius half of the diameter or is the diameter half of the radius?
  </div>,
];

export const showReader = () => {
  return false;
};
