import * as React from "react"

function SinglePlayer(props) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_1438_700)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.333 3.333a3.333 3.333 0 11-6.666 0 3.333 3.333 0 016.666 0zM7.5 19.167v-2.992l-2.258-2.258A.86.86 0 015 13.325v-5c0-.458.375-.833.833-.833h8.334c.458 0 .833.375.833.833v5a.825.825 0 01-.242.592L12.5 16.175v2.992a.836.836 0 01-.833.833H8.333a.836.836 0 01-.833-.833z"
                    fill="#5C6A79"
                />
            </g>
            <defs>
                <clipPath id="clip0_1438_700">
                    <path fill="#fff" d="M0 0H20V20H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SinglePlayer
