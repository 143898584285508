import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        The passage “Food Chains” identifies three types of organisms:
        carnivores, herbivores, and omnivores. Which foods might each type of
        organism eat? Some foods will be used more than once.
      </div>
      <div className={css.instructions}>
        Drag each food to the type of organism that might eat it. Click or tap
        the Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
