import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Determine Connotation</span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          Writers choose their words carefully when they want readers to feel a
          certain way. That's why it helps to think about how connotations are
          affecting you.
        </div>
        <div className={css.instructions}>
          Read the sentences. Does each bolded word have a positive or negative
          connotation?
        </div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.{" "}
        </Instructions>
        <div className={css.row}>
          <span>
            The <Bold>screechy</Bold> violin drove everyone from the music room.
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "positive" }, { text: "negative" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>
            The <Bold>powerful</Bold> blare of the trumpets echoed through the
            stadium.
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "positive" }, { text: "negative" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>
            The <Bold>ear-splitting</Bold> sound of the sirens alerted everyone
            to danger.
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "positive" }, { text: "negative" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div style={{ height: "100px" }} />
      </div>
    </div>
  );
};

export default Contents;
