export default (data, setData) => {};

export const correctAnswers = [true, true, true, true, true, false, false];

export const getDefaultState = () => {
  return {
    icons: [false, false, false, false, false, false, false],
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.icons.filter((x) => x == true).length == 0) return "empty";
  else if (
    data.icons[0] == correctAnswers[0] &&
    data.icons[1] == correctAnswers[1] &&
    data.icons[2] == correctAnswers[2] &&
    data.icons[3] == correctAnswers[3] &&
    data.icons[4] == correctAnswers[4] &&
    data.icons[5] == correctAnswers[5] &&
    data.icons[6] == correctAnswers[6]
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.icons.filter((x) => x > -1).length > 0;
};

export const getTitle = (data, setData) => {
  if (data.icons.filter((x) => x == true).length == 0) return "";

  if (
    data.icons[0] == correctAnswers[0] &&
    data.icons[1] == correctAnswers[1] &&
    data.icons[2] == correctAnswers[2] &&
    data.icons[3] == correctAnswers[3] &&
    data.icons[4] == correctAnswers[4] &&
    data.icons[5] == correctAnswers[5] &&
    data.icons[6] == correctAnswers[6]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "Put symbols in the picture graph to match the number of each of the shapes. Each symbol stands for 1 shape.",
  "Count the triangles in Emma's bag. There are 3 triangles. In the graph, there are 3 symbols in the row for Triangles.",
  "How many circles are in Emma's bag? How many symbols should you put in the row for Circles?",
];

export const showReader = () => {
  return false;
};
