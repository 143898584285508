import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Solve Word Problems with a Model</span>
      </div>
      <div className={css.contents}>
        <div>
          Three friends share a<Fraction numerator="1" denominator="4" /> -pound
          package of beads equally. What fraction of a pound of beads does each
          friend get?
        </div>
        <div>
          Divide
          <Fraction numerator="1" denominator="4" /> ÷ 3.
        </div>
        <div className={css.instructions}>Use a model to show the problem.</div>
        <Instructions>Click or tap each step.</Instructions>
        <div className={css.row}>
          <FoldBox fontSize="16px" width="48%" height="50px">
            <div>
              <Bold>Step 1:</Bold> Make a rectangle to show 1 pound of beads.
            </div>
            <div>
              <div>Divide the rectangle into fourths.</div>
              <Center>
                <img
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                  width="80%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/12263994df261d653f5283fc8820c0dc.svg"
                />
              </Center>
            </div>
          </FoldBox>
          <FoldBox fontSize="16px" width="48%" height="50px">
            <div>
              <Bold>Step 2:</Bold> Divide each fourth into 3 equal parts.
            </div>
            <div style={{ lineHeight: "30px" }}>
              <div>Look at one of three equal parts.</div>
              <Center>
                <img
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                  width="80%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/1a61ee7bf647ddafd4acd09dffdfd5a5.svg"
                />
              </Center>
              <div>
                Each part is a third of
                <Fraction numerator="1" denominator="4" />. Each part is
                <Fraction width="20px" numerator="1" denominator="12" /> of the
                whole.
              </div>
              <div>
                So, each friend gets
                <Fraction width="20px" numerator="1" denominator="12" /> pound
                of beads.
              </div>
            </div>
          </FoldBox>
        </div>
        <div style={{ height: "200px" }} />
      </div>
    </div>
  );
};

export default Contents;
