import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Center from "../../../../Center";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <Center>
        <img
          width="50%"
          src="https://d16qzots3do3wk.cloudfront.net/images/9b71276b9dee434df5d9799d09ba5508.svg"
        />
      </Center>
    </div>
  );
};

export default Sidebar;
