import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Apply: Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Sean divides 3 cups of granola into{" "}
          <Fraction numerator="1" denominator="4" /> -cup servings. How many
          servings of granola does he have?{" "}
        </div>
        <Instructions>
          Choose the correct number from each drop-down menu.
        </Instructions>
        <div>
          Let <Italic>s</Italic> = the number of servings Sean has.
        </div>
        <div className={css.row}>
          <span>The equation to solve the problem is</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction inline numerator="1" denominator="4" /> ÷ 3 ={" "}
                      <Italic>s</Italic>
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      3 ÷ <Fraction inline numerator="1" denominator="4" /> ={" "}
                      <Italic>s</Italic>
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      4 ÷ <Fraction inline numerator="1" denominator="3" /> ={" "}
                      <Italic>s</Italic>
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>of the whole pie.</span>
        </div>
        <div className={css.row}>
          <span>
            The value of <Italic>s</Italic> is
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      inline
                      width="20px"
                      numerator="1"
                      denominator="12"
                    />
                  ),
                },
                { text: 4 },
                { text: 6 },
                { text: 12 },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>Sean makes</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      inline
                      width="20px"
                      numerator="1"
                      denominator="12"
                    />
                  ),
                },
                { text: 4 },
                { text: 6 },
                { text: 12 },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>servings of granola.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
