import React from "react";
import css from "./Contents.module.css";

import Shape from "../Components/Shape";
import Reset from "../../../../Icons/Reset";

import Image from "../Assets/math_8_2_img.JPG";

const Contents = (props) => {
  const dragOverHandler = (ev, idx) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, id) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();

    const data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };

    const slotContains = data.left[id];
    // slot already contains item, need to swap
    if (slotContains != undefined) {
      const fromLeftSlot = props.data.left.findIndex((x) => x == dragId);

      // swapping with something already in left area
      if (fromLeftSlot > -1) {
        data.left[fromLeftSlot] = slotContains;
        data.showLeft[fromLeftSlot] = true;
      }
      // swapping with something from the right area
      else {
        data.right[slotContains] = slotContains;
        data.showRight[slotContains] = true;
      }
    }

    data.left[id] = dragId;
    data.showLeft[id] = true;
    props.setData(data);
  };

  const dragStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";
    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.showLeft[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragEndHandler = (ev, shape) => {
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    if (ev.dataTransfer.dropEffect !== "none") {
      data.left[shape.slot] = shape.index;
      props.setData(data);
    } else {
      console.log("dragged off " + shape.index);
      data.left[shape.slot] = -1;
      data.showLeft[shape.slot] = false;
      data.right[shape.index] = shape.index;
      data.showRight[shape.index] = true;
      props.setData(data);
    }
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Allessandro drew the following diagram of a baseball field. He wants to
        know if the path between home plate, third base, and the bottom of the
        foul pole along the first base line forms a right triangle.
      </div>
      <div className={css.header}>
        <div className={css.image}>
          <img src={Image} alt="image" />
        </div>
      </div>
      <div className={css.text}>
        Allessandro can use the equation
        <div
          className={css.bucket}
          onDragOver={(ev) => dragOverHandler(ev, 0)}
          onDrop={(ev) => dropHandler(ev, 0)}
        >
          <div className={css.contents}>
            <Shape
              onDragStart={dragStartHandler}
              onDragEnd={dragEndHandler}
              index={props.data.left[0]}
              slot={0}
              show={props.data.showLeft[0]}
            />
          </div>
        </div>
        to prove the path between home plate, third base, and the bottom of the
        foul pole form a right triangle.
      </div>
      <Reset style={{ bottom: "19px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
