import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Identify Directly Stated Main Ideas",
    sp: "Identifica ideas principales explícitas",
  },
  1: {
    en: "Some main ideas are directly stated in a text. When looking for a directly stated main idea, read the entire text, including the title and subheadings, to see what the passage is mainly about. Then look for a sentence or title that expresses this idea. If the main idea is directly stated, one of these will capture what the passage is mainly about.",
    sp: "Algunas ideas principales aparecen explícitamente en un texto. Cuando busques una idea principal explícita, lee el texto completo, incluidos el título y los subtítulos, para ver sobre qué trata principalmente el fragmento. Luego, busca una oración o un título que exprese esta idea. Si la idea principal es explícita, una de esas oraciones o títulos va a expresar sobre qué trata principalmente el fragmento.",
  },
  2: {
    en: "Read the passage. Then answer the question.",
    sp: "Lee el fragmento. Luego, contesta la pregunta.",
  },
  3: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/cfb3077882748255f366ee6a7301aed5.webp",
    sp: "https://d16qzots3do3wk.cloudfront.net/images/cfb3077882748255f366ee6a7301aed5.webp",
  },
  4: {
    en: "All About Polar Bears: Adaptations",
    sp: "Todo sobre los osos polares: Adaptaciones",
  },
  5: {
    en: "(1) Polar bears have adapted to their cold Arctic environment. (2) Their fur has hollow hairs filled with air, which provides insulation so that they can live on the ice and swim in frigid water. (3) In addition, since polar bears spend more time in the sea than on land, they have webbed front paws to help them swim.",
    sp: "(1) Los osos polares del Ártico se han adaptado al frío ambiente en el que viven. (2) Su pelaje tiene pelos huecos rellenos de aire que proporcionan aislamiento, de modo tal que los osos polares pueden vivir en el hielo y nadar en el agua gélida. (3) Además, dado que estos osos pasan más tiempo en el mar que en la tierra, tienen patas frontales palmeadas que les permiten nadar.",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <div className={css.instructions}>{languageLookup(2)}</div>
        <img className={css.image} src={`${languageLookup(3)}`} />
        <div className={css.title}>{languageLookup(4)}</div>
        <div className={css.details}>{languageLookup(5)}</div>
      </div>
    </div>
  );
};

export default Contents;
