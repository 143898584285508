import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          <Bold>
            {languageLookup(1)}Read the sentences. Complete them with the
            correct possessive noun.
          </Bold>
        </div>
        <Instructions>
          {languageLookup(2)}Click or tap the correct word from each drop-down
          menu.
        </Instructions>
        <div className={css.row}>
          <span>{languageLookup(3)}Next month, the new</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(4)}Choose</Italic>
                </div>
              }
              data={[
                { text: `${languageLookup(5)}children's` },
                { text: `${languageLookup(6)}childrens'` },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(7)}museum will open.</span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(8)}Isaac knows all of the fifty</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(9)}Choose</Italic>
                </div>
              }
              data={[
                { text: `${languageLookup(10)}state's` },
                { text: `${languageLookup(11)}states'` },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(12)}official birds!</span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(13)}Keisha borrowed</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(14)}Choose</Italic>
                </div>
              }
              data={[
                { text: `${languageLookup(15)}Haley's` },
                { text: `${languageLookup(16)}Haleys'` },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(17)}markers to finish her project.</span>
        </div>
        <div className={css.row}>
          <span>{languageLookup(18)}The strong wind took down one</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[3] - 1}
              selectIndex={(index) => selectIndexHandler(3, index + 1)}
              defaultText={
                <div>
                  <Italic>{languageLookup(19)}Choose</Italic>
                </div>
              }
              data={[
                { text: `${languageLookup(20)}house's` },
                { text: `${languageLookup(21)}houses'` },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>{languageLookup(22)}chimney.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
