import React from "react";
import css from "./Audio.module.css";

const Audio = (props) => {
  return (
    <div className={css.root}>
      <img
        src="https://practice.gogetwaggle.com/student/public/images/audio-on.svg"
        alt="icon"
      />
    </div>
  );
};

export default Audio;
