import React from "react";
import { useHistory } from "react-router-dom";

import css from "./Welcome.module.css";

import Avatar from "../../../assets/avatar.png";

import Sidebar from "../../../components/Sidebar";
import DropDown from "../../../components/DropDown";
import MissionBox from "../../../components/MissionBox";
import Logo from "../../../components/Logo";
import Logout from "../../../components/Icons/Logout";
import Help from "../../../components/Icons/Help";
import Messages from "../../../components/Icons/Messages/Messages";

import { toolTips } from "@indieasy.software/indieasy-engine";
import rolloverTips from "../../../data/rollovertips";
import Tokens from "../../Play/Tokens";
import ScoreDisplay from "./ScoreDisplay";

const ela = [
  {
    title: "Learn:",
    subtitle: [
      null,
      "Use Compound Sentences",
      "Use Apostrophes",
      "Use Precise Language",
      "Vary Sentences for Effect",
      "Determining Connotative Meanings",
      "Determine Main Idea and Identify Key Details",
      "Determing Meaning of Domain-Specific Language",
    ],
    activity: "lesson",
  },
  {
    title: "Learn:",
    subtitle: [
      null,
      null,
      null,
      null,
      null,
      null,
      "Determina la idea principal y los detalles de apoyo",
      null,
    ],
    activity: "lesson",
    language: "sp",
  },
  {
    title: "Lesson:",
    subtitle: [
      ["New for BTS 2021", "B", "C"],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    activity: "videos",
  },
  {
    title: "Practice:",
    subtitle: [
      null,
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
    ],
    activity: "learninggoal",
  },
];

const math = [
  {
    title: "Learn:",
    subtitle: [
      null,
      "",
      "Area of Rectangles",
      "Understanding Subtraction of Fractions",
      "Dividing with Unit Fractions and Whole Numbers",
      "Dividing Decimals",
      "Proportional Relationships",
      "Linear Equations",
    ],
    activity: "lesson",
  },
  {
    title: "Lesson:",
    subtitle: [
      ["New for BTS 2021", "B Math", "C Math"],
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ],
    activity: "videos",
  },
  {
    title: "Learn:",
    subtitle: [
      null,
      null,
      "Área de los rectángulos",
      null,
      null,
      null,
      "Relaciones proporcionales",
      null,
    ],
    activity: "lesson",
    language: "sp",
  },
  {
    title: "Practice:",
    subtitle: [
      null,
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
      "Sample Questions",
    ],
    activity: "learninggoal",
  },
];

const Welcome = (props) => {
  const history = useHistory();
  //const [typeIndex, setTypeIndex] = useState(0);

  const missionData = props.subject == "ela" ? ela : math;

  missionData.forEach((x) => (x.subject = props.subject));

  const gradeToSelectIndex = (grade) => {
    if (grade === 1) return 0;
    return grade - 2;
  };

  const subjectHandler = (index) => {
    history.push(`home-${index == 0 ? "ela" : "math"}-${props.grade}`);
  };

  const gradeHandler = (index) => {
    if (index === 0) {
      history.push(`home-${props.subject}-1`);
    } else {
      // There's no 2, so the there's a gap
      history.push(`home-${props.subject}-${index + 2}`);
    }
  };

  const createToolTip = (event, title, text) => {
    return;
    // const rect = event.currentTarget.getBoundingClientRect();
    // toolTips.manager.show(
    //   toolTips.createToolTip(
    //     rect.left + rect.width / 2,
    //     rect.top + rect.height / 2 + 30,
    //     {
    //       style: { width: "300px" },
    //       title: title,
    //       text: text,
    //     }
    //   )
    // );
  };

  return (
    <div className={css.root}>
      <div className={css.background}>
        <div className={css.honeycomb} />

        <Sidebar {...props} index={0} />
        <div className={css.top}>
          <div className={css.options}>
            <div className={css.logout}>
              <Logout />
            </div>
            <div className={css.help}>
              <Help />
            </div>
            <div className={css.messages}>
              <Messages />
            </div>
          </div>
        </div>
      </div>
      <div className={css.bottom}>
        <div className={css.footer}>
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC0xIDAgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSA1NzYgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgNDAgMTIwKSIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAyMEw0MCA0MEg1NzZMNjE2IDIwTDU3NiAwSDQwTDAgMjBaIiBmaWxsPSIjREREREREIi8+CjxwYXRoIGQ9Ik0xNiAyMEw0MCAzMkg1NzZMNjAwIDIwTDU3NiA4SDQwTDE2IDIwWiIgZmlsbD0iI0NDQ0NDQyIvPgo8cGF0aCBkPSJNMTYgMjBMNDAgMzJWOEwxNiAyMFoiIGZpbGw9IiNCQUJBQkEiLz4KPHBhdGggZD0iTTYwMCAyMEw1NzYgMzJWOEw2MDAgMjBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
            alt="header"
          />
          <div className={css.scoreDisplay}>
            <ScoreDisplay />
            <Tokens />
          </div>
        </div>
        <div className={css.icons}>
          <div onMouseDown={props.onSelectWorld} className={css.world}>
            <div className={css.bg}>
              <div className={css.icon}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIsSURBVHgBtVbrcZxADJbgcGAmM6GD0EHcQa4Ed5CkgrgD2x2kA7uDOBXY7uA6uEsFx4/MwO1LWS1gA8vu2R5bMwyMVvr0XAmACBFR2TRU8bci+iaV3iult0qpM+bxWdM0FbyUWFEq85vBGEBKubbgNHksjx1wRrW+frahwVMGEUp9dzytt54BZe747CDlZc/bt0KfR8GlposxSNB7P4onnsVYBBeafk4BlPPQpuk6ZIBT4xyz0Yz540iSIedI5nJs0BA9uA+EdShiIjjrZPXDmJ8mcDHUxBnIMsNhlRNtxA0btiAVhKm0KfqcWtk5f5V9cNGtnAfgvJ14YaTc5Hla25z+iBiAtm0xz/N7qfVMjjo/uUhWqJwrFkWxC52NyYLXiFgvtSmfeQXqi7dlgVELBh8h5S+WDbWyrQGdBt0jrOAIJUnyKXSGSBUX2UsBAdbwQrIZ93S4QRJ4K6JlpxJE2M2ZCBQt7BJxOhbYdUKEu4jS0VRpY/6GT2nz7m264kPRz/eJbaLbtoUyzbI1xOmWb3yaaU/OYt+4j6W7cLBGu70Qvwf/hDjlBRQahK6LlES+5pN829yti8LVJ1gHBNh9PDnZaPLuUi2FuHo0wEBk9NU0vPQLv42hPxC2cO9AMP06ZjMW19CTl7PR4JbJkYXjpdFiQIyEEOfDyjz0wovzSnXzitfqsDJZF55D7JUQ6oaVglH067Ibcuru1X8XgyJ3Sj8x98PPQLeQ4rf+P0CcFPrnHfPDAAAAAElFTkSuQmCC"
                  alt="icon"
                />
              </div>
            </div>
            <div className={css.text}>Select World</div>
          </div>
          <div onMouseDown={props.onSelectAvatar} className={css.edit}>
            <div className={css.bg}>
              <div className={css.icon}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADwSURBVHgB7ZXdDYIwFIXvheozI+AGjsAmugFuoGzCJsQJdAMYwTcSetsrrdHwawDDA4YvISnp7Tk9TX8A/hJm9qTis1T6lue531cnpQwk6aQgOvbVYFOYNITA+lT+ep8ihFgzX5E5s3WIPoJzKFtBRSjTwNFWiLhl0Cc8haYRFopDZH35VbjPCEmplBl8mAFj4pRrG8FM2ASmMUcKM3sh3J1jndopHjCe2hgz+1qvSSGJErO37TkgxWM+o/E6F5QQqbSSpJv3oKFshNup5cDMrAarwZINykcmg4GYe+dLXzf2ESLawwCEEHdEnHJ/LYAndni0QX/dSIwAAAAASUVORK5CYII="
                  alt="icon"
                />
              </div>
            </div>
            <div className={css.text}>Edit Avatar</div>
          </div>
        </div>
      </div>
      <div className={css.dashboard}>
        <div className={css.content}>
          <MissionBox {...props} title="Next Missions" data={missionData} />
          <MissionBox {...props} title="Missions In Progress" data={[]} />
          <div
            className={css.button}
            onMouseEnter={(ev) =>
              createToolTip(
                ev,
                rolloverTips["Home_Shout_lastweek"].title,
                rolloverTips["Home_Shout_lastweek"].text
              )
            }
            onMouseLeave={() => toolTips.manager.hide()}
          >
            ALL MISSIONS
          </div>
        </div>
      </div>

      <div className={css.header}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIGZpbGw9IiNFREVERUQiLz4KPHJlY3QgeD0iNTc2IiB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjRURFREVEIi8+CjxyZWN0IHg9IjQwIiB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAxMDBMNDAgODBINTc2TDYxNiAxMDBMNTc2IDEyMEg0MEwwIDEwMFoiIGZpbGw9IiNEREREREQiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OEg1NzZMNjAwIDEwMEw1NzYgMTEySDQwTDE2IDEwMFoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OFYxMTJMMTYgMTAwWiIgZmlsbD0iI0JBQkFCQSIvPgo8cGF0aCBkPSJNNjAwIDEwMEw1NzYgODhWMTEyTDYwMCAxMDBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
          alt="header"
        />
        <div className={css.welcome}>
          <div className={css.dropdown}>
            <DropDown
              width="190px"
              selectedIndex={props.subject == "ela" ? 0 : 1}
              selectIndex={subjectHandler}
              data={[
                { text: "Training60 - ELA" },
                { text: "Training60 - Math" },
              ]}
            /*rolloverTipTitle="Choose Subject"
                  rolloverTip="Choose english or math."*/
            />
          </div>
          <div className={css.dropdown}>
            <DropDown
              width="190px"
              selectedIndex={gradeToSelectIndex(props.grade)}
              selectIndex={gradeHandler}
              data={[
                { text: "Grade - 1" },
                { text: "Grade - 3" },
                { text: "Grade - 4" },
                { text: "Grade - 5" },
                { text: "Grade - 6" },
                { text: "Grade - 7" },
                { text: "Grade - 8" },
              ]}
            /*rolloverTipTitle="Choose Subject"
                  rolloverTip="Choose english or math."*/
            />
          </div>
        </div>

        <div className={css.logo}>
          <Logo />
        </div>
      </div>

      <div className={css.avatar}>
        <img src={Avatar} alt="avatar" />
      </div>

    </div>
  );
};

export default Welcome;
