import React, { useState } from "react";
import css from "./Contents.module.css";

import Highlight from "../../../../Highlight";

import Reset from "../../../../Icons/Reset";
import Bold from "../../../../Bold";
import Image from "../Assets/ela_3_2_big.JPG";
import Hotspot from "../../../../Hotspot";

const Contents = (props) => {
  const selectHandler = (hotspot) => {
    const data = { selected: hotspot.index };
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>

      <div className={css.contents}>
        <Hotspot
          style={{
            position: "absolute",
            width: "70px",
            height: "30px",
            top: "105px",
            left: "240px",
            background: "rgba(0, 0, 0, 0)",
          }}
          index={0}
          selected={props.data.selected == 0}
          onSelected={selectHandler}
        />
        <Hotspot
          style={{
            position: "absolute",
            width: "100px",
            height: "30px",
            top: "235px",
            left: "340px",
            background: "rgba(0, 0, 0, 0)",
          }}
          index={1}
          selected={props.data.selected == 1}
          onSelected={selectHandler}
        />
        <Hotspot
          style={{
            position: "absolute",
            width: "100px",
            height: "30px",
            top: "285px",
            left: "400px",
            background: "rgba(0, 0, 0, 0)",
          }}
          index={2}
          selected={props.data.selected == 2}
          onSelected={selectHandler}
        />
        <Hotspot
          style={{
            position: "absolute",
            width: "100px",
            height: "30px",
            top: "165px",
            left: "500px",
            background: "rgba(0, 0, 0, 0)",
          }}
          index={3}
          selected={props.data.selected == 3}
          onSelected={selectHandler}
        />
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
