import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FlipBox from "../../../../FlipBox";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
  44: {
    en: "",
    sp: "",
  },
  45: {
    en: "",
    sp: "",
  },
  46: {
    en: "",
    sp: "",
  },
  47: {
    en: "",
    sp: "",
  },
  48: {
    en: "",
    sp: "",
  },
  49: {
    en: "",
    sp: "",
  },
  50: {
    en: "",
    sp: "",
  },
  51: {
    en: "",
    sp: "",
  },
  52: {
    en: "",
    sp: "",
  },
  53: {
    en: "",
    sp: "",
  },
  54: {
    en: "",
    sp: "",
  },
  55: {
    en: "",
    sp: "",
  },
  56: {
    en: "",
    sp: "",
  },
  57: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          {languageLookup(0)}Learn about Apostrophes and Possession
        </span>
      </div>
      <div className={css.contents}>
        <div>
          <div>
            {languageLookup(1)}Contractions are words formed by combining two
            words.
          </div>
          <div>
            {languageLookup(2)}You use apostrophes to form contractions.
          </div>
        </div>

        <div className={css.entry}>
          <div>
            {languageLookup(3)}For contractions with{" "}
            <Italic>{languageLookup(4)}not</Italic>
            {languageLookup(5)}, replace the letter{" "}
            <Italic>{languageLookup(6)}o</Italic> {languageLookup(7)}with an
            apostrophe.
          </div>
          <BlockQuote>
            <Italic>{languageLookup(8)}do not</Italic> {languageLookup(9)}. . .
            becomes . . . <Italic>{languageLookup(10)}don’t</Italic>
          </BlockQuote>
          <BlockQuote>
            <Italic>{languageLookup(11)}is not</Italic> {languageLookup(12)}. .
            . becomes . . . <Italic>{languageLookup(13)}isn’t</Italic>
          </BlockQuote>
        </div>

        <div className={css.entry}>
          <div>{languageLookup(14)}Sometimes the verb changes, too.</div>
          <BlockQuote>
            <Italic>{languageLookup(15)}will not</Italic> {languageLookup(16)}.
            . . becomes . . . <Italic>{languageLookup(17)}won’t</Italic>
          </BlockQuote>
          <BlockQuote>
            <Italic>{languageLookup(18)}cannot</Italic> {languageLookup(19)}. .
            . becomes . . . <Italic>{languageLookup(20)}can’t</Italic>
          </BlockQuote>
        </div>

        <div className={css.entry}>
          <div>
            {languageLookup(21)}Pronouns and verbs can be combined into
            contractions. An apostrophe replaces letters in the verb.
          </div>
          <BlockQuote>
            <Italic>{languageLookup(22)}I am</Italic> {languageLookup(23)}. . .
            becomes . . . <Italic>{languageLookup(24)}I’m</Italic>
          </BlockQuote>
          <BlockQuote>
            <Italic>{languageLookup(25)}you are</Italic> {languageLookup(26)}. .
            . becomes . . . <Italic>{languageLookup(27)}you’re</Italic>
          </BlockQuote>
        </div>

        <Instructions>
          {languageLookup(28)}Click or tap each box to see more contractions.
        </Instructions>

        <div className={css.entries}>
          <FlipBox width="31%" height="232px">
            <div>
              {languageLookup(29)}Contractions with{" "}
              <Italic>{languageLookup(30)}not</Italic>
            </div>
            <div>
              <div>{languageLookup(31)}aren't</div>
              <div>{languageLookup(32)}couldn't</div>
              <div>{languageLookup(33)}didn't</div>
              <div>{languageLookup(34)}hasn't</div>
              <div>{languageLookup(35)}shouldn't</div>
              <div>{languageLookup(36)}wasn't</div>
              <div>{languageLookup(37)}weren't</div>
            </div>
          </FlipBox>
          <FlipBox width="31%" height="232px">
            <div>
              {languageLookup(38)}Pronoun +{" "}
              <Italic>{languageLookup(39)}will</Italic>
            </div>
            <div>
              <div>{languageLookup(40)}I'll</div>
              <div>{languageLookup(41)}you'll</div>
              <div>{languageLookup(42)}he'll</div>
              <div>{languageLookup(43)}she'll</div>
              <div>{languageLookup(44)}it'll</div>
              <div>{languageLookup(45)}we'll</div>
              <div>{languageLookup(46)}they'll</div>
              <div>{languageLookup(47)}that'll</div>
            </div>
          </FlipBox>
          <FlipBox width="31%" height="232px">
            <div>
              {languageLookup(48)}Pronoun +{" "}
              <Italic>{languageLookup(49)}would</Italic>
            </div>
            <div>
              <div>{languageLookup(50)}I'd</div>
              <div>{languageLookup(51)}you'd</div>
              <div>{languageLookup(52)}he'd</div>
              <div>{languageLookup(53)}she'd</div>
              <div>{languageLookup(54)}it'd</div>
              <div>{languageLookup(55)}we'd</div>
              <div>{languageLookup(56)}they'd</div>
              <div>{languageLookup(57)}that'd</div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
