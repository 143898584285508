import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That’s it! <Italic>Fluffy</Italic>, <Italic>booming</Italic>, and{" "}
        <Italic>itchy</Italic> are sensory words.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Try again. Choose only sensory words that make the sentences more
        precise.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
