import React from "react";
import css from "./CorrectBox.module.css";

import { components } from "@indieasy.software/indieasy-components";
const { CircleChart } = components;

const CorrectBox = (props) => {
  const index = props.index + (props.correct ? 1 : 0);
  const percent = index / 3;

  const gem =
    index < 3
      ? "https://practice.gogetwaggle.com/student/public/images/Streaks/Empty.png"
      : "https://practice.gogetwaggle.com/student/public/images/Streaks/Emerald.png";

  return (
    <div className={css.root}>
      <div className={css.left}>
        <CircleChart
          dropshadow
          style={{ marginLeft: "-72px", marginTop: "-76.5px" }}
          scale="0.425"
          strokeWidth="15px"
          base={{ stroke: "rgba(0, 0, 0, 0.075)" }}
          data={[{ percent: percent, stroke: "#93b962" }]}
        />
        <div className={css.gem}>
          <img src={gem} alt="gem" />
        </div>
      </div>
      <div className={css.text}>{`${index} correct!`}</div>
    </div>
  );
};

export default CorrectBox;
