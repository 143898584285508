import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the sentences. Then answer the question.
        </div>
        <div>
          Zulma has a test today. She is nervous. She studied all night. Zulma
          wants to do well. She will try hard.
        </div>
      </div>
    </div>
  );
};

export default Contents;
