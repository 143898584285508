import React from "react";
import css from "./Footer.module.css";
import Tools from "./Tools";
import Hints from "./Hints";
import Text from "./Text";

import Controller from "../Activity/Controller";

const Footer = (props) => {
  const selectHandler = (index) => {
    props.onSelectHint(index);
    props.onDone(false);
  };

  const doneHandler = () => {
    props.onDone(true);
    props.onSelectHint(-1);
  };

  const backHandler = () => {
    props.onBack();
    props.onSelectHint(-1);
  };

  const hints = Controller.GetHints(props.id);

  const tool = props.text ? (
    <Text language={props.language} onClick={() => props.setShowReader(true)} />
  ) : (
    <Tools language={props.language} />
  );

  let text = !props.isComplete
    ? props.language == "sp"
      ? "VER MI RESPUESTA"
      : "CHECK MY ANSWER"
    : props.language == "sp"
    ? "SIGUIENTE"
    : "NEXT";
  let backX = props.isComplete ? "150px" : "200px";
  if (props.isLast) text = "FINISHED";

  if (props.isClosed) backX = "10px";

  return (
    <div className={css.root}>
      {tool}
      <div className={css.divider} />
      {hints.length > 0 ? (
        <Hints
          language={props.language}
          hints={hints}
          index={props.hint}
          onSelect={selectHandler}
        />
      ) : null}
      {!props.isClosed || !props.isLast ? (
        <div className={css.button} onMouseDown={doneHandler}>
          {text}
        </div>
      ) : null}
      {props.back ? (
        <div
          style={{ right: backX }}
          className={css.back}
          onMouseDown={backHandler}
        >
          {props.language == "sp" ? "Atrás" : "BACK"}
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
