import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const lines = {
  title: {
    en: "Use Apostrophes",
    sp: "",
  },
  0: {
    en: "In this lesson, you will ...",
    sp: "",
  },
  1: {
    en: "find out how to show possession",
    sp: "",
  },
  2: {
    en: "identify where you can bring two words together in a contraction",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <span>{languageLookup(0)}</span>
        <BulletPoints>
          <li>{languageLookup(1)}</li>
          <li>{languageLookup(2)}</li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
