import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_8_0_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Getting Loopy: The Physics behind Roller Coasters
      </div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        Life may be like a roller coaster, as the saying goes, but you can’t
        always predict life’s ups and downs. Roller coasters, on the other hand,
        are such monstrous rides made of wood or steel—and often with loops,
        steep drops, fast rolls, and high-speed turns—that it is hard to hide
        all of their surprises. They tower before you in plain sight, and you
        always know where you will end up when the ride stops. Roller coasters
        have all the thrills of life but only half of the nerve-wracking
        suspense.
      </Paragraph>
      <div className={css.subtitle}>A Brief History</div>
      <Paragraph index="2">
        Roller coasters originated as winter sledding games in Russia in the
        1600s. Icy tracks quickly proved hazardous and difficult to navigate for
        sled riders. This led, over hundreds of years, to the invention of
        tracks that kept the sleds in place. These tracks did not require cold
        weather conditions because they were made of wood or steel instead of
        ice. As wheels and tracks became more sophisticated and safe, the sleds
        were replaced with cars that could travel faster and more smoothly. The
        United States began dabbling in roller coasters in the early 1800s, and
        the more creative the technology became, the more popular the rides
        became. In 1884, a roller coaster opened at the Coney Island amusement
        area in New York, and the ride was a great success. By the end of the
        1800s, roller coasters had become wildly popular in the United States.
        Yet, this was only the beginning. Since then, roller coasters have been
        improved and perfected through years of design and technology
        innovations. The introduction of high-speed roller coasters brought new
        safety features. Before the lap bars and shoulder harnesses used today,
        passengers had only the handles on their seats to hold on to and keep
        them in their car.
      </Paragraph>
      <div className={css.subtitle}>Many Kinds of Energy</div>
      <Paragraph index="3">
        A roller coaster ride begins with a motor that pulls a chain to lift a
        train of cars up a hill. As the cars climb the hill, they gather and
        store energy for later use. This is called potential energy because it
        has the potential, or capability, for movement. The higher the train
        goes, the more potential energy it stores. When gravity finally pulls
        the train of cars down the other side of the hill, the train builds up
        kinetic, or moving, energy. The greater the train's mass and the faster
        the train moves, the more kinetic energy it gains. As the cars speed
        down the hill, their potential energy decreases (because their distance
        from the ground decreases) and their kinetic energy increases (because
        their speed increases). This balance between potential and kinetic
        energy continues to shift back and forth as the cars go up and down the
        steel tracks and change speed. The total amount of energy the train of
        cars has, called mechanical energy, is always the same throughout the
        ride, regardless of how it is divided between potential and kinetic
        energy. Energy cannot be created or destroyed; it can only change forms.
      </Paragraph>
      <div className={css.subtitle}>Watch Out for Curves!</div>
      <Paragraph index="4">
        A body in motion tends to stay in motion, and it tends to move in a
        straight path until it is acted on by a force that changes its
        direction. This tendency toward straight, forward motion is called
        inertia. As you ride a roller coaster, your body wants to continue
        moving in a straight path, but because you are buckled into the seat,
        your body moves in the same way as the car. The feeling of being pushed
        to the outer edge as the car rounds a turn is caused by centripetal
        acceleration. While there is no actual force pushing and pulling you
        against the twists and turns, that feeling is the inertia of your body
        as it resists the change in directional movement. The faster the train
        is moving—or the sharper the curve of the train’s path—the greater the
        centripetal acceleration. To counteract this sensation of being pushed
        toward the edge of the turn, many of the faster roller coasters use
        banked, or tilted, turns instead of flat turns. This inclines the track
        enough to make it feel as if you are being pushed into your seat rather
        than thrown out of it.
      </Paragraph>
      <Paragraph index="5">
        While you do not have to be an expert in physics to enjoy riding a
        roller coaster, knowing a few basics can help you appreciate how natural
        forces in everyday life come together to make a roller coaster work.
        Engineers use these concepts to ensure that roller coasters are both
        exciting and safe.
      </Paragraph>
    </div>
  );
};

export default Reader;
