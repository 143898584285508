import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the story. Then answer the question.</Bold>
        </div>
        <ol>
          <li>There’s a family of hungry robins living in the maple tree.</li>
          <li>Make a bird feeder for them and fill it as soon as possible.</li>
          <li>Hang it outside on a high tree branch.</li>
          <li>
            Mike and his brother Sam watch as the robins fly to the bird feeder.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
