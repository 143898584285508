import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import DropDown from "../../../../../components/DropDown";

import Background from "../Assets/math_5_0_bg.png";

const Contents = (props) => {
  const selectIndexHandler = (index) => {
    const data = {
      index: props.data.index,
      fraction: [...props.data.fraction],
    };
    data.index = index + 1;

    props.setData(data);
  };

  const fractionHandler = (index, ev) => {
    const data = {
      index: props.data.index,
      fraction: [...props.data.fraction],
    };
    data.fraction[index] = ev.target.value;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.background}>
        <img src={Background} alt="bg" />
      </div>
      <div className={css.top}>
        <div className={css.text}>
          Let <span style={{ fontStyle: "italic" }}>n</span> = the number of
          fluid ounces in each glass.
        </div>
        <div className={css.line}>
          <div className={css.text}>
            An equation for this problem is:{" "}
            <span style={{ fontStyle: "italic" }}>n</span> =
          </div>
          <div className={css.dropdown}>
            <DropDown
              activity
              width="120px"
              selectedIndex={props.data.index - 1}
              selectIndex={selectIndexHandler}
              defaultText="Choose"
              data={[
                { text: "8 / 59" },
                { text: "8 * 59" },
                { text: "59 / 8" },
                { text: "59 * 8" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                    rolloverTip="Choose english or math."*/
            />
          </div>
        </div>
        <div className={css.equation}>
          <div className={css.text}>Each glass will have</div>
          <input
            type="text"
            className={css.input}
            value={props.data.fraction[0]}
            onChange={(ev) => fractionHandler(0, ev)}
          ></input>
          <div className={css.fraction}>
            <input
              type="text"
              className={css.input}
              value={props.data.fraction[1]}
              onChange={(ev) => fractionHandler(1, ev)}
            ></input>
            <div className={css.line} />
            <input
              type="text"
              className={css.input}
              value={props.data.fraction[2]}
              onChange={(ev) => fractionHandler(2, ev)}
            ></input>
          </div>
          <div className={css.text}>fluid ounces.</div>
        </div>
      </div>

      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
