/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import css from "./Rewards.module.css";

import Sidebar from "../../components/Sidebar";
import Logo from "../../components/Logo";
import Logout from "../../components/Icons/Logout";
import Help from "../../components/Icons/Help";
import Messages from "../../components/Icons/Messages/Messages";

import ItemBox from "./ItemBox";
import EntryPopup from "./EntryPopup";
import Bold from "../../components/Bold";

import profileData from "../../data/profiles.json";

const data = profileData[0];

const Rewards = (props) => {
  const [itemIndex, setItemIndex] = useState(-1);

  let popup;

  console.log(itemIndex);

  if (itemIndex === 0)
    popup = (
      <EntryPopup
        onClose={() => setItemIndex(-1)}
        color="#FFBE2E"
        icon="https://practice.gogetwaggle.com/student/public/badges/power-player.svg"
        name="Top Scorer"
        level="1"
        total="5"
        title="Number of Points"
      >
        <ul style={{ marginLeft: "50px", position: "relative" }}>
          <li style={{ listStyle: "none", marginBottom: "20px" }}>
            <img
              style={{ position: "absolute", left: 0 }}
              width="24px"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIuSURBVHgBrVZdbtNAEJ7ZTSiUB3ID3BOQGxA/kKhPNDdI0iKRFqnNCZqcoI0qFyQIcU/QvIWah3AE36A+ghFCkJTdYdfUkR3ZxXbyPVizP/N9O96ZsRH+g7Prk8rWtl8jYFWG+FxK+A5IPoJ05/Kx29v94D3kj2kLF19bNUb8VG2owcOYIIph95X9LZNAcOKnP8bK3IMcUET2b1EerEYUEzibvjW2+N1MmQYUgzcXZTMqghskTxRh4eyGyDWMR/xuHA4CAcvptDZEHkAnxuWXzsm9DXDp7N+uI6AvWAJVEbAamfbnP8UOW/f0RDjo1kftRVmaBORGlipPnpVeMrWhBgWhyY8an/ra7pm2L4Xsxdb/0J4qTnwBBRAl19BZyDkfx/aou2Co3t2qsyS4UovDPOQpWVgpJZG/a4xa2rZuDnxEOi1IHggwQvCjMyWG16GtiTRhQXINnyGRH4+AbMt5U10VKUCuj+Sh5bTPEdjxqrJqz+ZR/aOb5Ja1rej6YFLwScJaRfX7WTSSvOSBAOLkXyXfdG7VKMkhFkmuhkjkHTY+7wRZpC56oJTGkB6JuRDcz9cQsR88w6Hl7M8yfL2yYnJYHzW1sWzXi7Jo6rBgXSgOFGLZMpYCupeglOZaIkSu5uju2l44lfjRV69LpS4cQw6QhOH2L9FvN+1YXaX+VbyftgxivE9Ir1WfrySSgvp9kXiFJM6jp84kEMXF9KDGuDQUo3Hv5QFwN60Qo/gLQAMceCp1weQAAAAASUVORK5CYII="
            />
            <span>
              Level 1: <Bold>1000 of Points</Bold>
            </span>
          </li>
          <li style={{ listStyle: "none", marginBottom: "20px" }}>
            <span>
              Level 2: <Bold>5000 of Points</Bold>
            </span>
          </li>
          <li style={{ listStyle: "none", marginBottom: "20px" }}>
            <span>
              Level 3: <Bold>10000 of Points</Bold>
            </span>
          </li>
          <li style={{ listStyle: "none", marginBottom: "20px" }}>
            <span>
              Level 4: <Bold>50000 of Points</Bold>
            </span>
          </li>
          <li style={{ listStyle: "none" }}>
            <span>
              Level 5: <Bold>75000 of Points</Bold>
            </span>
          </li>
        </ul>
      </EntryPopup>
    );
  else if (itemIndex === 1)
    popup = (
      <EntryPopup
        onClose={() => setItemIndex(-1)}
        color="#AD79E9"
        icon="https://practice.gogetwaggle.com/student/public/badges/newbie.svg"
        name="Newbie"
        title="Newbie"
      >
        <div className={css.entryText}>
          The Newbie badge is the first of many badges you'll earn as you take
          your learning journey. Get started to earn more!
        </div>
      </EntryPopup>
    );

  return (
    <div className={css.root}>
      <div className={css.background}>
        <div className={css.honeycomb} />
        <Sidebar {...props} index={2} />
        <div className={css.top}>
          <div className={css.options}>
            <div className={css.logout}>
              <Logout />
            </div>
            <div className={css.help}>
              <Help />
            </div>
            <div className={css.messages}>
              <Messages />
            </div>
          </div>
        </div>
      </div>
      <div className={css.bottom}>
        <div className={css.footer}>
          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC0xIDAgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAtMSA1NzYgMTIwKSIgZmlsbD0iI0VERURFRCIvPgo8cmVjdCB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgLTEgNDAgMTIwKSIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAyMEw0MCA0MEg1NzZMNjE2IDIwTDU3NiAwSDQwTDAgMjBaIiBmaWxsPSIjREREREREIi8+CjxwYXRoIGQ9Ik0xNiAyMEw0MCAzMkg1NzZMNjAwIDIwTDU3NiA4SDQwTDE2IDIwWiIgZmlsbD0iI0NDQ0NDQyIvPgo8cGF0aCBkPSJNMTYgMjBMNDAgMzJWOEwxNiAyMFoiIGZpbGw9IiNCQUJBQkEiLz4KPHBhdGggZD0iTTYwMCAyMEw1NzYgMzJWOEw2MDAgMjBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
            alt="header"
          />
        </div>
      </div>
      <div className={css.dashboard}>
        <div className={css.content}>
          <div className={css.background}>
            <div className={css.row}>
              <ItemBox
                onClick={() => setItemIndex(0)}
                color="#FFBE2E"
                icon="https://practice.gogetwaggle.com/student/public/badges/power-player.svg"
                name="Top Scorer"
                level="1"
                total="5"
              />
              <ItemBox
                onClick={() => setItemIndex(1)}
                color="#AD79E9"
                icon="https://practice.gogetwaggle.com/student/public/badges/newbie.svg"
                name="Newbie"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/100000-pts-club.svg"
                disabled
                name="100k"
              />
            </div>
            <div className={css.row}>
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/centenial.svg"
                disabled
                name="Centennial"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/collector-badge.svg"
                disabled
                name="Collector"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/reading-informational-text.svg"
                disabled
                name="Informational Texts"
                level="0"
                total="14"
              />
            </div>
            <div className={css.row}>
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/waggle-wise.svg"
                disabled
                name="Knowledge Seeker"
                level="0"
                total="5"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/language.svg"
                disabled
                name="Language"
                level="0"
                total="31"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/reading-literature.svg"
                disabled
                name="Literature"
                level="0"
                total="15"
              />
            </div>
            <div className={css.row}>
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/marathoner.svg"
                disabled
                name="Marathoner"
                level="1"
                total="5"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/peak-perfomer.svg"
                disabled
                name="Peak Performer"
                level="0"
                total="5"
              />
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/skill-savy.svg"
                disabled
                name="Skill Maestro"
                level="0"
                total="5"
              />
            </div>
            <div className={css.row}>
              <ItemBox
                icon="https://practice.gogetwaggle.com/student/public/badges/writing.svg"
                disabled
                name="Writing"
                level="0"
                total="6"
              />
              <div style={{ padding: "10px" }} />
              <div style={{ padding: "10px" }} />
            </div>
          </div>
        </div>
      </div>
      <div className={css.header}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjE2IiBoZWlnaHQ9IjEyMCIgdmlld0JveD0iMCAwIDYxNiAxMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHdpZHRoPSI0MCIgaGVpZ2h0PSIxMDAiIGZpbGw9IiNFREVERUQiLz4KPHJlY3QgeD0iNTc2IiB3aWR0aD0iNDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjRURFREVEIi8+CjxyZWN0IHg9IjQwIiB3aWR0aD0iNTM2IiBoZWlnaHQ9IjEwMCIgZmlsbD0iI0ZCRkNGRCIvPgo8cGF0aCBkPSJNMCAxMDBMNDAgODBINTc2TDYxNiAxMDBMNTc2IDEyMEg0MEwwIDEwMFoiIGZpbGw9IiNEREREREQiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OEg1NzZMNjAwIDEwMEw1NzYgMTEySDQwTDE2IDEwMFoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZD0iTTE2IDEwMEw0MCA4OFYxMTJMMTYgMTAwWiIgZmlsbD0iI0JBQkFCQSIvPgo8cGF0aCBkPSJNNjAwIDEwMEw1NzYgODhWMTEyTDYwMCAxMDBaIiBmaWxsPSIjQkFCQUJBIi8+Cjwvc3ZnPgo="
          alt="header"
        />
        <div className={css.welcome}>
          <div className={css.left}>
            <div className={css.collected}>
              <div className={css.title}>BADGES</div>
              <div className={css.text}>2 of 13 collected</div>
            </div>
            <div className={css.score}>
              <div className={css.title}>SCORE</div>
              <div className={css.value}>{data.score.points.overall}</div>
            </div>
          </div>
          <div className={css.right}>
            <div className={css.text}>GEMS</div>
            <div className={css.gems}>
              <img
                width="24px"
                height="24px"
                src="https://practice.gogetwaggle.com/student/app/static/media/gemEmerald.svg"
              />
              <div className={css.value}>{data.score.emerald.overall}</div>
              <img
                width="24px"
                height="24px"
                src="https://practice.gogetwaggle.com/student/app/static/media/gemRuby.svg"
              />
              <div className={css.value}>{data.score.ruby.overall}</div>
              <img
                width="24px"
                height="24px"
                src="https://practice.gogetwaggle.com/student/app/static/media/gemSaphire.svg"
              />
              <div className={css.value}>{data.score.saphire.overall}</div>
              <img
                width="24px"
                height="24px"
                src="https://practice.gogetwaggle.com/student/app/static/media/gemDiamond.svg"
              />
              <div className={css.value}>{data.score.diamond.overall}</div>
            </div>
          </div>
        </div>
        <div className={css.logo}>
          <Logo />
        </div>
      </div>
      {popup}
    </div>
  );
};

export default Rewards;
