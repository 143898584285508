import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isEmpty, isCorrect } from "../Controller";

const Done = (props) => {
  let contents;
  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Way to go! You can determine that AB
        <span className={css.arrows}>
          <span>←</span>
          <span>→</span>
        </span>{" "}
        coincides with EF
        <span className={css.arrows}>
          <span>←</span>
          <span>→</span>
        </span>{" "}
        when translated and with CD
        <span className={css.arrows}>
          <span>←</span>
          <span>→</span>
        </span>{" "}
        when rotated 90° counterclockwise about the point shown on the line.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else {
    contents = (
      <div className={css.text}>
        Slide line AB to coincide with line EF. A translation slides a figure
        along a line. So, a translation will make these two lines coincide. Turn
        line AB 90° counterclockwise about the point on the line so that it
        completely covers line CD. A rotation is a turn of a figure around a
        point. So, a rotation will make these two lines coincide.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
