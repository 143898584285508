import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        The passages “Mermaid or Manatee?” and “Africa’s Unicorn” contain a
        variety of text structures. Complete each sentence to choose whether
        one, both, or neither passage contains each text structure.
      </div>
      <div className={css.instructions}>
        Click or tap the correct answer from each drop-down menu. Click or tap
        the Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
