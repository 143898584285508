import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the passage. Look at the bolded words. Then answer the question.
        </div>
        <div>
          The talent show was in full swing, and Asari knew it was her time to
          shine. She took to the stage with her guitar, and gave the crowd a
          look that said, “Prepare to be dazzled, people!”
        </div>
        <div>
          She saw her older brother Cadien in the front row. He could sense that
          Asari was secretly nervous, so he crossed his arms in an exaggerated
          way, then made a super-serious face. Asari looked at Cadien and
          cracked up laughing. His pretend stern face turned into a playful{" "}
          <Bold>smirk</Bold>. Cadien gave Asari a thumbs up that said, “You’ve
          got this!” She winked back with a big <Bold>grin</Bold>.
        </div>
        <div>
          Eager to show off her chops, Asari played a complex, catchy riff, then
          looked into the audience again. When she strummed the last chord,
          everyone stood up and cheered. Basking in their appreciation, Asari
          had to <Bold>beam</Bold>. Her <Bold>smile</Bold> had never been wider.
          Her countless hours of hard work and perseverance had paid off.
        </div>
      </div>
    </div>
  );
};

export default Contents;
