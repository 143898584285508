import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";

import ImageSP from "../Assets/math_3_5.svg";

const lines = {
  title: {
    en: "Use Multiplication to Find the Area of a Rectangle",
    sp: "Utilizar la multiplicación para hallar el área de un rectángulo",
  },
  0: {
    en: "Mr. Garcia’s office is shaped like the rectangle shown.",
    sp: "La oficina del señor García tiene la forma del rectángulo que se muestra a continuación.",
  },
  1: {
    en: "Each unit square represents 1 square yard.",
    sp: "Cada cuadrado de una unidad representa 1 yarda cuadrada.",
  },
  2: {
    en: "What is the area of his office?",
    sp: "¿Cuál es el área de la oficina del señor García?",
  },
  3: {
    en: "You can multiply to find the total number of squares in an array.",
    sp: "Puedes multiplicar para hallar el número total de cuadrados en una matriz.",
  },
  4: {
    en: "This rectangle is like an array.",
    sp: "Este rectángulo es como una matriz.",
  },
  5: {
    en: "Click or tap the boxes.",
    sp: "Pulsa o haz clic en los recuadros.",
  },
  6: {
    en: "Step 1:",
    sp: "PASO 1:",
  },
  7: {
    en: "Count the number of rows. Then, count the unit squares in each row.",
    sp: "Cuenta el número de filas. Luego, cuenta los cuadrados de una unidad que hay en cada fila.",
  },
  8: {
    en: "9 rows of 7=?",
    sp: "9 filas de 7 = ?",
  },
  9: {
    en: "Step 2:",
    sp: "PASO 2:",
  },
  10: {
    en: "Write an multiplication sentence.",
    sp: "Escribe un enunciado de multiplicación.",
  },
  11: {
    en: "9×7=63",
    sp: "9 × 7 = 63",
  },
  12: {
    en: "So, the area of Mr. Garcia’s office is 63 square yards.",
    sp: "Entonces, el área de la oficina del señor García mide 63 yardas cuadradas.",
  },
  13: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/cd809cb0927ab87d46b84eb2a0592e99.svg",
    sp: ImageSP,
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>{languageLookup(0)}</div>
          <div>{languageLookup(1)}</div>
          <div>{languageLookup(2)}</div>
        </div>
        <div>{languageLookup(3)}</div>
        <div>{languageLookup(4)}</div>
        <img
          width="286px"
          src="https://d16qzots3do3wk.cloudfront.net/images/9ae9b8be8fd1474684d4d712a72343a3.svg"
        />
        <Instructions>{languageLookup(5)}</Instructions>
        <div className={css.row}>
          <FoldBox
            fontSize="16px"
            width="48%"
            height={props.language == "en" ? "35px" : "55px"}
          >
            <div>
              <Bold>{languageLookup(6)}</Bold> {languageLookup(7)}
            </div>
            <div>
              <img width="90%" src={languageLookup(13)} />
              <div>{languageLookup(8)}</div>
            </div>
          </FoldBox>
          <FoldBox fontSize="16px" width="48%" height="35px">
            <div>
              <Bold>{languageLookup(9)}</Bold> {languageLookup(10)}
            </div>
            <div>
              <div style={{ textAlign: "center" }}>{languageLookup(11)}</div>
              <div>{languageLookup(12)}</div>
            </div>
          </FoldBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
