import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

import { getLanguage } from "../../../../../data/language";

const lines = {
  0: {
    en: "The main idea will not be stated directly here, but one of the answer choices will sum up what the passage is mainly about.",
    sp: "La idea principal no va a aparecer explícitamente aquí, pero una de las opciones de respuesta va a resumir sobre qué trata principalmente el fragmento.",
  },
  1: {
    en: "Be sure to look closely at the title, the second sentence, and the last sentence.",
    sp: "Asegúrate de observar con atención el título, la segunda oración y la última oración.",
  },
};

const languageLookup = (idx) => {
  return lines[idx][getLanguage()];
};

export default (data, setData) => {};
export const correctAnswers = [false, true, false, false];

export const getAnswers = () => {
  return { selected: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    selected: [false, false, false, false],
  };
};

export const isEmpty = (data) => {
  return data.selected.filter((x) => x == true).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.selected.length; i++) {
    if (data.selected[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = () => [
  <div>{languageLookup(0)}</div>,
  <div>{languageLookup(1)}</div>,
];

export const showReader = () => {
  return false;
};
