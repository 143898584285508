import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(0)}That’s correct! Sentence 1 and Sentence 3 show
        correct use of apostrophes.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>
        {languageLookup(1)}Please select an answer!
      </div>
    );
  } else if (props.data.selected.filter((x) => x == true).length != 2) {
    contents = (
      <div className={css.text}>
        {languageLookup(2)}
        Look again. Which two sentences show correct apostrophe use?
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        {languageLookup(3)}
        Try again. Choose only the sentences that show correct apostrophe use.
      </div>
    );
  }
  return <div className={css.root}>{contents}</div>;
};

export default Done;
