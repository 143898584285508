import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import DropDown from "../../../../DropDown";
import Italic from "../../../../Italic";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/8d14b6b6b979dad42d27942639eb3f3b.webp",
    sp: "https://d16qzots3do3wk.cloudfront.net/images/8d14b6b6b979dad42d27942639eb3f3b.webp",
  },
  1: {
    en: "Is the relationship proportional?",
    sp: "¿Es proporcional la relación?",
  },
  2: {
    en: "Click or tap the correct answer from the drop-down menu.",
    sp: "Pulsa o haz clic en la respuesta correcta del menú desplegable.",
  },
  3: {
    en: "The relationship",
    sp: "La relación",
  },
  4: {
    en: "Choose",
    sp: "Elige",
  },
  5: {
    en: "is",
    sp: "es",
  },
  6: {
    en: "is not",
    sp: "no es",
  },
  7: {
    en: "proportional.",
    sp: "proporcional.",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Sidebar = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <img width="90%" src={languageLookup(0)} />
      <div>{languageLookup(1)}</div>
      <Instructions>{languageLookup(2)}</Instructions>
      <div className={css.row}>
        <span>{languageLookup(3)}</span>
        <div className={css.dropdown}>
          <DropDown
            width="165px"
            selectedIndex={props.data.indices[0] - 1}
            selectIndex={(index) => selectIndexHandler(0, index + 1)}
            defaultText={
              <div>
                <Italic>{languageLookup(4)}</Italic>
              </div>
            }
            data={[{ text: languageLookup(5) }, { text: languageLookup(6) }]}
            /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
          />
        </div>
        <span>{languageLookup(7)}</span>
      </div>
    </div>
  );
};

export default Sidebar;
