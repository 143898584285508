import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_8_1_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>The Most Amazing Man on Earth</div>
      <div className={css.author}>
        an original retelling of a Davy Crockett tall tale
      </div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        Well, it just so happens that I was born with the strength of ten men,
        or maybe more. My folks didn't know nothing until I was three years old.
        One fine day, we were sitting at breakfast when a big, fat bear walked
        into the cabin and begun to lick up the maple syrup off our flapjacks,
        just like that. Well, afore anyone else could move, this little babe,
        me, just walked right up to that greedy bear and began to give him a
        fierce bear hug. I mean that hug was so fierce and so strong you'd of
        thought I was a python. I hugged so hard, he just dropped to the ground.
        Whenever we ran into each other after that, he would just give me a
        little bow of respect. I always nodded back at him.
      </Paragraph>
      <Paragraph index="2">
        I continued to grow up bigger and stronger, until I was as strong as a
        mountain lion. In fact, I met a mountain lion once, walking in the woods
        all alone. We just about came face-to-face and stopped. We looked at
        each other and looked at each other, and I decided that to run was the
        most pleasant solution to this puzzle. So I ran as fast as I could in
        the other direction, and he ran after me, and he was just about to get
        me when I tripped over a rock and fell down. I thought I was done. But
        then the rock went flying up into the air, and it hit that mountain lion
        on the head, and down he dropped. You won't believe what happened next,
        because it is hard for me to believe myself, but the rock broke up, and
        the parts of it went flying through the air. One piece flew straight up
        and hit a goose that was flying overhead. The goose fell right down next
        to me, and I picked it up for the soup pot. Another piece flew over and
        hit a bear in the head. Down he went too. As he fell, he knocked over a
        great oak tree, and down fell a squirrel, just ready to be cooked. Where
        that tree fell over, a wild boar was walking at the time. The tree hit
        him, and he was done. A tiny part of that rock jumped up and hit me in
        the stomach, and off popped a button from my shirt, and that button
        sprang out and dropped a fat, juicy rabbit. I lost my balance when the
        rock struck me, and I fell right down into the passing river.
      </Paragraph>
      <Paragraph index="3">
        But don't worry; I can swim better than any duck. When I came on up, all
        my pockets were filled with fish. In short, I came home with enough food
        to feed my family for a month. You can see that aside from being the
        strongest man alive, I am also the luckiest.
      </Paragraph>
      <Paragraph index="4">
        One time, we had a problem down in Washington, D.C., where it became
        just so cold that the Congress people couldn't talk, and you know how
        they love to do that! Whenever they tried to talk, their words would
        just freeze in the air, and they had to grab them and roast them over a
        fire afore anyone could begin to figure out what they were trying to
        say. Well, I knew I had to do something, so off I rode to the North
        Pole, because I figured that's where all the cold was coming from. Well,
        it turned out a comet had got its tail caught at the North Pole, and so
        it had stopped Earth from turning. Easy to fix! I just grabbed that
        comet and jerked it loose and gave it a swing back into orbit. As I
        always say: "Be sure you are right, then go on ahead."
      </Paragraph>
      <Paragraph index="5">
        I took care of everything and set the world back to its right way of
        turning. The grass began to grow and the flowers to bloom and the
        senators could talk again, all because of my heroic deed. They were all
        so grateful, they elected me to Congress. Every bit of this is true, I
        tell you.
      </Paragraph>
      <div className={css.line} />
      <div className={css.subtitle}>The Furry Trout</div>
      <Paragraph index="1">
        Having gotten into a bit of trouble in my home city, and therefore
        feeling the need for a new point of view, I packed up my suitcase and
        traveled west, using just about the last of my money on buses and
        trains. When I got as far away from my former home as I could afford, I
        immediately noticed something strange about my new city. Nobody there
        had any hair. They had no eyebrows or lashes, and nothing on their heads
        either! Whether men, women, or children, it didn't matter; they were all
        completely bald. Well, since I am an enterprising sort and can always
        find a way to get by, I decided that some money could be made there. So
        I called up my uncle Joe, who is in the beauty business. Joe is a wizard
        with chemistry and a beauty expert. He can make pale skin rosy and can
        clear up a complexion overnight, but he is most famous for his hair
        potion. I told him that my hair was falling out at an alarming rate, and
        I asked if I could test out a bottle of his super hair-growing formula.
        "How about a few dozen bottles? Lifetime supply," he said. Uncle Joe
        always was generous.
      </Paragraph>
      <Paragraph index="2">
        Well, anyway, he sent me the bottles right away. When I got the notice,
        I hurried to the post office, opened up the package, and caught a taxi
        right back toward the center of town, where I planned to sell the stuff
        and make my fortune. I normally wouldn't have spent my money on a taxi,
        but I felt no need to take the bus when I was about to strike it rich. I
        kept telling the driver to go faster, because I was eager to get back
        and start selling that stuff, but we hit a snag along the way. The
        bridge was gridlocked, and it wasn't about to clear up anytime soon. So
        I paid the fare, hopped out, and made a run for it. Tragically, I was
        running so fast that I tripped, and all my bottles went flying over the
        railing and into the river.
      </Paragraph>
      <Paragraph index="3">
        Boy, was I disappointed. But the next day, something unusual happened. I
        was standing on the bridge, staring forlornly into the stream where my
        bottles had gone, when I spied a peculiar fish. This fish was just
        swimming along with long, luxurious fur. I realized right away what must
        have happened. The fish had breathed in my uncle's potion. Then I saw
        that they all had long fur. It was so long, it floated up to the surface
        of the river. One fish was staring right up at me, and I realized I
        could reach my hand down, grab his fur, and reel him right in. I did
        this for a while, selling everyone my unusual trout at unusually high
        prices. Then I realized I could make money even more easily by getting
        the fish to come to me. Every day, I went to the bridge, set up a sign
        that said "Beauty Emporium for Trout," and when the fish jumped out to
        get their fur trimmed and permed, I scooped them right up. I would be
        there still selling my fish, but the police came sniffing around, so I
        soon felt the need for a new point of view and left for another town. I
        wonder who curls those fishes' hair now.
      </Paragraph>
    </div>
  );
};

export default Reader;
