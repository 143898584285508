import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Which student has both a correct prediction and a correct explanation?
      </div>
      <div className={css.instructions}>Click or tap the explanation.</div>
    </div>
  );
};

export default Sidebar;
