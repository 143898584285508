import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

import Italic from "../../../../Italic";
import Fraction from "../../../../Fraction";
import Arrow from "../Assets/math_7_1_arrow.png";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        <div>
          Great job! You correctly determined that the linear model for the data
          is <Italic>y</Italic> = 0.0033<Italic>x</Italic> + 40.
        </div>
        <div>That means that the memory card was initially filled to 40%.</div>
        <div>
          The slope of about 0.033 shows how much memory is filled for each
          image taken.
        </div>
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Keep in mind that to find the slope, you must divide the change in{" "}
          <Italic>y</Italic> by the change in <Italic>x</Italic>.
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "10px",
            top: "-160px",
            width: "115px",
            height: "50px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  } else if (props.data.indices[0] == 2) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Did you reverse the slope and the <Italic>y</Italic>-intercept in the
          linear model?
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "10px",
            top: "-160px",
            width: "115px",
            height: "50px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Think about using a memory card. Does the memory that is used increase
          or decrease as more images are added?
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "75px",
            top: "-170px",
            width: "125px",
            height: "50px",
            transform: "rotateZ(-135deg)",
          }}
        >
          <img src={Arrow} />
        </div>
      </Fragment>
    );
  } else if (props.data.indices[2] == 2) {
    contents = (
      <Fragment>
        <div className={css.text}>
          Look at the hints for the formula to find the slope.
        </div>
        <div className={css.text}>
          The change in <Italic>y</Italic> (percent) must be divided by the
          change in <Italic>x</Italic> (images).
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "330px",
            top: "-170px",
            width: "125px",
            height: "50px",
            transform: "rotateZ(-135deg)",
          }}
        >
          <img src={Arrow} />
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "440px",
            top: "-140px",
            width: "150px",
            height: "30px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "375px",
            top: "-310px",
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  } else {
    contents = (
      <Fragment>
        <div className={css.text}>
          You selected the correct linear model for the data. Use the equation
          of the linear model to determine how much memory was initially used.
        </div>
        <div
          style={{
            visibility: "hidden",
            position: "absolute",
            zIndex: 9999999999,
            left: "78px",
            top: "-42px",
            width: "50px",
            height: "30px",
            borderRadius: "50%",
            border: "4px solid #fa9e00",
          }}
        />
      </Fragment>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
