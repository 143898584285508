import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Determine the Meaning of Domain-Specific Language
        </span>
      </div>
      <div className={css.contents}>
        <span>In this lesson, you will ...</span>
        <BulletPoints>
          <li>learn the meaning of subject-related vocabulary</li>
          <li>understand text hints to figure out a word's definition</li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
