import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [
  [
    { id: 0, show: true },
    { id: 4, show: true },
  ],
  [
    { id: 1, show: true },
    { id: 5, show: true },
  ],
];

export const getDefaultState = () => {
  return {
    items: [[], []],
    right: [0, 1, 2, 3, 4, 5],
    showRight: [true, true, true, true, true, true],
  };
};

export const isCorrect = (data) => {
  if (
    data.items[0].length != correctAnswers[0].length ||
    data.items[1].length != correctAnswers[1].length
  )
    return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      if (
        data.items[i].filter((x) => x.id == correctAnswers[i][j].id).length == 0
      ) {
        return false;
      }
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.items[0].length == 0 && data.items[1].length == 0;
};
export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Even if an opinion is not explicitly stated in a passage, you can infer that
    the author would agree with a statement if you can find textual evidence in
    the passage to support it.
  </div>,
  <div>
    Read through each of the statements. If it appears to give an opinion that
    one of the authors would support, go back to the passage to try to find
    evidence that supports it.
  </div>,
  <div>
    Eliminate answer choices that cannot be supported by evidence from either
    passage.
  </div>,
  <div>
    Look for statements of opposing opinions in the answer choices. Figure out
    if one statement applies to one passage, and then consider if the other
    statement applies to the other passage.
  </div>,
];

export const showReader = () => {
  return true;
};
