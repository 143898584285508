import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FoldBox from "../../../../FoldBox";

const Contents = (props) => {
  const [boxes, setBoxes] = useState([false, false, false]);

  const boxHandler = (p) => {
    const data = [...boxes];
    data[p.index] = !data[p.index];
    setBoxes(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Sentence Structure</span>
      </div>
      <div className={css.contents}>
        <div>
          Writers can vary sentence <Italic>structure</Italic>.
        </div>
        <div>There are four basic sentence structures:</div>
        <div>
          A <Bold>simple sentence</Bold> has a subject and a predicate.
        </div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/13a6784aff8834a6794e6eee55d5930b.svg"
          width="40%"
        />
        <div>
          A <Bold>compound sentence</Bold> is made of two simple sentences
          joined by a comma and a coordinating conjunction—<Italic>and</Italic>,{" "}
          <Italic>but</Italic>, <Italic>or</Italic>, and <Italic>so</Italic>.{" "}
        </div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/74c1d4cfa32949a4321cfa23f9e6ccfe.svg"
          width="70%"
        />
        <div>
          A <Bold>complex sentence</Bold> has two parts: an independent clause
          and a dependent clause. The dependent clause begins with a
          subordinating conjunction and does not express a complete thought.
        </div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/37283effa5b286a28d70c47279f9c2b0.svg"
          width="70%"
        />
        <div>
          A <Bold>compound-complex</Bold> sentence has at least two independent
          clauses and one or more dependent clauses. In other words, it is a
          compound sentence with a dependent clause.
        </div>
        <BlockQuote>
          Tomás juggled three apples, and Cheryl rolled her eyes because she
          thought it was silly.
        </BlockQuote>
      </div>
    </div>
  );
};

export default Contents;
