import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Remember . . .</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>
            A compound sentence is made of two simple sentences joined by a
            coordinating conjunction.
          </li>
          <li>
            Coordinating conjunctions include <Italic>and</Italic>,{" "}
            <Italic>but</Italic>, <Italic>or</Italic>, and <Italic>so</Italic>.
          </li>
          <li>
            Place a comma before the coordinating conjunction in a compound
            sentence.
          </li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
