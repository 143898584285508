import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (
    props.data.icons[0] == correctAnswers[0] &&
    props.data.icons[1] == correctAnswers[1] &&
    props.data.icons[2] == correctAnswers[2] &&
    props.data.icons[3] == correctAnswers[3] &&
    props.data.icons[4] == correctAnswers[4] &&
    props.data.icons[5] == correctAnswers[5] &&
    props.data.icons[6] == correctAnswers[6]
  ) {
    contents = (
      <div className={css.text}>
        Nice job! You completed the picture graph by drawing 4 symbols to
        represent the number of books Miley read.
      </div>
    );
  }
  // empty
  else if (props.data.icons.filter((x) => x == true).length == 0) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.icons.filter((x) => x == true).length == 1) {
    contents = (
      <div className={css.text}>
        The row with the missing symbols represents the number of books Miley
        read. The table shows that she read 8 books. The key tells you that each
        book symbol equals 2 books. You need to show 4 symbols in the row for
        Miley to represent 8 books.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 2) {
    contents = (
      <div className={css.text}>
        Miley read 8 books. Each book symbol represents 2 books. Two symbols
        represent only 4 books. Try skip counting by 2s to 8 to find the correct
        number of symbols for this row.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 3) {
    contents = (
      <div className={css.text}>
        Miley read 8 books. Each book symbol represents 2 books. Three symbols
        represent only 6 books. Try skip counting by 2s to 8 to find the correct
        number of symbols for this row.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 4) {
    contents = (
      <div className={css.text}>
        The row with the missing symbols represents the number of books Miley
        read. The table shows that she read 8 books. The key tells you that each
        book symbol equals 2 books. You need to show 4 symbols in the row for
        Miley to represent 8 books.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 5) {
    contents = (
      <div className={css.text}>
        Miley read 8 books. Each book symbol represents 2 books. Five symbols
        represent 10 books. Try skip counting by 2s to 8 to find the correct
        number of symbols for this row.
      </div>
    );
  } else if (props.data.icons.filter((x) => x == true).length == 6) {
    contents = (
      <div className={css.text}>
        Miley read 8 books. Each book symbol represents 2 books. Six symbols
        represent 12 books. Try skip counting by 2s to 8 to find the correct
        number of symbols for this row.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
