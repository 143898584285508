import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Unit Fraction by a Whole Number
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Divide <Fraction numerator="1" denominator="2" /> ÷ 5.
        </div>
        <div>
          You can use fraction strips to help you divide a fraction by a whole
          number.
        </div>
        <Instructions>Click or tap each step.</Instructions>
        <div className={css.row}>
          <FoldBox fontSize="16px" width="48%" height="45px">
            <div>
              <Bold>Step 1:</Bold> Place a{" "}
              <Fraction numerator="1" denominator="2" /> fraction strip under a
              one-whole strip.
            </div>
            <img
              width="100%"
              src="https://d16qzots3do3wk.cloudfront.net/images/7acde09b1ed89070b8e38caa0527e4c9.svg"
            />
          </FoldBox>
          <FoldBox fontSize="16px" width="48%" height="75px">
            <div>
              <Bold>Step 2:</Bold> Find 5 fraction strips, all with the same
              denominator, that fit exactly under the{" "}
              <Fraction numerator="1" denominator="2" /> strip.
            </div>
            <div style={{ fontSize: "14px", lineHeight: "30px" }}>
              <img
                width="100%"
                src="https://d16qzots3do3wk.cloudfront.net/images/97398e0ace7a9ea9a3b7f20d6be4e246.svg"
              />
              <div>
                Five
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  width="20px"
                  numerator="1"
                  denominator="10"
                />{" "}
                strips fit exactly under the
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  numerator="1"
                  denominator="2"
                />{" "}
                strip.
              </div>
              <div>
                Each
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  width="20px"
                  numerator="1"
                  denominator="10"
                />{" "}
                -part is one-fifth of
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  numerator="1"
                  denominator="2"
                />
              </div>
              <div>
                So,
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  numerator="1"
                  denominator="2"
                />{" "}
                ÷ 5 =
                <Fraction
                  verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                  width="20px"
                  numerator="1"
                  denominator="10"
                />
              </div>
            </div>
          </FoldBox>
        </div>
        <div style={{ height: "200px" }} />
      </div>
    </div>
  );
};

export default Contents;
