import * as React from "react"

function PlayersIcon(props) {
    return (
        <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_3171_919)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 4a2 2 0 100-4 2 2 0 000 4zM3.5 2.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm8 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM1 10.5V8.655L.085 7.28A.483.483 0 010 7.005v-2c0-.275.225-.5.5-.5h2.09c-.055.155-.09.325-.09.5v3c0 .295.085.585.25.83l.75 1.12V11h-2a.501.501 0 01-.5-.5zm10.5-6H9.41c.055.155.09.325.09.5v3c0 .295-.085.585-.25.83L8.5 9.95v1.045h2c.275 0 .5-.225.5-.5v-1.85l.915-1.375A.495.495 0 0012 6.995v-2c0-.275-.225-.5-.5-.5V4.5zM3.5 8V5c0-.275.225-.5.5-.5h4c.275 0 .5.225.5.5v3c0 .095-.03.195-.085.275L7.5 9.65v1.85c0 .275-.225.5-.5.5H5a.501.501 0 01-.5-.5V9.65l-.915-1.375A.483.483 0 013.5 8z"
                    fill="#444"
                />
            </g>
            <defs>
                <clipPath id="clip0_3171_919">
                    <path fill="#fff" d="M0 0H12V12H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PlayersIcon
