import React from "react";
import css from "./Sidebar.module.css";

import BooksRead from "../Assets/books_read.JPG";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        The table below shows the number of books read by four students in
        October. Use the data in the table to complete the picture graph.
      </div>
      <div className={css.instructions}>
        Click or tap the graph to show the missing symbol. Fill the boxes from
        left to right. Click or tap the Reset button to start over.
      </div>
      <img src={BooksRead} />
    </div>
  );
};

export default Sidebar;
