import React, { useState } from "react";
import css from "./SelectWorld.module.css";

const SelectWorld = (props) => {
  const [worldIndex, setWorldIndex] = useState(2);

  const worlds = [
    {
      name: "Planet Zebus",
      description:
        "A dry, dusty planet in deep outer space that may be a new hope for sustainable and renewable resources. Planet Zebus is for explorers.",
      icon: "https://practice.gogetwaggle.com/student/public/images/world2-mini.png",
    },
    {
      name: "Hero City",
      description:
        "A shadowy metropolis where adventure lurks behind every corner. Hero City is for defenders of justice.",
      icon: "https://practice.gogetwaggle.com/student/public/images/world3-mini.png",
    },
    {
      name: "Oasis Outpost",
      description:
        "A natural environment full of trees, lakes, and valleys. Oasis Outpost is for nature lovers.",
      icon: "https://practice.gogetwaggle.com/student/public/images/world1-mini.png",
    },
  ];

  const world = worlds[worldIndex];

  const otherWorlds = worlds.filter((x) => x != world);

  const worldHandler = (w) => {
    const index = worlds.indexOf(w);
    setWorldIndex(index);
  };

  const closeHandler = () => {
    props.onClose();
  };

  return (
    <div className={css.root}>
      <img
        onMouseDown={closeHandler}
        className={css.close}
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAPYSURBVHgBzZi7T9tQFMZPYgY6ILlioIElXaALqgsMFUvM1gEkEP8AHUDdQsXSiiHpgrqVjDAxIXVABcHQTnEmWomHKzFUTOkCbE3FUhCPfse1oyTYvsfEhH6S5Xt977V/PufcZ4JuKcMwzGQyaSQSicz19TXfdTzW3eIKrjJfKPt+dXVl2bZt0S2UiFIZUDqgsoCZrYGRqozLury8fAfYsrSRCNAFy7lgcWhFCqqpKgwODmYBtw44k+KTgXeOp1Kp38fHx3ZYxVDAgYGBDwDLI9lO8UvHu8d7enr0o6OjL0GVfF3MLtU07ROSJrVGNlw+ApdXGguSfrXb2tqK1Do4luEa5IZuuJjdits4tV5pP3fXAQJuCnHxnu5Pz92O89V7UI1BxF0aZmbXpul+VUE8PvbisRqDgMuREK63t5fwpyRVR0cHD1fS6jr6QM7LOIBsPdymJK1zuRytrq7S5uYmjY2NKetznWKxSEtLSzQ3N0cSYXqc5ZGE004MIji5YxiqhvyBycnJat40TUK80OHhIQXB8Q956u/vd6y5vb1NKqEvnOHdludikwRioEYxgJ8lG+E8SV0NwCzfNXdVIp5jh4eHbzxrtGQQHGttbY12d3dJoHZ4tqR1d3dPSefZg4MD5+5nBQ+yr68vEG55edm5pOKlGgOyKZ9IG3l/HwTpFwa3gWNxHDLgG6QfRWkYBhkXnKt2BuSZI/JqRQrZBJwj7sVRV8Z1H7csK7Ccy5qBg/QkNSHurUExx+IyyWAeJnYxDzGRXRw2lNRKNZgrVNEwp75yd2Sxw3lqAvIHW9CkCMNMGBzHG3eesHEyIuQ3tmAKFnwhqc0fWVhYoCA4D5AVByQG6o+Y5ZI2CTUzM0NhcEH5Wk1PT5NUYLOSOzs7Fv07CVDq9PSUVHCq58J5mK1XZjZnuQUvP5DMx6VSyVksdHZ2hkI0wnjuZtfOz8/T+fk5qQSedYTDhrPkd7eZv0ggXs9lMhknlqTWGB0dddptbW35esFP7rK/XN2T4C8XccvS/6EV/PxLTlRnEhDnSRiLdymOPT638fLVbefJyckfxOKZdMi5Q73e39+3vEzdvpj3oxi4HyL5nO5Hhb29vbp9ue/ZDOKx1UcfLBtx96zxoe9qBjEwwQ2odeKDzRG/gtADzBb17AIsF7hpCz0fREx+Rsf5iaQRdcWjEnprBVPZW8Dlw+opT1j5BLSrq2sDL+POo9zcC1XAwfoEOoSlqhj1EJ0PmPL4+wwsmo7S1rVY4eLiYtHvoDIWwFoNDQ2ZsIIJ0KfIpgGQ9sKAYXCrIG8jXeIVk7soiay/W5O4rklnc1cAAAAASUVORK5CYII="
      />
      <div className={css.title}>Choose a World for your Learning Journey</div>
      <div className={css.dots} />
      <div className={css.circle} />
      <div className={css.selected}>
        <img src={world.icon} />
      </div>
      <div className={css.left}>
        <img src={otherWorlds[0].icon} />
        <div
          onMouseDown={() => worldHandler(otherWorlds[0])}
          className={css.world}
        >
          {otherWorlds[0].name}
        </div>
      </div>
      <div className={css.right}>
        <img src={otherWorlds[1].icon} />
        <div
          onMouseDown={() => worldHandler(otherWorlds[1])}
          className={css.world}
        >
          {otherWorlds[1].name}
        </div>
      </div>
      <div className={css.line} />
      <img
        className={css.planet}
        src="https://practice.gogetwaggle.com/student/app/static/media/dome.png"
      />
      <div className={css.box}>
        <div className={css.title}>{world.name}</div>
        <div className={css.desc}>{world.description}</div>
        <div onMouseDown={closeHandler} className={css.button}>
          CHOOSE
        </div>
      </div>
    </div>
  );
};

export default SelectWorld;
