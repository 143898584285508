import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You are correct! The quotient of 153.86 ÷ 14 is 10.99. That makes sense
        because 10.99 is close to the estimate 140 ÷ 14 = 10. By placing the
        decimal point just above the decimal in 153.86, you can find the correct
        value for the quotient.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Use estimation to see if your answer makes sense. Your answer should be
        close to the quotient of 140 ÷ 14.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
