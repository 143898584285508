import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You have correctly identified the opinions with which each author would
        agree. Even if an opinion is not stated directly, you can infer that the
        author would agree with it based on the textual evidence in the passage.
        Additionally, by comparing and contrasting the two passages, you see
        that the authors make opposite claims.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 2 || x.id == 3).length > 0 ||
    props.data.items[1].filter((x) => x.id == 2 || x.id == 3).length > 0
  ) {
    contents = (
      <div className={css.text}>
        Neither passage includes any information about sales of GPS devices at
        electronics stores. Try again.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 1).length > 0) {
    contents = (
      <div className={css.text}>
        The author of "GPS in All Cars" would not agree with the opinion “GPS
        devices can cause danger.” Review paragraph 4 of “GPS in All Cars” and
        try again.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 5).length > 0) {
    contents = (
      <div className={css.text}>
        The author of "GPS in All Cars" would not agree with the opinion “GPS
        devices should not be relied upon.” Review paragraphs 1 and 3 of “GPS in
        All Cars” and try again.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 0).length > 0) {
    contents = (
      <div className={css.text}>
        The author of "Don't Rely on the GPS" would not agree with the opinion
        “GPS devices can improve safety.” Review paragraphs 2 and 4 of “Don’t
        Rely on the GPS” and try again.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 4).length > 0) {
    contents = (
      <div className={css.text}>
        The author of "Don't Rely on the GPS" would not agree with the opinion
        “GPS devices should be relied upon.” Consider the title of the passage,
        review the information presented in it, and try again.
      </div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 0 || x.id == 4).length == 2 &&
    props.data.items[1].length == 0
  ) {
    contents = (
      <div className={css.text}>
        The author of GPS in All Cars would agree with the statements, GPS
        devices can improve safety and GPS devices should be relied upon. The
        author of Dont Rely on the GPS would agree with the statements, GPS
        devices can cause danger and GPS devices should not be relied upon. Drag
        these statements to the appropriate box to move on to the next question.
      </div>
    );
  } else if (
    props.data.items[0].filter((x) => x.id == 0 || x.id == 4).length == 2 &&
    props.data.items[1].length > 0
  ) {
    contents = (
      <div className={css.text}>
        You have found both statements that the author of "GPS in All Cars"
        would agree with. Look for the statements that the author of "Don't Rely
        on the GPS" would agree with.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 0).length == 1) {
    contents = (
      <div className={css.text}>
        You have found one statement that the author of "GPS in All Cars" would
        agree with: "GPS devices can improve safety." Look for another
        statement.
      </div>
    );
  } else if (props.data.items[0].filter((x) => x.id == 4).length == 1) {
    contents = (
      <div className={css.text}>
        You have found one statement that the author of "GPS in All Cars" would
        agree with: "GPS devices should be relied upon." Look for another
        statement.
      </div>
    );
  } else if (
    props.data.items[1].filter((x) => x.id == 1 || x.id == 5).length == 2 &&
    props.data.items[0].length == 0
  ) {
    contents = (
      <div className={css.text}>
        You have found both statements that the author of "Don't Rely on the
        GPS" would agree with. Look for the statements that the author of "GPS
        in All Cars" would agree with.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 1).length > 0) {
    contents = (
      <div className={css.text}>
        You have found one statement that the author of "Don't Rely on the GPS"
        would agree with: "GPS devices can cause danger." Look for another
        statement.
      </div>
    );
  } else if (props.data.items[1].filter((x) => x.id == 5).length > 0) {
    contents = (
      <div className={css.text}>
        You have found one statement that the author of "Don't Rely on the GPS"
        would agree with: "GPS devices should not be relied upon." Look for
        another statement.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
