import React from "react";
import css from "./ProfilePopup.module.css";

import Avatar from "./Assets/main_avatar.png";

import ItemBox from "./ItemBox";
import DropDown from "../../../components/DropDown";

const ProfilePopup = (props) => {
  const closeHandler = () => {
    props.onClose();
  };

  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.columns}>
          <div className={css.left}>
            <div className={css.avatar}>
              <img src={Avatar} />
            </div>
          </div>
          <div className={css.right}>
            <div className={css.top}>
              <div className={css.name}>{props.data.name}</div>
              <img
                onMouseDown={closeHandler}
                src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiIGZpbGw9IiMxMzEzMTMiLz4KPHBhdGggZD0iTTE0LjY1MzYgMTJMMTguNjMzNyA4LjAxOTk0QzE5LjEyMjEgNy41MzE1MyAxOS4xMjIxIDYuNzM5NjYgMTguNjMzNyA2LjI1MDg1TDE3Ljc0OTEgNS4zNjYzMUMxNy4yNjA3IDQuODc3OSAxNi40Njg5IDQuODc3OSAxNS45ODAxIDUuMzY2MzFMMTIgOS4zNDYzNkw4LjAxOTk0IDUuMzY2MzFDNy41MzE1MyA0Ljg3NzkgNi43Mzk2NiA0Ljg3NzkgNi4yNTA4NSA1LjM2NjMxTDUuMzY2MzEgNi4yNTA4NUM0Ljg3NzkgNi43MzkyNiA0Ljg3NzkgNy41MzExNCA1LjM2NjMxIDguMDE5OTRMOS4zNDYzNiAxMkw1LjM2NjMxIDE1Ljk4MDFDNC44Nzc5IDE2LjQ2ODUgNC44Nzc5IDE3LjI2MDMgNS4zNjYzMSAxNy43NDkxTDYuMjUwODUgMTguNjMzN0M2LjczOTI2IDE5LjEyMjEgNy41MzE1MyAxOS4xMjIxIDguMDE5OTQgMTguNjMzN0wxMiAxNC42NTM2TDE1Ljk4MDEgMTguNjMzN0MxNi40Njg1IDE5LjEyMjEgMTcuMjYwNyAxOS4xMjIxIDE3Ljc0OTEgMTguNjMzN0wxOC42MzM3IDE3Ljc0OTFDMTkuMTIyMSAxNy4yNjA3IDE5LjEyMjEgMTYuNDY4OSAxOC42MzM3IDE1Ljk4MDFMMTQuNjUzNiAxMloiIGZpbGw9IiNGQkZDRkQiLz4KPC9zdmc+Cg=="
                className={css.close}
              />
            </div>
            <div className={css.dropdown}>
              <DropDown
                width="100%"
                selectedIndex={1}
                data={[
                  {
                    text: (
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <img
                          style={{ marginRight: "20px" }}
                          width="64px"
                          height="64px"
                          src="https://practice.gogetwaggle.com/student/public/badges/power-player.svg"
                        />
                        <div>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#131313",
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            TOP SCORER
                          </div>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#131313",
                              textAlign: "left",
                            }}
                          >
                            Number of Points
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    text: (
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                        }}
                      >
                        <img
                          style={{ marginRight: "20px" }}
                          width="64px"
                          height="64px"
                          src="https://practice.gogetwaggle.com/student/public/badges/newbie.svg"
                        />
                        <div>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#131313",
                              textAlign: "left",
                              fontWeight: "bold",
                            }}
                          >
                            NEWBIE
                          </div>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#131313",
                              textAlign: "left",
                            }}
                          >
                            The Newbie badge is the first of many badges you'll
                            earn as you take your learning journey. Get started
                            to earn more.
                          </div>
                        </div>
                      </div>
                    ),
                  },
                ]}
                arrow={
                  <img
                    style={{ marginTop: "25px" }}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADXSURBVHgB3VCxDcIwELShStJYSQZgBDpaRmADkpKODYAJSElH2IARoKMMG8AAMdDYdOYMAVnBGEQHJ33yet+970zIz6OpP3Ecj3zfzzzPO0spty5BFEV9cJfgMnDXFIME87nBScqyXLwS45cbo7Rh4eUV8Z34FgE2iiAIKPquMe/B5u4exyZWSk0459n1DYQQK9sS5DyhOpTSmUU81j01D8IwHII8JW6keKP8EcE8geUNrO/17Z+InxZUS4r6Elg+wtmgLnaCMdZGpIMu3ZNvAGELxch/4wLec1DoiagYYAAAAABJRU5ErkJggg=="
                  />
                }
                /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
              />
            </div>
            <div className={css.details}>
              <div className={css.score}>
                <div className={css.header}>SCORE</div>
                <div className={css.value}>
                  {props.data.score.points.overall}
                </div>
              </div>
              <div className={css.gems}>
                <div className={css.header}>GEMS</div>
                <div className={css.content}>
                  <div className={css.gem}>
                    <div className={css.icon} />
                    <div className={css.value}>
                      {props.data.score.emerald.overall}
                    </div>
                  </div>
                  <div className={css.gem}>
                    <div className={css.icon} />
                    <div className={css.value}>
                      {props.data.score.ruby.overall}
                    </div>
                  </div>
                  <div className={css.gem}>
                    <div className={css.icon} />
                    <div className={css.value}>
                      {props.data.score.saphire.overall}
                    </div>
                  </div>
                  <div className={css.gem}>
                    <div className={css.icon} />
                    <div className={css.value}>
                      {props.data.score.diamond.overall}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.collection}>
              <div className={css.text}>2 of 13 collected</div>
              <div className={css.content}>
                <div className={css.row}>
                  <ItemBox
                    color="#FFBE2E"
                    icon="https://practice.gogetwaggle.com/student/public/badges/power-player.svg"
                    name="Top Scorer"
                    level="1"
                    total="5"
                  />
                  <ItemBox
                    color="#AD79E9"
                    icon="https://practice.gogetwaggle.com/student/public/badges/newbie.svg"
                    name="Newbie"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/100000-pts-club.svg"
                    disabled
                    name="100k"
                  />
                </div>
                <div className={css.row}>
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/centenial.svg"
                    disabled
                    name="Centennial"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/collector-badge.svg"
                    disabled
                    name="Collector"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/reading-informational-text.svg"
                    disabled
                    name="Informational Texts"
                    level="0"
                    total="14"
                  />
                </div>
                <div className={css.row}>
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/waggle-wise.svg"
                    disabled
                    name="Knowledge Seeker"
                    level="0"
                    total="5"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/language.svg"
                    disabled
                    name="Language"
                    level="0"
                    total="31"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/reading-literature.svg"
                    disabled
                    name="Literature"
                    level="0"
                    total="15"
                  />
                </div>
                <div className={css.row}>
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/marathoner.svg"
                    disabled
                    name="Marathoner"
                    level="1"
                    total="5"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/peak-perfomer.svg"
                    disabled
                    name="Peak Performer"
                    level="0"
                    total="5"
                  />
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/skill-savy.svg"
                    disabled
                    name="Skill Maestro"
                    level="0"
                    total="5"
                  />
                </div>
                <div className={css.row}>
                  <ItemBox
                    icon="https://practice.gogetwaggle.com/student/public/badges/writing.svg"
                    disabled
                    name="Writing"
                    level="0"
                    total="6"
                  />
                  <div style={{ padding: "10px" }} />
                  <div style={{ padding: "10px" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
