import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        That Henry took a horse and carriage on his first trip to California and
        that the rail line has just been completed led you to conclude that
        Henry has not been on a train before.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please highlight your answer!</div>;
  } else if (props.data.highlights[0]) {
    contents = (
      <div className={css.text}>
        Although there were few rail lines in 1869, the statement "It is 1869"
        this does not support the conclusion that Henry has never ridden a
        train. Try again.
      </div>
    );
  } else if (props.data.highlights[1]) {
    contents = (
      <div className={css.text}>
        The location of the Carlson’s home in Sacramento is not evidence that
        Henry has never ridden a train. Try again.
      </div>
    );
  } else if (props.data.highlights[2]) {
    contents = (
      <div className={css.text}>
        You have highlighted the sentence that begins "Henry reads in a local
        newspaper." Although the rail line that connects California to Utah is
        new, this does not give you enough information to know that Henry has
        never ridden a train. Try again.
      </div>
    );
  } else if (props.data.highlights[3]) {
    contents = (
      <div className={css.text}>
        Make sure you select sentences that talk about the train line and
        Henry’s experience with train travel. Try again.
      </div>
    );
  } else if (props.data.highlights[4]) {
    contents = (
      <div className={css.text}>
        Make sure you select sentences that talk about the train line and
        Henry’s experience with train travel. Try again.
      </div>
    );
  } else if (props.data.highlights[5]) {
    contents = (
      <div className={css.text}>
        Make sure you select sentences that talk about the train line and
        Henry’s experience with train travel. Try again.
      </div>
    );
  } else if (props.data.highlights[6]) {
    contents = (
      <div className={css.text}>
        Henry picking up the paper and shaking it shows that he is excited about
        the train connecting his current home to his childhood home. However,
        this excitement does not support the idea that he has never been on a
        train. Try again.
      </div>
    );
  } else if (props.data.highlights[7]) {
    contents = (
      <div className={css.text}>
        {" "}
        You know that Henry has lived in California and Utah, and since the
        transcontinental railroad is the first rail line linking California to
        areas to the east, you can assume that he has not had the opportunity to
        travel by train. The detail "This is the first railroad linking this
        part of the country with areas to the east" is correct. Look for one
        more detail that shows he has not been on a train. Think about how he
        moved from Utah to California. Try again.
      </div>
    );
  } else if (props.data.highlights[8]) {
    contents = (
      <div className={css.text}>
        When Henry moved from Utah to California, he did not travel by train but
        by horse and carriage. The detail "When I moved here from Utah, I had to
        travel by horse and carriage" is correct. Which other detail in the
        story shows that Henry has not been on a train? Try again.
      </div>
    );
  } else if (props.data.highlights[9]) {
    contents = (
      <div className={css.text}>
        Henry’s excitement about the length of his first trip to California when
        he says "'It was a very long trip!'" does not support the conclusion
        that Henry has never been on a train. Try again.
      </div>
    );
  } else if (props.data.highlights[10]) {
    contents = (
      <div className={css.text}>
        Henry’s wanting to see his family and the length of time it would take
        without train travel do not indicate that he has not been on a train.
        Try again.
      </div>
    );
  } else if (props.data.highlights[11]) {
    contents = (
      <div className={css.text}>
        Henry’s wanting to see his family and the length of time it would take
        without train travel do not indicate that he has not been on a train.
        Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
