import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import videoEN from "../../assets/math_1_en.mp4";
import videoES from "../../assets/math_1_es.mp4";
import numberline from "../../assets/art/954678722.png";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../usePopupMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { useLanguage } from "./useLanguage"

export const Screen2 = () => {
  const history = useHistory();

  const language = useLanguage();
  const video = language === 'en' ? videoEN : videoES

  const onNextScreen = () => history.push(`../3/${language}`);

  const {
    foxState,
    setFoxState,
    continueState,
    answerState,
    checkState,
    currentAnswer,
    onCheck,
    open,
    forceOpen,
    selectAnswer,
  } = useMCFeedback({
    8: { feedback: `dd1error1_G1_010A2_math_vo_${language}` },
    5: { feedback: `dd1error2_G1_010A2_math_vo_${language}` },
    4: { correct: true },
  }, language);

  const { getHint, playVideo, closeVideo } = useHints(
    [`hint1_G1_010A2_math_vo_${language}`,
    `hint2_G1_010A2_math_vo_${language}`],
    setFoxState,
    language
  );

  const playInstruction = useCallback(
    () => playVoiceover(`instruction_G1_010A2_math_vo_${language}`, setFoxState),
    [language, setFoxState]
  );

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.instructionContent}>
        <img className={css.numberline} src={numberline} />
      </div>

      <div className={css.prompt}>
        <span>6 - 2 = </span>
        <AnswerBox
          state={answerState}
          onClick={forceOpen}
          answer={currentAnswer}
        />
      </div>

      <div className={css.prompt}>
        {open && <PopupPromptBox onChange={selectAnswer} options={[8, 5, 4]} />}
      </div>

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
