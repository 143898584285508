import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            {languageLookup(1)}Read the sentences. Look at the bolded words, and
            then answer the question.
          </Bold>
        </div>
        <ol>
          <li>
            {languageLookup(2)}The <Bold>{languageLookup(3)}squirrels’</Bold>{" "}
            {languageLookup(4)}nuts <Bold>{languageLookup(5)}weren’t</Bold>{" "}
            {languageLookup(6)}hidden in my yard, but I found some in the tree.
          </li>
          <li>
            <Bold>{languageLookup(7)}Arabella’s</Bold> {languageLookup(8)}uncle
            told me that <Bold>{languageLookup(9)}he’s</Bold>{" "}
            {languageLookup(10)}planning to buy her a new bike.
          </li>
          <li>
            {languageLookup(11)}Do you think we should read the sign on Mr.{" "}
            <Bold>{languageLookup(12)}Singh’s</Bold> {languageLookup(13)}door?
          </li>
          <li>
            {languageLookup(14)}“<Bold>{languageLookup(15)}Who’s</Bold>{" "}
            {languageLookup(16)}there?” asked Rick, as he walked toward the
            shadow in Nana <Bold>{languageLookup(17)}Hala’s</Bold>{" "}
            {languageLookup(18)}front door.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
