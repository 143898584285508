import React, { useState } from "react";
import css from "./Contents.module.css";

import Highlight from "../../../../Highlight";

import Reset from "../../../../Icons/Reset";

const Contents = (props) => {
  const selectHandler = (index) => {
    const data = { highlights: [...props.data.highlights] };
    data.highlights[index] = !data.highlights[index];

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[0]}
          index={0}
        >
          Is is 1869.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[1]}
          index={1}
        >
          Henry Carlson and his wife, Patricia, live in Sacramento, California.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[2]}
          index={2}
        >
          Henry reads in a local newspaper that the Central Pacific Railroad
          Company has finished the railroad connecting his home in California to
          his childhood home in Utah, where much of his family still lives.
        </Highlight>
      </div>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[3]}
          index={3}
        >
          Henry quickly lowered the newspaper and exclaimed, "Yes! It's finally
          completed!"
        </Highlight>
      </div>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[4]}
          index={4}
        >
          Patricia and Jacob ran into the living room to find Henry smiling
          widely.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[5]}
          index={5}
        >
          "Why are you so happy, Daddy?" asked Jacob.
        </Highlight>
      </div>
      <div className={css.text}>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[6]}
          index={6}
        >
          Henry picked up the newspaper and shook it excitedly as he explained,
          "The transcontinental railroad has finally connected Sacramento to
          Utah.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[7]}
          index={7}
        >
          This is the first railroad linking this part of the country with areas
          to the east.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[8]}
          index={8}
        >
          When I moved here from Utah, I had to travel by horse and carriage.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[9]}
          index={9}
        >
          It was a very long trip!{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[10]}
          index={10}
        >
          Now I can see my family again without making that very long and
          difficult journey.{" "}
        </Highlight>
        <Highlight
          onSelect={selectHandler}
          selected={props.data.highlights[11]}
          index={11}
        >
          I miss them so much."
        </Highlight>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
