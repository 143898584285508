import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const lines = {
  0: {
    en: "Great job! There are 4 rows of 10 unit squares. So, you can use repeated addition to add 10+10+10+10=40 or multiply 4×10=40 to find that the area of the rectangle is 40 square feet.",
    sp: "¡Buen trabajo! Hay 4 filas de 10 cuadrados de una unidad. Entonces, puedes utilizar la suma repetida para sumar 10+10+10+10=40 o multiplicar 4x10=40 para hallar que el área del rectángulo mide 40 pies cuadrados.",
  },
  1: {
    en: "Find the number of rows again! Remember that in an array, rows go across.",
    sp: "¡Encuentra el número de filas de nuevo! Recuerde que en una matriz, las filas se cruzan.",
  },
  2: {
    en: "Give the addition another try! Since there are 4 rows, add the number of squares in each row four times.",
    sp: "¡Intenta sumar otra vez! Dado que hay 4 filas, suma el número de cuadrados que hay en cada fila cuatro veces.",
  },
  3: {
    en: "Try the multiplication again! Multiply the number of rows by the number of squares in each row.",
    sp: "¡Intenta multiplicar otra vez! Multiplica el número de filas por el número de cuadrados de cada fila.",
  },
  4: {
    en: "Find the area again! Four is the number of rows. You need to multiply the number of rows by the number of squares in each row.",
    sp: "¡Halla el área otra vez! Cuatro es el número de filas. Debes multiplicar el número de filas por el número de cuadrados que hay en cada fila.",
  },
  5: {
    en: "Find the area again! Four is the number of rows. You need to multiply the number of rows by the number of squares in each row.",
    sp: "¡Halla el área otra vez! Cuatro es el número de filas. Debes multiplicar el número de filas por el número de cuadrados que hay en cada fila.",
  },
  6: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(6)}</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.indices[2] != correctAnswers[2]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.indices[3] == 1) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  } else if (props.data.indices[3] == 2) {
    contents = <div className={css.text}>{languageLookup(5)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
