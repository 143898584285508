import React, { useState } from "react";
import css from "./Home.module.css";

import Welcome from "./Welcome";
import SelectWorld from "./SelectWorld";
import EditAvatar from "./EditAvatar";

const Home = (props) => {
  const [page, setPage] = useState(null);


  const pageHandler = (id) => {
    setPage(id);
  };

  let contents = null;
  switch (page) {
    case "world":
      contents = <SelectWorld {...props} onClose={() => pageHandler(null)} />;
      break;
    case "avatar":
      contents = <EditAvatar {...props} onClose={() => pageHandler(null)} />;
      break;
    default:
      contents = (
        <Welcome
          {...props}
          onSelectAvatar={() => pageHandler("avatar")}
          onSelectWorld={() => pageHandler("world")}
        />
      );
      break;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Home;
