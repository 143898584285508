import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <img
        width="90%"
        src="https://d16qzots3do3wk.cloudfront.net/images/6213e7bb2f3e89daa97d5f6f34f14330.svg"
      />
    </div>
  );
};

export default Sidebar;
