import React, { Fragment } from "react";
import css from "./Done.module.css";

import { correctAnswers, isEmpty, isCorrect } from "../Controller";

const Done = (props) => {
  let contents;
  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You can use the Pythagorean theorem to determine that the path between
        home plate, third base, and the bottom of the foul pole along the first
        base line forms a right triangle.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0 || props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        The Pythagorean theorem states that in right triangle,{" "}
        <span style={{ fontStyle: "italic" }}>a²</span> +{" "}
        <span style={{ fontStyle: "italic" }}>b²</span> ={" "}
        <span style={{ fontStyle: "italic" }}>c²</span> or side² + side² =
        hypotenuse². Which side is the hypotenuse?
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        The distance between home plate and third base is{" "}
        <span style={{ fontStyle: "italic" }}>x</span>, or 90 feet, which is one
        of the sides of the triangle. The distance between home plate and the
        bottom of the foul pole is 90 + 126 = 216 feet, which is the other side
        of the triangle. The distance between the bottom of the foul pole and
        third base is z, which is the hypotenuse of the right triangle. Using
        the Pythagorean theorem, <span style={{ fontStyle: "italic" }}>x</span>²
        + <span style={{ fontStyle: "italic" }}>y</span>² ={" "}
        <span style={{ fontStyle: "italic" }}>z</span>² or 90² + 216² ={" "}
        <span style={{ fontStyle: "italic" }}>z</span>².
      </div>
    );
  } else if (props.data.left[0] == 3) {
    contents = (
      <div className={css.text}>
        The longer side of the right triangle is 90 + 126 = 216 feet, not 90
        feet.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
