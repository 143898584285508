import React from "react";
import css from "./Reset.module.css";

const Reset = (props) => {
  const resetHandler = () => {
    if (props.onReset) props.onReset();
  };

  return (
    <div className={css.root} style={props.style} onMouseDown={resetHandler}>
      <img
        src="https://practice.gogetwaggle.com/student/public/images/ams/RefreshButton.png"
        alt="refresh"
      />
    </div>
  );
};

export default Reset;
