import { Howl, Howler } from "howler";

// Tap play to view this lesson again

import tap_arrow_to_begin from "./assets/vo/tap_arrow_to_begin.mp3";
import tap_play_get_started from "./assets/vo/tap_play_get_started.mp3";
import lesson_moveforward from "./assets/vo/lesson_moveforward.mp3";
import waytogo from "./assets/vo/waytogo.mp3";
import go_on_arrow from "./assets/vo/go_on_arrow.mp3";
import error from "./assets/vo/error_sfx.mp3";
import success from "./assets/vo/success_sfx.mp3";
import startApply from "./assets/vo/start_apply.mp3";

import dragStart from "./assets/drag-start.mp3";
import dragEnd from "./assets/drag-end.mp3";

import math_1_en_vo from "./math-1-voiceovers";
import math_1_es_vo from "./math-1-es-voiceovers";
import ela_1_vo from "./ela-1-voiceovers";

import { FOX_STATE } from "./Fox";
const audios = {
  startApply,
  error,
  success,
  go_on_arrow,
  waytogo,
  dragStart,
  dragEnd,
  lesson_moveforward,
  tap_arrow_to_begin,
  tap_play_get_started,
  ...math_1_en_vo,
  ...math_1_es_vo,
  ...ela_1_vo,

};

const pause = (promise) => new Promise((resolve) => setTimeout(resolve, 700));
const shortPause = (promise) =>
  new Promise((resolve) => setTimeout(resolve, 200));

export const playVoiceovers = (tags, setFoxState) =>
  tags.reduce(
    (acc, curr) =>
      acc.then(shortPause).then(() => playVoiceover(curr, setFoxState)),
    Promise.resolve()
  );

let currentVO = null;

export const stopActiveVoiceover = () => {
  if (currentVO) {
    try {
      currentVO.stop();
    } catch (e) { }
  }
};

export const playSoundEffect = (tag) => {
  return new Promise((resolve) => {
    currentVO = new Howl({
      src: audios[tag],
      autoplay: true,
      onend: resolve,
    });
  });
};

export const playVoiceover = (tag, setFoxState) => {
  console.info("Playing voiceover", tag);

  if (!audios[tag]) {
    console.error("COULD NOT FIND AUDIO", tag)
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const onPlay = () => {
      setFoxState && setFoxState(FOX_STATE.talking);
    };
    const onDone = () => {
      setFoxState && setFoxState(FOX_STATE.talkEnd);
      pause()
        .then(resolve)
        .then(() => setFoxState && setFoxState(FOX_STATE.idle));
    };

    stopActiveVoiceover();
    currentVO = new Howl({
      src: audios[tag],
      autoplay: true,
      onplay: onPlay,
      onend: onDone,
    });
  });
};
