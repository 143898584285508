import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Apply: Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.contents}>
        <div>Roger has 2 yards of string.</div>
        <div>
          He cuts the string into pieces that are
          <Fraction numerator="1" denominator="4" /> -yard long.
        </div>
        <div>How many pieces of string does Roger have?</div>
        <Instructions>
          Choose the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>Roger has</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: 2 }, { text: 4 }, { text: 8 }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pieces of string.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
