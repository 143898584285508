import React from "react";
import css from "./Feedback.module.css";

import Audio from "../../../../components/Icons/Audio";
import Close from "../../../../components/Icons/Close";
const Feedback = (props) => {
  let style = {
    background: "#f9f4ec",
    borderTop: "8px solid #fcbe39",
  };

  style = { ...style, ...props.style };

  const icon = props.audio ? (
    <div className={css.icon}>
      <Audio />
    </div>
  ) : null;

  const title = props.title ? (
    <div className={css.text}>{props.title}</div>
  ) : null;

  const close = props.close ? (
    <div className={css.close}>
      <Close onClick={props.onClose} />
    </div>
  ) : null;

  return (
    <div className={css.root} style={style}>
      <div className={css.mask} />
      {icon}
      {title}
      <div className={css.contents}>{props.children}</div>
      {close}
    </div>
  );
};

export default Feedback;
