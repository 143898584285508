const data = `ROLLOVER TEACHER  DEMO TIPS					
LOCATION	ID	Title Copy	Body Copy	Placement/Trigger Notes	
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_exportbutton	Export	Exports the current Report.  Not available in the demo.  	Home>righthand panel>click View Class Activity>all 4 tabs>  Export button	
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_viewgrowthbutton	Proficiency Growth Report	Displays the Proficiency Growth Report for the selected grade and subject.  Not available in the demo.	Home>righthand panel>click Class Activity>all 4 tabs>  View Growth button	This actually works, doesnt need a tip
Teacher Home screen: righthand panel: Class Activity view: Students tab	classactivity_changeselectionsbutton	Change Selections	Allows edits to selections.  Not available in the demo.	Home>righthand panel>click Class Activity>Students tab> Change Selections button	
Teacher Home screen: righthand panel: Class Activity view: Standards tab	classactivity_standardstab_standards	Standards	functionality?  Not available in the demo.	Home>righthand panel>click Class Activity>Standards tab> hovering over individual standards	
Teacher Home screen: righthand panel: Class Activity view: Skills tab	classactivity_skillstab_activity	Skills	functionality?  Not available in the demo.	Home>righthand panel>click Class Activity>Skills tab> hovering over individual skill	
Teacher Home screen: righthand panel: Class Activity view: Student tab	classactivity_studentstab_student	Select Student 	functionality?  Not available in the demo.	Home>righthand panel>click Class Activity>Students tab> hovering over individual student names	
Teacher Home screen: righthand panel: Mindset Report: Export button	mindset_exportbutton	Export	Exports Mindset Report data.  Not available in the demo.	Home>righthand panel>click Mindset Report>Export button	
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_findcontentbutton	Find Content	functionality?  Not available in the demo.	Home: Righthand panel: Skill Status Report>Skills tab>Find Content button	
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_changeselectionsbutton	Change Selections	Allows edits to selections.  Not available in the demo.	Home: Righthand panel: Skill Status Report>Skills tab>Change Selections button	
Teacher Home screen: righthand panel: Skills Status Report window	skillstatus_exportbutton	Export 	functionality?  Not available in the demo.	Home: Righthand panel: Skill Status Report>Skills tab> Export button	
Teacher Home screen: righthand panel: Skills Status Report window: Activity tab	skillstatus_datedropdown	Select Time Period	Displays data based on dates selected.  Not available in the demo.	Home: Righthand panel: Skill Status Report>Activity tab> Date dropdown	
Teacher Home screen: righthand panel: Skills Status Report window: Skills tab	skillstatus_skill	Skills	functionality?  Not available in the demo.	Home: Righthand panel: Skill Status Report>Skills tab> hovering over individual skills	
Teacher Home screen: Home tab: Student Needs Help tab: View by Students	Home_Help_ViewbyStudents_Find Content button	Find Content  	functionality?  Not available in the demo.	Home: Student Need Help tab: View by Students: Find Content buttons other than the top Find Content button	
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbySkills_Find Content button	Find Content  	functionality?  Not available in the demo.	Home: Student Need Help tab: View by Skills: Find Content buttons other than the top Find Content button	
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbyStudents_studentname		functionality?  Not available in the demo.	Home: Student Need Help tab: View by Skills: hovering over individual student names	
Teacher Home screen: Home tab: Student Needs Help tab: View by Skills	Home_Help_ViewbyStudents_bargraphicon	Student Report	functionality?  Not available in the demo.	Home: Student Need Help tab: View by Skills: hovering over bar graph icon	
Teacher Home screen: Home tab: Shout-outs tab	Home_Shout_lastweek	Shout-outs	Displays shout-outs for last week.  Not available in the demo.	Home: Shout-outs: Last Week link	
Teacher Home screen: Home tab: Leaderboard tab: Gems tab	Home_Leaderboardgems_viewgemsreport	Gems	Displays Gems Report.  Not available in the demo.	Home: Leaderboard tab: Gems tab: View Gems Report link	
Teacher Home screen: Home tab: Leaderboard tab: Points tab	Home_Leaderboardgems_viewpointsreport	Points	Displays Points Report.  Not available in the demo.	Home: Leaderboard tab: Points tab: View Points Report link	
Teacher Home screen: Track Assignments tab	TA_assignment_link	Select Assignment	Provides a class report on progress on this assignment. Not available in the demo.	Track Assignment: Assignment Links in "Assignment Name" column below the first row	
Teacher Home screen Track Assignments tab	TA_statusdropdown	Select Assignment Status	Filters results based on completion status.  Not available in the demo.	Track Assignment: Completion dropdown>hovering over All, Open or Completed	
Teacher Home screen Track Assignments tab	TA_contenttypedropdown	Select Content Type	Filters results based on content type.  Not available in the demo	Track Assignment: click Content Type dropdown>hover over Skill Boost, Learning Goals, or Lessons or Games	
Teacher Home screen Track Assignments tab	TA_editbutton	Edit	Allows edit access to Assigment details. Not available in the demo.	Track Assignments: Edit button	
Teacher Home screen Track Assignments tab	TA_trashbutton	Trash	Deletes assignment.  Not available in the demo.	Track Assignments: Trash button	
Teacher Home screen Track Assignments tab	TA_unassignbutton	Unassign	functionality?  Not available in the demo.	Track Assignments: Unassign button	
Teacher Home screen Track Assignments tab: Black vertical Edit Assignments bar	TA_vertical EditAssignments	Edit Assignments	Allows the teacher to edit Students and Due Dates.  Not available in the demo.	Track Assignments: vertical Edit Assignments bar at right of screen	
Teacher Home screen: Track Assignments tab: Open Assignments dropdown	TA_openassignments	Open Assignments	functionality?  Not available in the demo.	Track Assignments: Open Assignments dropdown	
Teacher Home screen: Track Assignments tab: All Content Types dropdown	TA_allcontenttypes	All Content Types	functionality?  Not available in the demo.	Track Assignments: All Content Types dropdown	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab: Start date	TA_literarystyle_startdate	Start	Allows edits to Start Date.  Not available in demo.	Track Assignments>Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Start button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab: Due date	TA_literarystyle_duedate	Due	Allows edits to Due Date.  Not available in demo.	Track Assignments>Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Due button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_exportbutton	Export/Print	functionality?  Not available in the demo.	Track Assignments>select Gr3 ELA> click first Assignment>Literary Style screen>Summary tab>Export/Print button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_notstartedlink	Not Started	Displays list of students who have not started this assignment.  Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Not Started link	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_needshelplink	Needs Help	Displays list of students who need help on this assignment.  Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Needs Help link	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_makingprogresslnk	Making Progress	Displays list of students who are making progress on this assignment.  Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Making Progress link	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Summary tab	TA_literarystyle_summary_completedlink	Completed	Displays list of students who have completed this assignment.  Not available in the demo.	Track Assignments>select Gr3 ELA>Click first Assignment>Literary Style screen>Summary tab>Completed link	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_topskillgapsbutton	Top Skill Gaps	Displays list of top skill gaps.  Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Top Skill Gaps button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_previewitembutton	Preview Item	Previews student item.  Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Preview Item button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab	TA_literarystyle_skills_findcontentbutton	Find Content	Displays content targeted for these skills. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Skills tab>Find Content button	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: Literary Style screen: Skills tab: Standards link	TA_literarystyle_skills_standardslink	Standards	Displays details for selected standard. Not available in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Stylet screen>Skills tab>Standards link	
Teacher Home screen Track Assignments tab: Gr3 ELA: click first Assignment: LIterary Style screen: Students tab	TA_literarystyle_students_findcontent	Find Content 	Displays targeted content.  Not availble in the demo.	Track Assignments>select Gr3 ELA>click first Assignment>Literary Style screen>Students tab>Find Content link	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_startdate	Start	Allows edits to Start Date.  Not available in demo.	Track Assignments>Gr3 Math> click first Lesson>Lesson screen>Start button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_duedate	Due	Allows edits to Due Date.  Not available in demo.	Track Assignments>Gr3 Math> click first Lesson>Lesson screen>Due button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_changestudentsbutton	Change Student	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Change Student button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_previewbutton	Preview   	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Preview button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_printbutton	Print 	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>Print button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonsscreen_viewclassperformancebutton	View Class Performance	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>View Class Performance button	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_studentnames	TBD	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>hover over student names	
Teacher Home screen: Track Assignments: Gr3 Math: Click first Lesson: Lesson screen	TA_lessonscreen_score	TBD	functionality?  Not available in the demo.	Track Assignments>select Gr3 Math>click first Lesson>Lesson screen>hover over individual student scores	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_startdate	Start  	Allows edits to Start Date.  Not available in demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Start button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_duedate	Due  	Allows edits to Due Date.  Not available in demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Due button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_changestudentsbutton	Change Student  	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Change Student button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_previewbutton	Preview  	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Preview button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboostscreen_printbutton	Print  	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>Print button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_viewclassperformancebutton	View Class Performance  	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>View Class Performance button	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_studentnames	TBD	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>hover over student names	
Teacher Home screen: Track Assignments: Gr7 ELA: Click first Skill Boost: Skill Boost screen	TA_skillboost_score	TBD	functionality?  Not available in the demo.	Track Assignments>select Gr7 ELA>click first Skill Boost>Skill Boost screen>hover over individual student scores	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_exportbutton	Export/Print  	functionality?  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Export/Print button	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_notstarted	Not Started  	Displays list of students who have not started this assignment.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Not started link	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_needshelp	Needs Help  	Displays list of students who need help on this assignment.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Needs Help link	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_makingprogress	Making Progress  	Displays list of students who are making progress on this assignment.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab>Making Progress link	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Summary tab	TA_proportional_summary_completed	Completed  	Displays list of students who have completed this assignment.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Summary tab> Completed link	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_topskillgapsbutton	Top Skill Gaps	Displays top skill gaps.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab> TopSkill Gaps button	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_previewitem button	Preview Item  	Displays student item.  Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab> Preview Item button	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_findcontentbutton	Find Content  	Displays content targeted for these skills. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab>Find Content button	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Skills tab	TA_proportional_skills_standardslink	Standards  	Displays details for selected standard. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Skills tab>Standards link	
Teacher Home screen: Track Assignments: Gr7 Math: Click first Assignment: Proportional Relationships screen: Students tab	TA_proportional_students_findcontentbutton	Standards  	Displays content targeted for these skills. Not available in the demo.	Track Assignments>select Gr7 Math>Click first Assignment>Students tab>Standards link	
Teacher Home screen Growth tab: Waggle Growth tab	WaggleGrowth_Timedropdown		Select report time period.  Not available in the demo.	Growth tab:>Waggle Growth tab> Time dropdown	
Teacher Home screen Growth tab: Growth Measure report	HMHGM_howdoIreadlink	How do I read this report?	functionality?  Not available in the demo.	Growth tab> HMH Growth Measure>Create Report>HMH Growth Measure Report>How do I read this report link	
Teacher Home screen Preview button	Home_preview	Preview	Provides a preview of the student app.  Not available in the demo.	Home: Preview button	
Teacher Home screen Rosters screen	Home_roster_addstudentbutton	Add Student  	Adds a new student to the roster.  Not available in the demo.	Home: Roster>Add Student button	
Teacher Home screen Rosters screen	Home_roster_addnewgroupbutton	Add New Group 	Adds a new group to the roster.  Not available in the demo.	Home: Roster>Add New Group button	
Teacher Home screen Rosters screen	Home_roster_turnonELbutton	Turn on EL Support	Turns on EL support.  Not available in the demo.	Home: Roster>Turn on EL Support button	
Teacher Home screen Rosters screen	Home_roster_turnonvocabbutton	Turn on Vocabulary	Turns on Vocabulary support.   Not available in the demo.	Home: Roster>Turn on Vocabulary button	
Teacher Home screen Rosters screen	Home_roster_viewprofilebutton	View Profile	Displays student profile.  Not avaiable in the demo.	Home: Roster>View Profile button	
Teacher Home screen Rosters screen	Home_roster_printbutton	Print  	Prints student login details.  Not available in the demo.	Home: Roster>Print button	
Teacher Home screen Rosters screen	Home_roster_viewreportbutton	View Report  		Home: Roster>View Report button	
Teacher Home screen Rosters screen	Home_roster_printtogetherbutton	All Together  	Prints roster login details all together.  Not available in the demo.	Home: Roster>All Together button	
Teacher Home screen Rosters screen	Home_roster_printseparatebutton	Separate Page  	Prints roster login details separately.  Not availble in the demo.	Home: Roster>Separate page button	
Teacher Home screen Alerts button	Home_alerts	Alerts	Displays student alerts.  Not available in the demo.	Home: Alerts button	
Teacher Home screen Message button	Home_message	Message 	Allows teacher to send messages to group, class, or individual students.  Not available in the demo.	Home: Message button	
Teacher Home screen Find Content tab: Math: Domain	FC_Choosestandard	Choosing Standards	For the purposes of this demo, click the top Standard listed.	Find Content: Math:  Domain: clicking any Standard in the list other than the top Standard	
	FC_Skill	Choosing Skills	For the purposes of this demo, select the top skill.		
Teacher Home screen Find Content tab: Math: Into Math Lesson: Choose Module	FC_Choosemodule	Choosing Modules	For the purposes of this demo, click the top Module.	Find Content: Math: Into Math Lesson: clicking any modules in the list other than the top modle	
Teacher Home screen Find Content tab: Math: Into Math Lesson: Choose Module	FC_Chooselesson	Choosing Lessons	For the purposes of this demo, click the top Lesson in the list.	Find Content: Math: Into Math Lesson: clicking any Lessons in the list other than the top Lesson	
	FC_Week	Choosing Weeks	For the purposes of this demo, click the top Week in the list.		
	FC_Domain	Choosing Domains	For the purposes of this demo, select the top domain.		
Teacher Home screen Find Content tab: ELA: Choose Strand	FC_Strand	Choosing Strands	For the purposes of this demo, select the top strand.	Find Content: Choose Strand: Strands: clicking any of the individual strands other than the top Strand listed	
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_moreinfo	More Info	For the purposes of this demo, click the "More Info" link in the first row.	Find Content: Student Practice: clicking on "More Info" links other than the top "More Info" link	
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_previewbutton	Preview	Functionality?  Previews student item.  Not available in the demo.	Find Content: Student Practice>More Info>Preview button	
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_viewreportbutton	View Report  	Functionality?  Previews student item.  Not available in the demo.	Find Content: Student Practice>View Report button	
Teacher Home screen Find Content tab: Student Practice	FC_Student Practice_selectbutton	Select  	For the purposes of this demo, click the "Select" link in the first row.	Find Content: Student Practice: clicking on "Select" button other than the top row	
Teacher Home screen Find Content tab: Student Practice: vertical bar Create Assignment> Assign Students and Dates>Assignment Details window	FC_Student Practice_assignmentdetails_updatemultipledatesbutton	Update Multiple Dates	Allows the teacher to edit multiple dates.  Not available in the demo.	Find Content: Student Practice: clicking on the vertical "Create Assignments" bar> Assign Students and Dates>Assignment Details window	
Teacher Home screen Find Content tab: Student Practice: vertical bar Create Assignment> Assign Students and Dates>Assignment Details window	FC_Student Practice_assignmentdetails_sssignbutton	Assign	Creates assignment.  Not available in the demo.	Find Content: Student Practice: clicking on the vertical "Create Assignments" bar> Assign Students and Dates>Assignment Details window	
Teacher Home screen Find Content tab: Student Practice: vertical bar Create Assignment> Assign Students and Dates>Assignment Details window StudentK12GamesPrevious	Additional Games	Additional games not seen in demo available in Waggle
K12Games	StudentK12MoreGames	Additional Games	Additional games not seen in demo available in Waggle	Placement/Trigger Notes`;



const lines = data.split("\n");

const rolloverTips = {};

for (var i = 3; i < lines.length; i++) {
  const line = lines[i];
  const tokens = line.split("\t");

  if (!tokens[1]) continue;

  rolloverTips[tokens[1]] = {
    title: tokens[2],
    text: tokens[3],
  };
}

export default rolloverTips;
