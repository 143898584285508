const initialState = {
  username: "Danielle White",
  totalMinutesSpent: 234,
  totalSessions: 17,
  averageSessionsPerWeek: 0,
  averageMinutesPerSession: 14,
  averageThemesComp: 0,
  averageScore: 93,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  return state;
};
