import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>
            Read the passage. Look at the bolded words. Then answer the
            question.
          </Bold>
        </div>
        <div>
          Poppy scurried around Aunt Lily’s kitchen, dashing from the
          refrigerator to the stove to the flour-covered counter. Chopped
          scallions and diced potatoes were in neat piles on the wooden cutting
          board. Ever since she was little, Aunt Lily had taught Poppy the ins
          and outs of cooking. Now that Poppy was 15, she prepared a special
          breakfast for the whole family. Everyone scrambled into the kitchen,
          lured by the delicious aromas.
        </div>
        <div>
          Poppy put the food on the table, then stopped in her tracks. “Where
          are the flowers from the garden? Oh, I forgot to pick them!” she said,
          on the verge of tears. “How could I be so <Bold>careless</Bold> and{" "}
          <Bold>irresponsible</Bold>?!” Aunt Lily put her arm around Poppy’s
          shoulder and said, “Poppy, I know how you feel, but don’t be so hard
          on yourself. You were just <Bold>preoccupied</Bold>. It’s easy to get{" "}
          <Bold>distracted</Bold> when you’re making a fabulous meal. If you ask
          me, your first family brunch is a huge success. Look how you’ve
          bloomed since you were a little girl!” Poppy smiled, knowing her aunt
          was right, and sat down to enjoy brunch with her family.
        </div>
      </div>
    </div>
  );
};

export default Contents;
