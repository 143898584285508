import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Match Subtraction of Fractions to Models
        </span>
      </div>
      <div className={css.contents}>
        <div>Which subtraction sentence is shown by each model?</div>
        <Instructions>
          Choose the correct subtraction sentence from each drop-down menu.
        </Instructions>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/d845d4098c058ebe365dd3efb45bc1f0.svg"
            width="30%"
          />
        </Center>
        <div className={css.row}>
          <span>The model above shows</span>
          <div className={css.dropdown}>
            <DropDown
              width="175px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="8"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="8"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="8"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="5"
                        denominator="5"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="5"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="5"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="5"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="5"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="5"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="5"
                        denominator="8"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="8"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="8"
                      />
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>.</span>
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/502d6c3c9b0122ec864d720b4a13a2fd.svg"
            width="30%"
          />
        </Center>
        <div className={css.row}>
          <span>The model above shows</span>
          <div className={css.dropdown}>
            <DropDown
              width="175px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="4"
                        denominator="6"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="6"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="6"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="5"
                        denominator="6"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="6"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="6"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="5"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="4"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="4"
                        denominator="4"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="4"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>.</span>
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/231df871509ee8a6cc169c647dae2eac.svg"
            width="30%"
          />
        </Center>
        <div className={css.row}>
          <span>The model above shows</span>
          <div className={css.dropdown}>
            <DropDown
              width="175px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="4"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="8"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="2"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="2"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="2"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="3"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="3"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="3"
                      />
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="3"
                        denominator="4"
                      />{" "}
                      –{" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="1"
                        denominator="4"
                      />{" "}
                      ={" "}
                      <Fraction
                        verticalStyle={{ fontSize: "12px", marginTop: "-6px" }}
                        numerator="2"
                        denominator="4"
                      />
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>.</span>
        </div>
        <div style={{ height: "175px" }} />
      </div>
    </div>
  );
};

export default Contents;
