import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          {languageLookup(0)}Learn about Apostrophes and Possession
        </span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>{languageLookup(1)}Apostrophes</Bold> {languageLookup(2)}show
          that a person, place, or thing <Italic>{languageLookup(3)}has</Italic>{" "}
          {languageLookup(4)}or <Italic>{languageLookup(5)}owns</Italic>{" "}
          {languageLookup(6)}something. Apostrophes show possession.
        </div>
        <div>{languageLookup(7)}Let’s look at some examples.</div>

        <div className={css.entry}>
          <div>
            {languageLookup(8)}For singular nouns, add an apostrophe and{" "}
            <Italic>{languageLookup(9)}-s</Italic>.
          </div>
          <BlockQuote>
            {languageLookup(10)}That is the{" "}
            <Instructions span>
              {languageLookup(11)}dog
              <Underline>{languageLookup(12)}'s</Underline>
            </Instructions>{" "}
            {languageLookup(13)}chew toy.
          </BlockQuote>
          <div>{languageLookup(14)}The toy belongs to the dog.</div>
        </div>

        <div className={css.entry}>
          <div>
            {languageLookup(15)}Plural nouns usually end in{" "}
            <Italic>{languageLookup(16)}-s</Italic>. {languageLookup(17)}To make
            it possessive, add an apostrophe after the{" "}
            <Italic>{languageLookup(18)}-s</Italic>.
          </div>
          <BlockQuote>
            {languageLookup(19)}Those are the{" "}
            <Instructions span>
              {languageLookup(20)}dogs
              <Underline>{languageLookup(21)}’</Underline>
            </Instructions>{" "}
            {languageLookup(22)}chew toy.
          </BlockQuote>
          <div>{languageLookup(23)}The toy belongs to more than one dog.</div>
        </div>

        <div className={css.entry}>
          <div>
            {languageLookup(24)}If a plural noun does not end in{" "}
            <Italic>{languageLookup(25)}-s</Italic>
            {languageLookup(26)}, add an apostrophe and{" "}
            <Italic>{languageLookup(27)}-s</Italic>
            {languageLookup(28)}.
          </div>
          <BlockQuote>
            {languageLookup(29)}This is a{" "}
            <Instructions span>
              {languageLookup(30)}children
              <Underline>{languageLookup(31)}’s</Underline>
            </Instructions>{" "}
            {languageLookup(32)}playground
          </BlockQuote>
        </div>

        <div className={css.entry}>
          <div>
            {languageLookup(33)}Apostrophes also show that a person, place, or
            thing is related to someone.
          </div>
          <div>{languageLookup(34)}Apostrophes show relationships.</div>
          <BlockQuote>
            {languageLookup(35)}Yolanda is{" "}
            <Instructions span>
              {languageLookup(36)}Harry
              <Underline>{languageLookup(37)}’s</Underline>
            </Instructions>{" "}
            {languageLookup(38)}older sister.
          </BlockQuote>
          <div>
            {languageLookup(39)}Yolanda and Harry are related to each other.
            They are sister and brother.
          </div>
          <BlockQuote>
            <Instructions span>
              {languageLookup(40)}Silvana
              <Underline>{languageLookup(41)}’s</Underline>
            </Instructions>{" "}
            {languageLookup(42)}favorite city is San Antonio.
          </BlockQuote>
          <div>
            {languageLookup(43)}Silvana likes San Antonio. That feeling is her
            relationship to the city.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contents;
