import React from "react";

export default (data, setData) => {};

export const correctAnswers = [0, 8, 3];

export const isCorrect = (data) => {
  return (
    data.left[0] == correctAnswers[0] &&
    data.left[1] == correctAnswers[1] &&
    data.left[2] == correctAnswers[2]
  );
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1],
    showLeft: [false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    showRight: [true, true, true, true, true, true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty;
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "How many faces does a rectangular prism have?",
  "You can use a net to represent each face of a rectangular prism.",
  <div>
    Use the formula <span style={{ fontStyle: "italic" }}>A = lw</span> to find
    the area of a rectangle and{" "}
    <span style={{ fontStyle: "italic" }}>A = s2</span> to find the area of a
    square.
  </div>,
  "Add the areas of each face to find the surface area.",
  <div>
    You can also use the formula{" "}
    <span style={{ fontStyle: "italic" }}>SA = 2(lw + lh + wh)</span> to find
    the surface area of a rectangular prism.
  </div>,
];

export const showReader = () => {
  return false;
};
