import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>You can organize data into a dot plot.</div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.icons[0].length > correctAnswers[0].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 2 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[1].length > correctAnswers[1].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 3 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[2].length > correctAnswers[2].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 4 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[3].length > correctAnswers[3].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 5 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[4].length > correctAnswers[4].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 6 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[5].length > correctAnswers[5].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 7 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[6].length > correctAnswers[6].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 8 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[7].length > correctAnswers[7].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 9 sheets of paper? Check your work. Be sure
        to fill boxes above each number from the bottom up. Do not skip rows.
      </div>
    );
  } else if (props.data.icons[8].length > correctAnswers[8].length) {
    contents = (
      <div className={css.text}>
        How many folders had exactly 10 sheets of paper? Check your work. Be
        sure to fill boxes above each number from the bottom up. Do not skip
        rows.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        To help you make the dot plot, organize the data in a tally table that
        shows the frequency for each number of sheets of paper. Then place a dot
        or dots above each number on the number line to show the frequency for
        that number.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
