import React from "react";
import css from "./Tools.module.css";

const Tools = (props) => {
  return (
    <div className={props.language == "sp" ? css.sp : css.root}>
      <div className={css.icon}>
        <img
          src="https://practice.gogetwaggle.com/student/public/images/disabled-tools.png"
          alt="icon"
        />
      </div>
      <div className={css.text}>
        {props.language == "sp" ? "Herramientas" : "TOOLS"}
      </div>
    </div>
  );
};

export default Tools;
