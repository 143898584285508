import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        The words <Underline>elation</Underline>,{" "}
        <Underline>scorching</Underline>, and <Underline>reveled</Underline>{" "}
        appear in the story. For each of these words, choose the one word whose
        connotation is MOST similar. Not all words will be used.
      </div>
      <div className={css.instructions}>
        Drag each theme to the correct spot. Click or tap the Reset button to
        start over.
      </div>
    </div>
  );
};

export default Sidebar;
