import React, { useState } from "react";
import Box from "./Box";
import css from "./FoldBox.module.css";

const FoldBox = (props) => {
  const [selected, setSelected] = useState(false);

  const isSelected = props.selected != undefined ? props.selected : selected;

  const firstBox = {};
  firstBox.color = "#056B90";
  firstBox.background = isSelected ? "#CCECF2" : "white";

  const arrow = isSelected
    ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEMSURBVHgBjVA7bsJAEJ3ZXcegJJKP4CO4TyKFKqHLEeIyFUZRgC4pkSKxuQEcAUqSAkUCao5ASenKIMA77IIAC2yLkVY7b2bemw9AjtkfrYpdb1XyanhWolCTnwDUBIJnfl+GeNT/v1jArkkJpBp7jECPWSJ4GriqyzYq9ZomTACd5fe7nznBGRlhql+oPWcHweN3T248/u1BcgIn+HIi67atwUuSjIyVtp2VGuj2biLXLS6ZH/5UQzTkuXUz0FHvlLxoVqcGFhrSPRMBnBRXWGKRuA7yyMaMv53GrHS8iBeJdcAIqXtIpJAzRfTPhOhwNf6bWQ/lHsXkMMH8NPLe1sN+aGr1URA5fzO1G8seckk2PFy+AAAAAElFTkSuQmCC"
    : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAKCAYAAAC9vt6cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADoSURBVHgBlY8/DgFBFMbfW7tDVI7gHCoKPSdAp7OSlVCJSmET6wS4gZqCSu0IjqCUWeaZmSBj1q74kknmz+/35Q2CTMGflomxHnK+uEajM2REscJjYyfmE8Wilj22l29luc4Y81paic0KuDcd4brt54WKBhT4Q9YsEjYcfnMjAjwZbKLkiyxDp6J0Ue/9eYl5YikPDaNIf0ejlkwAm2LsdC5R/4LmmCwIV4jYMkteU71lojUPg/br/FGQUgJpskrOhu7H7car1BEQq5a+kHLX5hMFKrfj7mCWEIkJDwdD+Df5YOarlcU8AJ+HaeR2Um6rAAAAAElFTkSuQmCC";

  const style = {};
  style.width = props.width;
  style.height = props.height;
  style.fontSize = props.fontSize;

  const mouseDownHandler = () => {
    if (props.onClick) props.onClick(props);

    if (props.selected == undefined) setSelected(!selected);
  };

  return (
    <div style={style} className={css.root}>
      <Box
        fontSize={props.fontSize}
        style={{
          height: props.height,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        onClick={mouseDownHandler}
        pointer
        {...firstBox}
      >
        <div className={css.header}>
          {props.children[0]}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img className={css.arrow} src={arrow} />
          </div>
        </div>
      </Box>
      {isSelected ? <Box>{props.children[1]}</Box> : null}
    </div>
  );
};

export default FoldBox;
