import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { playSoundEffect } from "../voiceover";
import css from "./K2Lessons.module.css";

export const DragBox = ({ text, data }) => {
  const [dragging, setDragging] = useState(false);
  const onEnd = () => {
    setDragging(false);
    playSoundEffect("dragEnd");
  };
  const onBegin = () => {
    console.info("onBegin");
    playSoundEffect("dragStart");
    setDragging(true);
    return { id: text };
  };

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "EQUATION",
    item: onBegin,
    end: onEnd,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  }));

  return (
    <div
      ref={drag}
      className={css.dragBox}
      style={{ opacity: collected.opacity }}
    >
      {text}
    </div>
  );
};
