import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";
import { components } from "@indieasy.software/indieasy-components";

const { LineGraph } = components;

const Contents = (props) => {
  const selectIconHandler = (index) => {
    const data = { icons: [...props.data.icons] };
    data.icons[index] = !data.icons[index];

    props.setData(data);
  };

  const rolloverPositions = [];

  for (let i = 0; i < 16; i++) {
    for (let j = 0; j < 16; j++) {
      rolloverPositions.push({ value: i, crossValue: j });
    }
  }

  return (
    <div className={css.root}>
      <div className={css.linegraph}>
        <LineGraph
          width="300px"
          height="300px"
          xAxis="BOTTOM"
          yAxis="LEFT"
          border="1px solid rgba(0, 0, 0, 0.2)"
          gridLine="1px solid rgba(0, 0, 0, 0.2)"
          pointerEvents={!props.data.complete ? "all" : "none"}
          //horizontal
          onHighlightItem={(event) => {
            if (props.data.indices.length > 0 && !props.data.complete) {
              const data = {
                indices: [props.data.indices[0], event.position],
                complete: false,
              };
              props.setData(data);
            }
          }}
          onSelectItem={(item, position, ev) => {
            const indices = [...props.data.indices];
            if (indices.length < 2) indices.push(position);
            else indices[1] = position;

            const data = { indices: indices, complete: indices.length == 2 };
            props.setData(data);
          }}
          maxValue={15}
          values={[
            {
              name: " ",
              value: 0,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 1,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 2,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 3,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 4,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 5,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 6,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 7,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 8,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 9,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 10,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 11,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 12,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 13,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 14,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 15,
              style: { fontSize: "12px", fontWeight: "700" },
            },
          ]}
          valueMargin="10px"
          valueRotate="0"
          valueTextAlign="center"
          valueLines
          //valueReverse
          maxCrossValue={15}
          crossValues={[
            {
              name: " ",
              value: 0,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 1,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 2,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 3,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 4,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 5,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 6,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 7,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 8,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 9,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 10,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 11,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 12,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 13,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 14,
              style: { fontSize: "12px", fontWeight: "700" },
            },
            {
              name: " ",
              value: 15,
              style: { fontSize: "12px", fontWeight: "700" },
            },
          ]}
          crossValueMargin="10px"
          crossValueRotate="0"
          crossValueTextAlign="right"
          crossValueLines
          crossValueReverse
          data={[
            {
              name: "default",
              color: `rgb(175, 175, 175)`,
              innerColor: "rgb(175, 175, 175)",
              line: `1.5px solid rgb(175, 175, 175)`,
              positions: [
                { value: 5, crossValue: 5 },
                { value: 5, crossValue: 10 },
                { value: 9, crossValue: 10 },
                { value: 9, crossValue: 5 },
                { value: 7, crossValue: 7 },
                { value: 5, crossValue: 5 },
              ],
            },
            {
              name: "rollovers",
              color: `transparent`,
              innerColor: "transparent",
              highlightColor: "transparent",
              innerHighlightColor: "#368fcb",
              positions: rolloverPositions,
              size: 24,
              innerSize: 12,
            },
            {
              name: "selected",
              color: `#368fcb`,
              innerColor: "#368fcb",
              line: `1.5px solid #368fcb`,
              positions: props.data.indices,
            },
          ]}
        />
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
