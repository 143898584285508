export default (data, setData) => {};

export const correctAnswers = [1, 3, 0, 2];

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1, -1],
    showLeft: [false, false, false, false],
    right: [0, 1, 2, 3],
    showRight: [true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (data.left.filter((x) => x > -1).length == 0) return "empty";
  else if (
    data.left[0] == correctAnswers[0] &&
    data.left[1] == correctAnswers[1] &&
    data.left[2] == correctAnswers[2] &&
    data.left[3] == correctAnswers[3]
  )
    return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return data.left.filter((x) => x > -1).length > 0;
};

export const getTitle = (data, setData) => {
  if (data.left.filter((x) => x > -1).length == 0) return "";

  if (
    data.left[0] == correctAnswers[0] &&
    data.left[1] == correctAnswers[1] &&
    data.left[2] == correctAnswers[2] &&
    data.left[3] == correctAnswers[3]
  )
    return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "How many straight sides does a triangle have?",
  "How many straight sides does a square have?",
  "How many straight sides does a pentagon have?",
  "How many straight sides does a hexagon have?",
];

export const showReader = () => {
  return false;
};
