import React from "react";
import css from "./ApplyBox.module.css";

const ApplyBox = (props) => {
  const classes = [css.root];

  if (props.applyIndex == props.index) classes.push(css.current);
  else if (props.applyIndex > props.index) classes.push(css.completed);

  return <div className={[classes.join(" ")]}>{props.children}</div>;
};

export default ApplyBox;
