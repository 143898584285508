import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_4_2_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Food Chains</div>
      <Paragraph index="1">
        What do human beings have in common with cats, dogs, and birds? What
        about fish, lizards, and insects? How about plants? Is there something
        you share with the tallest tree and the smallest blades of grass?
      </Paragraph>
      <div className={css.subtitle}>The Need for Energy</div>
      <Paragraph index="2">
        One answer is energy. Every living thing, or organism, on Earth needs
        energy to survive. Energy allows animals to run, jump, and grow.
        Organisms get their energy by eating food. For most organisms, food
        comes from other living things, such as plants or other animals. As
        energy moves from one organism to another, it makes up a food chain.
        Every part of the food chain plays an important role within its
        environment. In a food chain, energy passes from creature to creature,
        usually beginning with plants and ending with larger animals. For
        example, on land, a grasshopper eats some grass. Then, a mouse scurries
        by and pounces on the grasshopper. A snake later slithers alongside the
        mouse and snaps it up. A hawk flying overhead swoops down and eats the
        snake. Why does all this take place in nature?
      </Paragraph>
      <div className={css.subtitle}>It Starts with the Sun</div>
      <Paragraph index="3">
        Most of Earth's energy comes from the sun. Plants use the sun's energy
        to turn certain chemicals into food they need to live and grow. This
        process is called photosynthesis. But not all organisms can make their
        food like this. Many animals have to eat plants to get energy. The
        energy that animals get from eating plants helps them grow. Some animals
        eat other animals and use that energy to grow. Usually larger animals
        eat smaller ones, like tuna eating smaller mackerel.
      </Paragraph>
      <Paragraph index="4">
        All organisms eventually die. When an organism dies, it begins to
        decompose. This means its body breaks down into chemicals that are
        absorbed into the ground. Living plants absorb these chemicals, called
        nutrients, from the soil. These nutrients become food for plants.
        Through photosynthesis, plants use these nutrients with the sun's energy
        to live and grow. In this way, the food chain begins again.
      </Paragraph>
      <div className={css.subtitle}>Parts of Food Chains</div>
      <Paragraph index="5">
        How does energy affect food chains? A food chain shows one possible way
        that energy moves through an ecosystem. (An ecosystem is made up of all
        the living and nonliving things in a particular environment.) Different
        ecosystems have different food chains because different animals live
        there. In a desert food chain, the animals from the bottom to the top
        might be: sage brush, ants, lizards, and coyotes. However, in a forest
        food chain, the animals from the bottom to the top might be: grass,
        mice, weasels, and lynx. Even though the animals in a food chain differ,
        most food chains have the same basic structure.
      </Paragraph>
      <Paragraph index="6">
        <ul>
          <li>
            Most food chains on Earth begin with plants. Remember, plants use
            energy from the sun to make their own food through photosynthesis.
            So, plants are called photosynthesizers.
          </li>
          <li>
            Up one level are organisms such as rabbits and deer, which eat only
            plants. These organisms are called herbivores. (
            <Italic>Herbi</Italic>- comes from a Latin word for "grass," and{" "}
            <Italic>-vore</Italic> comes from a Latin word for "eat.")
          </li>
          <li>
            Further up the food chain are organisms such as lions and hawks,
            which eat only animals. These organisms are called carnivores. (
            <Italic>Carni</Italic>- comes from a Latin word for "meat.") Some
            carnivores eat only herbivores. Some carnivores eat other carnivores
            they are usually higher on a food chain. It is important to note
            that large carnivores eating small carnivores is a normal process in
            the food chain.
          </li>
          <li>
            Some animals, such as humans and raccoons, eat both plants and
            animals. They are called omnivores. (<Italic>Omni</Italic>- comes
            from a Latin word for "all.") Because of their varied diets,
            omnivores are often at the top of a food chain.
          </li>
        </ul>
      </Paragraph>
      <div className={css.image}>
        <img src={Image} />
      </div>
      <div className={css.subtitle}>
        This table compares food chains in two different ecosystems. Plants are
        at the bottom of each food chain. Carnivores and omnivores are at the
        top.
      </div>
      <Paragraph index="7">
        Food chains also include decomposers that break down dead plants and
        animals. Some decomposers are small animals, such as worms and slugs.
        Other decomposers are bacteria and fungi, such as mushrooms. Regardless
        of an organism's place in the food chain, every part of the chain is
        very important. The living creatures at each level-including
        humans-depend on all the other levels to survive.
      </Paragraph>
    </div>
  );
};

export default Reader;
