import React from "react";

import foxIdle from "./assets/Fox_Idle.gif";
import foxTalkEnd from "./assets/Fox_Talk_End.gif";
import foxTalkLoop from "./assets/Fox_Talk_Loop.gif";
import foxStartTurn from "./assets/Fox_Start_Turn_wo_talking.gif";
import foxWatching from "./assets/Fox_watching_idle.gif";

import css from "./K2.module.css";

export const FOX_STATE = {
  idle: foxIdle,
  talking: foxTalkLoop,
  talkEnd: foxTalkEnd,
  startTurn: foxStartTurn,
  watching: foxWatching,
};

export const Fox = ({ state = FOX_STATE.idle }) => (
  <img className={css.fox} src={state} />
);
