import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_4_1_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Treasure at Sea</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.caption}>
        Spanish galleons carried treasure from the New World to Spain.
      </div>
      <Paragraph index="1">
        Most of the countries today in North and South America, including the
        United States, are hundreds of years old. To Europeans in the 1500s and
        1600s, however, the Americas were a "New World." Following Christopher
        Columbus's "discovery" of the New World in 1492, explorers from many
        European countries sailed across the Atlantic Ocean to claim new lands
        and riches. At the time, Spain was one of the most powerful of these
        countries.
      </Paragraph>
      <Paragraph index="2">
        By 1592, several hundred-thousand people had moved from Spain to the
        Americas. Many of these people were fortune hunters. As more and more
        Spanish people arrived in the New World, they conquered native peoples
        already living there and took their wealth. The Spanish forced some of
        the conquered peoples to work in mines or on large farms. That forced
        labor produced even more wealth that the Spanish took back to Europe.
        For three hundred years, three-quarters of the world's silver and gold
        came from the Americas.
      </Paragraph>
      <div className={css.subtitle}>The Spanish Treasure Fleet</div>
      <Paragraph index="3">
        After the Spanish established settlements in the New World, ships
        sailing from Spain brought supplies to the settlers in the New World.
        These ships-called <Italic>galleons</Italic>-then returned to Spain with
        cargoes full of treasure. The treasure ships were sometimes attacked by
        pirates. For this reason, galleons sailed in large groups, or fleets,
        protected by heavily armed warships. The two warships with the most
        firepower sailed at the front and rear of the fleets. These fleets could
        extend for miles along the ocean.
      </Paragraph>
      <Paragraph index="4">
        In September 1622, twenty-eight treasure ships set sail from the New
        World for Spain. Sailing at the rear of the fleet was a large warship
        called <Italic>Nuestra Senora de Atocha</Italic>. (This name means "Our
        Lady of Atocha." Atocha is a neighborhood in Madrid, Spain.) The{" "}
        <Italic>Atocha</Italic> carried an unbelievable treasure: gold and
        silver, coins and tobacco, bronze and jewels. It is estimated that the
        value of the <Italic>Atocha</Italic>'s cargo would be millions of
        dollars today.
      </Paragraph>
      <Paragraph index="5">
        The fleet set sail from the island of Cuba on September 4. When the
        boats were just off the coast of Florida, the winds started to pick up,
        growing stronger throughout the night. By the next day, most of the
        ships in the fleet had gotten through the storm. The ships at the rear,
        however, were not as fortunate. In total, five ships sank, including the{" "}
        <Italic>Atocha</Italic>. Of the 265 people aboard the{" "}
        <Italic>Atocha</Italic>, only five survived by clinging to the ship's
        broken mast.
      </Paragraph>
      <Paragraph index="6">
        When news of the disaster reached Havana, Cuba, authorities sent ships
        to try to recover anything they could from the seafloor. The wreck was
        in deep enough water that salvaging, or recovering, any of the cargo was
        very difficult and dangerous. However, the Spaniards, using enslaved
        Native Americans, managed to salvage much of it. But the rest of the
        treasure remained hidden for hundreds of years, scattered across the
        ocean floor by hurricane after hurricane.
      </Paragraph>
      <div className={css.subtitle}>Found at Last</div>
      <Paragraph index="7">
        Modern technology has made it easier to locate and salvage treasure from
        wrecked ships. In 1969, an American named Mel Fisher began searching for
        the main wreck of the <Italic>Atocha</Italic>. In 1973, Fisher's team
        had its first big find. They found three silver bars that matched the
        descriptions of bars known to have been onboard the Atocha. However, the
        team would not locate the ship's remains-off the coast of Florida-for
        another twelve long years.
      </Paragraph>
      <Paragraph index="8">
        On July 20, 1985, Fisher received a message from his son: "Put away the
        charts; we've found the main pile!" Like those first silver bars that
        Fisher found, the treasure in the "main pile" matched descriptions of
        objects known to have sunk with the ship. These include golden cups and
        chains, and emeralds from mines in South America. Even after all his
        team's efforts, Fisher knew that most of the treasure remained on the
        ocean floor. Today, treasure hunters are still finding objects from this
        famous shipwreck along the coast of Florida.
      </Paragraph>
    </div>
  );
};

export default Reader;
