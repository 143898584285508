import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_5_2_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Pocahontas and John Smith: Fact or Myth?</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.caption}>
        Statues of Captain John Smith and Pocahontas at the historic site of
        Jamestown, Virginia
      </div>
      <Paragraph index="1">
        From books and movies, you may have heard romantic stories about
        Pocahontas, a Native American girl who saved English explorer John Smith
        hundreds of years ago. Are these stories based on facts? What is the
        true story of Pocahontas?
      </Paragraph>
      <Paragraph index="2">
        Historians know this much: Pocahontas was the daughter of{" "}
        <Underline>Chief</Underline> Powhatan, a ruler of the Algonquian people.
        (Her real name was Matoaka, but she was better known as Pocahontas, a
        nickname that means "playful little girl.") Powhatan and his people
        lived on the east coast of North America, near the Atlantic Ocean. When
        colonists began arriving from England in the early 1600s, they settled
        this land and named it Virginia. Their first{" "}
        <Underline>settlement</Underline> was called Jamestown.
      </Paragraph>
      <div className={css.subtitle}>Did Pocahontas Save John Smith?</div>
      <Paragraph index="3">
        In 1607, an English captain named John Smith was leading an{" "}
        <Underline>expedition</Underline> in Virginia when he was captured by
        Algonquians. They led him through the woods to Chief Powhatan. Captain
        Smith wrote in his journal that he was tied up and about to be killed
        when Pocahontas put her arms around him, saving his life. But can we
        trust Smith's version of the story?
      </Paragraph>
      <Paragraph index="4">
        Some historians believe that Smith may really have been tied up as part
        of an Algonquian welcoming ceremony. When Pocahontas came to embrace
        him, it was part of the <Underline>ritual</Underline>. In other words,
        Smith's life may not actually have been in danger.
      </Paragraph>
      <Paragraph index="5">
        Several movies portray Pocahontas as a beautiful young woman at the time
        she met Smith. These movies show the two falling in love. In fact,
        Pocahontas was only about ten or eleven years old when she met Smith,
        and he was twenty-seven. In his diary, he describes her as a charming
        little girl, not a love interest.
      </Paragraph>
      <Paragraph index="6">
        Pocahontas did become friends with Smith. She visited him at Jamestown,
        bringing messages from her father and going with other Algonquians to
        trade with the colonists. According to Smith's journal, Pocahontas
        taught him some words in the Algonquian language. But there is no
        evidence that they had a romantic relationship. That part of the story
        is just a <Underline>myth</Underline>.
      </Paragraph>
      <div className={css.subtitle}>Did Pocahontas Help Create Peace?</div>
      <Paragraph index="7">
        The relationship between the colonists and the Algonquians did not
        remain <Underline>amicable</Underline>. Instead, they became enemies,
        fighting over food, land, and supplies. In some stories about
        Pocahontas, she is shown as a hero who tries to create peace and
        understanding between her people and the English settlers. What did she
        actually do during this time of conflict?
      </Paragraph>
      <Paragraph index="8">
        In 1609, the situation between the English and the Algonquians became
        extremely <Underline>hostile</Underline>. According to Smith's journal,
        Powhatan became angry and ordered that Smith be killed. Pocahontas
        secretly sent a message to Smith to warn him. Though he escaped, he was
        later wounded by an accidental explosion and had to return to England.
        Pocahontas was told that Smith was dead.
      </Paragraph>
      <Paragraph index="9">
        As war between the English and Algonquians continued, Pocahontas was
        caught in the middle. In 1613, she was kidnapped by an English captain
        who held her for ransom. He demanded that her father release English
        prisoners and return stolen weapons and tools. Pocahontas was held
        captive for nearly a year while the English{" "}
        <Underline>negotiated</Underline> with Chief Powhatan.
      </Paragraph>
      <Paragraph index="10">
        While in captivity, Pocahontas met an English tobacco farmer named John
        Rolfe. They married in 1614. The marriage achieved a temporary peace
        between the colonists and the Algonquians. For several years, they
        enjoyed friendly <Underline>commerce</Underline> and trade. Sadly, this
        peace did not last. In 1622, war began again.
      </Paragraph>
      <div className={css.subtitle}>
        Did Pocahontas Have a "Fairy-Tale" Ending?
      </div>
      <Paragraph index="11">
        When some of the colonists sailed back to England in 1616, Pocahontas
        and John Rolfe went with them, bringing along their baby son. Pocahontas
        was presented to King James I, and the people of London came in great
        crowds to see her at plays, balls, and other events. Some movies and
        stories make it seem as though Pocahontas was welcomed in England as a
        princess from another land. In fact, she was probably viewed more as a
        curiosity. Most English people had never seen a Native American before.
      </Paragraph>
      <Paragraph index="12">
        Some stories about Pocahontas claim that she went to England to look for
        Captain Smith. They did meet once while she was in London, but it was a
        complete surprise to Pocahontas. She was shocked to learn that her old
        friend was actually alive. After this meeting, they never saw each other
        again.
      </Paragraph>
      <Paragraph index="13">
        Pocahontas and her family boarded a ship back to Virginia in 1617. But
        as the ship set sail, Pocahontas became very ill, and they had to turn
        back. She died in England, never returning to her homeland. She was
        about twenty-two years old. Her life may not have been the fairy tale
        some stories suggest, but the real history of her life is no less
        fascinating.
      </Paragraph>
    </div>
  );
};

export default Reader;
