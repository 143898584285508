import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        With which of these statements would each author of "GPS in All Cars"
        and "Don't Rely on the GPS" agree? Match each statement with the author
        who would agree with it. Some statements might not match either author.
      </div>
      <div className={css.instructions}>
        Drag the statements to the appropriate bin. Click or tap the Reset
        button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
