import React, { useState } from "react";
import { useDrop } from "react-dnd";
import css from "./K2Lessons.module.css";
import { CORRECT_STATES } from "./useDropFeedback";

export const DropBox = ({
  children,
  border,
  onDrop,
  canDrop,
  state,
  pulse,
}) => {
  const onDropped = (item, monitor) => {
    console.info("onDrop", item, monitor);
    onDrop(item.id);
  };

  const [collectedProps, drop] = useDrop(
    () => ({
      accept: "EQUATION",
      drop: onDropped,
      canDrop: () => canDrop,
      collect: (monitor) => ({ hovering: monitor.isOver() }),
    }),
    [canDrop]
  );

  const classes = [css.dropBox];
  if (border) classes.push(css.dropBoxBorder);
  if (state === CORRECT_STATES.incorrect) classes.push(css.dropBoxIncorrect);
  if (state === CORRECT_STATES.correct) classes.push(css.dropBoxCorrect);
  if (pulse) classes.push(css.pulse);

  return (
    <div ref={drop} className={classes.join(" ")}>
      {collectedProps.hovering && <div className={css.innerBorder} />}
      {children}
    </div>
  );
};
