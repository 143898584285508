import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_3_0_reader.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>My Bed Is a Boat</div>
      <div className={css.author}>by Robert Louis Stevenson</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="">
        <div>My bed is like a little boat;</div>
        <div>
          Nurse <Underline>helps</Underline> me in when I{" "}
          <Underline>embark</Underline>;
        </div>
        <div>She girds me in my sailor's coat</div>
        <div>And starts me in the dark.</div>
      </Paragraph>
      <Paragraph index="5">
        <div>At night, I go on board and say</div>
        <div>Good-night to all my friends on shore;</div>
        <div>I shut my eyes and sail away</div>
        <div>And see and hear no more.</div>
        <div>And sometimes things to bed I take,</div>
      </Paragraph>
      <Paragraph index="10">
        <div>As prudent sailors have to do;</div>
        <div>Perhaps a slice of wedding-cake,</div>
        <div>Perhaps a toy or two.</div>
        <div>
          All night across the dark we <Underline>steer</Underline>;
        </div>
        <div>But when the day returns at last,</div>
      </Paragraph>
      <Paragraph index="15">
        <div>Safe in my room, beside the pier,</div>
        <div>I find my vessel fast.</div>
      </Paragraph>
    </div>
  );
};

export default Reader;
