import React from "react";
import css from "./Hexagon.module.css";

import Center from "../../../components/Center";

const Hexagon = (props) => {
  const halfWidth = props.width / 2;
  const halfHeight = props.height / 2;

  return (
    <div className={css.root}>
      <div
        style={{
          borderBottom: `${halfHeight}px solid ${props.color || "#eeeeee"}`,
          borderLeft: `${halfWidth}px solid transparent`,
          borderRight: `${halfWidth}px solid transparent`,
        }}
        className={css.hextop}
      />
      <div
        style={{
          background: props.color,
          width: `${props.width}px`,
          height: `${props.height}px`,
        }}
        className={css.hex}
      >
        <Center>{props.children}</Center>
      </div>
      <div
        style={{
          borderTop: `${halfHeight}px solid ${props.color || "#eeeeee"}`,
          borderLeft: `${halfWidth}px solid transparent`,
          borderRight: `${halfWidth}px solid transparent`,
        }}
        className={css.hexbottom}
      />
    </div>
  );
};

export default Hexagon;
