import React from "react";
import css from "./K2Lessons.module.css";
import { PromptOption } from "./PromptOption";

export const PromptBox = ({ currentValue, onChange, options, state }) => {
  const onClick = (value) => (event) => onChange(value);
  return (
    <div className={css.promptBox}>
      {options.map((option) => (
        <PromptOption
          state={state}
          option={option}
          active={option === currentValue}
          onClick={onClick(option)}
        />
      ))}
    </div>
  );
};
