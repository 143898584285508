import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};

export const correctAnswers = { indices: [1, 3, 1, 2] };

export const isEmpty = (data) => {
  return data.indices.filter((x) => x == 0).length > 0;
};

export const isCorrect = (data) => {
  return (
    data.indices[0] == correctAnswers.indices[0] &&
    data.indices[1] == correctAnswers.indices[1] &&
    data.indices[2] == correctAnswers.indices[2] &&
    data.indices[3] == correctAnswers.indices[3]
  );
};

export const getDefaultState = () => {
  return {
    indices: [0, 0, 0, 0],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    Which passage uses examples across many years, from past to present?{" "}
  </div>,
  <div>
    Which passage describes a problem and offers solutions to help fix it?
  </div>,
  <div>
    Which passage compares living creatures with creatures that do not actually
    exist?
  </div>,
];

export const showReader = () => {
  return true;
};
