import Contents from "./Contents";
import Setup, {
  getDefaultState,
  getFeedbackType,
  hints,
  showReader,
  getAnswers,
  isCorrect,
} from "./Controller";
import Sidebar from "./Sidebar";
import Hint from "./Hint";
import Done from "./Done";
import Reader from "./Reader";

const data = {
  getDefaultState: getDefaultState,
  setup: Setup,
  contents: Contents,
  //sidebar: Sidebar,
  hint: Hint,
  done: Done,
  getFeedbackType: getFeedbackType,
  hints: hints,
  reader: Reader,
  showReader: showReader,
  isCorrect: isCorrect,
  getAnswers: getAnswers,
  isInteractive: true,
};

export default data;
