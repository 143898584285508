import React from "react";
import { Screen1 } from "./Screen1";
import { Screen2 } from "./Screen2";
import { Screen3 } from "./Screen3";
import { Screen4 } from "./Screen4";
import { Screen5 } from "./Screen5";
import { Screen6 } from "./Screen6";
import { Screen7 } from "./Screen7";
import { Screen8 } from "./Screen8";
import { Screen9 } from "./Screen9";
import { Screen10 } from "./Screen10";

import { LessonProgression } from "../LessonProgression";
import { useParams } from "react-router-dom";

const screens = [
  Screen1,
  Screen2,
  Screen3,
  Screen4,
  Screen5,
  Screen6,
  Screen7,
  Screen8,
  Screen9,
  Screen10,
];

export const K2Math1 = (props) => {
  const params = useParams();
  const language = params.language || 'en'

  return <LessonProgression {...props} screens={screens} language={language} />
}
