import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import video from "../../assets/ela_1.mp4";
import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playSoundEffect,
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { AnswerBox } from "../AnswerBox";
import { PopupPromptBox } from "../PopupPromptBox";
import { useMCFeedback } from "../useMCFeedback";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";
import { PromptBox } from "../PromptBox";

import sh from "../../assets/art/5d7bf52809e143e31d38e4ef5cd70841.png";

import chair from "../../assets/art/chair.png";
import ice from "../../assets/art/ice.png";
import shoes from "../../assets/art/shoes.png";
import thumb from "../../assets/art/thumb.png";

import { VOPrompt } from "../VOPrompt";

const options = [
  {
    text: "thumb",
    vo: "s2_opt3_GK_078A2_ela_vo_en",
    feedback: "s2_error2_GK_078A2_ela_vo_en",
    correct: false,
    img: thumb,
  },
  {
    text: "chair",
    vo: "s2_opt2_GK_078A2_ela_vo_en",
    feedback: "s2_error1_GK_078A2_ela_vo_en",
    correct: false,
    img: chair,
  },
  {
    text: "shoes",
    vo: "s2_opt1_GK_078A2_ela_vo_en",
    feedback: "",
    correct: true,
    img: shoes,
  },
  {
    text: "ice",
    vo: "s2_opt4_GK_078A2_ela_vo_en",
    feedback: "s2_error3_GK_078A2_ela_vo_en",
    correct: false,
    img: ice,
  },
];

export const Screen3 = () => {
  const history = useHistory();
  const onNextScreen = () => history.push("4");
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    feedbackState,
    selectedOption,
    onCheck,
    chooseAnswer,
    continueState,
    checkState,
  } = useMCFeedback(options, setFoxState, {
    correctFeedback: "s2_successAnswer_GK_078A2_ela_vo_en",
  });

  const { getHint, playVideo, closeVideo } = useHints(
    ["s2_hint2_GK_078A_ela_vo_en"],
    setFoxState
  );

  const playInstruction = useCallback(
    () => playVoiceover("s2_instruction_GK_078A_ela_vo_en", setFoxState),
    [setFoxState]
  );

  const playTargetSound = useCallback(() => {
    // The Screen 2 vo was wrong
    playSoundEffect("s1_tapButton_GK_078A_ela_vo_en");
  }, []);

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.instructionContent}>
        <VOPrompt img={sh} onClick={playTargetSound} />
      </div>

      <PromptBox
        state={feedbackState}
        currentValue={selectedOption}
        options={options}
        onChange={chooseAnswer}
      />

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
