import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>
            Riley cried her eyes out when she discovered that her dog was
            missing.
          </li>
          <li>
            After banging his way into the house, Otto stomped up the stairs and
            kicked his bedroom door open.
          </li>
          <li>
            Kuan let out a whoop and pumped his arms when his mother told him
            the good news.
          </li>
          <li>
            After she read the letter, Janel’s shoulders drooped, and her face
            turned to stone.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
