import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const data = { selected: [...props.data.selected] };
    data.selected[p.index] = !data.selected[p.index];
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Select the two sentences that show anger most precisely.
      </div>
      <Instructions>Click or tap each correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>Sentence 1</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>Sentence 2</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>Sentence 3</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>Sentence 4</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
