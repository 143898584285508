import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Bold from "../../../../Bold";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Context Clues</span>
      </div>
      <div className={css.contents}>
        <div>
          In reading, you can use the context, or the bigger picture, to figure
          out the meaning of unfamiliar words. Often, you can find hints about a
          word's meaning in <Bold>context clues</Bold>—clues about the word in
          surrounding sentences.
        </div>
        <div>
          Take a look at the bolded word <Italic>amiable</Italic>. To understand
          its meaning, find the context clue in italics.
        </div>
        <BlockQuote>
          Mr. Clarkson always wore an <Bold>amiable</Bold> smile. His students
          regularly commented on his genuine, friendly nature.
        </BlockQuote>
        <div>
          Here, the word <Italic>friendly</Italic> gives you a clue to the
          definition of “amiable.”
        </div>
        <img
          src="https://d16qzots3do3wk.cloudfront.net/images/5f6d74f559ed71102df53b6ccf63ffb9.webp"
          width="50%"
        />
      </div>
    </div>
  );
};

export default Contents;
