import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_6_1_reader.JPG";
import ImageB from "../Assets/ela_6_1_readerb.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.author}>excerpted from</div>
      <div className={css.title}>My Own Story</div>
      <div className={css.author}>by Emmeline Pankhurst</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <div className={css.caption}>
        Emmeline Pankhurst being arrested at a protest.
      </div>
      <div className={css.author}>
        This excerpt from Emmeline Pankhurst's autobiography, My Own Story,
        describes the tactics Pankhurst used in the fight for women's suffrage
        <sup>1</sup> in Great Britain. It also tells of the reaction by
        citizens, police, and government officials.
      </div>
      <Paragraph index="1">
        We had a lot of suffrage literature printed, and day by day our members
        went forth and held street meetings. Selecting a favorable spot, with a
        chair for a rostrum,<sup>2</sup> one of us would ring a bell until
        people began to stop to see what was going to happen. What happened, of
        course, was a lively suffrage speech, and the distribution of
        literature. Soon after our campaign had started, the sound of the bell
        was a signal for a crowd to spring up as if by magic. All over the
        neighborhood you heard the cry: "Here are the Suffragettes!<sup>3</sup>{" "}
        Come on!" We covered London in this way; we never lacked an audience,
        and best of all, an audience to which the woman-suffrage doctrine was
        new. We were increasing our favorable public as well as waking it up.
        Besides these street meetings, we held many hall and drawing-room
        meetings, and we got a great deal of press publicity, which was
        something never accorded the older suffrage methods.
      </Paragraph>
      <Paragraph index="2">
        Our plans included the introduction of a Government suffrage bill at the
        earliest possible moment, and in the spring of 1906 we sent a deputation
        <sup>4</sup> of about thirty of our members to interview the Prime
        Minister, Sir Henry Campbell-Bannerman. The Prime Minister, it was
        stated, was not at home; so in a few days we sent another deputation.
        This time the servant agreed to carry our request to the Prime Minister.
        The women waited patiently on the doorstep of the official residence,
        No. 10 Downing Street, for nearly an hour. Then the door opened and two
        men appeared. One of the men addressed the leader of the deputation,
        roughly ordering her and the others to leave. "We have sent a message to
        the Prime Minister," she replied, "and we are waiting for the answer."
        "There will be no answer," was the stern rejoinder,<sup>5</sup> and the
        door closed.
      </Paragraph>
      <Paragraph index="3">
        "Yes, there will be an answer," exclaimed the leader, and she seized the
        door-knocker and banged it sharply. Instantly the men reappeared, and
        one of them called to a policeman standing near, "Take this woman in
        charge." The order was obeyed, and the peaceful deputation saw its
        leader taken off to Canon Row Station.
      </Paragraph>
      <Paragraph index="4">
        Instantly the women protested vigorously. Annie Kenney began to address
        the crowd that had gathered, and Mrs. Drummond actually forced her way
        past the doorkeeper into the sacred residence of the Prime Minister of
        the British Empire! Her arrest and Annie's followed. The three women
        were detained at the police station for about an hour, long enough, the
        Prime Minister probably thought, to frighten them thoroughly and teach
        them not to do such dreadful things again. Then he sent them word that
        he had decided not to prosecute them, but would, on the contrary,
        receive a deputation from the W.S.P.U.,<sup>6</sup> and, if they cared
        to attend, from other suffrage societies as well.
      </Paragraph>
      <Paragraph index="5">
        All the suffrage organizations at once began making preparations for the
        great event. At the same time two hundred members of Parliament
        <sup>7</sup> sent a petition to the Prime Minister, asking him to
        receive their committee that they might urge upon him the necessity of a
        Government measure for woman suffrage. Sir Henry fixed May 19th as the
        day on which he would receive a joint deputation from Parliament and
        from the women's suffrage organizations.
      </Paragraph>
      <Paragraph index="6">
        The W.S.P.U. determined to make the occasion as public as possible, and
        began preparations for a procession and a demonstration. When the day
        came we assembled at the foot of the beautiful monument to the
        warrior-queen, Boadicea, that guards the entrance to Westminster Bridge,
        and from there we marched to the Foreign Office. At the meeting eight
        women spoke in behalf of an immediate suffrage measure, and Mr. Keir
        Hardie presented the argument for the suffrage members of Parliament. I
        spoke for the W.S.P.U., and I tried to make the Prime Minister see that
        no business could be more pressing than ours. I told him that the group
        of women organized in our Union felt so strongly the necessity for women
        enfranchisement that they were prepared to sacrifice for it everything
        they possessed, their means of livelihood, their very lives, if
        necessary. I begged him to make such a sacrifice needless by doing us
        justice now.
      </Paragraph>
      <div>
        1. <Bold>suffrage</Bold>: the right to vote
      </div>
      <div>
        2. <Bold>rostrum</Bold>: raised platform on which a speaker or performer
        stands
      </div>
      <div>
        3. <Bold>Suffragettes</Bold>: women who were protesting for the right to
        vote
      </div>
      <div>
        4. <Bold>deputation</Bold>: a group chosen to represent others
      </div>
      <div>
        5. <Bold>rejoinder</Bold>: reply
      </div>
      <div>
        6. <Bold>W.S.P.U.</Bold>: Women's Social and Political Union, founded by
        Emmeline Pankhurst in 1903
      </div>
      <div>
        7. <Bold>Parliament</Bold>: Great Britain's legislative body
      </div>
      <div className={css.line} />
      <div className={css.title}>
        Emmeline Pankhurst: Women's Suffrage Champion
      </div>
      <Paragraph index="1">
        Emmeline Goulden Pankhurst is considered one of the most influential
        leaders of the women's suffrage movement of the late 1800s and early
        1900s. She spent almost her entire life fighting for women's right to
        vote.
      </Paragraph>
      <div className={css.subtitle}>Early Life</div>
      <Paragraph index="2">
        You could say that Emmeline Pankhurst was born to fight for women's
        suffrage. Both of her parents held radical beliefs about equality in
        society. Her mother was a passionate advocate for women's right to vote.
        As a young child, Emmeline went with her mother to women's suffrage
        meetings, where she was exposed to feminist ideas.
      </Paragraph>
      <Paragraph index="3">
        From the age of fifteen, Emmeline was educated in Paris. The headmaster
        of the school believed that girls should have as rigorous an education
        as boys, and Emmeline studied bookkeeping as well as chemistry and other
        sciences. After her schooling, she met Richard Pankhurst, a lawyer who
        was also a strong supporter of women's suffrage. He had written a law in
        1870 that allowed female property owners to vote in local elections.
      </Paragraph>
      <div className={css.subtitle}>Work for Suffrage</div>
      <Paragraph index="4">
        Given her background, it was a natural step for Pankhurst to become a
        leader in the women's rights movement in Great Britain. In 1889,
        Pankhurst helped form the Women's Franchise League. Its purpose was to
        pressure Parliament to give married women the right to vote in local
        elections. It won this right in 1894, but women's right to vote for
        members of Parliament remained out of reach.
      </Paragraph>
      <Paragraph index="5">
        Disappointed about their inability to gain wider voting rights for
        women, Pankhurst and her three daughters founded the Women's Social and
        Political Union (WSPU) in 1903. The WSPU was an all-women's organization
        dedicated to gaining the same voting rights for women that men had.
      </Paragraph>
      <Paragraph index="6">
        The WSPU regularly called on members of Parliament to confront them
        about voting rights. Finally, in 1904, Parliament promised to consider a
        suffrage bill. Pankhurst had hopes of seeing her dream come true.
        However, anti-suffrage members blocked discussion of the bill.
      </Paragraph>
      <div className={css.subtitle}>Becoming Radical</div>
      <Paragraph index="7">
        After this defeat, the public's interest in suffrage began to wane. In
        order to be heard, Pankhurst and other leaders of the WSPU had to be
        inventive and change their tactics. Instead of being peaceful and
        polite, they became loud and confrontational. They began to disrupt
        political speeches with chants such as "Will you give votes to women?"
        Formerly peaceful protest marches turned violent. WSPU members smashed
        store windows and set off bombs in mailboxes. When they were arrested,
        they used their imprisonment as a source of publicity, staging hunger
        strikes in the jails.
      </Paragraph>
      <Paragraph index="8">
        Although these tactics kept women's suffrage in the public eye, it
        caused a split in the WSPU's ranks. A large number of members
        disapproved of this militancy. In 1907, more than seventy women left the
        WSPU to form their own group, the Women's Freedom League. Another
        suffrage group, the North of England Society for Women's Suffrage,
        opposed the WSPU's tactics, claiming they would set back the movement. A
        number of former supporters also objected to what they saw as
        Pankhurst's habit of making decisions without consulting others. Despite
        these defections, in 1908 Pankhurst helped organize the largest
        demonstration ever to occur in London in support of a suffrage bill, but
        Parliament still refused to act.
      </Paragraph>
      <Paragraph index="9">
        After the 1908 disappointment and then another in 1910, Pankhurst and
        the WSPU stepped up their militant activities. Renewed acts of civil
        disobedience, including destroying store windows and throwing stones
        through building windows, resulted in repeated arrests. Pankhurst
        continued staging hunger strikes in jail: During one eighteen-month
        period, she participated in ten such strikes. This took a grave toll on
        Pankhurst's health.
      </Paragraph>
      <Paragraph index="10">
        The height of militancy occurred in 1913, when a bomb exploded at the
        house of a government official. Pankhurst was arrested for the act, and
        several years later she was convicted and sentenced to prison. While
        awaiting trial, Pankhurst continued leading meetings and advocating for
        suffrage.
      </Paragraph>
      <div className={css.subtitle}>World War 1</div>
      <Paragraph index="11">
        In 1914, World War I broke out in Europe. Because of the need for
        national unity, Pankhurst suspended her suffrage activities. She
        negotiated with the government for the release of all WSPU members who
        were in jail. In return, she led the WSPU to help the war effort.
        Although this went against her former opposition to the government,
        Pankhurst believed fighting for the survival of Great Britain was of the
        highest importance.
      </Paragraph>
      <Paragraph index="12">
        After the war ended in 1918, Pankhurst resumed her fight for equal
        rights for women. She contributed to the movement through her speeches
        and writings, traveling across Great Britain and to Canada and the
        United States to lecture about economic as well as political equality.
        In 1926, she returned home in poor health. She died in 1928, a few weeks
        after British women were granted full voting rights.
      </Paragraph>
      <div className={css.subtitle}>History of Women's Suffrage in England</div>
      <div className={css.imageb}>
        <img src={ImageB} alt="image" />
      </div>
    </div>
  );
};

export default Reader;
