export default (data, setData) => {};

export const correctAnswers = { indices: [2, 2], inputs: ["7", "5", "8", "2"] };

export const isCorrect = (data) => {
  return (
    data.indices[0] == correctAnswers.indices[0] &&
    data.indices[1] == correctAnswers.indices[1] &&
    data.inputs[0] == correctAnswers.inputs[0] &&
    data.inputs[1] == correctAnswers.inputs[1] &&
    data.inputs[2] == correctAnswers.inputs[2] &&
    data.inputs[3] == correctAnswers.inputs[3]
  );
};

export const isEmpty = (data) => {
  return (
    data.indices.filter((x) => x > 0).length == 0 &&
    data.inputs.filter((x) => x.length > 0).length == 0
  );
};

export const getDefaultState = () => {
  return {
    indices: [0, 0],
    inputs: ["", "", "", ""],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "What is the height of the bridge? What is the height of the truck? ",
  "Compare the decimals in each place from left to right until the first place that differs.",
  "How should the height of the bridge compare with the height of the truck for the truck to safely pass under it?",
];

export const showReader = () => {
  return false;
};
