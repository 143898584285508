import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! You used the points (4,−3) and (8,0) to find the slope:{" "}
        <Fraction width="60px" numerator="0-(-3)" denominator="8-4" /> =
        <Fraction numerator="3" denominator="4" />. The line crosses the{" "}
        <Italic>y</Italic>-axis at <Italic>y</Italic> = –6. So, the equation of
        the line is <Italic>y</Italic> ={" "}
        <Fraction numerator="3" denominator="4" />
        <Italic>x</Italic> − 6.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Try again! Look at Step 1 again. Be sure to write the coordinates in the
        correct order of (<Italic>x</Italic>, <Italic>y</Italic>).
      </div>
    );
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div className={css.text}>
        Try again! Look at the graph. Be sure the line passes through the point.
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div className={css.text}>
        Try again! Calculate the slope carefully. Use the coordinates (4,−3) and
        (8,0) to find the slope.
      </div>
    );
  } else if (props.data.indices[1] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Use <Italic>m</Italic> ={" "}
        <Fraction
          width="60px"
          numerator={
            <span>
              <Italic>y</Italic>2 - <Italic>y</Italic>1
            </span>
          }
          denominator={
            <span>
              <Italic>x</Italic>2 - <Italic>x</Italic>1
            </span>
          }
        />{" "}
        to find the slope.
      </div>
    );
  } else if (props.data.indices[1] == 4) {
    contents = (
      <div className={css.text}>
        Try again! Use <Italic>m</Italic> ={" "}
        <Fraction
          width="60px"
          numerator={
            <span>
              <Italic>y</Italic>2 - <Italic>y</Italic>1
            </span>
          }
          denominator={
            <span>
              <Italic>x</Italic>2 - <Italic>x</Italic>1
            </span>
          }
        />{" "}
        to find the slope.
      </div>
    );
  } else if (props.data.indices[2] == 1) {
    contents = (
      <div className={css.text}>
        Try again! Look at Step 2 again. Be sure to write the <Italic>y</Italic>
        -intercept coordinates in the correct order of (<Italic>x</Italic>,{" "}
        <Italic>y</Italic>).
      </div>
    );
  } else if (props.data.indices[2] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Look for the point where the line crosses the y-axis.
      </div>
    );
  } else if (props.data.indices[3] == 2) {
    contents = (
      <div className={css.text}>
        Try again! Take another look at Step 2. Look for the point where the
        line crosses the <Italic>y</Italic>-axis.
      </div>
    );
  } else if (props.data.indices[3] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Take another look at Step 2. Look for the point where the
        line crosses the <Italic>y</Italic>-axis.
      </div>
    );
  } else if (props.data.indices[4] == 2) {
    contents = (
      <div className={css.text}>
        Try again! Look at Step 3 again. Use the slope you found in Step 1.
      </div>
    );
  } else if (props.data.indices[4] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Look at Step 3 again. Use the the y-intercept you found in
        Step 2.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
