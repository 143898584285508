import React from "react";
import Shape from "../Components/Shape";
import css from "./Sidebar.module.css";

import AB from "../Assets/math_8_1_answer_a.JPG";

const Sidebar = (props) => {
  const dragStartHandler = (ev, shape, ref) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      var data = {
        left: [...props.data.left],
        right: [...props.data.right],
        showLeft: [...props.data.showLeft],
        showRight: [...props.data.showRight],
      };
      data.right[shape.slot] = -1;
      data.showRight[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;
    var data = {
      left: [...props.data.left],
      right: [...props.data.right],
      showLeft: [...props.data.showLeft],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Look at the grid. Identify the line that could coincide with{" "}
        <span style={{ width: "30px" }}>
          <img
            style={{ marginBottom: "-5px", width: "30px" }}
            src={AB}
            alt="line"
          />
        </span>{" "}
        image asset if the rigid motions described are performed.
      </div>
      <div className={css.instructions}>
        Drag the line name to the correct place in the sentence. Click or tap
        the Reset button to start over.
      </div>
      <div className={css.grid}>
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={3}
          slot={3}
          show={props.data.showRight[3]}
        />
      </div>
    </div>
  );
};

export default Sidebar;
