import React from "react";
import css from "./Sidebar.module.css";

import Shape from "../Components/Shape";

const Sidebar = (props) => {
  const dragStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      var data = {
        left: [
          [...props.data.left[0]],
          [...props.data.left[1]],
          [...props.data.left[2]],
        ],
        right: [...props.data.right],
        showRight: [...props.data.showRight],
      };
      data.right[shape.index] = -1;
      data.showRight[shape.index] = false;
      props.setData(data);
      console.log("drag");
    }, 10);
  };

  const dragEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;
    var data = {
      left: [
        [...props.data.left[0]],
        [...props.data.left[1]],
        [...props.data.left[2]],
      ],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        What fraction does the shaded part of the model show?
      </div>
      <div className={css.instructions}>
        Drag each model to the fraction it shows. Click or tap the Reset button
        to start over.
      </div>
      <div className={css.grid}>
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          onDragStart={dragStartHandler}
          onDragEnd={dragEndHandler}
          index={3}
          slot={3}
          show={props.data.showRight[3]}
        />
      </div>
    </div>
  );
};

export default Sidebar;
