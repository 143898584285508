import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import { Fragment } from "react";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Identify Supporting Details",
    sp: "Identifica los detalles de apoyo",
  },
  1: {
    en: "Read the main idea and details. Then answer the question.",
    sp: "Lee la idea principal y los detalles. Luego, contesta la pregunta.",
  },
  2: {
    en: (
      <Fragment>
        <Bold>Main idea:</Bold> School uniforms help students.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>Idea principal:</Bold> Los uniformes escolares son beneficiosos
        para los estudiantes.
      </Fragment>
    ),
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: (
      <Fragment>
        <Bold>Details:</Bold>
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>Detalles:</Bold>
      </Fragment>
    ),
  },
  5: {
    en: "Teens have different sleep cycles than adults; they need to sleep later in the mornings.",
    sp: "Los adolescentes tienen un ciclo de sueño diferente al de los adultos y deben dormir hasta más tarde por las mañanas.",
  },
  6: {
    en: "When students aren’t focused on their clothes, they can focus on their lessons.",
    sp: "Cuando los estudiantes no están preocupados por su vestimenta, pueden concentrarse en las clases.",
  },
  7: {
    en: "Experts say that high school students are more alert and productive after 9:00 a.m.",
    sp: "Los expertos dicen que los estudiantes de escuela secundaria están más atentos y son más productivos después de las 9:00 a. m.",
  },
  8: {
    en: "School uniforms can prevent students from expressing their individuality.",
    sp: "Los uniformes escolares evitan que los estudiantes expresen su individualidad.",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={props.language == "sp" ? css.spanish : css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>{languageLookup(1)}</div>
        <div>{languageLookup(2)}</div>
        <div>{languageLookup(4)}</div>
        <or>
          <li>{languageLookup(5)}</li>
          <li>{languageLookup(6)}</li>
          <li>{languageLookup(7)}</li>
          <li>{languageLookup(8)}</li>
        </or>
      </div>
    </div>
  );
};

export default Contents;
