import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Where are the magnets that help produce electricity located—the dam, the
        generator, the turbine, or the power lines?
      </div>
      <div className={css.instructions}>Click or tap the correct location.</div>
    </div>
  );
};

export default Sidebar;
