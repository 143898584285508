import React from "react";
import css from "./Shape.module.css";

const contents = ["beginning", "middle", "end"];

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
    justifyContent: props.center ? "center" : null,
    textAlign: props.center ? "center" : null,
    color: props.blue ? "#1588c9" : null,
    height: props.height || null,
    width: props.width || null,
  };

  let content = contents[props.index];
  return (
    <div className={css.root} style={style}>
      <div
        className={css.contents}
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        style={props.innerStyle}
      >
        {content}
      </div>
    </div>
  );
};

export default Shape;
