import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        <div>Great work!</div>
        <div>
          9 twelfths − 3 twelfths = 6 twelfths, and{" "}
          <Fraction width="25px" numerator="9" denominator="12" /> −{" "}
          <Fraction width="25px" numerator="3" denominator="12" /> ={" "}
          <Fraction width="25px" numerator="6" denominator="12" />
        </div>
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Try again! Check your subtraction carefully!
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div className={css.text}>
        Try again! Make sure that you are subtracting, not adding.
      </div>
    );
  } else if (props.data.indices[1] == 1) {
    contents = (
      <div className={css.text}>
        Keep trying! Remember: If the denominators are the same, you can
        subtract the numerators and keep the same denominator.
      </div>
    );
  } else if (props.data.indices[1] == 3) {
    contents = (
      <div className={css.text}>
        Keep trying! Remember: If the denominators are the same, you can
        subtract the numerators and keep the same denominator.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
