import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Solve Word Problems with a Model</span>
      </div>
      <div className={css.contents}>
        <div>
          Two friends share
          <Fraction numerator="1" denominator="2" /> of a pineapple equally.
          What fraction of a whole pineapple does each friend get?
        </div>
        <div>
          Divide <Fraction numerator="1" denominator="4" /> ÷ 2.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/4671d1523ed038c03b502a6abf617312.svg"
            width="40%"
          />
        </Center>
        <div>
          <Fraction numerator="1" denominator="2" /> ÷ 2 ={" "}
          <Fraction numerator="1" denominator="2" /> ÷{" "}
          <Fraction numerator="2" denominator="1" />
        </div>
        <div>
          <span style={{ marginLeft: "58px" }} />={" "}
          <Fraction numerator="1" denominator="2" /> x{" "}
          <Fraction numerator="1" denominator="2" />
        </div>
        <Instructions>
          Choose the correct fraction from the drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>Each friend gets</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: <Fraction inline numerator="1" denominator="2" /> },
                { text: <Fraction inline numerator="1" denominator="3" /> },
                { text: <Fraction inline numerator="1" denominator="4" /> },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>of a whole pineapple.</span>
        </div>
      </div>
      <div style={{ height: "150px" }} />
    </div>
  );
};

export default Contents;
