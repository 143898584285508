import React from "react";
import css from "./ProgressBox.module.css";

const ProgressBox = (props) => {
  const index = props.index + (props.correct ? 1 : 0);
  const fillAmount = `${Math.floor(index * 33.4)}%`;
  return (
    <div className={css.root}>
      <div className={css.bar}>
        <div style={{ width: fillAmount }} className={css.fill} />
      </div>
      <div className={css.track}>
        <div style={{ left: fillAmount }} className={css.thumb}>
          <img
            src="https://practice.gogetwaggle.com/student/public/images/human_sm.png"
            alt="thumb"
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBox;
