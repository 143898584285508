import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Models to Subtract Fractions</span>
      </div>
      <div className={css.contents}>
        <div>
          Viv started with <Fraction numerator="5" denominator="6" /> pan of
          pasta. She served <Fraction numerator="3" denominator="6" /> of her
          pan of pasta. How much of the pan of pasta does she have left?
        </div>
        <Instructions>
          Click or tap the correct answer from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>Viv started with</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="5"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="1"
                      denominator="1"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pan of pasta.</span>
        </div>
        <div className={css.row}>
          <span>She served</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="5"
                      denominator="6"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pan of pasta.</span>
        </div>
        <div className={css.row}>
          <span>Now Viv has</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="12"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="2"
                      denominator="6"
                    />
                  ),
                },
                {
                  text: (
                    <Fraction
                      verticalStyle={{ fontSize: "12px", marginTop: "0px" }}
                      numerator="3"
                      denominator="6"
                    />
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>pan of pasta left.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
