import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Well done! You selected the correct definition for each word.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else {
    contents = (
      <div className={css.text}>
        Try again. Look closely at the text surrounding each word in the
        passage, and make sure you have selected the correct definition for each
        word.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
