import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Complete } from "./Complete";
import { useLanguage } from "./math-1/useLanguage";

export const LessonProgression = (props) => {
  const { setProgress, screens } = props;
  const { path, url } = useRouteMatch();
  const language = useLanguage()

  const defaultPath = props.subject === 'math' ? path + `/1/${language}` : path + `/1`

  return (
    <Switch>
      {screens.map((Screen, index) => (
        <Route
          key={index}
          path={
            [`${path}/${index + 1}/:language`, `${path}/${index + 1}`]
          }
          render={(props) => {
            setProgress(Math.round((100 * index) / screens.length));
            return <Screen {...props} />;
          }}
        />
      ))}

      <Route
        path={path + "/" + (screens.length + 1)}
        render={(cprops) => (
          <Complete {...props} {...cprops} setProgress={setProgress} />
        )}
      />

      <Route>
        <Redirect to={defaultPath} />
      </Route>
    </Switch>
  );
};

// <div>

// </div>
