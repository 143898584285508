import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_6_0.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import Italic from "../../../../Italic";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>Bioluminescence in the Sea</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="1">
        Imagine you are a deep sea explorer. As you dive farther and farther
        down into the sapphire ocean, light from the sun fades away. Hundreds of
        feet below, total darkness surrounds you in what is known as the
        benthic, or deep-sea, <Underline>environment</Underline>. It is as dark
        as the outer reaches of space. Then, an{" "}
        <Underline>illuminated</Underline> world begins to appear. It is as
        fantastic as a fairy tale: sea creatures emit a glowing light as they
        float and swim through the darkness of the sea. However, these creatures
        are real!
      </Paragraph>
      <div className={css.subtitle}>A Glow in the Ocean</div>
      <Paragraph index="2">
        Scientists who study <Underline>bioluminescence</Underline> believe that
        this <Underline>adaptation</Underline> evolved many times with the
        different species in the sea that display it. The light that the animals
        produce is a cold light. It is not to be confused with a{" "}
        <Underline>thermal light</Underline> that emits heat, such as the light
        and warmth that come from the sun. Some creatures can glow{" "}
        <Underline>continuously</Underline>, while others repeatedly flash their
        light on and off. Bioluminescence provides the animals with an important
        survival edge. It can be used by these animals for the purposes of{" "}
        <Underline>camouflage</Underline> (blending in with the surroundings),{" "}
        <Underline>mimicry</Underline> (imitation),{" "}
        <Underline>mating</Underline>, hunting prey, hiding from predators, and{" "}
        <Underline>communication</Underline>.
      </Paragraph>
      <Paragraph index="3">
        Although bioluminescent animals are the light bulbs of the sea, their
        light does not run on <Underline>electricity</Underline>.
        Bioluminescence happens because of a chemical{" "}
        <Underline>reaction</Underline> that occurs within certain animals,
        usually sea creatures, that causes them to emit a glowing light. These
        extraordinary creatures include jellyfish, anglerfish, glowworms, and
        brittle stars. Although this <Underline>phenomenon</Underline> can occur
        in the ocean at any depth, it is most common at 650 feet below the
        surface and deeper. This is where the light of the sun no longer
        reaches. The magnificent colors that are produced by these animals may
        include yellows and pinks-colors brilliant like the most{" "}
        <Underline>amazing</Underline> sunset-although blue-green is most
        common.
      </Paragraph>
      <div className={css.subtitle}>Eat or Be Eaten</div>
      <Paragraph index="4">
        In the Pacific Ocean, alien-like jellyfish float off the shores.
        Scientists believe their unique characteristics assist them in mating as
        well as defending themselves from enemies. Although jellyfish would be
        like <Underline>blobs</Underline> on land-their{" "}
        <Underline>gelatinous</Underline> shapes could not hold together-in the
        sea, these bioluminescent beings are gorgeous umbrellas of light. Why
        are they lit up? When a jellyfish detects the danger of a predator
        nearby, a chemical reaction begins in which a green fluorescent protein
        lights up. This eerie glow is likely to <Underline>deter</Underline> any
        imposing creature, keeping the jellyfish from becoming someone's dinner.
      </Paragraph>
      <Paragraph index="5">
        There's a creature lurking in the depths of the sea that uses
        bioluminescence to trick its prey and get an easy meal. The female
        anglerfish has a long fin on top of its head that looks like seaweed, a
        food common to its prey. The <Underline>lure</Underline>, lit up with
        bioluminescent bacteria, hangs over the hungry fish's mouth. This{" "}
        <Underline>deception</Underline> ensures that smaller fish, curious
        about the lit-up "seaweed," will get too close to flee before they
        realize they are in trouble. The anglerfish also emits dots of light
        from its body through a chemical reaction, making it the only organism
        known to glow from another organism, the bacteria, as well as from its
        own self-produced light. The male anglerfish is attracted to the light
        emitted by the female. The male is weaker and fastens on to the female,
        becoming parasitic and using her to survive.
      </Paragraph>
      <Paragraph index="6">
        There are many other examples of glowing creatures. Unlike the
        anglerfish, which uses its light to <Underline>attract</Underline> its
        dinner, the hatchetfish uses its bioluminescence primarily to conceal
        itself. As the sun shines through the water, a hatchetfish produces
        light on its underside so that it looks the same as the water to
        predators swimming below, who therefore cannot see its outline. If you
        travel down deep into a watery New Zealand cave, you may come across
        glowworms, the larval stage of a unique insect that lives in the ceiling
        of the Waitomo Glowworm Caves. These tiny worms glow in dotted clusters
        like stars in the sky. Sperm whales don't produce their own
        bioluminescence, but they stay still, <Underline>hovering</Underline> in
        the water near drifting, glowing plankton. They wait patiently for the
        fish that are drawn to the light and come to feed on the plankton. The
        fish get their meal, but it may be their last if the sperm whale sees
        them! The brittle star, a kind of sea star, can selectively glow in one
        arm and not the others. If a predator is near, the brittle star glows in
        one arm and then detaches it. The predator goes after the glowing arm
        while the brittle star escapes! But don't worry; it can{" "}
        <Underline>regenerate</Underline> the lost arm over time. These are just
        some of the many kinds of bioluminescent animals.
      </Paragraph>
      <div className={css.subtitle}>A Glowing Future</div>
      <Paragraph index="7">
        Scientists are currently researching different ways the bioluminescence
        found in nature might be harnessed to help humans. Bioluminescence's
        potential for use in medical research and lighting options is now being
        explored. But you don't have to wait for these advances in technology to
        experience bioluminescence for yourself. Most of these creatures may
        seem far away in the ocean, but if you look around outside on a warm
        summer night, you might just see a firefly, or lightning bug, flashing
        in the air. Perhaps it will remind you of the other universe that exists
        under the sea.
      </Paragraph>
    </div>
  );
};

export default Reader;
