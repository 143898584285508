import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "",
    sp: "",
  },
  1: {
    en: "",
    sp: "",
  },
  2: {
    en: "",
    sp: "",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}Remember . . .</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>
            {languageLookup(1)}Apostrophes show possession and relationships.
          </li>
          <li>
            {languageLookup(2)}For singular nouns, add an apostrophe and{" "}
            <Italic>{languageLookup(3)}-s</Italic>.
          </li>
          <li>
            {languageLookup(4)}To make a plural possessive noun, add an
            apostrophe after the <Italic>{languageLookup(5)}-s</Italic>
            {languageLookup(6)}. For plural nouns that do not end in{" "}
            <Italic>{languageLookup(7)}-s</Italic>
            {languageLookup(8)}, add an apostrophe and{" "}
            <Italic>{languageLookup(9)}-s</Italic>
            {languageLookup(10)}.
          </li>
          <li>{languageLookup(11)}Apostrophes also form contractions.</li>
          <li>
            {languageLookup(12)}For contractions with{" "}
            <Italic>{languageLookup(13)}not</Italic>
            {languageLookup(14)}, replace the letter{" "}
            <Italic>{languageLookup(15)}o</Italic> {languageLookup(16)}with an
            apostrophe.
          </li>
          <li>
            {languageLookup(17)}Pronouns and verbs can be combined into
            contractions. An apostrophe replaces letters in the verb.
          </li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
