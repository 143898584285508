import React from "react";
import css from "./Complete.module.css";

const Complete = (props) => {
  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.text}>Lesson Complete</div>
        <div onMouseDown={props.onClose} className={css.button}>
          Continue
        </div>
      </div>
    </div>
  );
};

export default Complete;
