import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Apply: Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Three friends share
          <Fraction numerator="1" denominator="2" /> of a peach pie.
        </div>
        <div>What fraction of the whole pie does each friend get?</div>
        <Instructions>
          Choose the correct answer from the drop-down menu.
        </Instructions>
        <div>
          <Fraction numerator="1" denominator="2" /> ÷ 3 ={" "}
          <Fraction numerator="1" denominator="2" /> ÷{" "}
          <Fraction numerator="3" denominator="1" />
        </div>
        <div>
          <span style={{ marginLeft: "57px" }} />={" "}
          <Fraction numerator="1" denominator="2" /> x{" "}
          <Fraction numerator="1" denominator="3" />
        </div>
        <div className={css.row}>
          <span>Each friend will get</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: <Fraction inline numerator="1" denominator="6" /> },
                { text: <Fraction inline numerator="1" denominator="2" /> },
                { text: <Fraction inline numerator="2" denominator="3" /> },
                { text: <Fraction inline numerator="3" denominator="2" /> },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>of the whole pie.</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
