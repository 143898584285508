import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = ["1.59"];

export const getAnswers = () => {
  return { inputs: [...correctAnswers] };
};

export const getDefaultState = () => {
  return {
    inputs: [""],
  };
};

export const isEmpty = (data) => {
  return data.inputs.filter((x) => x.length == 0).length > 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.inputs.length; i++) {
    if (data.inputs[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>Think about where to place the first digit of the quotient.</div>,
  <div>Remember to place the decimal point in the quotient.</div>,
  <div>
    Use each subtraction step to work backward to find your quotient. To
    subtract 15 from the dividend, for example, you know you need to multiply
    the divisor 15 times 1. The 1 will be the first digit in your quotient.
  </div>,
];

export const showReader = () => {
  return false;
};
