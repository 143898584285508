import React from "react";

import css from "./Header.module.css";

const Header = (props) => {
  return (
    <div
      style={{ background: props.headerColor || "#f76464" }}
      className={css.background}
    />
  );
};

export default Header;
