import React from "react";

import css from "./K2Lessons.module.css";

export const K2Progress = ({ progress = 0 }) => {
  return (
    <div className={css.progressContainer}>
      <div className={css.progressBar} style={{ width: `${progress}%` }} />
      <div className={css.progressBarBall} style={{ left: "33%" }} />
      <div className={css.progressBarBall} style={{ left: "66%" }} />
    </div>
  );
};
