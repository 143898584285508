import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";
import Italic from "../../../../Italic";

const Sidebar = (props) => {
  const checkHandler = (p) => {
    const data = { selected: [...props.data.selected] };
    data.selected[p.index] = !data.selected[p.index];
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.title}>
        Which three statements about the lines are true?
      </div>
      <Instructions>Click or tap each correct answer.</Instructions>
      <div className={css.list}>
        <div className={css.row}>
          <CheckBox
            index={0}
            selected={props.data.selected[0]}
            onClick={checkHandler}
          />
          <span>Line 1 has a negative slope.</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={1}
            selected={props.data.selected[1]}
            onClick={checkHandler}
          />
          <span>
            Line 1 can be represented by an equation in the form{" "}
            <Italic>y</Italic> = <Italic>mx</Italic>.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={2}
            selected={props.data.selected[2]}
            onClick={checkHandler}
          />
          <span>
            Line 1 can be represented by an equation in the form{" "}
            <Italic>y</Italic> = <Italic>mx</Italic> + <Italic>b</Italic>.
          </span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={3}
            selected={props.data.selected[3]}
            onClick={checkHandler}
          />
          <span>Line 2 has a negative slope.</span>
        </div>
        <div className={css.row}>
          <CheckBox
            index={4}
            selected={props.data.selected[4]}
            onClick={checkHandler}
          />
          <span>
            Line 2 can be represented by an equation in the form{" "}
            <Italic>y</Italic> = <Italic>mx</Italic>.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
