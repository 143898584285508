import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        The speaker is thrilled by the sound of the wind and is describing the
        sounds and movements of the wind throughout the whole poem.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.selected == 0) {
    contents = (
      <div className={css.text}>
        Do you see any other instance in stanza 1 or in the rest of the poem
        where money is referenced? Because money does not have a direct
        connection to music in this poem, what might <Italic>charge</Italic>{" "}
        mean?
      </div>
    );
  } else if (props.data.selected == 2) {
    contents = (
      <div className={css.text}>
        There are no instances in stanza 1 or throughout the poem where the
        speaker is forced to do something or plays any role in what she is
        describing. Try once more.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
