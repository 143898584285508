import React from "react";
import css from "./Shape.module.css";

import ShapeA from "../../Assets/math_3_1_a.png";
import ShapeB from "../../Assets/math_3_1_b.png";
import ShapeC from "../../Assets/math_3_1_c.png";
import ShapeD from "../../Assets/math_3_1_d.png";

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let img;

  switch (props.index) {
    case 0:
      img = ShapeA;
      break;
    case 1:
      img = ShapeB;
      break;
    case 2:
      img = ShapeC;
      break;
    case 3:
      img = ShapeD;
      break;
  }

  return (
    <div className={css.root} style={style}>
      <img
        draggable
        onDragStart={(ev) => props.onDragStart(ev, props)}
        onDragEnd={(ev) => props.onDragEnd(ev, props)}
        src={img}
      />
    </div>
  );
};

export default Shape;
