import React from "react";
import css from "./Shape.module.css";

import Triangle from "../../Assets/triangle.png";
import Square from "../../Assets/square.png";
import Pentagon from "../../Assets/pentagon.png";
import Hexagon from "../../Assets/hexagon.png";

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let img;

  switch (props.index) {
    case 0:
      img = Pentagon;
      break;
    case 1:
      img = Triangle;
      break;
    case 2:
      img = Hexagon;
      break;
    case 3:
      img = Square;
      break;
  }

  return (
    <div
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
      className={css.root}
      style={style}
    >
      <img src={img} />
    </div>
  );
};

export default Shape;
