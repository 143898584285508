import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Which sentences from the passage describe an{" "}
        <Underline>amicable</Underline> relationship between the Algonquians and
        the English settlers? Which sentences from the passage describe a{" "}
        <Underline>hostile</Underline> relationship?
      </div>
      <div className={css.instructions}>
        Drag each sentence to the correct bin. Click or tap the Reset button to
        start over.
      </div>
    </div>
  );
};

export default Sidebar;
