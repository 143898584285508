import React from "react";

import css from "./K2.module.css";
import lcss from "./K2Lessons/K2Lessons.module.css";

export const VideoOverlay = ({ close, src }) => {
  return (
    <div className={css.shade}>
      <div className={lcss.videoHolder}>
        <video height="100%" controls src={src} onEnded={close} />
        <button onClick={close} className={css.shadeClose}></button>
      </div>
    </div>
  );
};
