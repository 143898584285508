import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Dividing with Unit Fractions and Whole Numbers
        </span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>In this lesson, you will ...</span>
          <BulletPoints>
            <li>Divide a unit fraction by a whole number.</li>
            <li>Divide a whole number by a unit fraction.</li>
            <li>
              Solve real-world problems by dividing unit fractions and whole
              numbers.
            </li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>
            Click the photo to learn more about the history of pound cakes.
          </Instructions>
          <FlipBox backPadding="20px" width="400px" height="275px">
            <Center>
              <img
                src="https://d16qzots3do3wk.cloudfront.net/images/6f38f18db6802252eef2e8a34fe34de5.webp"
                width="45%"
              />
            </Center>
            <div>
              <div style={{ textAlign: "left", marginBottom: "20px" }}>
                The pound cake originally weighed 4 pounds.
              </div>
              <div style={{ textAlign: "left", marginBottom: "20px" }}>
                That's because the recipe called for 1 pound of each four
                ingredients-flour, butter, eggs, and sugar.
              </div>
              <div style={{ textAlign: "left" }}>
                Each ingredient is <Fraction numerator="1" denominator="4" /> of
                the pound cake's total weight.
              </div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
