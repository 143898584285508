import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Solve Word Problems with Dividing a Whole Number by a Unit Fraction
        </span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          Mia walks a 2 -mile fitness trail. She stops to exercise every{" "}
          <Fraction numerator="1" denominator="5" /> mile. How many times does
          Mia stop to exercise?
        </div>
        <Center style={{ marginTop: "20px", marginBottom: "10px" }}>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/ace33d47628cc01b338eca154dbdd214.svg"
            width="80%"
          />
        </Center>
        <div>
          Let <Italic>t</Italic> = the number of times Mia stops to exercise.
        </div>
        <Instructions>
          Choose the correct number from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>The equation to solve the problem is</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                {
                  text: (
                    <div>
                      5 ÷ <Fraction inline numerator="1" denominator="2" /> ={" "}
                      <Italic>t</Italic>
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      <Fraction inline numerator="1" denominator="2" /> ÷ 2 ={" "}
                      <Italic>t</Italic>
                    </div>
                  ),
                },
                {
                  text: (
                    <div>
                      2 ÷ <Fraction inline numerator="1" denominator="5" /> ={" "}
                      <Italic>t</Italic>
                    </div>
                  ),
                },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>
            <Italic>t</Italic> =
          </span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: <Fraction inline numerator="2" denominator="5" /> },
                { text: "5" },
                { text: "10" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span></span>
        </div>
        <div className={css.row}>
          <span>So, Mia stops</span>
          <div className={css.dropdown}>
            <DropDown
              width="125px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[
                { text: <Fraction inline numerator="2" denominator="5" /> },
                { text: "5" },
                { text: "10" },
              ]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>times to exercise.</span>
        </div>
      </div>
      <div style={{ marginTop: "100px" }} />
    </div>
  );
};

export default Contents;
