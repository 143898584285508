import React from "react";
import css from "./Fraction.module.css";

const Fraction = (props) => {
  const verticalStyle = { ...props.verticalStyle };
  verticalStyle.width = props.width;

  if (props.inline) {
    verticalStyle.fontSize = "12px";
    verticalStyle.marginTop = "-6px";
  }

  return (
    <div className={css.root}>
      <div
        className={
          css.text + (props.integer == undefined ? " " + css.hidden : "")
        }
      >
        {props.integer != undefined ? props.integer : "1"}
      </div>
      <div className={css.vertical} style={verticalStyle}>
        <div className={css.text}>{props.numerator}</div>
        <div className={css.line} />
        <div className={css.text}>{props.denominator}</div>
      </div>
    </div>
  );
};

export default Fraction;
