import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Remember . . .</span>
      </div>
      <div className={css.contents}>
        <BulletPoints>
          <li>
            Vary sentences to make your writing more interesting. Sentences can
            differ by <Italic>structure</Italic>, <Italic>length</Italic>, and{" "}
            <Italic>type</Italic>.
            <BulletPoints>
              <li>Structure: simple, compound, complex</li>
              <li>Length: short, long</li>
              <li>Type: declarative, interrogative, imperative, exclamatory</li>
            </BulletPoints>
          </li>
        </BulletPoints>
      </div>
    </div>
  );
};

export default Contents;
