import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good job! All lines for equations in the form <Italic>y</Italic> ={" "}
        <Italic>mx</Italic> pass through (0,0). No lines for equations in the
        form <Italic>y</Italic> = <Italic>mx</Italic> + <Italic>b</Italic> pass
        through (0,0).
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div className={css.text}>
        Try again! If there is no <Italic>b</Italic> in the equation, where does
        the line cross the <Italic>y</Italic>-axis?
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div className={css.text}>
        Try again! If there is no <Italic>b</Italic> in the equation, where does
        the line cross the <Italic>y</Italic>-axis?
      </div>
    );
  } else if (props.data.indices[1] == 1) {
    contents = (
      <div className={css.text}>
        Try again! No lines for equations in the form <Italic>y</Italic> ={" "}
        <Italic>mx</Italic> + <Italic>b</Italic> pass through (0,0) because the
        lines cross the <Italic>y</Italic>-axis at a point other than (0,0).
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div className={css.text}>
        Try again! No lines for equations in the form <Italic>y</Italic> ={" "}
        <Italic>mx</Italic> + <Italic>b</Italic> pass through (0,0) because the
        lines cross the <Italic>y</Italic>-axis at a point other than (0,0).
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
