import React from "react";
import css from "./Shape.module.css";

const Shape = (props) => {
  const style = {
    visibility: props.show ? "visible" : "hidden",
  };

  let text;

  switch (props.index) {
    case 0:
      text = "triangle";
      break;
    case 1:
      text = "rectangle";
      break;
    case 2:
      text = "hexagon";
      break;
    case 3:
      text = "trapezoid";
      break;
    case 4:
      text = "octagon";
      break;
    case 5:
      text = "decagon";
      break;
  }

  return (
    <div
      className={css.root}
      style={style}
      draggable
      onDragStart={(ev) => props.onDragStart(ev, props)}
      onDragEnd={(ev) => props.onDragEnd(ev, props)}
    >
      {text}
    </div>
  );
};

export default Shape;
