import React, { useCallback, useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import zip from "lodash/zip";
import { useHistory } from "react-router-dom";
import { BottomToolbar, BUTTON_STATES } from "../../BottomToolbar";
import videoEN from "../../assets/math_1_en.mp4";
import videoES from "../../assets/math_1_es.mp4";

import css from "../K2Lessons.module.css";

import { Fox, FOX_STATE } from "../../Fox";
import {
  playVoiceover,
  playVoiceovers,
  stopActiveVoiceover,
} from "../../voiceover";
import { VideoOverlay } from "../../VideoOverlay";
import { useHints } from "../useHints";

import n1 from "../../assets/art/954709987_a-0.png";
import n2 from "../../assets/art/954709987_b-0.png";
import n3 from "../../assets/art/954709987_c-0.png";
import { DropBox } from "../DropBox";
import { DragBox } from "../DragBox";
import { CORRECT_STATES, useDropFeedback } from "../useDropFeedback";
import { useLanguage } from "./useLanguage";


const imgs = [n1, n2, n3];

export const Screen9 = () => {
  const history = useHistory();
  const language = useLanguage();
  const video = language === 'en' ? videoEN : videoES
  const onNextScreen = () => history.push(`../10/${language}`);


  const incorrectFeedback = [
    {
      "5 - 1 = 4": `dz1error1_G1_010C1_math_vo_${language}`,
      "5 - 3 = 2": `dz1error2_G1_010C1_math_vo_${language}`,
    },
    {
      "5 - 1 = 4": `dz2error1_G1_010C1_math_vo_${language}`,
      "5 - 2 = 3": `dz2error2_G1_010C1_math_vo_${language}`,
    },
    {
      "5 - 2 = 3": `dz3error1_G1_010C1_math_vo_${language}`,
      "5 - 3 = 2": `dz3error2_G1_010C1_math_vo_${language}`,
    },
  ];

  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const {
    onCheck,
    drops,
    sources,
    onDropSource,
    onDropTarget,
    continueState,
    allowPulse,
    checkState,
    correctStates,
    resetState,
    onReset,
  } = useDropFeedback(
    ["5 - 1 = 4", "5 - 2 = 3", "5 - 3 = 2"], // options
    ["5 - 2 = 3", "5 - 3 = 2", "5 - 1 = 4"], // correct spots
    incorrectFeedback,
    setFoxState,
    language
  );

  const { getHint, playVideo, closeVideo } = useHints(
    [
      `hint1_G1_010C1_math_vo_${language}`,
      `hint2_G1_010C1_math_vo_${language}`],
    setFoxState, language
  );

  const playInstruction = useCallback(
    () => playVoiceover(`instruction_G1_010C1_math_vo_${language}`, setFoxState),
    [language]
  );

  useEffect(() => {
    playInstruction();
    return stopActiveVoiceover;
  }, [playInstruction, setFoxState]);

  return (
    <React.Fragment>
      <div className={css.instructionContent}></div>

      <DndProvider backend={HTML5Backend}>
        {zip(imgs, drops, sources, correctStates).map(
          ([img, drop, source, state], index) => (
            <div key={index} className={css.dropRow}>
              <img src={img} />
              <DropBox
                pulse={
                  // Pulse if we're the first incorrect
                  allowPulse &&
                  state === CORRECT_STATES.incorrect &&
                  correctStates.indexOf(CORRECT_STATES.incorrect) === index
                }
                state={state}
                border={true}
                onDrop={onDropTarget(index)}
                canDrop={!drop}
              >
                {drop && <DragBox text={drop} />}
              </DropBox>
              <DropBox
                border={false}
                state={CORRECT_STATES.unknown}
                onDrop={onDropSource(index)}
                canDrop={!source}
              >
                {source && <DragBox text={source} />}
              </DropBox>
            </div>
          )
        )}
      </DndProvider>

      <Fox state={foxState} />

      <BottomToolbar
        instructionState={BUTTON_STATES.enabled}
        hintState={BUTTON_STATES.enabled}
        checkState={checkState}
        continueState={continueState}
        onContinue={onNextScreen}
        onCheck={onCheck}
        onHint={getHint}
        onInstruction={playInstruction}
        resetState={resetState}
        onReset={onReset}
      />

      {playVideo && <VideoOverlay close={closeVideo} src={video} />}
    </React.Fragment>
  );
};
