import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import InputField from "../../../../InputField";

const lines = {
  title: {
    en: "Apply: Area of Rectangles",
    sp: "¡Aplícalo! Área de los rectángulos",
  },
  0: {
    en: "Find the area of the figure.",
    sp: "Halla el área de la figura.",
  },
  1: {
    en: "Each unit square is 1 square foot.",
    sp: "Cada cuadrado de una unidad mide 1 pie cuadrado.",
  },
  2: {
    en: "Type the missing number into the blank.",
    sp: "Escribe el número que falta en el espacio en blanco.",
  },
  3: {
    en: "The area of the figure is",
    sp: "El área de la figura mide",
  },
  4: {
    en: "square feet.",
    sp: "pies cuadrados.",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  const valueHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup("title")}</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>{languageLookup(0)}</Bold>
        </div>
        <div>{languageLookup(1)}</div>
        <Instructions>{languageLookup(2)}</Instructions>
        <div>
          <span>{languageLookup(3)}</span>
          <InputField
            language={props.language}
            value={props.data.inputs[0]}
            onChange={(ev) => valueHandler(0, ev)}
            width="175px"
          ></InputField>
          <span>{languageLookup(4)}</span>
        </div>
      </div>
    </div>
  );
};

export default Contents;
