import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import { Fragment } from "react";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Apply",
    sp: "¡Aplícalo!",
  },
  1: {
    en: "Read the following main idea and details. Then answer the question.",
    sp: "Lee la idea principal y los detalles. Luego, contesta la pregunta.",
  },
  2: {
    en: (
      <Fragment>
        <Bold>Main Idea:</Bold> The design of roller coasters is based on the
        laws of physics.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>Idea principal:</Bold> El diseño de las montañas rusas se basa en
        las leyes de la física.
      </Fragment>
    ),
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: (
      <Fragment>
        <Bold>Details:</Bold>
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>Detalles:</Bold>
      </Fragment>
    ),
  },
  5: {
    en: "There are no engines on roller coasters; kinetic energy drives the coaster forward.",
    sp: "En las montañas rusas, no hay motores; estas son impulsadas por la energía cinética.",
  },
  6: {
    en: "The amount of friction on the wheels affects the speed of the ride.",
    sp: "La cantidad de fricción de las ruedas afecta la velocidad de la vuelta.",
  },
  7: {
    en: "Wooden roller coasters are scarier than steel roller coasters.",
    sp: "Las montañas rusas de madera son más atemorizantes que las de acero.",
  },
  8: {
    en: "Inertia is the force that keeps you in your seat during a loop-the-loop.",
    sp: "La inercia es la fuerza que te mantiene en tu asiento cuando pasas por un giro completo.",
  },
  9: {
    en: "The first modern roller coaster opened in Paris in 1817.",
    sp: "La primera montaña rusa moderna se inauguró en París en 1817.",
  },
  10: {
    en: "According to the legend, Newton discovered the laws of motion when an apple fell on his head.",
    sp: "Según la leyenda, Newton descubrió las leyes del movimiento cuando le cayó una manzana en la cabeza.",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>{languageLookup(1)}</div>
        <div>{languageLookup(2)}</div>
        <div>{languageLookup(4)}</div>
        <ol>
          <li>{languageLookup(5)}</li>
          <li>{languageLookup(6)}</li>
          <li>{languageLookup(7)}</li>
          <li>{languageLookup(8)}</li>
          <li>{languageLookup(9)}</li>
          <li>{languageLookup(10)}</li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
