import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly identified the themes found in each story.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        You placed “If you lie convincingly enough, you can get what you want”
        in the wrong box. Davy Crockett surely stretches the truth when he tells
        stories, but does he lie to other characters to get what he wants? Think
        about it and try again.
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        You placed “If you are clever and resourceful, you can do anything” in
        the wrong box. Does this theme apply to both stories or only to one of
        them? Try again.
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        You placed “Helping others is its own reward” in the wrong box. Think
        about how the narrator of “The Furry Trout” helps others, and then try
        again.
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        You placed “If you lie convincingly enough, you can get what you want”
        in the wrong box. Davy Crockett surely stretches the truth when he tells
        stories, but does he lie to other characters to get what he wants? Think
        about it and try again.
      </div>
    );
  } else if (props.data.left[2] == 0) {
    contents = (
      <div className={css.text}>
        You placed “Helping others is its own reward” in the wrong box. Think
        about how the narrator of “The Furry Trout” helps others, and then try
        again.
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        You placed “If you are clever and resourceful, you can do anything” in
        the wrong box. Does this theme apply to both stories or only to one of
        them? Try again.
      </div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        A theme of "The Most Amazing Man on Earth" is "Helping others is its own
        reward." A theme of "The Furry Trout" is "If you lie convincingly
        enough, you can get what you want." A theme of both stories is "If you
        are clever and resourceful, you can do anything." Drag these themes into
        the correct spots on the chart to move on to the next question.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        A theme of "The Most Amazing Man on Earth" is "Helping others is its own
        reward." A theme of "The Furry Trout" is "If you lie convincingly
        enough, you can get what you want." A theme of both stories is "If you
        are clever and resourceful, you can do anything." Drag these themes into
        the correct spots on the chart to move on to the next question.
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        A theme of "The Most Amazing Man on Earth" is "Helping others is its own
        reward." A theme of "The Furry Trout" is "If you lie convincingly
        enough, you can get what you want." A theme of both stories is "If you
        are clever and resourceful, you can do anything." Drag these themes into
        the correct spots on the chart to move on to the next question.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
