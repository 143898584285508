import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Center from "../../../../Center";
import Bold from "../../../../Bold";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn about Denotation</span>
      </div>
      <div className={css.contents}>
        <div>
          All words have meaning, and you can find the meaning of a word in a
          dictionary. The dictionary definition of a word is its{" "}
          <Bold>denotation</Bold>.
        </div>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/e07c8b25239d6b419c534be7acc3d467.webp"
            width="70%"
          />
        </Center>
        <div>
          For example, you might say that this student is studying—he's learning
          the required information for a class. That is the dictionary
          definition of the word.
        </div>
      </div>
    </div>
  );
};

export default Contents;
