import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Good work! Viv started with <Fraction numerator="5" denominator="6" />{" "}
        pan of pasta. She served <Fraction numerator="3" denominator="6" /> pan
        of pasta. Now Viv has <Fraction numerator="2" denominator="6" /> pan of
        pasta left.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != correctAnswers[0]) {
    contents = (
      <div className={css.text}>
        Try again! Check the model. How many unit fraction pieces are shown for
        the amount of a pan of pasta that Viv started with?
      </div>
    );
  } else if (props.data.indices[1] != correctAnswers[1]) {
    contents = (
      <div className={css.text}>
        Try again! Check the model to find how much pasta Viv served. How many
        crossed-out parts are there?
      </div>
    );
  } else if (props.data.indices[2] != correctAnswers[2]) {
    contents = (
      <div className={css.text}>
        Keep trying! Check the model. How many parts are not crossed out? This
        is how much pasta Viv has left. Remember that the denominator stays the
        same!
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
