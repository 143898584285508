import React from "react";
import css from "./Contents.module.css";

import Shape from "../Components/Shape";
import Reset from "../../../../Icons/Reset";
import Italic from "../../../../Italic";

import Arrow from "../Assets/ela_6_1_arrow.png";

const Contents = (props) => {
  const dragOverHandler = (ev, idx) => {
    ev.preventDefault();
  };
  const dropHandler = (ev, id) => {
    const dragId = parseInt(ev.dataTransfer.getData("text/plain"));
    ev.preventDefault();
    const data = {
      items: [[...props.data.items[0]]],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };

    const found = data.items[id].find((x) => x.id == dragId);
    if (found) {
      found.show = true;
    } else {
      data.items[id].push({ id: dragId, show: true });
    }

    props.setData(data);
  };

  const dragLeftStartHandler = (ev, slot, id, index) => {
    ev.dataTransfer.setData("text/plain", id);
    ev.dataTransfer.effectAllowed = "move";

    setTimeout(() => {
      const data = {
        items: [[...props.data.items[0]]],
        right: [...props.data.right],
        showRight: [...props.data.showRight],
      };
      data.items[slot][index].show = false;
      props.setData(data);
    }, 10);
  };

  const dragLeftEndHandler = (ev, slot, id, index) => {
    const data = {
      items: [[...props.data.items[0]]],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };
    if (ev.dataTransfer.dropEffect == "none") {
      data.items[slot].splice(index, 1);
      data.right[id] = id;
      data.showRight[id] = true;
      props.setData(data);
    }
  };

  const dragRightStartHandler = (ev, shape) => {
    ev.dataTransfer.setData("text/plain", shape.index);
    ev.dataTransfer.effectAllowed = "move";
    console.log("Start dragging: " + shape.index);
    setTimeout(() => {
      const data = {
        items: [[...props.data.items[0]]],
        right: [...props.data.right],
        showRight: [...props.data.showRight],
      };
      data.right[shape.slot] = -1;
      data.showRight[shape.slot] = false;
      props.setData(data);
    }, 10);
  };

  const dragRightEndHandler = (ev, shape) => {
    if (ev.dataTransfer.dropEffect !== "none") return;

    const data = {
      items: [[...props.data.items[0]]],
      right: [...props.data.right],
      showRight: [...props.data.showRight],
    };
    data.right[shape.slot] = shape.index;
    data.showRight[shape.slot] = true;
    props.setData(data);
  };

  const items = [[]];

  for (let i = 0; i < props.data.items.length; i++) {
    for (let j = 0; j < props.data.items[i].length; j++) {
      const index = props.data.items[i][j].id;
      items[i].push(
        <Shape
          key={i + "_" + j}
          style={{ fontSize: "14px" }}
          width="250px"
          onDragStart={(ev) => dragLeftStartHandler(ev, i, index, j)}
          onDragEnd={(ev) => dragLeftEndHandler(ev, i, index, j)}
          index={index}
          slot={i}
          show={props.data.items[i][j].show}
        />
      );
    }
  }

  return (
    <div className={css.root}>
      <div className={css.contents}>
        <div className={css.box}>
          <div className={css.header}>
            <div>Conclusion</div>
          </div>
          <div className={css.text}>
            The suffrage movement was popular and organized.
          </div>
        </div>
        <div className={css.arrow}>
          <img src={Arrow} />
        </div>
        <div className={css.box}>
          <div className={css.header}>
            <div>Text Evidence</div>
          </div>
          <div
            className={css.bucket}
            onDragOver={(ev) => dragOverHandler(ev, 0)}
            onDrop={(ev) => dropHandler(ev, 0)}
          >
            {items[0]}
          </div>
        </div>
      </div>
      <div className={css.contents}>
        <Shape
          width="250px"
          style={{ fontSize: "14px" }}
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={0}
          slot={0}
          show={props.data.showRight[0]}
        />
        <Shape
          width="250px"
          style={{ fontSize: "14px" }}
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={1}
          slot={1}
          show={props.data.showRight[1]}
        />
        <Shape
          width="250px"
          style={{ fontSize: "14px" }}
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={2}
          slot={2}
          show={props.data.showRight[2]}
        />
        <Shape
          width="250px"
          style={{ fontSize: "14px" }}
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={3}
          slot={3}
          show={props.data.showRight[3]}
        />
        <Shape
          width="250px"
          style={{ fontSize: "14px" }}
          onDragStart={dragRightStartHandler}
          onDragEnd={dragRightEndHandler}
          index={4}
          slot={4}
          show={props.data.showRight[4]}
        />
      </div>
      <Reset style={{ bottom: "19px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
