import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Models to Subtract Fractions</span>
      </div>
      <div className={css.contents}>
        <div>
          <div style={{ lineHeight: "30px" }}>
            Abe has{" "}
            <Fraction
              verticalStyle={{ fontSize: "13px" }}
              numerator="4"
              denominator="6"
            />{" "}
            of a pizza.
          </div>
          <div style={{ lineHeight: "30px" }}>
            He eats{" "}
            <Fraction
              verticalStyle={{ fontSize: "13px" }}
              numerator="2"
              denominator="6"
            />{" "}
            of the pizza.
          </div>
        </div>
        <div>How much pizza does Abe have left?</div>
        <Center>
          <img
            width="250px"
            src="https://d16qzots3do3wk.cloudfront.net/images/5ac7c4ceece812a03e0e3574c0d6294e.svg"
          />
        </Center>
        <Instructions>Click or tap each step.</Instructions>
        <FoldBox>
          <div>
            <Bold>Step 1:</Bold> Model Abe’s pizza with a fraction circle.
          </div>
          <div>
            <div>The fractions in the problem have denominators of 6.</div>
            <div>So, divide the circle into 6 equal parts.</div>
            <div>
              Each part is <Fraction numerator="1" denominator="6" />.
            </div>
            <div>
              To model the amount of pizza Abe has to start, shade 4 of the
              parts.
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>Step 2:</Bold> Find how many{" "}
            <Fraction numerator="1" denominator="6" /> -size parts to take away.
          </div>
          <div>
            <div>
              Abe eats <Fraction numerator="2" denominator="6" /> of the pizza.
              That’s two slices!
            </div>
            <div>
              Cross out 2 of the <Fraction numerator="1" denominator="6" />{" "}
              -size parts.
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>Step 3:</Bold> Solve the problem.
          </div>
          <div>
            <Center>
              <Fraction
                verticalStyle={{ marginTop: "0px" }}
                numerator="4"
                denominator="6"
              />{" "}
              −{" "}
              <Fraction
                verticalStyle={{ marginTop: "0px" }}
                numerator="2"
                denominator="6"
              />{" "}
              ={" "}
              <Fraction
                verticalStyle={{ marginTop: "0px" }}
                numerator="2"
                denominator="6"
              />
            </Center>
            <div>
              Abe has <Fraction numerator="2" denominator="6" /> of a pie left.
            </div>
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
