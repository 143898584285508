import React from "react";
import css from "./Reader.module.css";

import Paragraph from "../../../../Reader/Paragraph";

import Image from "../Assets/ela_6_2_img.JPG";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>The Wind</div>
      <div className={css.author}>by Emily Dickinson</div>
      <div className={css.image}>
        <img src={Image} alt="image" />
      </div>
      <Paragraph index="">
        <div>Of all the sounds despatched abroad,</div>
        <div>
          There's not a <Underline>charge</Underline> to me
        </div>
        <div>Like that old measure in the boughs,¹</div>
        <div>That phraseless melody</div>
      </Paragraph>
      <Paragraph index="5">
        <div>The wind does, working like a hand</div>
        <div>
          Whose fingers <Underline>brush</Underline> the sky,
        </div>
        <div>
          Then <Underline>quiver</Underline> down, with{" "}
          <Underline>tufts</Underline> of tune
        </div>
        <div>Permitted gods and me.</div>
        <div>When winds go round and round in bands,</div>
      </Paragraph>
      <Paragraph index="10">
        <div>And thrum upon the door,</div>
        <div>And birds take places overhead,</div>
        <div>To bear them orchestra,</div>
        <div>I crave him grace, of summer boughs,</div>
        <div>If such an outcast be,</div>
      </Paragraph>
      <Paragraph index="15">
        <div>He never heard that fleshless chant</div>
        <div>
          Rise <Underline>solemn</Underline> in the tree,
        </div>
        <div>As if some caravan of sound</div>
        <div>On deserts, in the sky,</div>
        <div>Had broken rank,</div>
      </Paragraph>
      <Paragraph index="20">
        <div>Then knit, and passed</div>
        <div>
          In <Underline>seamless</Underline> company.
        </div>
      </Paragraph>
      <div className={css.legend}>
        ¹. <Bold>boughs:</Bold> branches
      </div>
    </div>
  );
};

export default Reader;
