import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

import Video from "../Assets/video.mp4";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Use Compound Sentences</span>
      </div>
      <div className={css.contents}>
        <div>
          A <Bold>compound sentence</Bold> is made of two simple sentences
          joined by <Italic>and</Italic>, <Italic>or</Italic>,{" "}
          <Italic>but</Italic>, or so.
        </div>
        <Instructions>
          Click or tap the video to learn more about compound sentences.
        </Instructions>
        <video width="320" height="240" controls src={Video} />
      </div>
    </div>
  );
};

export default Contents;
