import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>The nights are cool, and the days are warm.</li>
          <li>Molly owns an electric scooter, so she rides it to school.</li>
          <li>Grandma’s two cats share a food dish and a water dish.</li>
          <li>Ian enjoys fishing with both of his parents.</li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
