import { useParams } from "react-router-dom";

const allowed = ["en", "es"]

export const useLanguage = () => {
    const params = useParams();
    const language = params.language;

    if (allowed.includes(language)) {
        return language;
    }

    return "en";
}