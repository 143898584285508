import React from "react";
import css from "./Done.module.css";

import Fraction from "../../../../Fraction";

import { isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        The models for
        <Fraction numerator="1" denominator="6" /> have 6 equal parts and 1 part
        shaded. The models for
        <Fraction numerator="1" denominator="8" /> have 8 equal parts and 1 part
        shaded.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.left[2].length > 0) {
    contents = (
      <div className={css.text}>
        Count the number of equal parts in each figure. This number is the
        denominator of the fraction.
      </div>
    );
  } else {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        The denominator, or bottom number, is the number of equal parts in the
        figure. The numerator, or top number, is the number of parts that are
        shaded. The figures with 6 equal parts and 1 part shaded go into the
        <Fraction numerator="1" denominator="6" /> bin, and the figures with 8
        equal parts and 1 part shaded go into the
        <Fraction numerator="1" denominator="8" /> bin.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
