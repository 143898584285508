import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You got it! You correctly described the connotation of each word.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] != 3) {
    contents = (
      <div className={css.text}>
        Look again. The word <Italic>toasty</Italic> does not have that kind of
        connotation.
      </div>
    );
  } else if (props.data.indices[1] != 1) {
    contents = (
      <div className={css.text}>
        Try again. The word <Italic>sweltering</Italic> does not have that kind
        of connotation.
      </div>
    );
  } else if (props.data.indices[2] != 2) {
    contents = (
      <div className={css.text}>
        Try again. The word <Italic>warm</Italic> does not have that kind of
        connotation.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
