import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the sentences. Notice how each bolded word has a meaning similar
          to curious. Then answer the question.
        </div>
        <ol>
          <li>
            Amita’s family often praises her <Bold>inquisitive</Bold> nature and
            drive to learn.
          </li>
          <li>
            It took every ounce of strength she had, but Candice resisted the
            urge to read Olympia’s diary. Candice did not want to be{" "}
            <Bold>snoopy</Bold> or unkind.
          </li>
          <li>
            Shawn didn’t mention his decision about quitting band at dinner. He
            wasn’t ready to talk about it, and he knew his sister would be very{" "}
            <Bold>interested</Bold>.
          </li>
          <li>
            “Okay, okay, I’ll tell you what happened yesterday. No need to be{" "}
            <Bold>nosy</Bold>,” Rachel joked, with a wink, to her brother Artie.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
