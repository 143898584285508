import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import DropDown from "../../../../DropDown";

import Background from "../Assets/math_6_0_img.JPG";

const Contents = (props) => {
  const inputHandler = (slot, ev) => {
    const data = { inputs: [...props.data.inputs] };
    data.inputs[slot] = ev.target.value;

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.text}>
          Monica wants to make a tricolored parallelogram-shaped earring. She
          needs to know how much material of each color to purchase.
        </div>
      </div>
      <div className={css.contents}>
        <div className={css.inputs}>
          <div className={css.text}>The area of the purple triangle is</div>
          <div className={css.row}>
            <input
              type="text"
              className={css.input}
              value={props.data.inputs[0]}
              onChange={(ev) => inputHandler(0, ev)}
            ></input>{" "}
            cm².
          </div>
          <div className={css.text}>The area of the yellow rectangle is</div>
          <div className={css.row}>
            <input
              type="text"
              className={css.input}
              value={props.data.inputs[1]}
              onChange={(ev) => inputHandler(1, ev)}
            ></input>{" "}
            cm².
          </div>
          <div className={css.text}>The area of the green triangle is</div>
          <div className={css.row}>
            <input
              type="text"
              className={css.input}
              value={props.data.inputs[2]}
              onChange={(ev) => inputHandler(2, ev)}
            ></input>{" "}
            cm².
          </div>
          <div className={css.text}>The area of the parallelogram is</div>
          <div className={css.row}>
            <input
              type="text"
              className={css.input}
              value={props.data.inputs[3]}
              onChange={(ev) => inputHandler(3, ev)}
            ></input>{" "}
            cm².
          </div>
        </div>
        <div className={css.image}>
          <img src={Background} alt="image" />
        </div>
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
