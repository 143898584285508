import React, { Fragment } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Underline from "../../../../Underline";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";
import FlipBox from "../../../../FlipBox";
import Center from "../../../../Center";
import Fraction from "../../../../Fraction";

import SpanishA from "../Assets/spanish_a.svg";
import SpanishB from "../Assets/spanish_b.svg";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Find the Rate",
    sp: "Hallar la tasa",
  },
  1: {
    en: "The table shows the distance a tortoise walked for 3 seconds.",
    sp: "La tabla muestra la distancia que una tortuga recorrió en 3 segundos.",
  },
  2: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/5e31c98ae5edea14495db089622f14e0.svg",
    sp: SpanishA,
  },
  3: {
    en: "What is the tortoise’s rate for walking? Is the rate constant?",
    sp: "¿Cuál es la tasa de velocidad a la que camina la tortuga? ¿Es una tasa constante?",
  },
  4: {
    en: "Click or tap each step to answer the questions.",
    sp: "Pulsa o haz clic en cada paso para contestar las preguntas.",
  },
  5: {
    en: (
      <Fragment>
        <Bold>STEP 1:</Bold> Use the table to divide the distance by the time
        for every second that the tortoise walked.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>PASO 1:</Bold> Usa la tabla para dividir la distancia entre el
        tiempo para cada segundo que caminó la tortuga.
      </Fragment>
    ),
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: (
      <Fragment>
        <Bold>STEP 2:</Bold> If each ratio is equivalent, then the tortoise’s
        rate is constant.
      </Fragment>
    ),
    sp: (
      <Fragment>
        <Bold>PASO 2:</Bold> Si las razones son equivalentes, entonces la tasa
        de velocidad de la tortuga es constante.
      </Fragment>
    ),
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "https://d16qzots3do3wk.cloudfront.net/images/4a8e8928c3f0a5f1dc02ad67ffe18f84.svg",
    sp: SpanishB,
  },
  12: {
    en: "All three rates equal 3.5, so this is a proportional relationship.",
    sp: "Las tres razones son iguales a 3.5, por lo tanto, se trata de una relación proporcional.",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div>{languageLookup(1)}</div>
        <Center>
          <img src={`${languageLookup(2)}`} width="30%" />
        </Center>
        <div>{languageLookup(3)}</div>
        <Instructions>{languageLookup(4)}</Instructions>

        <div className={css.entries}>
          <FlipBox
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "normal",
              fontFamily: "Open Sans",
            }}
            padding="10px"
            width="288px"
            height="232px"
          >
            <div>{languageLookup(5)}</div>
            <div>
              <div style={{ marginBottom: "20px" }}>
                <Fraction width="25px" numerator="3.5" denominator="1" /> = 3.5
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Fraction numerator="7" denominator="2" /> = 3.5
              </div>
              <div style={{ marginBottom: "20px" }}>
                <Fraction width="35px" numerator="10.5" denominator="3" /> = 3.5
              </div>
            </div>
          </FlipBox>
          <FlipBox
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "normal",
              fontFamily: "Open Sans",
            }}
            padding="10px"
            backPadding="20px"
            width="288px"
            height="232px"
          >
            <div>{languageLookup(9)}</div>
            <div>
              <img width="80%" src={`${languageLookup(11)}`} />
              <div style={{ textAlign: "left" }}>{languageLookup(12)}</div>
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
