import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const language = "en";

const lines = {
  0: {
    en: "Excellent work! There are 32 unit squares. So, the area of the rectangle is 32 square meters.",
    sp: "¡Excelente trabajo! Hay 32 cuadrados de una unidad. Entonces, el área del rectángulo mide 32 metros cuadrados.",
  },
  1: {
    en: "Please select an answer!",
    sp: "Por favor, selecciona una respuesta.",
  },
  2: {
    en: "Try again! Be sure to count the number of squares in the rectangle, not the number of rectangles.",
    sp: "¡Inténtalo otra vez! Asegúrate de contar el número de cuadrados que hay en el rectángulo, no el número de rectángulos.",
  },
  3: {
    en: "Try again! Be sure to count the number of squares in the whole rectangle.",
    sp: "¡Inténtalo otra vez! Asegúrate de contar el número de cuadrados que hay en el rectángulo entero, no solo el número de cuadrados que hay en una fila.",
  },
  4: {
    en: "Try again! There are 32 unit squares. So, the area of the rectangle is ? square meters.",
    sp: "¡Inténtalo otra vez! Asegúrate de contar el número de cuadrados que hay en el rectángulo entero.",
  },
};

const Done = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = <div className={css.text}>{languageLookup(0)}</div>;
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>{languageLookup(1)}</div>;
  } else if (
    props.data.inputs[0] != correctAnswers[0] &&
    props.data.inputs[0] == 1
  ) {
    contents = <div className={css.text}>{languageLookup(2)}</div>;
  } else if (props.data.inputs[0] != correctAnswers[0]) {
    contents = <div className={css.text}>{languageLookup(3)}</div>;
  } else if (props.data.inputs[1] != correctAnswers[1]) {
    contents = <div className={css.text}>{languageLookup(4)}</div>;
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
