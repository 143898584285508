import React, { useEffect, useState } from "react";
import { K2Frame } from "../K2Frame";
import world from "../assets/world.png";
import { BottomToolbar, BUTTON_STATES } from "../BottomToolbar";
import { useHistory } from "react-router-dom";
import { K2Header } from "../K2Header";
import css from "../K2.module.css";
import { Fox, FOX_STATE } from "../Fox";
import { playVoiceover } from "../voiceover";
import ScoreBars from "../ScoreBars";
import { useParams } from "react-router-dom";

export const K2Home = ({ subject, grade }) => {

  const history = useHistory();
  const [foxState, setFoxState] = useState(FOX_STATE.idle);

  const params = useParams();
  const language = params.language || 'en'

  const continueUrl = subject === 'math' ?
    `/lesson-${subject}-${grade}/1/${language}` :
    `/lesson-${subject}-${grade}/1` // No language for ELA

  useEffect(() => {
    playVoiceover("tap_arrow_to_begin", setFoxState);
  }, []);

  return (
    <div className={css.root}>
      <K2Header grade={grade} subject={subject} />
      <div className={css.home}>
        <img src={world} />
        <ScoreBars style={{
          position: "absolute",
          top: "0",
          left: 10,
          display: "flex",
          flexDirection: "column",
          gap: 10
        }} />
        <Fox state={foxState} />
        <BottomToolbar
          gamesState={BUTTON_STATES.enabled}
          checkState={BUTTON_STATES.hidden}
          onContinue={() => history.push(continueUrl)}
          onGames={() => history.push(`/game-${subject}-${grade}`)}
        />
      </div>
    </div>
  );
};
