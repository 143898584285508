import React from "react";
import css from "./Center.module.css";

const Center = (props) => {
  return (
    <div {...props} className={css.root}>
      {props.children}
    </div>
  );
};

export default Center;
