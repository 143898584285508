import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div>
          <Bold>Read the sentences. Then answer the question.</Bold>
        </div>
        <ol>
          <li>
            I want to send Grandma Zelda some cards, so I asked all my cousins
            to help out.
          </li>
          <li>I talked to Izaak and asked him to make a card.</li>
          <li>I talked to Shonda and asked her to make a card.</li>
          <li>
            I talked to the twins, Kadisha and Kala, and asked them to make a
            card, too.
          </li>
          <li>
            When we visit Grandma Zelda, I hope she has our homemade cards on
            her fridge!
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Contents;
