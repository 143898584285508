import React, { useState } from "react";
import css from "./Highlight.module.css";

const Highlight = (props) => {
  const [isOver, setIsOver] = useState(false);

  const selectHandler = () => {
    props.onSelect(props.index);
    setIsOver(false);
  };

  const highlightColor = props.color || "#ffc809";

  const style = { ...props.style };
  if (isOver || props.selected) style.backgroundColor = highlightColor;

  return (
    <span
      style={style}
      onMouseDown={selectHandler}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      className={css.root}
    >
      {props.children}
    </span>
  );
};

export default Highlight;
