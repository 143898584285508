import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        Great job! If a programmer gave a robot incomplete instructions, the
        robot would not perform the intended task.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.selected[0]) {
    contents = (
      <div className={css.text}>
        If a programmer gave a robot incomplete instructions, the robot would
        not “figure out what to do on its own” because robots cannot make
        decisions. Try again.
      </div>
    );
  } else if (props.data.selected[2]) {
    contents = (
      <div className={css.text}>
        If a programmer gave a robot incomplete instructions, the robot might
        “drop what it was carrying,” but this answer choice is too specific to
        be true all the time. Try again.
      </div>
    );
  } else if (props.data.selected[3]) {
    contents = (
      <div className={css.text}>
        If a programmer gave a robot incomplete instructions, the robot would
        not “get frustrated with the programmer” because robots do not have
        emotions. Try again.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
