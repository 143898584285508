import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You correctly matched each key detail to the main idea it supports. Good
        writers organize their writing around a main idea. They use key details
        to provide examples, to explain ideas, and to make their writing more
        specific and interesting.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        What happened to the <Italic>Atocha’s</Italic> treasure when the ship
        sank? Reread the main ideas, and try again.
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        What happened when the ship was finally located in 1985? Which main idea
        does this support? Try again.
      </div>
    );
  } else if (props.data.left[1] == 1) {
    contents = (
      <div className={css.text}>
        What happened when the ship was finally located in 1985? Which main idea
        does this support? Try again.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        Where should the description of the Atocha’s treasure be placed? Read
        the main ideas carefully, and try again.
      </div>
    );
  } else if (props.data.left[2] == 0) {
    contents = (
      <div className={css.text}>
        What happened to the Atocha’s treasure when the ship sank? Reread the
        main ideas, and try again.
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        Where should the description of the Atocha’s treasure be placed? Read
        the main ideas carefully, and try again.
      </div>
    );
  } else if (props.data.left[0] == 2) {
    contents = (
      <div className={css.text}>
        The detail{" "}
        <Italic>
          The Atocha was full of gold, silver, coins, tobacco, and jewels
        </Italic>{" "}
        supports the main idea "The <Italic>Nuestra Senora de Atocha</Italic>{" "}
        carried a great treasure." The detail{" "}
        <Italic>The Atocha sank in a hurricane off the coast of Florida</Italic>{" "}
        supports the main idea "The treasure from the <Italic>Atocha</Italic>{" "}
        was lost." The detail{" "}
        <Italic>
          In 1985, a team led by Mel Fisher found the location where the Atocha
          sank
        </Italic>{" "}
        supports the main idea "Some of the <Italic>Atocha's</Italic> treasure
        was eventually salvaged." Drag each detail to the correct main idea to
        move on to the next question.
      </div>
    );
  } else if (props.data.left[1] == 0) {
    contents = (
      <div className={css.text}>
        The detail{" "}
        <Italic>
          The Atocha was full of gold, silver, coins, tobacco, and jewels
        </Italic>{" "}
        supports the main idea "The <Italic>Nuestra Senora de Atocha</Italic>{" "}
        carried a great treasure." The detail{" "}
        <Italic>The Atocha sank in a hurricane off the coast of Florida</Italic>{" "}
        supports the main idea "The treasure from the <Italic>Atocha</Italic>{" "}
        was lost." The detail{" "}
        <Italic>
          In 1985, a team led by Mel Fisher found the location where the Atocha
          sank
        </Italic>{" "}
        supports the main idea "Some of the <Italic>Atocha's</Italic> treasure
        was eventually salvaged." Drag each detail to the correct main idea to
        move on to the next question.
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        The detail{" "}
        <Italic>
          The Atocha was full of gold, silver, coins, tobacco, and jewels
        </Italic>{" "}
        supports the main idea "The <Italic>Nuestra Senora de Atocha</Italic>{" "}
        carried a great treasure." The detail{" "}
        <Italic>The Atocha sank in a hurricane off the coast of Florida</Italic>{" "}
        supports the main idea "The treasure from the <Italic>Atocha</Italic>{" "}
        was lost." The detail{" "}
        <Italic>
          In 1985, a team led by Mel Fisher found the location where the Atocha
          sank
        </Italic>{" "}
        supports the main idea "Some of the <Italic>Atocha's</Italic> treasure
        was eventually salvaged." Drag each detail to the correct main idea to
        move on to the next question.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
