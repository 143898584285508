import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const lines = {
  title: {
    en: "",
    sp: "",
  },
  0: {
    en: "Use Graphic Organizers and Outlines",
    sp: "Usa organizadores gráficos y esquemas",
  },
  1: {
    en: "Read the passage. Then answer the question.",
    sp: "Lee el fragmento. Luego, contesta la pregunta.",
  },
  2: {
    en: "Coral reefs can help people. First of all, about 500 million people depend on coral reefs for employment. They are essential for jobs in tourism, fishing, and recreation. Second, coral reefs protect coastal lands and homes from sea damage. In addition, they provide compounds used for all kinds of medicine, including treatments for cancer, HIV, cardiovascular diseases, ulcers, and other ailments. Human bone grafts have even been done with the skeletons of coral reefs. And one quarter of all marine fish species live around coral reefs.",
    sp: "Los arrecifes de coral pueden ser de ayuda para los seres humanos. En primer lugar, el trabajo de aproximadamente 500 millones de personas depende de los arrecifes de coral. Son esenciales para el turismo, la pesca y la recreación. En segundo lugar, los arrecifes de coral protegen los terrenos y los hogares que están en la costa de los daños causados por el mar. Además, proporcionan compuestos que se usan en toda clase de medicamentos, como ser tratamientos contra el cáncer, el VIH, las enfermedades cardiovasculares, las úlceras y otros padecimientos. Incluso se han realizado injertos de hueso usando los esqueletos de los arrecifes de coral. Y un cuarto de todas las especies de peces marinos viven alrededor de los arrecifes de coral.",
  },
  3: {
    en: "",
    sp: "",
  },
  4: {
    en: "",
    sp: "",
  },
  5: {
    en: "",
    sp: "",
  },
  6: {
    en: "",
    sp: "",
  },
  7: {
    en: "",
    sp: "",
  },
  8: {
    en: "",
    sp: "",
  },
  9: {
    en: "",
    sp: "",
  },
  10: {
    en: "",
    sp: "",
  },
  11: {
    en: "",
    sp: "",
  },
  12: {
    en: "",
    sp: "",
  },
  13: {
    en: "",
    sp: "",
  },
  14: {
    en: "",
    sp: "",
  },
  15: {
    en: "",
    sp: "",
  },
  16: {
    en: "",
    sp: "",
  },
  17: {
    en: "",
    sp: "",
  },
  18: {
    en: "",
    sp: "",
  },
  19: {
    en: "",
    sp: "",
  },
  20: {
    en: "",
    sp: "",
  },
  21: {
    en: "",
    sp: "",
  },
  22: {
    en: "",
    sp: "",
  },
  23: {
    en: "",
    sp: "",
  },
  24: {
    en: "",
    sp: "",
  },
  25: {
    en: "",
    sp: "",
  },
  26: {
    en: "",
    sp: "",
  },
  27: {
    en: "",
    sp: "",
  },
  28: {
    en: "",
    sp: "",
  },
  29: {
    en: "",
    sp: "",
  },
  30: {
    en: "",
    sp: "",
  },
  31: {
    en: "",
    sp: "",
  },
  32: {
    en: "",
    sp: "",
  },
  33: {
    en: "",
    sp: "",
  },
  34: {
    en: "",
    sp: "",
  },
  35: {
    en: "",
    sp: "",
  },
  36: {
    en: "",
    sp: "",
  },
  37: {
    en: "",
    sp: "",
  },
  38: {
    en: "",
    sp: "",
  },
  39: {
    en: "",
    sp: "",
  },
  40: {
    en: "",
    sp: "",
  },
  41: {
    en: "",
    sp: "",
  },
  42: {
    en: "",
    sp: "",
  },
  43: {
    en: "",
    sp: "",
  },
};

const Contents = (props) => {
  const languageLookup = (idx) => {
    return lines[idx][props.language];
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>{languageLookup(0)}</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>{languageLookup(1)}</div>
        <div className={css.details}>{languageLookup(2)}</div>
      </div>
    </div>
  );
};

export default Contents;
