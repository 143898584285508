import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You matched the words whose connotations are most similar. Exhilaration
        and elation both have an especially strong positive connotation, whereas
        contentedness is merely positive. The word hot has a similar meaning as
        scorching, but its connotation is more neutral. Reveled and rejoiced
        both carry a strongly positive connotation, whereas the connotation of
        enjoyed isn't as strong.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = (
      <div className={css.text}>Please drag and drop your answer!</div>
    );
  } else if (props.data.left[0] == 0) {
    contents = (
      <div className={css.text}>
        Look again at your answer for the first box. The word{" "}
        <Italic>contentedness</Italic> has a similar meaning as{" "}
        <Italic>elation</Italic>, but the connotation is not quite the same.
        Look for a word whose positive connotation is even stronger. Try again.
      </div>
    );
  } else if (props.data.left[0] == 1) {
    contents = (
      <div className={css.text}>
        The word you put in the first box does not have the same meaning as{" "}
        <Italic>elation</Italic>. You need to find a word with a similar meaning
        and feeling. Try again.
      </div>
    );
  } else if (props.data.left[0] == 3) {
    contents = (
      <div className={css.text}>
        The word you put in the first box does not have the same meaning as{" "}
        <Italic>elation</Italic>. You need to find a word with a similar meaning
        and feeling. Try again.
      </div>
    );
  } else if (props.data.left[0] == 5) {
    contents = (
      <div className={css.text}>
        The word <Italic>enjoyed</Italic> has a positive connotation, but it is
        not as strong as <Italic>elation</Italic>. Additionally,{" "}
        <Italic>enjoyed</Italic> is a verb rather than a noun. Look for a noun
        that has a stronger, more positive connotation.
      </div>
    );
  } else if (props.data.left[1] == 2) {
    contents = (
      <div className={css.text}>
        The word you put in the second box does not have the same meaning as{" "}
        <Italic>scorching</Italic>. You need to find a word with a similar
        meaning and feeling. Try again.
      </div>
    );
  } else if (props.data.left[1] == 3) {
    contents = (
      <div className={css.text}>
        The word you put in the second box does not have the same meaning as{" "}
        <Italic>scorching</Italic>. You need to find a word with a similar
        meaning and feeling. Try again.
      </div>
    );
  } else if (props.data.left[2] == 1) {
    contents = (
      <div className={css.text}>
        The word you put in the third box does not have the same meaning as{" "}
        <Italic>reveled</Italic>. You need to find a word with a similar meaning
        and feeling. Try again.
      </div>
    );
  } else if (props.data.left[2] == 2) {
    contents = (
      <div className={css.text}>
        The word you put in the third box does not have the same meaning as{" "}
        <Italic>reveled</Italic>. You need to find a word with a similar meaning
        and feeling. Try again.
      </div>
    );
  } else if (props.data.left[1] == 4) {
    contents = (
      <div className={css.text}>
        Look again at your answer for the second box. If the word{" "}
        <Italic>scorching</Italic> were replaced with <Italic>hot</Italic> in
        paragraph 11, would the paragraph be as effective? What other word has a
        stronger, more negative connotation? Try again.
      </div>
    );
  } else if (props.data.left[2] == 5) {
    contents = (
      <div className={css.text}>
        Look again at your answer for the last box. If the word{" "}
        <Italic>reveled</Italic> were replaced with <Italic>enjoyed</Italic> in
        paragraph 11, would the paragraph be as effective? What other word has a
        stronger, more positive connotation? Try again.
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        Place the word <Italic>exhilaration</Italic> under{" "}
        <Italic>elation</Italic>; place the word <Italic>scalding</Italic> under{" "}
        <Italic>scorching</Italic>; and place the word <Italic>rejoiced</Italic>{" "}
        under <Italic>reveled</Italic>. Then move on to the next question.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
