import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FlipBox from "../../../../FlipBox";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Dividing Decimals</span>
      </div>
      <div className={css.row}>
        <div className={css.contents}>
          <span>In this lesson, you will ...</span>
          <BulletPoints>
            <li>Divide multi-digit decimals by whole numbers and decimals.</li>
            <li>
              Write a division equation using decimals to represent a real-world
              problem.
            </li>
            <li>
              Find the quotient of two decimals in the context of a real-world
              problem.
            </li>
          </BulletPoints>
        </div>
        <div className={css.right}>
          <Instructions>
            Click or tap the photo to find out about this record-breaking
            runner.
          </Instructions>
          <FlipBox backPadding="20px" width="400px" height="320px">
            <img src="https://d16qzots3do3wk.cloudfront.net/images/38ffd8cc72d15a17fb91dc13e4fd8a99.jpeg" />
            <div>
              A 4-minute mile is the completion of running 1 mile (5,280 feet)
              in four minutes or less. The first person to do this was Roger
              Bannister, at age 25, in 1954. His time for the mile was 3 minutes
              and 59.4 seconds. By dividing 5,280 feet by 239.4 (his time in
              seconds), it can be determined that Bannister ran just over 22
              feet per second!
            </div>
          </FlipBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
