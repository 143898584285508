import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        In paragraph 2, the boy thinks, “Life would be more fun if only I were a
        bird instead of me!”
      </div>
      <div className={css.title}>
        Which part of the illustration shows this?
      </div>
      <div className={css.instructions}>Click or tap the image.</div>
    </div>
  );
};

export default Sidebar;
