import React from "react";
import css from "./K2Lessons.module.css";
import { PromptOption } from "./PromptOption";

export const MultiPromptBox = ({
  options,
  selectedOptions,
  onChange,
  state,
}) => {
  const onClick = (value) => (event) => onChange(value);
  return (
    <div className={css.mcPromptBox}>
      {options.map((option, index) => (
        <React.Fragment>
          <PromptOption
            state={state}
            option={option}
            active={selectedOptions.indexOf(option) !== -1}
            onClick={onClick(option)}
          />
          {index === 2 && <div className={css.break} />}
        </React.Fragment>
      ))}
    </div>
  );
};
