import React, { useState } from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Icon from "../Components/Icon";

import Timeline from "../Assets/math_6_2_timeline.JPG";

const Contents = (props) => {
  const selectIconHandler = (slot, index) => {
    const data = {
      icons: [
        [...props.data.icons[0]],
        [...props.data.icons[1]],
        [...props.data.icons[2]],
        [...props.data.icons[3]],
        [...props.data.icons[4]],
        [...props.data.icons[5]],
        [...props.data.icons[6]],
        [...props.data.icons[7]],
        [...props.data.icons[8]],
      ],
    };

    if (data.icons[slot].filter((x) => x == index).length == 0)
      data.icons[slot].push(index);
    else data.icons[slot] = data.icons[slot].filter((x) => x != index);

    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.top}>
        <div className={css.instructions}>
          <div className={css.text}>
            Ramona listed the number of sheets of paper in each of 15 folders:
          </div>
          <div className={css.text}>
            5, 3, 9, 7, 5, 2, 6, 6, 4, 7, 9, 7, 3, 10, 6
          </div>
        </div>
      </div>
      <div className={css.header}>Number of Sheets of Paper</div>

      <div className={css.grid}>
        <div className={css.icon}>
          <Icon
            slot={0}
            index={4}
            selected={props.data.icons[0].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={1}
            index={4}
            selected={props.data.icons[1].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={2}
            index={4}
            selected={props.data.icons[2].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={3}
            index={4}
            selected={props.data.icons[3].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={4}
            index={4}
            selected={props.data.icons[4].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={5}
            index={4}
            selected={props.data.icons[5].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={6}
            index={4}
            selected={props.data.icons[6].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={7}
            index={4}
            selected={props.data.icons[7].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={8}
            index={4}
            selected={props.data.icons[8].filter((x) => x == 4).length == 1}
            onSelect={selectIconHandler}
          />
        </div>

        <div className={css.icon}>
          <Icon
            slot={0}
            index={3}
            selected={props.data.icons[0].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={1}
            index={3}
            selected={props.data.icons[1].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={2}
            index={3}
            selected={props.data.icons[2].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={3}
            index={3}
            selected={props.data.icons[3].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={4}
            index={3}
            selected={props.data.icons[4].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={5}
            index={3}
            selected={props.data.icons[5].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={6}
            index={3}
            selected={props.data.icons[6].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={7}
            index={3}
            selected={props.data.icons[7].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={8}
            index={3}
            selected={props.data.icons[8].filter((x) => x == 3).length == 1}
            onSelect={selectIconHandler}
          />
        </div>

        <div className={css.icon}>
          <Icon
            slot={0}
            index={2}
            selected={props.data.icons[0].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={1}
            index={2}
            selected={props.data.icons[1].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={2}
            index={2}
            selected={props.data.icons[2].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={3}
            index={2}
            selected={props.data.icons[3].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={4}
            index={2}
            selected={props.data.icons[4].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={5}
            index={2}
            selected={props.data.icons[5].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={6}
            index={2}
            selected={props.data.icons[6].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={7}
            index={2}
            selected={props.data.icons[7].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={8}
            index={2}
            selected={props.data.icons[8].filter((x) => x == 2).length == 1}
            onSelect={selectIconHandler}
          />
        </div>

        <div className={css.icon}>
          <Icon
            slot={0}
            index={1}
            selected={props.data.icons[0].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={1}
            index={1}
            selected={props.data.icons[1].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={2}
            index={1}
            selected={props.data.icons[2].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={3}
            index={1}
            selected={props.data.icons[3].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={4}
            index={1}
            selected={props.data.icons[4].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={5}
            index={1}
            selected={props.data.icons[5].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={6}
            index={1}
            selected={props.data.icons[6].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={7}
            index={1}
            selected={props.data.icons[7].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={8}
            index={1}
            selected={props.data.icons[8].filter((x) => x == 1).length == 1}
            onSelect={selectIconHandler}
          />
        </div>

        <div className={css.icon}>
          <Icon
            slot={0}
            index={0}
            selected={props.data.icons[0].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={1}
            index={0}
            selected={props.data.icons[1].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={2}
            index={0}
            selected={props.data.icons[2].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={3}
            index={0}
            selected={props.data.icons[3].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={4}
            index={0}
            selected={props.data.icons[4].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={5}
            index={0}
            selected={props.data.icons[5].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={6}
            index={0}
            selected={props.data.icons[6].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={7}
            index={0}
            selected={props.data.icons[7].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
        <div className={css.icon}>
          <Icon
            slot={8}
            index={0}
            selected={props.data.icons[8].filter((x) => x == 0).length == 1}
            onSelect={selectIconHandler}
          />
        </div>
      </div>

      <div className={css.bottom}>
        <img src={Timeline} alt="time" />
      </div>
      <Reset style={{ bottom: "5px", right: "10px" }} onReset={props.reset} />
    </div>
  );
};

export default Contents;
