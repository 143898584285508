import React from "react";
import Bold from "../../../../Bold";
import Italic from "../../../../Italic";

export default (data, setData) => {};
export const correctAnswers = [4, 1, -1, -1, -1, -1, -1, -1];

export const getDefaultState = () => {
  return {
    left: [-1, -1, -1, -1, -1, -1, -1, -1],
    showLeft: [false, false, false, false, false, false, false, false],
    right: [0, 1, 2, 3, 4, 5, 6, 7],
    showRight: [true, true, true, true, true, true, true, true],
  };
};

export const isEmpty = (data) => {
  return data.left.filter((x) => x > -1).length == 0;
};

export const isCorrect = (data) => {
  for (let i = 0; i < data.left.length; i++) {
    if (data.left[i] != correctAnswers[i]) return false;
  }
  return true;
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  <div>
    An idiom is a figurative expression that is so regularly used that it has
    become a common saying. The words in the idiom are not meant to be taken
    literally.
  </div>,
  <div>
    Not all of these expressions are figurative; some may be literal. Which one
    is a common, everyday saying not meant to be taken literally?
  </div>,
  <div>
    “He was a million miles away” is another example of an idiom in the story.
    Find another phrase like this one, which is a common saying that is meant
    figuratively.
  </div>,
];

export const showReader = () => {
  return true;
};
