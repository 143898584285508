import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Apply</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the passage. Look at the bolded word, and find context clues.
          Then answer the question.
        </div>
        <div className={css.title}>The Psychology of Catchy Songs</div>
        <div>
          Some songs are so catchy that people call them “earworms.” Once these
          memorable melodies and irresistible rhythms enter the listener’s
          brain, the music sticks around for a long time. Psychologists,
          scientists who study how the mind works, have identified some
          qualities about these songs that make them challenging to forget.
        </div>
        <div>
          Catchy songs have what their composers call a hook. Many songs include
          a lyrical hook, where words are repeated often. Songs may also have a
          melodic hook, where the tune repeats frequently. If someone hears a
          popular song multiple times a day, the song’s hook can become{" "}
          <Bold>encoded</Bold>, or brought into the memory system. After that,
          it can be stored in the person’s memory.{" "}
        </div>
        <div>
          Sometimes melodies and rhythms become encoded with physical actions.
          If a song, such as “We Will Rock You” by Queen, features rhythmic
          stomping and clapping, listeners will start to make these motions as
          soon as it is played.
        </div>
      </div>
    </div>
  );
};

export default Contents;
