import React, { Fragment } from "react";
import css from "./Done.module.css";

import { isCorrect, isEmpty } from "../Controller";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        Good job! You identified the cross-sections formed when slicing the
        pyramid with a plane parallel to the base, perpendicular to the base
        through the apex, and perpendicular to the base but not through the
        apex.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (
    props.data.left[0].find((x) => x.id == 2 || x.id == 5 || x.id == 6)
  ) {
    contents = (
      <Fragment>
        <div className={css.text}>
          The number of sides of a cross-section of a three-dimensional solid
          must be less than or equal to the number of faces of that
          three-dimensional solid. How many faces does this pyramid have?
        </div>
      </Fragment>
    );
  } else if (props.data.left[1].find((x) => x.id == 0 || x.id == 1)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          If you slice the pyramid perpendicular to its base through the apex,
          the cross-section will have the same shape as a face of the pyramid.
          What is the shape of a face?
        </div>
      </Fragment>
    );
  } else if (props.data.left[1].find((x) => x.id == 3)) {
    contents = (
      <Fragment>
        <div className={css.text}>
          What is the cross-section when you slice the pyramid perpendicular to
          its base but not through the apex?
        </div>
      </Fragment>
    );
  } else {
    contents = (
      <div
        className={css.text}
        style={{ lineHeight: "30px", marginTop: "-4px" }}
      >
        If you slice the pyramid parallel to its base, the cross-section will
        have the same shape as the base, which is a rectangle. If you slice the
        pyramid perpendicular to its base through the apex, the cross-section
        will have the same shape as a face, which is a triangle. If you slice
        the pyramid perpendicular to its base not through the apex, the
        cross-section will be a trapezoid.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
