import React from "react";
import css from "./Sidebar.module.css";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Choose the linear model for the data. Determine the initial memory used
        and describe how the memory used changes with each picture taken.
      </div>
      <div className={css.instructions}>
        Click or tap the correct term or equation from each drop-down menu. Type
        the missing number into the blank.
      </div>
    </div>
  );
};

export default Sidebar;
