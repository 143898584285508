import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Solve Word Problems with a Model</span>
      </div>
      <div className={css.contents}>
        <div>
          Three friends share a<Fraction numerator="1" denominator="4" /> -pound
          package of beads equally. What fraction of a pound of beads does each
          friend get?
        </div>
        <div>
          Divide
          <Fraction numerator="1" denominator="4" /> ÷ 3.
        </div>
        <div className={css.instructions}>Solve using the reciprocal.</div>
        <Instructions>Click or tap each step.</Instructions>
        <div className={css.row}>
          <FoldBox fontSize="16px" width="48%" height="50px">
            <div>
              <Bold>Step 1:</Bold> Multiply
              <Fraction numerator="1" denominator="4" /> by the reciprocal of 3.
            </div>
            <div>
              <div>
                The reciprocal of 3 is{" "}
                <Fraction inline numerator="1" denominator="3" />
              </div>
              <div style={{ height: "5px" }} />
              <Center>
                <Fraction inline numerator="1" denominator="4" /> ÷ 3 =
                <Fraction inline numerator="1" denominator="4" /> ÷
                <Fraction inline numerator="3" denominator="1" />
              </Center>
              <div style={{ height: "5px" }} />
              <Center>
                <div style={{ width: "43px" }} />=
                <Fraction inline width="20px" numerator="1" denominator="14" />
                <div style={{ width: "3px" }} /> x
                <Fraction inline numerator="1" denominator="3" />
              </Center>
              <div style={{ height: "5px" }} />
              <Center>
                <div style={{ width: "15px" }} />=
                <Fraction inline width="20px" numerator="1" denominator="12" />
              </Center>
            </div>
          </FoldBox>
          <FoldBox fontSize="16px" width="48%" height="50px">
            <div>
              <Bold>Step 2:</Bold> Check your answer.
            </div>
            <div style={{ lineHeight: "30px" }}>
              <div>Use multiplication.</div>
              <div>
                <Fraction inline numerator="1" denominator="4" /> ÷ 3 ={" "}
                <Fraction inline width="20px" numerator="1" denominator="12" />{" "}
                because
                <Fraction
                  inline
                  width="20px"
                  numerator="1"
                  denominator="12"
                />{" "}
                x 3 ={" "}
                <Fraction inline width="20px" numerator="3" denominator="12" />{" "}
                =<Fraction inline numerator="1" denominator="4" />.
              </div>
              <div>
                Each friend will get
                <Fraction
                  inline
                  width="20px"
                  numerator="1"
                  denominator="12"
                />{" "}
                pound of beads.
              </div>
            </div>
          </FoldBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
