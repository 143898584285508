import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Use a Model to Divide a Whole Number by a Unit Fraction
        </span>
      </div>
      <div className={css.contents}>
        <div>
          Divide 2 ÷ <Fraction numerator="1" denominator="3" />.
        </div>
        <div>You can use other methods to divide.</div>
        <Instructions>Click or tap each step.</Instructions>
        <FoldBox width="80%">
          <div>
            <Bold>ONE WAY:</Bold> Use a number line.
          </div>
          <div style={{ fontSize: "14px", lineHeight: "30px" }}>
            <img
              width="100%"
              src="https://d16qzots3do3wk.cloudfront.net/images/18c2b437f1dd198454f9c8e08b0b8a10.svg"
            />
            <div>Draw a number line from 0 to 2 .</div>
            <div>Skip count by thirds from 0 to 2 to divide.</div>
            <div>Count the jumps to find the quotient. There are 6 jumps.</div>
            <div>
              So, 2 ÷
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              = 6.
            </div>
          </div>
        </FoldBox>
        <FoldBox width="80%">
          <div>
            <Bold>ANOTHER WAY:</Bold> Multiply by the reciprocal.
          </div>
          <div style={{ fontSize: "14px", lineHeight: "30px" }}>
            <div>
              The reciprocal of
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              is
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="3"
                denominator="1"
              />{" "}
              or 3.
            </div>
            <div>
              Dividing 2 by
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />
              is the same as multiplying 2 by 3.
            </div>
            <div style={{ height: "5px" }} />
            <Center>
              2 ÷
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />{" "}
              =
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="2"
                denominator="1"
              />{" "}
              ÷
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="1"
                denominator="3"
              />
            </Center>
            <div style={{ height: "5px" }} />
            <Center>
              <div style={{ width: "43px" }} />=
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="2"
                denominator="1"
              />{" "}
              x
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="3"
                denominator="1"
              />
            </Center>
            <div style={{ height: "5px" }} />
            <Center>
              <div style={{ width: "15px" }} />=
              <Fraction
                verticalStyle={{ fontSize: "14px", marginTop: "-6px" }}
                numerator="6"
                denominator="1"
              />
            </Center>
            <Center>
              <div style={{ width: "7px" }} /> = 6
            </Center>
          </div>
        </FoldBox>
        <div style={{ height: "200px" }} />
      </div>
    </div>
  );
};

export default Contents;
