import React from "react";

import css from "./TutorialTipToggle.module.css";

const TutorialTipToggle = (props) => {
  const mouseDownHandler = () => {
    if (props.onToggle) props.onToggle();
  };

  return (
    <div style={props.style} className={css.root}>
      <div className={css.contents}>
        <input
          onMouseDown={mouseDownHandler}
          className={css.checkbox}
          type="checkbox"
          defaultChecked={props.selected}
        />
        <div>Show Tips</div>
      </div>
    </div>
  );
};

export default TutorialTipToggle;
