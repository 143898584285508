import React from "react";

import css from "./XButton.module.css";

const Button = (props) => {
  return (
    <div className={css["x-button"]} {...props}>
      X
    </div>
  );
};

export default Button;
