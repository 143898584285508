import React from "react";
import css from "./Reader.module.css";

import Audio from "../Icons/Audio";
import Close from "../Icons/Close";

const Reader = (props) => {
  return (
    <div className={css.root}>
      <div className={css.popup}>
        <div className={css.header}>
          <Audio />
          <Close onClick={() => props.onClose()} circle />
        </div>
        <div className={css.contents}>
          <div className={css.contents}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Reader;
