import React from "react";
import css from "./Done.module.css";

import { correctAnswers } from "../Controller";

import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  const arrows = [];

  // correct
  if (
    props.data.index == correctAnswers.index &&
    props.data.fraction[0] == correctAnswers.fraction[0] &&
    props.data.fraction[1] == correctAnswers.fraction[1] &&
    props.data.fraction[2] == correctAnswers.fraction[2]
  ) {
    contents = (
      <div className={css.text}>
        Nice work! The equation that represents the problem is{" "}
        <span style={{ fontStyle: "italic" }}>n</span> = 59 / 8. Carla pours{" "}
        <Fraction integer="7" numerator="3" denominator="8" /> fluid ounces of
        pink lemonade into each glass. Because 59 is between 56 and 64, the
        quotient should fall between 7 and 8. So,{" "}
        <Fraction integer="7" numerator="3" denominator="8" /> is a reasonable
        answer.
      </div>
    );
  }
  // empty
  else if (
    props.data.index == 0 ||
    props.data.fraction.filter((x) => x !== "").length == 0
  ) {
    contents = <div className={css.text}>Please select your answer!</div>;
  } else if (props.data.index == 1) {
    contents = (
      <div className={css.text}>
        The equation <span style={{ fontStyle: "italic" }}>n</span> = 8 ÷ 59
        would mean that 8 fluid ounces are divided among 59 glasses. Does this
        match the information given in the problem?{" "}
      </div>
    );
  } else {
    contents = (
      <div className={css.text}>
        Think about dividing 59 into 8 equal groups. The equation that
        represents the problem is <span style={{ fontStyle: "italic" }}>n</span>{" "}
        = 59 / 8. So, Carla pours{" "}
        <Fraction integer="7" numerator="3" denominator="8" /> fluid ounces of
        pink lemonade into each glass. Because 59 is between 56 and 64, the
        quotient of 59 / 8 should fall between 7 and 8. Thus,{" "}
        <Fraction integer="7" numerator="3" denominator="8" /> is a reasonable
        answer.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
