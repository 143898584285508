import React from "react";
import css from "./K2Lessons.module.css";

export const PopupPromptBox = ({ currentValue, onChange, options }) => {
  const onClick = (value) => (event) => onChange(value);
  return (
    <div className={css.popupPromptBox}>
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9727 7.50186L0.722656 14.4757L0.722656 0.528068L11.9727 7.50186Z"
          fill="#ffffff"
        ></path>
      </svg>

      {options.map((option) => (
        <div
          key={option}
          onClick={onClick(option)}
          className={css.popupPromptBoxOption}
        >
          {option}
        </div>
      ))}
    </div>
  );
};
