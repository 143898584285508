import React from "react";
import css from "./Sidebar.module.css";

import Shape from "../Components/Shape";

import Underline from "../../../../Underline";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <div className={css.title}>
        Which of the phrases in quotation marks is an idiom? What phrase gives a
        definition of the idiom?
      </div>
      <div className={css.instructions}>
        Drag the idiom to the "Idiom" box and the definition to the "Meaning"
        box. Click or tap the Reset button to start over.
      </div>
    </div>
  );
};

export default Sidebar;
