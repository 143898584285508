import React from "react";
import css from "./Done.module.css";

import { correctAnswers, isCorrect, isEmpty } from "../Controller";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Fraction from "../../../../Fraction";

const Done = (props) => {
  let contents;

  // correct
  if (isCorrect(props.data)) {
    contents = (
      <div className={css.text}>
        You got it! The first model shows{" "}
        <Fraction numerator="5" denominator="8" /> −{" "}
        <Fraction numerator="2" denominator="8" /> ={" "}
        <Fraction numerator="3" denominator="8" />. The second model shows{" "}
        <Fraction numerator="4" denominator="6" /> −{" "}
        <Fraction numerator="3" denominator="6" /> ={" "}
        <Fraction numerator="1" denominator="6" />. The third model shows{" "}
        <Fraction numerator="3" denominator="4" /> −{" "}
        <Fraction numerator="1" denominator="4" /> ={" "}
        <Fraction numerator="2" denominator="4" />.
      </div>
    );
  }
  // empty
  else if (isEmpty(props.data)) {
    contents = <div className={css.text}>Please select an answer!</div>;
  } else if (props.data.indices[0] == 1) {
    contents = (
      <div className={css.text}>
        Look at the first model. You know that there are 8 pieces altogether.
        What fraction of the model is shaded? This is the first fraction in the
        subtraction. The number of pieces crossed out shows the second fraction
        in the subtraction.
      </div>
    );
  } else if (props.data.indices[0] == 2) {
    contents = (
      <div className={css.text}>
        Look at the first model. There are 8 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 8.
      </div>
    );
  } else if (props.data.indices[0] == 3) {
    contents = (
      <div className={css.text}>
        Look at the first model. There are 8 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 8.
      </div>
    );
  } else if (props.data.indices[1] == 2) {
    contents = (
      <div className={css.text}>
        Look at the second model. You know that there are 6 pieces altogether.
        What fraction of the model is shaded? This is the first fraction in the
        subtraction. The number of pieces crossed out shows the second fraction
        in the subtraction.
      </div>
    );
  } else if (props.data.indices[1] == 3) {
    contents = (
      <div className={css.text}>
        Look at the second model. There are 6 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 6.
      </div>
    );
  } else if (props.data.indices[1] == 4) {
    contents = (
      <div className={css.text}>
        Look at the second model. There are 6 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 6.
      </div>
    );
  } else if (props.data.indices[2] == 1) {
    contents = (
      <div className={css.text}>
        Look at the third model. If you have 3 fourths and you subtract 1
        fourth, can the difference be counted in eighths?
      </div>
    );
  } else if (props.data.indices[2] == 2) {
    contents = (
      <div className={css.text}>
        Look at the third model. There are 4 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 4.
      </div>
    );
  } else if (props.data.indices[2] == 3) {
    contents = (
      <div className={css.text}>
        Look at the third model. There are 4 pieces in the model altogether, so
        the denominator in the subtraction sentence should be 4.
      </div>
    );
  }

  return <div className={css.root}>{contents}</div>;
};

export default Done;
