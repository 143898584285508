import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";
import FoldBox from "../../../../FoldBox";
import Fraction from "../../../../Fraction";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>
          Write an Equation in the Form <Italic>y</Italic> = <Italic>mx</Italic>{" "}
          + <Italic>b</Italic>
        </span>
      </div>
      <div className={css.contents}>
        <div>
          You can also find the slope of a line by calculating the ratio of the
          change of the <Italic>y</Italic>-values (rise) to the change of the{" "}
          <Italic>x</Italic>-values (run).
        </div>
        <Center>
          <span>
            <Italic>m</Italic> =
            <Fraction width="25px" numerator="rise" denominator="run" />
          </span>
        </Center>
        <Center>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/881a396b62a311ee9c53542b7635366a.svg"
            width="40%"
          />
        </Center>
        <div>
          Write the equation for the line shown on the coordinate plane.
        </div>
        <Instructions>
          Click or tap each step to learn how to write the equation of the line.
        </Instructions>
        <FoldBox>
          <div>
            <Bold>STEP 1:</Bold> Find the slope.
          </div>
          <div>
            <div>
              Count the change in <Italic>y</Italic>-values (rise) and the
              change in <Italic>x</Italic>-values (run) between two points on
              the line. From (2,1) , count up 3 units, and then count over 2
              units to get to (4,4).
            </div>
            <Center style={{ marginBottom: "20px" }}>
              <img
                src="https://d16qzots3do3wk.cloudfront.net/images/8ae758f100c3791000665d161a77817e.svg"
                width="45%"
              />
            </Center>
            <div>
              So, the slope is <Italic>m</Italic> =
              <Fraction width="40px" numerator="rise" denominator="run" /> =
              <Fraction numerator="3" denominator="2" />.
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>STEP 2:</Bold> Find the <Italic>y</Italic>-intercept.
          </div>
          <div>
            <div>
              The line crosses the y-axis at (0, −2). The <Italic>y</Italic>
              -intercept is −2.
            </div>
          </div>
        </FoldBox>
        <FoldBox>
          <div>
            <Bold>STEP 3:</Bold> Write an equation.
          </div>
          <div>
            <div>
              The slope is
              <Fraction numerator="3" denominator="2" />, and the{" "}
              <Italic>y</Italic>-intercept is −2. The equation of the line is:
            </div>
            <Center style={{ marginTop: "20px" }}>
              <span>
                <Italic>y</Italic> =<Fraction numerator="3" denominator="2" />
                <Italic>x</Italic> - 2
              </span>
            </Center>
          </div>
        </FoldBox>
      </div>
    </div>
  );
};

export default Contents;
