import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Divide Decimals to Solve Problems</span>
      </div>
      <div className={css.contents}>
        <div>
          Elizabeth has a piece of ribbon that is 4.5 meters long. She wants to
          cut it into pieces that are 0.25 meter long. How many pieces of ribbon
          will she have?
        </div>
        <Center style={{ marginTop: "20px" }}>
          <img
            src="https://d16qzots3do3wk.cloudfront.net/images/822ccb711f7f5e3641310d9c4ccd0e68.webp"
            width="60%"
          />
        </Center>
      </div>
    </div>
  );
};

export default Contents;
