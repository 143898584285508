import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Sentences That Lack Variety</span>
      </div>
      <div className={css.contents}>
        <div className={css.instructions}>
          Read the sentences. Then answer the question.
        </div>
        <div>
          Abby loves to draw in the park, and she enjoys sketching the dogs she
          meets. Sometimes she sits on a park bench, and other times she lies on
          the grass. A big dog barks at her, but it becomes friendly when she
          gives it treats. The dog sits patiently, and Abby creates a beautiful
          portrait.{" "}
        </div>
      </div>
    </div>
  );
};

export default Contents;
