import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import DropDown from "../../../../DropDown";
import Instructions from "../../../../Instructions";

const Contents = (props) => {
  const selectIndexHandler = (slot, index) => {
    const data = { indices: [...props.data.indices] };
    data.indices[slot] = index;
    props.setData(data);
  };

  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Take a Look: Shades of Meaning</span>
      </div>
      <div className={css.contents}>
        <div className={css.inst}>
          Connotation is also used to convey <Bold>shades of meaning</Bold>.
          Just like colors have shades, words can have subtle differences in
          meaning. Think about the words <Italic>giggle</Italic>,{" "}
          <Italic>chuckle</Italic>, and <Italic>guffaw</Italic>. They all mean
          “to laugh,” but each one probably gives you a slightly different image
          in your mind.
        </div>
        <div className={css.instructions}>
          Read the sentences. Consider shades of meaning as you choose the word
          that BEST fits in each sentence.
        </div>
        <Instructions>
          Click or tap the correct choice from each drop-down menu.
        </Instructions>
        <div className={css.row}>
          <span>Xavier went out for a refreshing early-morning</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[0] - 1}
              selectIndex={(index) => selectIndexHandler(0, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "sprint" }, { text: "jog" }, { text: "gallop" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>to start his day.</span>
        </div>
        <div className={css.row}>
          <span>Kelsey had to</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[1] - 1}
              selectIndex={(index) => selectIndexHandler(1, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "sprint" }, { text: "jog" }, { text: "gallop" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>to school after sleeping through her alarm.</span>
        </div>
        <div className={css.row}>
          <span>Ian watched the wild horses</span>
          <div className={css.dropdown}>
            <DropDown
              width="200px"
              selectedIndex={props.data.indices[2] - 1}
              selectIndex={(index) => selectIndexHandler(2, index + 1)}
              defaultText={
                <div>
                  <Italic>Choose</Italic>
                </div>
              }
              data={[{ text: "sprint" }, { text: "jog" }, { text: "gallop" }]}
              /*rolloverTipTitle="Choose Subject"
                        rolloverTip="Choose english or math."*/
            />
          </div>
          <span>across the open field.</span>
        </div>
        <div style={{ height: "100px" }} />
      </div>
    </div>
  );
};

export default Contents;
