import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import FoldBox from "../../../../FoldBox";
import Center from "../../../../Center";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Insert Zeros in the Dividend</span>
      </div>
      <div className={css.contents}>
        <div>
          <div>
            Sometimes when dividing decimals, there will be a remainder.
          </div>
          <div>For example, what is 29.5 ÷ 4?</div>
        </div>
        <Instructions>Click or tap each step to divide.</Instructions>
        <div className={css.row}>
          <FoldBox width="48%">
            <div>
              <Bold>Step 1:</Bold> Divide. Notice the remainder of 3.
            </div>
            <Center>
              <img
                width="40%"
                src="https://d16qzots3do3wk.cloudfront.net/images/fc8af5722df902f2eee30cf9f5963715.svg"
              />
            </Center>
          </FoldBox>
          <FoldBox width="48%">
            <div>
              <Bold>Step 2:</Bold> Place zeros in the dividend and continue
              dividing until there is no longer a remainder.
            </div>
            <div>
              <Center>
                <img
                  width="40%"
                  src="https://d16qzots3do3wk.cloudfront.net/images/a464cef9d9996b8bcfd8276894046317.svg"
                />
              </Center>
              <Center>29.5 ÷ 4 = 7.375</Center>
            </div>
          </FoldBox>
        </div>
      </div>
    </div>
  );
};

export default Contents;
