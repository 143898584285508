import React from "react";
import css from "./Contents.module.css";

import Reset from "../../../../Icons/Reset";

import Audio from "../../../../Icons/Audio";
import Italic from "../../../../Italic";
import Bold from "../../../../Bold";
import BulletPoints from "../../../../BulletPoints";
import Instructions from "../../../../Instructions";
import BlockQuote from "../../../../BlockQuote";

const Contents = (props) => {
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Audio />
        <span className={css.title}>Learn More about Compound Sentences</span>
      </div>
      <div className={css.contents}>
        <div>
          The coordinating conjunction you use depends on what you want to say.
        </div>
        <div>
          <span>Let’s look at these examples.</span>
          <BulletPoints>
            <li>
              Use <Italic>and</Italic> to join two similar ideas:
              <BlockQuote>
                We love to pick apples, <Instructions span>and</Instructions> we
                love to pick oranges.
              </BlockQuote>
            </li>
            <li>
              Use <Italic>but</Italic> to join two different ideas:
              <BlockQuote>
                We love to pick apples, <Instructions span>but</Instructions>{" "}
                it’s raining outside.
              </BlockQuote>
            </li>
            <li>
              Use <Italic>or</Italic> to join two possible ideas:
              <BlockQuote>
                We could make applesauce, <Instructions span>or</Instructions>{" "}
                we could bake pies.
              </BlockQuote>
            </li>
            <li>
              Use <Italic>so</Italic> when the second idea happened because of
              the first.
              <BlockQuote>
                We picked dozens of apples, <Instructions span>so</Instructions>{" "}
                we made five pies.
              </BlockQuote>
            </li>
          </BulletPoints>
        </div>
      </div>
    </div>
  );
};

export default Contents;
