import React from "react";
import css from "./Sidebar.module.css";

import Underline from "../../../../Underline";
import Instructions from "../../../../Instructions";
import CheckBox from "../../../../CheckBox";

const Sidebar = (props) => {
  return (
    <div className={css.root}>
      <img
        width="100%"
        src="https://d16qzots3do3wk.cloudfront.net/images/a3698eb75fe3d6dab6cf577077422e06.svg"
      />
    </div>
  );
};

export default Sidebar;
