export default (data, setData) => {};

export const correctAnswers = [[1, 2], [0, 3], []];

export const isCorrect = (data) => {
  if (data.left[0].length != correctAnswers[0].length) return false;

  if (data.left[1].length != correctAnswers[1].length) return false;

  if (data.left[2].length != correctAnswers[2].length) return false;

  for (let i = 0; i < correctAnswers.length; i++) {
    for (let j = 0; j < correctAnswers[i].length; j++) {
      const answer = correctAnswers[i][j];
      if (!data.left[i].find((x) => x.id == answer)) return false;
    }
  }

  return true;
};

export const isEmpty = (data) => {
  return data.right.filter((x) => x == -1).length == 0;
};

export const getDefaultState = () => {
  return {
    left: [[], [], []],
    right: [0, 1, 2, 3],
    showRight: [true, true, true, true],
  };
};

export const getFeedbackType = (data, setData) => {
  if (isEmpty(data)) return "empty";
  else if (isCorrect(data)) return "correct";
  else return "incorrect";
};

export const showAudioButton = (data, setData) => {
  return !isEmpty(data);
};

export const getTitle = (data, setData) => {
  if (!isEmpty(data)) return "";

  if (isCorrect(data)) return "CORRECT";
  else return "TAKE ANOTHER LOOK";
};

export const showClosed = (Data, setData) => {
  return true;
};

export const hints = [
  "The denominator of a fraction tells the number of equal parts.",
  "The numerator tells the number of those equal parts that are being considered. Here, this is the number of shaded parts.",
  "The top number in a fraction is the numerator.",
  "The bottom number in a fraction is the denominator. ",
];

export const showReader = () => {
  return false;
};
