import React from "react";
import css from "./K2Lessons.module.css";

export const PromptOption = ({ active, option, onClick, state }) => {
  const classes = [css.promptBoxOption];
  if (active) {
    switch (state) {
      case "display":
        if (option.correct) {
          classes.push(css.promptBoxOptionCorrect);
        } else {
          classes.push(css.promptBoxOptionIncorrect);
        }
        break;
      default:
        classes.push(css.promptBoxOptionActive);
    }
  }

  const renderOption = () => {
    if (option.img) {
      return <img src={option.img} alt={option.text} />;
    }
    return option.text;
  };

  return (
    <div key={option} onClick={onClick} className={classes.join(" ")}>
      {renderOption()}
    </div>
  );
};
